import { useEffect, useDispatch, useMemo } from 'hooks';
import { getAdminInfo } from 'store/actions';
import { selectAdminPermissions } from 'store/reducers/app';
import { adminPermissionStatuses } from 'common/enums/app';
import { useSelector } from 'react-redux';

const useAdminPermissions = ({ init } = {}) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectAdminPermissions);

  useEffect(() => {
    if (init) {
      dispatch(getAdminInfo());
    }
  }, [dispatch, init]);

  const hasAdminRole = useMemo(
    () => permissions?.some((permission) => permission?.position === adminPermissionStatuses.ADMIN.POSITION_ID),
    [permissions]
  );

  return { hasAdminRole };
};

export default useAdminPermissions;
