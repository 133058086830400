import styled from 'styled-components';
import { iconButtonVariants } from 'common/enums/buttons';
import { icons } from 'common/enums/icons';

export const StyledIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size, width }) => width ?? size}px;
  width: ${({ size, width }) => width ?? size}px;
  height: ${({ size, height }) => height ?? size}px;
  border-radius: ${({ square, text }) => {
    if (square) return '4px';
    return text ? '30px' : '50%';
  }};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  div svg {
    transform: translateX(
      ${({ iconName }) =>
        iconName === icons.ARROW_FILL_LEFT ? '-25%' : iconName === icons.ARROW_FILL_RIGHT ? '25%' : '0'}
    );
  }

  ${({ variant, theme, disabled }) => {
    switch (variant) {
      case iconButtonVariants.PRIMARY:
        return `
        background: ${disabled ? theme.colors.ICON_BTN : theme.colors.ICON_BTN_PRIMARY};
        &:hover:not([disabled]) {
          background: ${theme.colors.ICON_BTN_PRIMARY_HOVER};
        }
        div svg path {
          fill: ${theme.colors.WHITE};
        }
      `;
      default:
        return `
        &:hover:not([disabled]) {
          background: ${theme.colors.ICON_BTN_HOVER};
        }
        div svg path {
          fill: ${disabled ? theme.colors.DARK_GREY : theme.colors.DISABLED_ICON};
        }
        background: ${theme.colors.ICON_BTN};
        color: ${disabled ? theme.colors.DISABLED_DEFAULT_ICON_BUTTON : theme.colors.DEFAULT_ICON_BUTTON};
        ${!theme.darkMode && `border: 1px solid ${theme.colors.LIGHT_GRAY};`}

      `;
    }
  }}
  ${({ text }) =>
    text &&
    `font-weight: 400;
  font-size: 12px;
  line-height: 1.33`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ noFill }) =>
    noFill &&
    `div svg path {
    fill: unset;
  }`}
`;
export const IconButtonText = styled.div`
  font-weight: 400;
  font-size: 12px;
  ${({ bigText }) =>
    bigText &&
    `font-weight: 400;
font-size: 14px;
`}
  ${({ iconName }) => iconName && 'margin: 0 0 0 6px;'}
`;
