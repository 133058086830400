import React from 'react';

const IconDownload = ({ size = 12, color = '#272727' }) => (
  <svg width={size} height={(size * 13) / 12} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M8.62541 4.90484L6.51228 7.14523V0.543179C6.51228 0.24319 6.28291 0 5.99996 0C5.71701 0 5.48763 0.24319 5.48763 0.543179L5.48763 7.14514L3.37459 4.90484C3.17452 4.69272 2.85013 4.69272 2.65005 4.90484C2.44998 5.11697 2.44998 5.46089 2.65005 5.67301L5.63115 8.83364C5.72393 8.93563 5.85514 8.99979 5.99996 8.99979C6.14323 8.99979 6.27324 8.93698 6.36583 8.83684L9.34994 5.67301C9.55002 5.46089 9.55002 5.11697 9.34994 4.90484C9.14987 4.69272 8.82548 4.69272 8.62541 4.90484ZM1 9.99979C1 9.72365 0.776142 9.49979 0.5 9.49979C0.223858 9.49979 0 9.72365 0 9.99979C0 11.3805 1.11929 12.4998 2.5 12.4998H9.5C10.8807 12.4998 12 11.3805 12 9.99979C12 9.72365 11.7761 9.49979 11.5 9.49979C11.2239 9.49979 11 9.72365 11 9.99979C11 10.8282 10.3284 11.4998 9.5 11.4998H2.5C1.67157 11.4998 1 10.8282 1 9.99979Z"
    />
  </svg>
);

export default IconDownload;
