import { sidebarMenuItems, topNavigationTabs } from 'common/enums/navigation';

export const findMenuItem = ({ type, pathname }) => {
  const menuItems = Object.values(sidebarMenuItems).filter(({ ROUTE }) => ROUTE === pathname);
  const matchedMenuItem = menuItems.find(({ TYPE }) => TYPE === type);
  const defaultMenuItem = menuItems.find(({ IS_DEFAULT }) => IS_DEFAULT);
  return matchedMenuItem || defaultMenuItem;
};

export const findNavigationTab = ({ menuItemType, tabId }) => {
  const navigationTabs = topNavigationTabs[menuItemType];
  if (!navigationTabs) {
    return null;
  }

  const defaultTab = navigationTabs?.DEFAULT;
  if (!tabId) {
    return defaultTab;
  }

  const currentTab = Object.values(navigationTabs)?.find((tab) => tab.TYPE === tabId);

  return currentTab || defaultTab;
};

export const isHOCohortMenuItem = (menuItem) =>
  [
    sidebarMenuItems.HO_COHORT_FIRST_TIME.TYPE,
    sidebarMenuItems.HO_COHORT_ACTIVATED.TYPE,
    sidebarMenuItems.HO_COHORT_REPEAT.TYPE,
    sidebarMenuItems.HO_COHORT_SUPER.TYPE
  ].includes(menuItem);
