import { useCallback, useEffect, useRouter, useMemo, useSelector } from 'hooks';
import { apiPageSizes } from 'common/enums/queryParams';

const useSiblingsDetailsPagination = ({
  pageSize = apiPageSizes.DEFAULT,
  page,
  route,
  selectInstanceId,
  selectList,
  handleLoadMore,
  hasLoadMoreButton,
  totalCount,
  siblingIdName,
  isPayment
} = {}) => {
  const {
    navigate,
    location: { pathname, state: locationState }
  } = useRouter();
  const instanceId = useSelector(selectInstanceId);
  const list = useSelector(selectList);

  const findSiblings = () => {
    if (!instanceId) {
      return {
        currentIndex: null,
        globalIndex: 0,
        nextId: null,
        prevId: null
      };
    }
    let currentIndex;
    if (isPayment) {
      currentIndex = list.findIndex((listInstance) => listInstance?.id === locationState?.payment?.id) ?? null;
    } else {
      currentIndex = list.findIndex((listInstance) => listInstance[siblingIdName] === instanceId) ?? null;
    }

    const prevPagesItemsCount = hasLoadMoreButton ? 0 : (page - 1) * pageSize;
    const globalIndex = prevPagesItemsCount + currentIndex;
    return {
      currentIndex,
      globalIndex: globalIndex + 1,
      nextId: list[currentIndex + 1]?.[siblingIdName] ?? null,
      prevId: list[currentIndex - 1]?.[siblingIdName] ?? null
    };
  };

  const { globalIndex, prevId, nextId, currentIndex } = findSiblings();

  // handle load more for user's job lists
  useEffect(() => {
    // we can't use instance id because it can cause bugs
    // open the last item => go back => open not last item =>
    // previous last item remains in store and triggers load more action
    const [, , rootId, , nestedId] = pathname.split('/');

    if (!hasLoadMoreButton || (!nestedId && !rootId)) {
      return;
    }

    if (list[list.length - 1]?.[siblingIdName] === Number(nestedId || rootId) && page * pageSize < totalCount) {
      handleLoadMore();
    }
  }, [handleLoadMore, hasLoadMoreButton, list, page, pageSize, totalCount, pathname, siblingIdName]);

  const handleNavigateSibling = useCallback(
    (id, next) => {
      if (!id) {
        return;
      }

      const [, rootPath, rootId, nestedPath] = pathname.split('/');
      const navigateRoute = `${nestedPath ? `/${rootPath}/${rootId}` : ''}${route}`;
      const payment = isPayment ? { payment: list[next ? currentIndex + 1 : currentIndex - 1] } : {};
      navigate(`${navigateRoute}/${id}`, {
        replace: true,
        ...(locationState
          ? {
              state: {
                ...locationState,
                ...payment
              }
            }
          : {})
      });
    },
    [locationState, navigate, pathname, route]
  );

  return useMemo(
    () => ({
      currentIndex: globalIndex,
      nextId,
      onNavigateSibling: handleNavigateSibling,
      prevId
    }),
    [globalIndex, handleNavigateSibling, nextId, prevId]
  );
};

export default useSiblingsDetailsPagination;
