import React from 'react';

import { Label, Span } from './styled';

const Switch = ({ checked, onChange, size, BGUnchecked }) => {
  return (
    <Label checked={checked} onClick={() => onChange(!checked)} size={size} BGUnchecked={BGUnchecked}>
      <Span checked={checked} size={size} />
    </Label>
  );
};

Switch.defaultProps = {
  onChange: () => {},
  checked: false,
  size: 20
};

export default Switch;
