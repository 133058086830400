import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { StyledButton } from './styled';
import { buttonVariants } from 'common/enums/buttons';
import { icons } from 'common/enums/icons';
import { LoadingSpinner } from 'components';

const Button = ({
  onClick,
  children,
  disabled,
  variant,
  width,
  height,
  style,
  iconName,
  iconWidth,
  iconHeight,
  iconSize,
  iconColor,
  backgroundIconSize,
  title,
  loading,
  smallSize,
  margin,
  ...props
}) => (
  <StyledButton
    smallSize={smallSize}
    margin={margin}
    onClick={onClick}
    disabled={disabled || loading}
    variant={variant}
    width={width}
    height={height}
    style={style}
    {...props}>
    {loading ? (
      <LoadingSpinner />
    ) : (
      <>
        {iconName && (
          <Icon
            name={iconName}
            width={iconWidth}
            height={iconHeight}
            size={iconSize}
            color={iconColor}
            backgroundSize={backgroundIconSize}
          />
        )}
        {title || children}
      </>
    )}
  </StyledButton>
);

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(buttonVariants)),
  disabled: PropTypes.bool,
  iconName: PropTypes.oneOf(Object.values(icons))
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default Button;
