import React, { Component } from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { Icon, LoadingSpinner, Dropdown, IconButton } from 'components';
import styles from 'constants/styles';

import { remainderHours } from 'constants/content';

import {
  NoteFile,
  NoteFileName,
  NoteFormContainer,
  NoteFormInputSection,
  NoteInput,
  AttachmentLabel,
  NoteFormActions,
  SubmitNoteButton,
  CloseNoteForm,
  ReminderWrapper
} from './styled';
import { StyledTextarea } from '../../components/HookForm/Textarea/styled';

class NoteForm extends Component {
  defaultState = {
    description: this.props.noteDescription || '',
    attachedFiles: [],
    selectedReminderCounter: this.props.reminderCounter || 0
  };
  state = this.defaultState;

  componentDidUpdate(prevProps) {
    const { updateNoteLoading, editMode } = this.props;
    if (editMode && updateNoteLoading !== prevProps.updateNoteLoading && !updateNoteLoading) {
      this.setState(this.defaultState);
      this.props.hideForm();
    }
  }

  handleAttachedFiles = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const files = Array.from(e.target.files);

    const { attachedFiles } = this.state;
    this.setState({ attachedFiles: [...attachedFiles, ...files] });
  };

  removeAttachedFile = (index) => {
    const { attachedFiles } = this.state;
    this.setState({
      attachedFiles: [...attachedFiles.slice(0, index), ...attachedFiles.slice(index + 1)]
    });
  };

  submitNoteForm = (e) => {
    const { serviceId, userId, createNote, updateNote, noteId } = this.props;
    const { description, attachedFiles, selectedReminderCounter } = this.state;
    if (noteId) {
      updateNote({
        id: noteId,
        ...(serviceId && { service_request_id: serviceId }),
        ...(userId && { user_id: userId }),
        description,
        reminder_counter: selectedReminderCounter
      });
    } else {
      const createdAt = moment().toISOString().split('.')[0];
      createNote({
        id: uuidv4(),
        ...(serviceId && { service_request_id: serviceId }),
        ...(userId && { user_id: userId }),
        date_created: createdAt,
        description,
        attachedFiles,
        reminder_counter: selectedReminderCounter
      });
      this.setState(this.defaultState);
      this.props.hideForm();
    }
  };

  onRemainderHourChange = (value) => {
    this.setState({ selectedReminderCounter: value });
  };

  render() {
    const { hideForm, editMode, updateNoteLoading } = this.props;
    const { attachedFiles, description, selectedReminderCounter } = this.state;
    return (
      <NoteFormContainer>
        <NoteFormInputSection>
          <StyledTextarea
            value={description}
            rows="4"
            onChange={(e) => this.setState({ description: e.target.value })}
          />
          {attachedFiles.map((file, index) => (
            <NoteFile key={index} clickable={false}>
              <Icon name="file" size={17} />
              <NoteFileName>{file.name}</NoteFileName>

              <IconButton iconName="close2" iconSize={8} size={16} onClick={() => this.removeAttachedFile(index)} />
            </NoteFile>
          ))}
        </NoteFormInputSection>
        <CloseNoteForm onClick={hideForm}>
          <Icon name="closeOutlined2" />
        </CloseNoteForm>
        <NoteFormActions>
          <NoteFormActions>
            {editMode ? null : (
              <AttachmentLabel>
                <input type="file" multiple style={{ display: 'none' }} onChange={this.handleAttachedFiles} />
                <Icon name="attachment" size={18} color={styles.colors.GREY} />
                <span>Attach Media</span>
              </AttachmentLabel>
            )}
            <ReminderWrapper>
              <Icon name="time" size={18} />
              <Dropdown
                value={
                  selectedReminderCounter
                    ? {
                        id: selectedReminderCounter,
                        name: `${selectedReminderCounter}h`
                      }
                    : { id: null }
                }
                valuePlaceholder="Set Reminder"
                withPlaceholderPrefix
                valueColor={styles.colors.GRAY}
                placeholder="Remaind me in"
                options={remainderHours}
                onChange={this.onRemainderHourChange}
              />
            </ReminderWrapper>
          </NoteFormActions>

          <IconButton
            onClick={this.submitNoteForm}
            square
            width="83"
            height="24"
            disabled={updateNoteLoading || (!attachedFiles.length && !description.length)}>
            {editMode ? updateNoteLoading ? <LoadingSpinner color="light" /> : 'Save Note' : 'Add Note'}
          </IconButton>
        </NoteFormActions>
      </NoteFormContainer>
    );
  }
}

export default NoteForm;
