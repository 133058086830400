import React from 'react';
import routes from 'constants/routes';

import { Wrapper, Info, UserId, AdditionalInfo, Name } from './styled';
import { formatPhone } from 'utils';

const Referral = ({ referral }) => {
  const {
    id,
    target_user: { id: userId, full_name, phone_number, address }
  } = referral;
  const handleHomeownerNavigate = () => {
    window.open(`${routes.CUSTOMER}/${userId}`, '_blank');
  };

  return (
    <Wrapper onClick={handleHomeownerNavigate} key={id}>
      <Info>
        {full_name && <Name>{full_name}</Name>}
        {phone_number && <AdditionalInfo> {formatPhone(phone_number)}</AdditionalInfo>}
        {address && <AdditionalInfo> {address}</AdditionalInfo>}
      </Info>
      <UserId>User ID {userId}</UserId>
    </Wrapper>
  );
};

export default Referral;
