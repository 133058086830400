import styled from 'styled-components';
import styles from 'constants/styles';

export const DataRecord = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  flex: ${({ flex }) => flex || 1};
`;

export const DataRow = styled.div`
  display: flex;
  margin: ${({ marginTop }) => marginTop || 25}px 0 ${({ marginBottom }) => marginBottom || 0}px;
  label span {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: text-top;
  }
`;

export const InfoSectionHeader = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  margin-bottom: 5px;
`;

export const FieldTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  ${({ height }) => height && `height: ${height}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ alignItems }) =>
    alignItems &&
    `
    display: flex;
    align-items: center;
  `};

  ${({ withoutMargin }) => !withoutMargin && 'margin-bottom: 5px'};

  padding: 0 18px 0 0;
  white-space: break-spaces;
`;

export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  ${({ width }) => width && `width: ${width}%`};
  ${({ flex }) =>
    flex &&
    `
    display: flex;
    flex-wrap: wrap;
  `}
  a {
    color: ${styles.colors.DARK_BLUE};
    cursor: pointer;
    word-break: break-word;
    &:hover {
      color: ${styles.colors.DARK_GREEN};
    }
  }
  padding: ${({ padding }) => padding ?? '0 48px 0 0'};

  span {
    color: ${({ isHighlighted }) => (isHighlighted ? styles.colors.RED : styles.colors.BLACK)};
  }
`;

export const AnalyticFieldValue = styled(FieldValue)`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme, color }) => color || theme.colors.INVOICE_TEXT};
`;
