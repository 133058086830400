import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'hooks';
import {
  closeModal,
  deleteCognitoUser,
  openModal,
  updateUserInstance,
  updateCustomerInstance,
  // getRewards,
  getProviderAreas,
  getCustomerDobbyCash
} from 'store/actions';
import { selectUpdateUserIsActivateResult, selectDeleteCognitoUserResult } from 'store/reducers/user';
import { selectCustomerTagsLoading } from 'store/reducers/customer';
// import { selectRewards } from 'store/reducers/customer';
import { selectProviderAreaList, selectProviderAreaListLoading } from 'store/reducers/provider';
import {
  // isRewardsLoading,
  selectUpdateUserActivationLoading,
  selectDeleteCognitoUserLoading,
  selectMarkUserAsTeamMemberLoading,
  selectMarkIsCreateJobEnabled,
  selectMarkIsRemindPayment,
  selectMarkUserAsTestLoading
} from 'store/reducers/loading';

import {
  Checkbox,
  ModalConfirmation,
  LoadingSpinner,
  DropdownMenu,
  IconButton,
  // Tooltip,
  // Reward,
  Line,
  UserName
  // Icon
} from 'components';
// import { DeleteReward } from 'modals/customer';

import { getCRMLinkByGroupId, getFullName, checkProviderAccess, mapFieldsToProviderCohort } from 'common/helpers/user';
import { formatPhone, getDaysCountFromDate, getHumanizedDuration, formatters } from 'utils';
// Constants
import StripeRoutes from 'common/constants/routes';
import { deviceNotificationStatus } from 'common/enums/app';
import { homeownerTags, userGroups, platforms, oneClickActions } from 'common/enums/user';
import dates from 'constants/dates';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';

import { AvatarLightbox, DobbyCashBalance, DobbyCashTable } from './containers';
import { prepareInitialValues } from './helpers/form';
import { getAppNameByGroupId } from './helpers/user';
import {
  // AddReward,
  ProviderAnalytics,
  ProviderAreaCategory,
  ProviderPositiveReviews
} from './components';

import { DataRecord, DataRow, FieldTitle, FieldValue } from './commonStyled';
import {
  AnalyticFieldTitle,
  AnalyticFieldValue,
  ActivateButton,
  // AvailableRewards,
  AvatarRow,
  BoldTextWrapper,
  // RemoveIcon,
  UserCheckbox,
  FullWidthSection,
  InfoSectionHeader,
  ModalText,
  LatestVersionMark,
  // RewardWrapper,
  ReportFileName,
  // RewardsRow,
  // RewardsWrapper,
  Section,
  UserEmail,
  UserFullNameRow,
  UserPhone,
  MainInfo,
  ProLabel,
  lineStyle,
  // rewardStyle,
  userFullNameStyle
} from './styled';
import { ChargesTable } from './containers/ChargesTable/ChargesTable';

const UserDetails = ({
  user,
  user: {
    id,
    given_name,
    family_name,
    preferred_name,
    phone_number,
    email,
    date_login,
    date_created,
    subscription,
    is_active,
    is_pre_filled,
    is_report_uploaded,
    inspection_original_name,
    profile_image_thumbnail,
    profile_image_original,
    team,
    company_name,
    stripe_account_id,
    about,
    amount_total_earned,
    amount_average_earned,
    category,
    primary_category,
    secondary_category,
    count_jobs_assigned,
    count_jobs_accepted,
    count_jobs_ongoing,
    count_jobs_scheduled,
    count_jobs_completed,
    count_jobs_unfulfilled,
    days_since_last_completed,
    external_author_1,
    external_author_2,
    external_author_3,
    external_review_1,
    external_review_2,
    external_review_3,
    website_link,
    additional_user_id,
    personal_logo_media_url,
    pro_stage,
    is_test,
    is_team_member,
    lead_source_user,
    lead_source,
    is_api_active,
    is_cognito_active,
    referral_code,
    service_total_spent,
    lifetime_margin,
    lifetime_margin_percent,
    service_count,
    service_ongoing,
    service_unfulfilled,
    service_completed,
    service_last_date_create,
    service_area_list,
    percent_positive_reviews,
    percent_completed,
    percent_completed_per_period,
    percent_scheduled,
    provider_skills,
    count_referral_jobs,
    social_profile,
    planning_mode_link,
    home_listing,
    is_parked,
    is_core,
    stage,
    auto_assign_service_area_list,
    auto_assign_service_area,
    auto_assign_service_id,
    one_click_service_area_list,
    one_click_service_area,
    one_click_service_id
  },
  isCreateJobEnabled,
  isRemindPaymentEnabled,
  location,
  deleteCognitoUser,
  // getRewards,
  deviceData,
  onUploadReport,
  onOpenReport,
  openModal,
  closeModal,
  updateUserInstance,
  updateUserIsActivateResult,
  userGroupId = userGroups.CUSTOMER.TYPE,
  // rewards,
  // rewardsLoading,
  getProviderAreas,
  serviceAreaList,
  serviceAreaListLoading,
  updateIsActiveUserLoading,
  updateIsTestUserLoading,
  updateIsTeamMemberLoading,
  deleteCognitoUserLoading,
  deleteCognitoUserResult
}) => {
  const dispatch = useDispatch();
  const updateIsCreateJobEnabledLoading = useSelector(selectMarkIsCreateJobEnabled);
  const updateIsRemindPaymentLoading = useSelector(selectMarkIsRemindPayment);
  const customerTagsLoading = useSelector(selectCustomerTagsLoading);
  const [confirmDeletionStep, setConfirmDeletionStep] = useState('first');
  const isAgent = userGroupId === userGroups.AGENT.TYPE;
  const isCustomer = userGroupId === userGroups.CUSTOMER.TYPE;
  const isProvider = userGroupId === userGroups.PROVIDER.TYPE;

  useEffect(() => {
    // getRewards({ user_id: id });
    if (isProvider) {
      getProviderAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appName = getAppNameByGroupId(userGroupId);

  const openCallModal = () => openModal(modalTypes.TWILIO_VOICE, { userID: id });

  const openToggleUserIsActivate = () => openModal(modalTypes.USER_TOGGLE_IS_ACTIVATE);

  const openCreateTaskModal = () => openModal(modalTypes.TASK, { userDetails: user, userSelectDisabled: true });

  const onToggleIsActivateResultClose = () => closeModal(modalTypes.USER_TOGGLE_IS_ACTIVATE_RESULT);

  const resetConfirmDeletionStep = () => {
    if (confirmDeletionStep !== 'first') {
      setConfirmDeletionStep('first');
    }
  };

  const openDeleteModal = () => {
    resetConfirmDeletionStep();
    openModal(modalTypes.DELETE_COGNITO_USER);
  };

  const onDeleteModalClose = () => {
    resetConfirmDeletionStep();
    closeModal(modalTypes.DELETE_COGNITO_USER);
  };

  const onDeleteResultModalClose = () => closeModal(modalTypes.DELETE_COGNITO_USER_RESULT);

  const isUserActive = Boolean(is_active && is_api_active);

  const openEditModal = () => {
    if (serviceAreaListLoading) {
      return;
    }
    openModal(modalTypes.EDIT_USER, {
      initialValues: prepareInitialValues({ initialValues, isProvider, isCustomer, serviceAreaList }),
      additional_user_id,
      is_active: isUserActive,
      is_api_active,
      is_cognito_active,
      is_pre_filled,
      id,
      location_id,
      onToggleUserIsActive: () => openToggleUserIsActivate(),
      onOpenDeleteUserModal: () => openDeleteModal(),
      userGroupId
    });
  };

  const addToTroubled = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        updateAction: oneClickActions.IS_TROUBLED,
        data: {
          stage: homeownerTags.TROUBLED_MANUAL.TYPE,
          is_auto_stage: false
        }
      })
    );
  };
  const removeFromTroubled = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        updateAction: oneClickActions.REMOVE_IS_TROUBLED,
        data: {
          stage: null, // empty stage => auto assign stage on server
          is_auto_stage: true
        }
      })
    );
  };
  const addToNonICP = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        updateAction: oneClickActions.IS_NON_ICP,
        data: {
          stage: homeownerTags.NON_ICP.TYPE,
          is_auto_stage: false
        }
      })
    );
  };
  const removeFromNonICP = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        updateAction: oneClickActions.REMOVE_IS_NON_ICP,
        data: {
          stage: null, // empty stage => auto assign stage on server
          is_auto_stage: true
        }
      })
    );
  };

  const activateProfile = () =>
    updateUserInstance({
      id,
      is_active: !is_active || !is_api_active,
      is_api_active: !is_active || !is_api_active,
      userGroupId,
      updateAction: oneClickActions.IS_ACTIVE
    });

  const markProfileAsTest = () =>
    updateUserInstance({
      id,
      is_test: !is_test,
      userGroupId,
      updateAction: oneClickActions.IS_TEST
    });

  const markProfileAsTeamMember = () =>
    updateUserInstance({
      id,
      is_team_member: !is_team_member,
      userGroupId,
      updateAction: oneClickActions.IS_TEAM_MEMBER
    });

  const markCustomerJobCreationEnabled = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        data: {
          id,
          is_create_job_enabled: !isCreateJobEnabled
        },
        updateAction: oneClickActions.IS_CREATE_JOB_ENABLED
      })
    );
  };

  const changePaymentDueReminder = () => {
    dispatch(
      updateCustomerInstance({
        skipNestedUpdate: true,
        userId: id,
        data: {
          id,
          is_remind_payment: !isRemindPaymentEnabled
        },
        updateAction: oneClickActions.IS_REMIND_PAYMENT
      })
    );
  };

  const { id: location_id, estimate_value, year_built, square, unit_number, address } = location || {};
  const customerSoldDate = location?.sold_date ? new Date(location.sold_date).getFullYear() : null;

  const additionalInfo = isProvider
    ? {
        pro_cohort: mapFieldsToProviderCohort({ is_core, is_parked }),
        about,
        auto_assign_service_area,
        auto_assign_service_id,
        category,
        primary_category,
        secondary_category,
        preferred_name,
        logo_media: null,
        one_click_service_area,
        one_click_service_id,
        pro_stage,
        service_area_list,
        // Extra fields for external review
        external_author_1,
        external_author_2,
        external_author_3,
        external_review_1,
        external_review_2,
        external_review_3,
        provider_skills
      }
    : isAgent
    ? {
        about,
        company_name,
        team,
        website_link
      }
    : isCustomer
    ? {
        lead_source,
        estimate_value,
        year_built,
        sold_date: customerSoldDate,
        square,
        unit_number,
        planning_mode_link
      }
    : {};

  // TODO: (unassigned) handle reset after modal close via change object
  const initialValues = {
    email,
    family_name,
    given_name,
    social_profile,
    home_listing,
    media: null,
    ...additionalInfo
  };
  const actionOptions = [
    {
      id: 1,
      name: 'Create Job',
      onClick: () => openCreateTaskModal()
    },
    {
      id: 2,
      name: isUserActive ? 'Block Account' : 'Activate Account',
      onClick: () => openToggleUserIsActivate()
    },
    {
      id: 3,
      name: 'Remove Account',
      onClick: () => openDeleteModal()
    },
    ...(![homeownerTags.TROUBLED_MANUAL.TYPE].includes(stage) && isCustomer
      ? [
          {
            id: 4,
            name: 'Add to Sara Todd',
            onClick: addToTroubled,
            isLoading: customerTagsLoading[homeownerTags.TROUBLED_MANUAL.TYPE]
          }
        ]
      : []),
    ...(![homeownerTags.NON_ICP.TYPE].includes(stage) && isCustomer
      ? [
          {
            id: 6,
            name: 'Add to Non-ICP',
            onClick: addToNonICP,
            isLoading: customerTagsLoading[homeownerTags.TROUBLED_MANUAL.TYPE]
          }
        ]
      : []),
    ...([homeownerTags.TROUBLED_MANUAL.TYPE].includes(stage) && isCustomer
      ? [
          {
            id: 5,
            name: 'Remove from Sara Todd',
            onClick: removeFromTroubled,
            isLoading: customerTagsLoading[homeownerTags.NON_ICP.TYPE]
          }
        ]
      : []),
    ...([homeownerTags.NON_ICP.TYPE].includes(stage) && isCustomer
      ? [
          {
            id: 7,
            name: 'Remove from Non-ICP',
            onClick: removeFromNonICP,
            isLoading: customerTagsLoading[homeownerTags.NON_ICP.TYPE]
          }
        ]
      : [])
  ];

  useEffect(() => {
    dispatch(getCustomerDobbyCash(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenderLeftIcon = () => {
    if (!isProvider) {
      return null;
    }

    return <ProviderPositiveReviews percent_positive_reviews={percent_positive_reviews} />;
  };

  const { hasAutoAreaCategoryDisabled } = checkProviderAccess({
    is_pre_filled,
    is_api_active,
    is_active,
    is_cognito_active,
    pro_cohort: additionalInfo.pro_cohort,
    pro_stage: additionalInfo.pro_stage
  });

  return (
    <>
      <Section>
        <AvatarRow>
          <AvatarLightbox
            profile_image_original={profile_image_original}
            profile_image_thumbnail={profile_image_thumbnail}
            is_cognito_active={is_cognito_active}
            is_api_active={is_api_active}
            is_active={is_active}
            is_pre_filled={is_pre_filled}
            id={id}
            onRenderLeftIcon={handleRenderLeftIcon}
          />
          <DataRecord>
            <UserFullNameRow>
              <UserName
                stage={stage}
                pro_stage={pro_stage}
                given_name={given_name}
                family_name={`${family_name?.trim()} ${preferred_name?.trim() ? ` - "${preferred_name.trim()}"` : ''}`}
                id={id}
                textStyle={userFullNameStyle}
              />
              {is_parked && <ProLabel>PARKED</ProLabel>}
              {is_core && <ProLabel isCore>CORE</ProLabel>}
              <IconButton iconName="call" onClick={openCallModal} />
            </UserFullNameRow>
            <UserPhone>{phone_number ? formatPhone(phone_number) : ''}</UserPhone>
            <UserEmail underlineText={Boolean(email)} marginBottom={!isUserActive ? 8 : 0}>
              {Boolean(email) ? email : '-'}
            </UserEmail>
            {!isUserActive && !is_pre_filled && (isProvider || isAgent) ? (
              <ActivateButton onClick={activateProfile} disabled={updateIsActiveUserLoading}>
                {updateIsActiveUserLoading ? <LoadingSpinner color="dark" /> : 'Activate'}
              </ActivateButton>
            ) : null}
          </DataRecord>
          <DataRecord flex="0 1" flexDirection="row-reverse">
            {isCustomer && !is_pre_filled && (
              <DropdownMenu dropdownWidth={200} options={actionOptions} placeholder="Actions" margin="0" />
            )}
            <IconButton iconName="edit" iconSize={14} margin="0 8px 0 0" onClick={openEditModal} />
          </DataRecord>
        </AvatarRow>
        <DataRow marginTop={13}>
          <Checkbox
            onChange={() => markProfileAsTest()}
            checked={is_test}
            green
            smallSize
            label="Test account"
            disabled={updateIsTestUserLoading}
          />
          {updateIsTestUserLoading && <LoadingSpinner color="dark" />}
          <UserCheckbox>
            <Checkbox
              onChange={() => markProfileAsTeamMember()}
              checked={is_team_member}
              green
              smallSize
              label="Team member account"
              disabled={updateIsTeamMemberLoading}
            />
            {updateIsTeamMemberLoading && <LoadingSpinner color="dark" />}
          </UserCheckbox>
          {isCustomer ? (
            <UserCheckbox>
              <Checkbox
                onChange={() => markCustomerJobCreationEnabled()}
                checked={isCreateJobEnabled}
                green
                smallSize
                label="Create jobs when payment due"
                disabled={updateIsCreateJobEnabledLoading}
              />
              {updateIsCreateJobEnabledLoading && <LoadingSpinner color="dark" />}
            </UserCheckbox>
          ) : null}
        </DataRow>
        {isCustomer && (
          <DataRow>
            <Checkbox
              onChange={() => changePaymentDueReminder()}
              checked={!isRemindPaymentEnabled}
              green
              smallSize
              label="Don't send payment/quote reminders"
              disabled={updateIsRemindPaymentLoading}
            />
            {updateIsRemindPaymentLoading && <LoadingSpinner color="dark" />}
          </DataRow>
        )}
        <FullWidthSection>
          {isCustomer && (
            <>
              <DataRow>
                <DataRecord flex="3 1 0">
                  <FieldTitle>Home Listing</FieldTitle>
                  <FieldValue>
                    {Boolean(home_listing) ? (
                      <a href={home_listing} rel="noopener noreferrer" target="_blank">
                        {home_listing}
                      </a>
                    ) : (
                      '-'
                    )}
                  </FieldValue>
                </DataRecord>

                <DataRecord flex={5}>
                  <FieldTitle>Public Profile</FieldTitle>
                  <FieldValue>
                    {Boolean(social_profile) ? (
                      <a href={social_profile} rel="noopener noreferrer" target="_blank">
                        {social_profile}
                      </a>
                    ) : (
                      '-'
                    )}
                  </FieldValue>
                </DataRecord>

                <DataRecord flex={5}>
                  <FieldTitle>Planning Mode Link</FieldTitle>
                  <FieldValue>
                    {Boolean(planning_mode_link) ? (
                      <a href={planning_mode_link} rel="noopener noreferrer" target="_blank">
                        {planning_mode_link}
                      </a>
                    ) : (
                      '-'
                    )}
                  </FieldValue>
                </DataRecord>
              </DataRow>
              <DataRow>
                <DataRecord flex="3 1 0">
                  <FieldTitle alignItems>Address</FieldTitle>
                  <FieldValue>{address || '-'}</FieldValue>
                  {unit_number ? <FieldValue>unit #{unit_number}</FieldValue> : null}
                </DataRecord>
                <DataRecord>
                  <FieldTitle>Sold price</FieldTitle>
                  <FieldValue padding="0 18px 0 0">
                    {estimate_value ? `$${formatters.commaSeparated(estimate_value)}` : '-'}
                  </FieldValue>
                </DataRecord>
                <DataRecord>
                  <FieldTitle>Year Built</FieldTitle>
                  <FieldValue padding="0 18px 0 0">{year_built || '-'}</FieldValue>
                </DataRecord>
                <DataRecord>
                  <FieldTitle>SQFT</FieldTitle>
                  <FieldValue padding="0 18px 0 0">{square ? `${formatters.commaSeparated(square)}` : '-'}</FieldValue>
                </DataRecord>
                <DataRecord>
                  <FieldTitle padding="0px">Year Purchased</FieldTitle>
                  <FieldValue>{customerSoldDate || '-'}</FieldValue>
                </DataRecord>
                <DataRecord />
              </DataRow>
            </>
          )}
          {isAgent ? (
            <DataRow>
              <DataRecord>
                <FieldTitle>Brokerage</FieldTitle>
                <FieldValue>{company_name || '-'}</FieldValue>
              </DataRecord>
              <DataRecord>
                <FieldTitle>Team</FieldTitle>
                <FieldValue>{team || '-'}</FieldValue>
              </DataRecord>
            </DataRow>
          ) : null}
          {isProvider && (
            <DataRow>
              <DataRecord>
                <FieldTitle>Stripe Profile</FieldTitle>
                <FieldValue>
                  {Boolean(stripe_account_id) ? (
                    <a
                      href={`${StripeRoutes.ROOT}${StripeRoutes.CONNECT_ACCOUNT}${user.stripe_account_id}`}
                      rel="noopener noreferrer"
                      target="_blank">
                      {`${StripeRoutes.ROOT}${StripeRoutes.CONNECT_ACCOUNT}${user.stripe_account_id}`}
                    </a>
                  ) : (
                    '-'
                  )}
                </FieldValue>
              </DataRecord>
            </DataRow>
          )}
          <DataRow>
            <DataRecord flex={isCustomer ? '3 1 0' : 1}>
              <FieldTitle>Referral Link</FieldTitle>
              <FieldValue>
                {Boolean(referral_code) ? (
                  <a
                    href={`https://www.hellodobby.com/dobby_app/${referral_code}`}
                    rel="noopener noreferrer"
                    target="_blank">
                    {`https://www.hellodobby.com/dobby_app/${referral_code}`}
                  </a>
                ) : (
                  '-'
                )}
              </FieldValue>
            </DataRecord>
            <DataRecord>
              <FieldTitle>Invited By</FieldTitle>
              <FieldValue>
                {Boolean(lead_source_user?.id) ? (
                  <NavLink to={getCRMLinkByGroupId(lead_source_user)} rel="noopener noreferrer" target="_blank">
                    {getFullName(lead_source_user)}
                  </NavLink>
                ) : Boolean(lead_source) ? (
                  lead_source
                ) : (
                  '-'
                )}
              </FieldValue>
            </DataRecord>
            {isCustomer && (
              <DataRecord>
                <FieldTitle>Subscription</FieldTitle>
                <FieldValue>{subscription ? subscription : '-'}</FieldValue>
              </DataRecord>
            )}
            {isProvider && <DataRecord />}
            {isCustomer && <DataRecord flex={3} />}
          </DataRow>
          {isCustomer ? (
            <>
              <DataRow marginBottom={25}>
                <DataRecord>
                  <FieldTitle alignItems>
                    Property Report
                    <IconButton
                      square
                      bigText
                      margin="0 0 0 8px"
                      height={24}
                      width={112}
                      onClick={onUploadReport}
                      text="Upload report"
                    />
                  </FieldTitle>
                  <FieldValue>
                    {is_report_uploaded ? (
                      <ReportFileName onClick={onOpenReport}>
                        {inspection_original_name || 'Home Inspection Report.pdf'}
                      </ReportFileName>
                    ) : (
                      '-'
                    )}
                  </FieldValue>
                </DataRecord>
              </DataRow>

              <DobbyCashBalance user_id={id} />
              <DobbyCashTable userId={id} />
              {/* <RewardsWrapper>
                <AvailableRewards>Available Rewards</AvailableRewards>
                <RewardsRow>
                  {Boolean(rewards?.length && !rewardsLoading)
                    ? rewards.map((reward, index) => (
                        <RewardWrapper key={`${reward?.promo_code_id}-${index}`}>
                          <RemoveIcon
                            onClick={() => {
                              openModal(modalTypes.DELETE_REWARD, reward);
                            }}>
                            <Icon name="closeFill2" size={16} color={theme.colors.DARK_GREY_3} />
                          </RemoveIcon>
                          <Tooltip
                            clickable
                            left={15}
                            maxWidth={250}
                            title={reward?.description}
                            wrappedComponent={<Reward promocodeId={reward?.promo_code_id} rewardStyle={rewardStyle} />}
                          />
                        </RewardWrapper>
                      ))
                    : null}
                  <DeleteReward user={user} />
                  <AddReward userId={id} />
                </RewardsRow>
              </RewardsWrapper> */}
            </>
          ) : null}
          {isProvider && (
            <ProviderAreaCategory
              service_area_list={service_area_list}
              category={category}
              primary_category={primary_category}
              secondary_category={secondary_category}
              provider_skills={provider_skills}
              personal_logo_media_url={personal_logo_media_url}
              auto_assign_service_area_list={auto_assign_service_area_list}
              auto_assign_service_id={auto_assign_service_id}
              one_click_service_area_list={one_click_service_area_list}
              one_click_service_id={one_click_service_id}
              pro_stage={pro_stage}
              is_parked={is_parked}
            />
          )}
          {isProvider && (
            <DataRow>
              <DataRecord>
                <FieldTitle>Bio</FieldTitle>
                <FieldValue>{about || '-'}</FieldValue>
              </DataRecord>
            </DataRow>
          )}

          {(isProvider || isAgent) && <Line lineStyle={lineStyle} />}

          {isAgent && (
            <>
              <DobbyCashBalance user_id={id} isAgent />
              <DobbyCashTable userId={id} isAgent />
              <ChargesTable userId={id} />
              <DataRow>
                <DataRecord>
                  <FieldTitle width={85}>Number days since Sign Up</FieldTitle>
                  <FieldValue>{Boolean(date_created) ? getDaysCountFromDate(date_created) : '-'}</FieldValue>
                </DataRecord>
                <DataRecord>
                  <FieldTitle width={100}>Number days since Last Activity</FieldTitle>
                  <FieldValue>{Boolean(date_login) ? getDaysCountFromDate(date_login) : '-'}</FieldValue>
                </DataRecord>
                <DataRecord></DataRecord>
                <DataRecord></DataRecord>
              </DataRow>
            </>
          )}
          {isProvider && (
            <ProviderAnalytics
              date_login={date_login}
              date_created={date_created}
              amount_total_earned={amount_total_earned}
              amount_average_earned={amount_average_earned}
              count_jobs_assigned={count_jobs_assigned}
              count_jobs_accepted={count_jobs_accepted}
              count_jobs_ongoing={count_jobs_ongoing}
              count_jobs_scheduled={count_jobs_scheduled}
              count_jobs_completed={count_jobs_completed}
              count_jobs_unfulfilled={count_jobs_unfulfilled}
              lifetime_margin={lifetime_margin}
              lifetime_margin_percent={lifetime_margin_percent}
              percent_completed={percent_completed}
              percent_completed_per_period={percent_completed_per_period}
              percent_scheduled={percent_scheduled}
              is_parked={is_parked}
              hasAutoAreaCategoryDisabled={hasAutoAreaCategoryDisabled}
            />
          )}
        </FullWidthSection>
        {isCustomer && (
          <FullWidthSection>
            <MainInfo>
              <div>
                <InfoSectionHeader>Lifetime Spend</InfoSectionHeader>
                <AnalyticFieldValue color={styles.colors.DARK_GREEN}>
                  {service_total_spent ? `${formatters.dollarCurrencyDB(service_total_spent || 0)}` : '-'}
                </AnalyticFieldValue>
              </div>
              <div>
                <InfoSectionHeader>Lifetime Margin</InfoSectionHeader>
                <AnalyticFieldValue color={styles.colors.DARK_GREEN}>
                  {lifetime_margin
                    ? `${formatters.dollarCurrencyDB(lifetime_margin || 0)} (${lifetime_margin_percent}%)`
                    : '-'}
                </AnalyticFieldValue>
              </div>
            </MainInfo>
            <DataRow>
              <DataRecord>
                <AnalyticFieldTitle>Number of Requested Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>{service_count || '-'}</AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle>Number of Ongoing Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>{service_ongoing || '-'}</AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle>Number of Completed Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>{service_completed || '-'}</AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle>Number of Unfulfilled Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>{service_unfulfilled || '-'}</AnalyticFieldValue>
              </DataRecord>
            </DataRow>
            <DataRow>
              <DataRecord>
                <AnalyticFieldTitle width={100}>Number days since last Requested Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>
                  {service_last_date_create ? getDaysCountFromDate(service_last_date_create) : '-'}
                </AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle width={100}>Number days since last Completed Maintenances</AnalyticFieldTitle>
                <AnalyticFieldValue>
                  {days_since_last_completed || days_since_last_completed === 0 ? days_since_last_completed : '-'}
                </AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle width={60}>
                  Number days since {is_pre_filled ? 'Invitation' : 'Sign Up'}
                </AnalyticFieldTitle>
                <AnalyticFieldValue>
                  {Boolean(date_created) ? getDaysCountFromDate(date_created) : '-'}
                </AnalyticFieldValue>
              </DataRecord>
              <DataRecord>
                <AnalyticFieldTitle>Last Activity Date</AnalyticFieldTitle>
                {Boolean(date_login) ? (
                  <FieldValue smallSize isHighlighted={getDaysCountFromDate(date_login) >= 30}>
                    {formatters.dateWithTimezone(date_login, dates.DATE)}
                    {',\n'}
                    <div>{getHumanizedDuration(date_login)}</div>
                  </FieldValue>
                ) : (
                  <FieldValue>-</FieldValue>
                )}
              </DataRecord>
            </DataRow>
          </FullWidthSection>
        )}
        <FullWidthSection>
          <DataRow marginBottom={18}>
            <DataRecord>
              <FieldTitle>Platform Version</FieldTitle>
              <FieldValue width={80} smallSize flex>
                <div>
                  {deviceData && deviceData.platform_type
                    ? `${Object.values(platforms).find(({ TYPE }) => TYPE === deviceData.platform_type)?.NAME || ''} ${
                        deviceData.platform_version ? deviceData.platform_version : ''
                      }`
                    : '-'}{' '}
                </div>
                {deviceData && deviceData.platform_type ? (
                  <LatestVersionMark smallSize isLatest={deviceData.is_platform_latest}>
                    {`(${deviceData.is_platform_latest ? 'Latest' : 'Not Latest'})`}
                  </LatestVersionMark>
                ) : null}
              </FieldValue>
              {Boolean(deviceData?.model) && (
                <FieldValue width={80} flex>
                  {deviceData.model}
                </FieldValue>
              )}
            </DataRecord>
            <DataRecord>
              <FieldTitle>App Version</FieldTitle>
              <FieldValue width={80} flex smallSize>
                <div>
                  {deviceData && deviceData.platform_type ? (
                    <>
                      {(deviceData.app_version && appName) || ''}{' '}
                      <LatestVersionMark isLatest={deviceData.is_app_latest}>
                        {`(${deviceData.is_app_latest ? 'Latest' : 'Not Latest'})`}
                      </LatestVersionMark>
                      <div>
                        {deviceData.app_version || ''}
                        {Boolean(deviceData.app_build_number) && ` (#${deviceData.app_build_number})`}
                      </div>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </FieldValue>
              {deviceData?.installer_package_name === 'TestFlight' && (
                <FieldValue smallSize width={80} flex>
                  <div>{deviceData.installer_package_name}</div>
                </FieldValue>
              )}
            </DataRecord>
            <DataRecord>
              <FieldTitle>Notifications</FieldTitle>
              <FieldValue flex>
                <FieldValue smallSize>
                  {Object.values(deviceNotificationStatus).find(
                    (item) => item.TYPE === deviceData?.device_notification_status
                  )?.NAME || '-'}
                </FieldValue>
              </FieldValue>
            </DataRecord>
            <DataRecord>
              <AnalyticFieldTitle width={80}>Number of jobs completed from referrals</AnalyticFieldTitle>
              <AnalyticFieldValue>{count_referral_jobs || '-'}</AnalyticFieldValue>
            </DataRecord>
          </DataRow>
          <DataRow>
            <DataRecord>
              <FieldTitle width={80}>Registration Date</FieldTitle>
              <FieldValue maxWidth="150px">
                {Boolean(date_created)
                  ? formatters.dateWithTimezone(date_created, dates.DATETIME_FULL_YEAR_SECONDS)
                  : '-'}
              </FieldValue>
            </DataRecord>
          </DataRow>
        </FullWidthSection>
      </Section>
      <ModalConfirmation
        modalType={modalTypes.USER_TOGGLE_IS_ACTIVATE}
        smallPaddings={false}
        title={`${isUserActive ? 'Block' : 'Activate'} ${getFullName({
          given_name,
          family_name,
          id
        })} account?`}
        text={`This user will ${isUserActive ? 'lose' : 'get'} access to the ${appName} app`}
        hideModal={() => closeModal(modalTypes.USER_TOGGLE_IS_ACTIVATE)}
        primaryButton={{
          title: 'Cancel',
          onClick: () => closeModal(modalTypes.USER_TOGGLE_IS_ACTIVATE),
          disabled: updateIsActiveUserLoading
        }}
        secondaryButton={{
          title: isUserActive ? 'Block' : 'Activate',
          onClick: activateProfile,
          disabled: updateIsActiveUserLoading,
          loading: updateIsActiveUserLoading
        }}
      />
      <ModalConfirmation
        modalType={modalTypes.USER_TOGGLE_IS_ACTIVATE_RESULT}
        smallPaddings={false}
        title={
          updateUserIsActivateResult === 'success'
            ? `${getFullName({
                given_name,
                family_name,
                id
              })} has successfully ${isUserActive ? 'activated' : 'blocked'}`
            : 'Something went wrong. Please try again later'
        }
        hideModal={onToggleIsActivateResultClose}
        secondaryButton={{
          title: 'Ok',
          onClick: onToggleIsActivateResultClose
        }}
      />
      <ModalConfirmation
        maxWidth={null}
        modalType={modalTypes.DELETE_COGNITO_USER}
        smallPaddings={false}
        title={`${confirmDeletionStep === 'first' ? 'Remove User' : 'Are you sure'}?`}
        text={
          <ModalText>
            Phone number <BoldTextWrapper>{phone_number ? formatPhone(phone_number) : ''} </BoldTextWrapper>
            will lose access to the {appName} app.{'\n'}
            Current profile ID <BoldTextWrapper>({additional_user_id}) </BoldTextWrapper>
            will be archived in the CRM list.
          </ModalText>
        }
        hideModal={() => closeModal(modalTypes.DELETE_COGNITO_USER)}
        primaryButton={{
          title: 'Cancel',
          onClick: onDeleteModalClose,
          disabled: deleteCognitoUserLoading
        }}
        secondaryButton={{
          title: confirmDeletionStep === 'first' ? 'Confirm' : 'Ok',
          onClick: () => {
            if (confirmDeletionStep === 'first') {
              setConfirmDeletionStep('second');
            } else {
              deleteCognitoUser({ id: additional_user_id, userGroupId });
            }
          },
          disabled: deleteCognitoUserLoading,
          loading: deleteCognitoUserLoading
        }}
      />
      <ModalConfirmation
        maxWidth={null}
        modalType={modalTypes.DELETE_COGNITO_USER_RESULT}
        smallPaddings={false}
        title={deleteCognitoUserResult === 'success' ? 'Success' : 'Error'}
        text={
          deleteCognitoUserResult === 'success' ? (
            <ModalText>
              Phone number <BoldTextWrapper>{phone_number ? formatPhone(phone_number) : ''} </BoldTextWrapper>
              has been removed from the {appName} app.
            </ModalText>
          ) : (
            'Something went wrong. Please try again later'
          )
        }
        hideModal={onDeleteResultModalClose}
        secondaryButton={{
          title: 'OK',
          onClick: onDeleteResultModalClose
        }}
      />
    </>
  );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  userGroupId: PropTypes.number.isRequired,
  onUploadReport: PropTypes.func,
  onOpenReport: PropTypes.func
};

const mapStateToProps = (state) => ({
  deleteCognitoUserLoading: selectDeleteCognitoUserLoading(state),
  deleteCognitoUserResult: selectDeleteCognitoUserResult(state),
  updateUserIsActivateResult: selectUpdateUserIsActivateResult(state),
  updateIsActiveUserLoading: selectUpdateUserActivationLoading(state),
  updateIsTestUserLoading: selectMarkUserAsTestLoading(state),
  updateIsTeamMemberLoading: selectMarkUserAsTeamMemberLoading(state),
  // rewards: selectRewards(state),
  // rewardsLoading: isRewardsLoading(state),
  serviceAreaList: selectProviderAreaList(state),
  serviceAreaListLoading: selectProviderAreaListLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  deleteCognitoUser,
  openModal,
  updateUserInstance,
  // getRewards,
  getProviderAreas
};

UserDetails.defaultProps = {
  user: {},
  rewards: []
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
