import React from 'react';

const IconClose = props => {
  const { size = 20, color = '#253757' } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M0.927592 8.36823C0.732388 8.56355 0.732481 8.88014 0.927802 9.07534C1.12312 9.27054 1.4397 9.27045 1.63491 9.07513L5.00065 5.70739L8.36654 9.07527C8.56174 9.27059 8.87833 9.27069 9.07365 9.07548C9.26896 8.88028 9.26906 8.5637 9.07385 8.36838L5.70755 5.00007L9.07385 1.63177C9.26906 1.43645 9.26896 1.11987 9.07364 0.924662C8.87832 0.729458 8.56174 0.729552 8.36654 0.924872L5.00065 4.29276L1.63491 0.925016C1.4397 0.729696 1.12312 0.729602 0.927802 0.924806C0.732481 1.12001 0.732388 1.43659 0.927592 1.63191L4.29375 5.00007L0.927592 8.36823Z"
      />
    </svg>
  );
};

export default IconClose;
