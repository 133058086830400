import React from 'react';

import { isDayDifferent, formatDate } from './helpers/dates';

import { MessageDayContainer, MessageDayWrapper, MessageDayText } from './styled';

const MessageDay = ({ message = {}, prevMessage = {} }) => {
  const isVisible = isDayDifferent(message.createdAt, prevMessage.createdAt);
  if (!isVisible) {
    return null;
  }

  return (
    <MessageDayContainer>
      <MessageDayWrapper>
        <MessageDayText>{formatDate(message.createdAt)}</MessageDayText>
      </MessageDayWrapper>
    </MessageDayContainer>
  );
};

export default MessageDay;
