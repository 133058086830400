import styled from 'styled-components';
import styles from 'constants/styles';

export const DataRecord = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  flex: ${({ flex }) => flex ?? 1};
  ${({ maxWidth, width }) => (maxWidth || width) && `max-width: ${maxWidth || width}px`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ width }) =>
    width &&
    `width: ${width}px;
  min-width: ${width}px`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
`;

export const DataRow = styled.div`
  display: flex;
  margin: ${({ marginTop }) => marginTop || 25}px 0 ${({ marginBottom }) => marginBottom || 0}px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ width }) => width && `width: ${width}px`};
  label span {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: text-top;
  }
`;

export const FieldTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  ${({ height }) => height && `height: ${height}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ alignItems }) =>
    alignItems &&
    `
    display: flex;
    align-items: center;
  `};

  ${({ withoutMargin }) => !withoutMargin && 'margin-bottom: 5px'};

  padding: ${({ padding }) => padding || '0 24px 0 0'};
  white-space: break-spaces;
`;

export const FieldValue = styled.div`
  font-weight: 400;
  font-size: ${({ smallSize }) => (smallSize ? 12 : 14)}px;
  line-height: 1.14;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  ${({ wrap }) => wrap && 'flex-wrap: wrap'};

  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  ${({ width }) => width && `width: ${width}%`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ flex }) =>
    flex &&
    `
    display: flex;
    flex-wrap: wrap;
  `}
  a {
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
    cursor: pointer;
    word-break: break-word;
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
  padding: ${({ padding }) => padding ?? '0 24px 0 0'};

  span {
    color: ${({ isHighlighted }) => (isHighlighted ? styles.colors.DARK_GREEN : styles.colors.INVOICE_TEXT)};
  }
`;
