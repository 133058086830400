import React from 'react';

const IconMicrophone = ({ size = 48 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#2C3537" />
      <path
        d="M24 26C25.66 26 27 24.66 27 23V17C27 15.34 25.66 14 24 14C22.34 14 21 15.34 21 17V23C21 24.66 22.34 26 24 26ZM23 17C23 16.45 23.45 16 24 16C24.55 16 25 16.45 25 17V23C25 23.55 24.55 24 24 24C23.45 24 23 23.55 23 23V17Z"
        fill="#F7F7F7"
      />
      <path
        d="M29 23C29 25.76 26.76 28 24 28C21.24 28 19 25.76 19 23H17C17 26.53 19.61 29.43 23 29.92V33H25V29.92C28.39 29.43 31 26.53 31 23H29Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default IconMicrophone;
