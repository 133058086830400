import React from 'react';

const IconTimeFill = ({ size = 12, color = '#FFFFFF' }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39526 12C9.4904 12 11.9995 9.49089 11.9995 6.39575C11.9995 3.30061 9.4904 0.791504 6.39526 0.791504C3.30012 0.791504 0.791016 3.30061 0.791016 6.39575C0.791016 9.49089 3.30012 12 6.39526 12ZM6.5293 4.49707C6.5293 4.22093 6.30544 3.99707 6.0293 3.99707C5.75315 3.99707 5.5293 4.22093 5.5293 4.49707V7.28885C5.5293 7.565 5.75315 7.78885 6.0293 7.78885H8.19923C8.47538 7.78885 8.69923 7.565 8.69923 7.28885C8.69923 7.01271 8.47538 6.78885 8.19923 6.78885H6.5293V4.49707Z"
      fill={color}
    />
  </svg>
);

export default IconTimeFill;
