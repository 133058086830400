import React from 'react';

import { useDispatch, useSelector, useTheme } from 'hooks';

import { IconButton } from 'components';
import modalTypes from 'constants/modalTypes';
import { formatters } from 'utils';
import { selectCustomerDobbyCash, selectCustomerDobbyCashLoading } from 'store/reducers/customer';

import { openModal } from 'store/actions';

import { DobbyCash, DobbyCashTitle, DobbyCashTitleSpan, DobbyCashDesc, DobbyCashInfo } from './styled';

export const DobbyCashBalance = ({ user_id, isAgent = false }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const balanceLoading = useSelector(selectCustomerDobbyCashLoading);
  const balance = useSelector(selectCustomerDobbyCash);

  const handleAddRewardModal = () => {
    dispatch(openModal(modalTypes.MANAGE_REWARD, { user_id }));
  };

  const handleRemoveRewardModal = () => {
    dispatch(
      openModal(modalTypes.MANAGE_REWARD, {
        user_id,
        hasRewardReduce: true,
        currentBalance: balance?.amount_current_balance
      })
    );
  };

  const hasLoading = balance.user_id !== user_id && balanceLoading;
  return (
    <DobbyCash>
      <DobbyCashInfo>
        {!isAgent && (
          <>
            <div>
              <DobbyCashTitle>
                <DobbyCashTitleSpan>Total DobbyCash balance</DobbyCashTitleSpan>
              </DobbyCashTitle>
              {hasLoading ? (
                <DobbyCashDesc>-</DobbyCashDesc>
              ) : (
                <DobbyCashDesc color={theme.colors.DARK_GREEN}>
                  {balance?.amount_current_balance
                    ? formatters.dollarCurrencyDB(balance?.amount_current_balance)
                    : '$0.00'}
                </DobbyCashDesc>
              )}
            </div>
            <div>
              <DobbyCashTitle>
                <DobbyCashTitleSpan>Personal funds</DobbyCashTitleSpan>
              </DobbyCashTitle>
              {hasLoading ? (
                <DobbyCashDesc>-</DobbyCashDesc>
              ) : (
                <DobbyCashDesc>
                  {balance?.amount_paid_balance ? formatters.dollarCurrencyDB(balance?.amount_paid_balance) : '$0.00'}
                </DobbyCashDesc>
              )}
            </div>
          </>
        )}
        <div>
          <DobbyCashTitle>
            <DobbyCashTitleSpan>{isAgent ? "Total Dobby's reward gift amount" : "Dobby's reward"}</DobbyCashTitleSpan>
            <IconButton
              square
              bigText
              margin="0 0 0 8px"
              height={24}
              width={100}
              onClick={handleAddRewardModal}
              text="Add Reward"
            />
            <IconButton
              square
              bigText
              margin="0 0 0 8px"
              height={24}
              width={120}
              onClick={handleRemoveRewardModal}
              text="Remove Reward"
              disabled={balance?.amount_bonus_balance <= 0}
            />
          </DobbyCashTitle>
          {hasLoading ? (
            <DobbyCashDesc>-</DobbyCashDesc>
          ) : (
            <DobbyCashDesc>
              {balance?.amount_bonus_balance ? formatters.dollarCurrencyDB(balance?.amount_bonus_balance) : '$0.00'}
            </DobbyCashDesc>
          )}
        </div>
      </DobbyCashInfo>
    </DobbyCash>
  );
};
