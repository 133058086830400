import styled from 'styled-components';

export const PickerWrapper = styled.div`
  width: 350px;
`;

export const FieldWrapper = styled.div`
  label {
    color: ${({ theme }) => theme.colors.DARK_GREY};
  }
  fieldset {
    border-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    &:hover {
      border-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    }
  }
`;
