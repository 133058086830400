import React from 'react';
import { authors } from 'common/enums/job';
import routes from 'constants/routes';
import { useDispatch } from 'react-redux';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';

const UserRole = ({ full_name, id, group_id, userId }) => {
  const dispatch = useDispatch();
  const handleClickOnSameUser = (e) => {
    if (id === userId) {
      e.preventDefault();
      dispatch(
        openModal(modalTypes.RESULT, {
          title: 'You are already located on this page'
        })
      );
    }
  };
  if (group_id === authors.HO) {
    return (
      <a href={`${routes.CUSTOMER}/${id}`} target="_blank" onClick={handleClickOnSameUser}>
        {full_name || 'HO'} ({id})
      </a>
    );
  }
  if (group_id === authors.PRO) {
    return (
      <a href={`${routes.PROVIDER}/${id}`} target="_blank" onClick={handleClickOnSameUser}>
        {full_name || 'Pro'} ({id})
      </a>
    );
  }
  if (group_id === authors.OPS) {
    return full_name;
  }

  return 'Dobby automation';
};

export default UserRole;
