import React from 'react';

import { Icon } from 'components';
import { StyledCloseButton } from './styled';

const CloseButton = ({ size, iconSize, iconColor, onClick }) => (
  <StyledCloseButton size={size} onClick={onClick}>
    <Icon name="close" size={iconSize} color={iconColor} />
  </StyledCloseButton>
);

export default CloseButton;
