import React, { useEffect, useState } from 'react';
import { Icon, LoadingSpinner } from 'components';
import { CollapseButton, HeaderRow, Row, Table, RowItem, LoadingWrapper, EmptyText, Wrapper } from './styled';
import dates from 'constants/dates';
import { Collapse } from '@mui/material';
import { formatters } from 'utils';
import { getCustomerDobbyCashTransactions } from 'store/actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { selectDobbyCashTransactions, selectDobbyCashTransactionsLoading } from 'store/reducers/customer';
import { ActionReference, CurrencyRowItem, UserRole } from './components';

export const DobbyCashTable = ({ userId, isAgent = false }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const dobbyCashList = useSelector(selectDobbyCashTransactions);
  const isLoading = useSelector(selectDobbyCashTransactionsLoading);

  useEffect(() => {
    dispatch(getCustomerDobbyCashTransactions(userId));
  }, [userId]);

  return (
    <Wrapper>
      <Collapse sx={{ overflowY: 'auto', maxHeight: 360 }} in={checked}>
        <Table>
          <thead>
            <HeaderRow>
              <RowItem header>Date</RowItem>
              {isAgent ? (
                <RowItem header>Dobby's reward gift amount</RowItem>
              ) : (
                <>
                  <RowItem header>Total DobbyCash</RowItem>
                  <RowItem header>Personal Funds</RowItem>
                  <RowItem header>Dobby's Reward</RowItem>
                </>
              )}
              <RowItem header>User</RowItem>
              <RowItem header>Action</RowItem>
            </HeaderRow>
          </thead>
          <tbody>
            {!isLoading &&
              dobbyCashList?.map(
                (
                  {
                    id,
                    date_created,
                    amount_current_balance,
                    amount_bonus_balance,
                    amount_paid_balance,
                    action_description,
                    created_by_user,
                    reference_entity,
                    reference_id,
                    service_request_id,
                    related_user
                  },
                  i,
                  arr
                ) => (
                  <Row isLast={i === arr.length - 1} key={id}>
                    <RowItem width={80}>
                      {Boolean(date_created)
                        ? formatters.dateWithTimezone(date_created, dates.DATETIME_FULL_YEAR)
                        : '-'}
                    </RowItem>
                    {isAgent ? (
                      <CurrencyRowItem amount={amount_current_balance} />
                    ) : (
                      <>
                        <CurrencyRowItem amount={amount_current_balance} />
                        <CurrencyRowItem amount={amount_paid_balance} />
                        <CurrencyRowItem amount={amount_bonus_balance} />
                      </>
                    )}
                    <RowItem>
                      <UserRole {...created_by_user} userId={userId} />
                    </RowItem>
                    <RowItem>
                      {action_description}{' '}
                      <ActionReference
                        reference_entity={reference_entity}
                        reference_id={reference_id}
                        service_request_id={service_request_id}
                        related_user={related_user}
                      />
                    </RowItem>
                  </Row>
                )
              )}
          </tbody>
        </Table>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        ) : (
          !dobbyCashList?.length && <EmptyText>No transactions records yet</EmptyText>
        )}
      </Collapse>
      <CollapseButton onClick={() => setChecked(!checked)}>
        <Icon name="collapse" rotate={checked ? 0 : 180} />

        {checked ? 'Collapse' : 'Transactions History'}
      </CollapseButton>
    </Wrapper>
  );
};
