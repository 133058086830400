import styled from 'styled-components';

export const Label = styled.div`
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin-right: 10px;
  margin-bottom: 5px;
  max-width: 150px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
`;

export const DatePickerOpenLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  line-height: 1.17;

  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
  margin-right: 10px;
  width: 100%;
  height: 32px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.PRIMARY};
  }
`;

export const DatePickerWrapper = styled.div`
  div {
    background-color: ${({ theme }) => theme.colors.DARK_1};
  }

  .MuiCalendarPicker-root {
    color: ${({ theme }) => theme.colors.DARK_GREY};
  }

  .MuiTypography-caption {
    color: ${({ theme }) => theme.colors.DARK_GREY};
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.DARK_GREY};
  }

  .MuiPickersDay-root:hover {
    background-color: ${({ theme }) => theme.colors.DARK_GREY_2};
  }
`;

export const ArrowDown = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: ${({ theme }) => theme.colors.WHITE} transparent transparent transparent;
  margin-left: 8px;
`;

export const Error = styled.div`
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.ERROR};
  margin-top: 8px;
`;
