import React from 'react';
import { Container } from './styled';

const NotExist = ({ name, id }) => {
  return (
    <Container>
      <span>
        {name} ID {id} doesn't exist
      </span>
    </Container>
  );
};

export default NotExist;
