import React from 'react';
import styles from 'constants/styles';

const IconCloseOutlined2 = ({ size = 24, color = styles.colors.DARK_4 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill={color} stroke="#262E30" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21967 15.2197C6.92678 15.5126 6.92678 15.9874 7.21967 16.2803C7.51256 16.5732 7.98744 16.5732 8.28033 16.2803L11.75 12.8107L15.2197 16.2803C15.5126 16.5732 15.9874 16.5732 16.2803 16.2803C16.5732 15.9874 16.5732 15.5126 16.2803 15.2197L12.8107 11.75L16.2803 8.28033C16.5732 7.98744 16.5732 7.51256 16.2803 7.21967C15.9874 6.92678 15.5126 6.92678 15.2197 7.21967L11.75 10.6893L8.28033 7.21967C7.98744 6.92678 7.51256 6.92678 7.21967 7.21967C6.92678 7.51256 6.92678 7.98744 7.21967 8.28033L10.6893 11.75L7.21967 15.2197Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default IconCloseOutlined2;
