import React from 'react';

import MessageBubble from './MessageBubble';

import { MessageUserContainer, MessageUserWrapper } from './styled';

const MessageUser = ({ currentUser = {}, message = {}, nextMessage = {}, overwriteComponents = {} }) => {
  const { messageBubble = null, ...bubbleOverwrites } = overwriteComponents;
  const isCurrentUser = message.user && currentUser._id && currentUser._id === message.user._id;

  return (
    <MessageUserContainer>
      <MessageUserWrapper isCurrentUser={isCurrentUser}>
        {messageBubble ? (
          messageBubble({ isCurrentUser, message, nextMessage, overwriteComponents: bubbleOverwrites })
        ) : (
          <MessageBubble
            isCurrentUser={isCurrentUser}
            message={message}
            nextMessage={nextMessage}
            overwriteComponents={bubbleOverwrites}
          />
        )}
      </MessageUserWrapper>
    </MessageUserContainer>
  );
};

export default MessageUser;
