import React from 'react';
import PropTypes from 'prop-types';
import styles from 'constants/styles';

import { getUrlFileExtension } from 'common/helpers/media';
import { videoExtensions } from 'common/enums/media';
import { IconButton, Icon } from 'components';
import {
  Label,
  FlexRow,
  AttachedFile,
  CloseButtonWrapper,
  AddFileButton,
  Wrapper,
  StyledReactPlayer,
  PlayerWrapper
} from './styled';
import { useTheme } from 'hooks';

export const MediaUploader = ({ label, files, setFiles, maxAmount, disabled, accept = 'image/*, video/*' }) => {
  const onAddFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { files: newFiles } = e.target;
    if (newFiles && newFiles.length) {
      const addedFiles = Array.from(newFiles, (file) => {
        const previewUrl = URL.createObjectURL(file);
        return { file, previewUrl, isUploading: false };
      });
      setFiles([...addedFiles, ...files]);
    }
  };

  const onRemoveFile = (mediaIndex) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    setFiles(files.filter((_item, index) => index !== mediaIndex));
  };

  const handleRenderCloseButton = (index) => {
    if (disabled) {
      return null;
    }
    return (
      <CloseButtonWrapper>
        <IconButton
          size={16}
          iconSize={7.2}
          iconName="close2"
          iconColor={styles.colors.WHITE}
          backgroundColor={styles.colors.BLACK}
          onClick={onRemoveFile(index)}
        />
      </CloseButtonWrapper>
    );
  };

  const theme = useTheme();
  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}
      <Wrapper>
        <FlexRow>
          {(!maxAmount || files.length < maxAmount) && (
            <AddFileButton>
              <input accept={accept} type="file" multiple style={{ display: 'none' }} onChange={onAddFile} />
              <Icon name="plus" color={theme.colors.DARK_GREY_2} size={16} />
            </AddFileButton>
          )}
          {files.map((file, index) => {
            const extName = getUrlFileExtension(file?.file?.name || '');
            const extPreviewUrl = getUrlFileExtension(file?.previewUrl || '');
            const hasVideo = Object.values(videoExtensions).includes(extName);

            if (hasVideo && !extPreviewUrl) {
              return (
                <PlayerWrapper key={index}>
                  <StyledReactPlayer key={file.previewUrl}>
                    <source src={file.previewUrl} type={file?.type} />
                  </StyledReactPlayer>
                  {handleRenderCloseButton(index)}
                </PlayerWrapper>
              );
            }

            return (
              <AttachedFile key={index} src={file.previewUrl}>
                {handleRenderCloseButton(index)}
              </AttachedFile>
            );
          })}
        </FlexRow>
      </Wrapper>
    </>
  );
};

MediaUploader.propTypes = {
  label: PropTypes.string,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired
};

MediaUploader.defaultProps = {
  label: '',
  files: [],
  setFiles: () => {}
};
