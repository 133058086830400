import React from 'react';
import styles from 'constants/styles';

const IconChatOutlined = ({ size = 24, color = styles.colors.DARK_YELLOW }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5288 17.3587C18.7929 17.8029 19 18.1333 19 18.1333C19 18.1333 18.6306 18.0834 18.1068 18.002C17.0853 17.8434 15.4766 17.5653 14.8744 17.3053C13.9723 17.6634 12.9614 17.8641 11.8937 17.8641C8.08643 17.8641 5 15.3127 5 12.1654C5 9.01807 8.08643 6.46667 11.8937 6.46667C15.701 6.46667 18.7874 9.01807 18.7874 12.1654C18.7874 13.3954 18.316 14.5345 17.5142 15.4657C17.7085 15.9466 18.171 16.757 18.5288 17.3587ZM8.5 11.1333C8.5 10.8112 8.76117 10.55 9.08333 10.55H14.9167C15.2388 10.55 15.5 10.8112 15.5 11.1333C15.5 11.4555 15.2388 11.7167 14.9167 11.7167H9.08333C8.76117 11.7167 8.5 11.4555 8.5 11.1333ZM9.08333 12.8833C8.76117 12.8833 8.5 13.1445 8.5 13.4667C8.5 13.7888 8.76117 14.05 9.08333 14.05H13.75C14.0722 14.05 14.3333 13.7888 14.3333 13.4667C14.3333 13.1445 14.0722 12.8833 13.75 12.8833H9.08333Z"
        fill={color}
      />
    </svg>
  );
};

export default IconChatOutlined;
