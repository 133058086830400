import React from 'react';

const IconEmptyList = ({ size = 160 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="80" cy="80" r="80" fill="#F7F8FB" />
      <path
        d="M117 53.4565V114.458C116.998 115.132 116.728 115.777 116.25 116.253C115.772 116.73 115.124 116.998 114.448 117H45.5517C44.8756 116.998 44.2277 116.73 43.7496 116.253C43.2715 115.777 43.002 115.132 43 114.458V53.4565H117Z"
        fill="#C1C1C1"
      />
      <path
        d="M43 81.6089V114.472C43 115.143 43.2688 115.786 43.7474 116.26C44.2259 116.734 44.875 117 45.5517 117H80C59.5492 117 43 101.171 43 81.6089Z"
        fill="#999999"
      />
      <path
        d="M114.448 43H45.5517C44.875 43 44.2259 43.2754 43.7474 43.7657C43.2688 44.2559 43 44.9208 43 45.6141V53.4565H117V45.6141C117 44.9208 116.731 44.2559 116.253 43.7657C115.774 43.2754 115.125 43 114.448 43Z"
        fill="#999999"
      />
      <path
        d="M48.1034 49.3794H49.3793C49.7177 49.3794 50.0422 49.2449 50.2815 49.0057C50.5207 48.7664 50.6552 48.4419 50.6552 48.1035C50.6552 47.7651 50.5207 47.4406 50.2815 47.2013C50.0422 46.9621 49.7177 46.8276 49.3793 46.8276H48.1034C47.7651 46.8276 47.4405 46.9621 47.2013 47.2013C46.962 47.4406 46.8276 47.7651 46.8276 48.1035C46.8276 48.4419 46.962 48.7664 47.2013 49.0057C47.4405 49.2449 47.7651 49.3794 48.1034 49.3794Z"
        fill="#F1F2F2"
      />
      <path
        d="M54.4827 49.3789H55.7586C56.097 49.3789 56.4215 49.2445 56.6607 49.0052C56.9 48.7659 57.0344 48.4414 57.0344 48.103C57.0344 47.7646 56.9 47.4401 56.6607 47.2008C56.4215 46.9616 56.097 46.8271 55.7586 46.8271H54.4827C54.1443 46.8271 53.8198 46.9616 53.5805 47.2008C53.3413 47.4401 53.2068 47.7646 53.2068 48.103C53.2068 48.4414 53.3413 48.7659 53.5805 49.0052C53.8198 49.2445 54.1443 49.3789 54.4827 49.3789Z"
        fill="#F1F2F2"
      />
      <path
        d="M60.862 49.3789H62.1379C62.4763 49.3789 62.8008 49.2445 63.0401 49.0052C63.2793 48.7659 63.4138 48.4414 63.4138 48.103C63.4138 47.7646 63.2793 47.4401 63.0401 47.2008C62.8008 46.9616 62.4763 46.8271 62.1379 46.8271H60.862C60.5237 46.8271 60.1991 46.9616 59.9599 47.2008C59.7206 47.4401 59.5862 47.7646 59.5862 48.103C59.5862 48.4414 59.7206 48.7659 59.9599 49.0052C60.1991 49.2445 60.5237 49.3789 60.862 49.3789Z"
        fill="#F1F2F2"
      />
      <rect
        x="52.0244"
        y="60.1465"
        width="56.8537"
        height="9.02439"
        rx="4.5122"
        fill="#F7F8FB"
      />
      <rect
        x="52.0244"
        y="72.7803"
        width="56.8537"
        height="9.02439"
        rx="4.5122"
        fill="#F7F8FB"
      />
      <rect
        x="71.8781"
        y="85.4146"
        width="37"
        height="9.02439"
        rx="4.5122"
        fill="#F7F8FB"
      />
    </svg>
  );
};

export default IconEmptyList;
