import React from 'react';
import styles from 'constants/styles';

const IconCloseFill2 = ({ size = 16, color = styles.colors.DARK_4, secondColor = styles.colors.WHITE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49517 4.43403C5.20228 4.14113 4.72741 4.14113 4.43451 4.43403C4.14162 4.72692 4.14162 5.20179 4.43451 5.49469L6.76133 7.8215L4.43451 10.1483C4.14162 10.4412 4.14162 10.9161 4.43451 11.209C4.72741 11.5019 5.20228 11.5019 5.49517 11.209L7.82199 8.88216L10.1488 11.209C10.4417 11.5019 10.9166 11.5019 11.2095 11.209C11.5024 10.9161 11.5024 10.4412 11.2095 10.1483L8.88265 7.8215L11.2095 5.49469C11.5024 5.20179 11.5024 4.72692 11.2095 4.43403C10.9166 4.14113 10.4417 4.14113 10.1488 4.43403L7.82199 6.76084L5.49517 4.43403Z"
        fill={secondColor}
      />
    </svg>
  );
};

export default IconCloseFill2;
