import React from 'react';
import styles from 'constants/styles';

const IconSend = props => {
  const { size = 18, color = styles.colors.WHITE } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5227 1.95141L0.180732 7.7597L5.27155 11.1321L11.2333 6.24082L6.34201 12.2025L9.7144 17.2934L15.5227 1.95141Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSend;
