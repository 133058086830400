import React from 'react';
import styles from 'constants/styles';

const IconThumbUpOutlined = ({ size = 24, color = styles.colors.DARK_GREEN_2 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6745 12.3528C17.881 12.5683 18 12.8605 18 13.1741C18 13.6264 17.752 14.0235 17.3825 14.2176C17.4595 14.3778 17.5 14.5546 17.5 14.7393C17.5 15.1922 17.2515 15.5893 16.8821 15.7834C16.9966 16.0213 17.03 16.2952 16.9726 16.567C16.8606 17.0956 16.3841 17.4785 15.8396 17.4785H11.2504C10.9224 17.4785 10.4074 17.3705 10.0004 17.1702V11.578L10.0604 11.5462L11.5004 8.29099V6.52174C11.5004 6.44245 11.5349 6.36732 11.5939 6.31775L11.5955 6.31647C11.6266 6.29168 11.9925 6 12.5003 6C13.0788 6 14.0002 6.95532 14.0002 8.087C14.0002 8.80075 13.7552 9.68928 13.6022 10.174H16.6906C17.3635 10.174 17.9359 10.6937 17.9945 11.3568C18.027 11.7288 17.908 12.0862 17.6745 12.3528ZM6 12.5216C6 11.8026 6.56097 11.2172 7.24993 11.2172H9.49981V17.736C9.29031 17.9008 9.03133 18 8.74985 18H7.24993C6.56097 18 6 17.4146 6 16.6956V12.5216Z"
      fill={color}
    />
  </svg>
);

export default IconThumbUpOutlined;
