import React from 'react';
import styles from 'constants/styles';

const IconAvatar = ({ size = 12, color = styles.colors.DARK_GREY }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9177 5.13362C4.14121 5.78605 4.77112 6.60995 4.77112 6.60995C4.77112 6.60995 2.99879 7.89042 1.29428 8.97979C0.823577 9.28062 0.988847 9.94762 1.29428 10.4172C1.52789 10.7765 1.7938 11 2.22023 11H9.93696C10.3258 11 10.6214 10.7877 10.7472 10.4172C10.9243 9.89554 11.2143 9.28629 10.7472 8.97979C9.4691 8.14106 7.20941 6.60995 7.20941 6.60995C7.20941 6.60995 7.92803 5.82517 8.2007 5.13362C8.53429 4.28754 8.43511 3.68179 8.2007 2.80261C7.9151 1.7315 7.4063 1.02998 6.01574 1.0008C4.62519 0.971611 4.17492 1.71594 3.9177 2.80261C3.7081 3.68812 3.6229 4.27306 3.9177 5.13362Z"
      fill={color}
    />
  </svg>
);

export default IconAvatar;
