import React from 'react';

import { MessageTextContainer, MessageTextWrapper, MessageTextText } from './styled';

const MessageText = ({ message = { text: '' } }) => {
  return (
    <MessageTextContainer>
      <MessageTextWrapper>
        <MessageTextText>{message.text}</MessageTextText>
      </MessageTextWrapper>
    </MessageTextContainer>
  );
};

export default MessageText;
