import styled, { css } from 'styled-components';
import styles from 'constants/styles';

export const nameTitleCSS = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  ${({ margin }) => `margin: ${margin}`};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: overlay;
  height: calc(100vh - ${styles.layout.headerHeight + styles.layout.tabsHeaderHeight * 2}px) !important;
`;

export const Info = styled.div`
  text-transform: capitalize;
  padding: 4px 16px;
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.DARK_GREY_3};
  border: 1px solid ${({ theme }) => theme.colors.DARK_GREY_3};
  border-radius: 20px;

  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || 14}px;

  color: ${({ theme, color }) => color || theme.colors.WHITE};
  margin-right: 8px;

  text-align: center;
  line-height: 1.2;
`;

export const InfoJob = styled.div`
  text-transform: capitalize;
  padding: 4px 16px;
  background: ${({ theme, backgroundColor }) => backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.DARK_GREY_3};
  border-radius: 20px;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || 14}px;
  color: ${({ theme, color }) => color || theme.colors.WHITE};
  margin-right: 8px;
  text-align: center;
  line-height: 1.2;
`;

export const Tag = styled.div`
  text-transform: capitalize;
  padding: 4px 16px;
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-right: 8px;
`;

export const ServiceWrapper = styled.div`
  padding: 22px 24px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    ${Info} {
      background: ${({ theme, backgroundColor, isJob }) => backgroundColor || theme.colors.DARK_4};
    }
  }
`;

export const ServiceInfoRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
`;
export const ServiceRightBlock = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  align-items: start;
  min-height: 24px;
  gap: 20px;
`;

export const TotalValue = styled.div`
  ${nameTitleCSS}
  display: flex;
  align-items: center;
`;

export const ServiceLeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const GrayTitle = styled.div`
  ${nameTitleCSS}
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.WHITE};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
`;

export const MainColumn = styled.div``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 25px;
`;

export const LoadMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.GRAY};
  padding: 15px 20px 20px;

  &:hover {
    cursor: pointer;
    color: ${styles.colors.DARK_GREEN_3};
  }
`;

export const AssignPro = styled.div`
  ${nameTitleCSS}
  color: ${({ theme }) => theme.colors.DARK_WHITE}80;
  margin-bottom: 2px;
`;

export const ScheduledText = styled.div`
  font-weight: 400;
  font-size: 8px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_2};
  margin: 0 24px ${({ marginBottom }) => marginBottom} 24px;
`;

export const DateTime = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.GRAY};
  margin: 0px 0 4px 0;
`;

export const DobbyCash = styled.div`
  margin-top: 5px;
  text-align: end;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const EmptyServiceLists = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  position: relative;
`;

export const EmptyText = styled.div`
  text-align: center;
  padding-top: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.WHITE};
`;
