const routes = {
  PRIORITY_TASKS: '/',
  PAYMENTS: '/payments',
  INVOICE_PENDING: '/invoice_pending',
  SERVICE: '/service',
  CUSTOMER: '/homeowner',
  PROVIDER: '/provider',
  AGENT: '/agent',
  ANALYTICS: '/analytics',
  ANALYTICS_JOBS: '/analytics/service',
  PROMO_CODES: '/rewards',
  COMMUNICATION: '/communication',
  PROMO_CODES_CUSTOMERS: '/rewards/homeowner',
  CAMPAIGNS: '/campaign',
  CAMPAIGN_CREATE: '/new_campaign/create'
};

export default routes;
