import React from 'react';

const IconAdd = ({ size = 16, color = '#97A0A0' }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33333 11.3333C7.33333 11.7015 7.63181 12 8 12C8.36819 12 8.66667 11.7015 8.66667 11.3333V8.66667H11.3333C11.7015 8.66667 12 8.36819 12 8C12 7.63181 11.7015 7.33333 11.3333 7.33333H8.66667V4.66667C8.66667 4.29848 8.36819 4 8 4C7.63181 4 7.33333 4.29848 7.33333 4.66667V7.33333H4.66667C4.29848 7.33333 4 7.63181 4 8C4 8.36819 4.29848 8.66667 4.66667 8.66667H7.33333V11.3333Z"
      fill={color}
    />
    <circle cx="8" cy="8" r="7.5" stroke={color} />
  </svg>
);

export default IconAdd;
