import styled from 'styled-components';
import styles from 'constants/styles';

export const DropdownContainer = styled.div`
  ${({ disabled }) => !disabled && 'min-width: 80px;'}
  position: relative;
`;

export const DropdownValueContainer = styled.div`
  display: flex;
  align-items: center;

  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.DROPDOWN_VALUE};
  &:hover {
    background-color: ${({ theme }) => theme.colors.DROPDOWN_VALUE};
  }
  padding: ${({ disabled }) => (disabled ? '4px 16px' : '4px 8px 4px 16px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  ${({ type, width }) =>
    type === 'rounded'
      ? `
    border-radius: 61px;
  `
      : type === 'square'
      ? `
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    border-radius: 4px;
    min-width: ${width}px;
    border: unset;
  `
      : type === 'square-small'
      ? `
    justify-content: space-between;
    min-width: ${width}px;
  `
      : null};
  ${({ theme, fill }) => fill && `background-color: ${theme.colors.DARK_GREY_3}`};
`;

export const DropdownValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  ${({ disabled }) => !disabled && 'margin-right: 15px'};
  text-transform: capitalize;
  color: ${({ theme, color }) => color || theme.colors.SELECT_ITEM_TEXT};
  white-space: nowrap;
  text-transform: ellipsis;
`;

export const DropdownMenu = styled.div`
  background: ${({ theme }) => theme.colors.DROPDOWN_MENU};
  box-shadow: ${({ theme }) => theme.boxShadows.THIN_DARK_3};
  border-radius: 4px;
  position: absolute;
  top: ${({ hasHeaderHidden }) => (hasHeaderHidden ? 30 : 0)}px;
  ${({ type, width }) =>
    type === 'square'
      ? `
    margin: 8px 8px 8px 0;
    min-width: ${width}px;
  `
      : type === 'square-small'
      ? `
  min-width: ${width}px;
`
      : null};
  z-index: 2;
  overflow: hidden;
`;

export const DropdownMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.GRAY};
  padding: 12px 12px 12px 18px;
  background: ${({ theme }) => theme.colors.DROPDOWN_HEADER};
`;

export const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`;

export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  display: inline-block;
  outline: 0 none;
  width: 100%;
  padding: 8px 16px;
  margin: 10px 0;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }

  ${({ invalid, theme }) =>
    invalid &&
    `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}

  ${({ width }) =>
    Boolean(width) &&
    `
    width: ${width}px;
  `};

  &:active,
  &:focus {
    outline: 0 none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
    border: none;

    ${({ invalid, theme }) =>
      invalid &&
      `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}
  }

  &[disabled],
  &[readOnly] {
    color: ${({ theme }) => theme.colors.DARK_GREY_2};
    box-shadow: none;
    background: ${({ theme }) => theme.colors.INPUT_BG};
  }
`;

export const DropdownMenuDescription = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
  margin-right: 10px;
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 16px 13px 0;

  position: relative;
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER} !important;
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    margin-left: 0px;
    padding-left: 16px;
  }
  &:hover:after {
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
  }
  ${({ disabled, theme }) =>
    !disabled &&
    `
    cursor: pointer;
    &:hover {
      background: ${theme.colors.SELECT_ITEM};
    }
  `};
`;

export const DropdownItemText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  width: 100%;
  color: ${({ theme }) => theme.colors.SELECT_ITEM_TEXT};
  ${({ centered, selected }) =>
    centered
      ? `
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  ${selected && 'margin-right: -16px;'}

  `
      : ''}
`;

export const DropdownItems = styled.div`
  overflow-y: overlay;
  min-width: ${({ width }) => width || 166}px;
  max-height: ${({ maxHeight = 244 }) => maxHeight}px;
  z-index: 10;

  div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
  }
`;
