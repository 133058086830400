import React from 'react';
import { TooltipWrapper, TooltipArrow, TooltipTitle, TooltipRelativeWrapper, WrappedComponent } from './styled';

const Tooltip = ({
  direction = 'right',
  inline,
  clickable,
  wrappedComponent,
  title = '',
  minWidth,
  maxWidth,
  left,
  right,
  bottom,
  top
}) => {
  return (
    <TooltipRelativeWrapper inline={inline}>
      <WrappedComponent inline={inline} clickable={clickable}>
        {wrappedComponent}
      </WrappedComponent>
      {title ? (
        <TooltipWrapper direction={direction} minWidth={minWidth} left={left} bottom={bottom} right={right} top={top}>
          {Boolean(title) && <TooltipTitle maxWidth={maxWidth}>{title}</TooltipTitle>}
          <TooltipArrow direction={direction} />
        </TooltipWrapper>
      ) : null}
    </TooltipRelativeWrapper>
  );
};

export default Tooltip;
