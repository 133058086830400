import React from 'react';
import { LogoSpinnerWrapper, LogoSpinner, Spinner } from './styled';

const spinnerColors = {
  dark: {
    secondaryColor: 'rgba(0,0,0,0.4)',
    mainColor: 'rgba(0,0,0,1)'
  },
  light: {
    secondaryColor: 'rgba(255,255,255,0.4)',
    mainColor: 'rgba(255,255,255,1)'
  }
};

const LoadingSpinner = ({ color = 'light', type, text = '', transparent }) =>
  type === 'logo' ? (
    <LogoSpinnerWrapper transparent={transparent}>
      <Spinner
        size={24}
        secondaryColor={spinnerColors[color]?.secondaryColor || spinnerColors['dark'].secondaryColor}
        mainColor={spinnerColors[color]?.mainColor || spinnerColors['dark'].secondaryColor}
      />
      {Boolean(text) && <span>{text}</span>}
    </LogoSpinnerWrapper>
  ) : (
    <Spinner
      secondaryColor={spinnerColors[color]?.secondaryColor || spinnerColors['dark'].secondaryColor}
      mainColor={spinnerColors[color]?.mainColor || spinnerColors['dark'].secondaryColor}
    />
  );

export default LoadingSpinner;
