import React from 'react';
import { Icon } from 'components';

import { IconButtonText, StyledIconButton } from './styled';
import PropTypes from 'prop-types';
import { iconButtonVariants } from 'common/enums/buttons';
import { icons } from 'common/enums/icons';

export const IconButton = ({
  size = 24,
  iconName,
  iconWidth,
  iconHeight,
  iconColor,
  onClick,
  disabled,
  style,
  square,
  variant,
  iconSize,
  width,
  height,
  text,
  bigText,
  backgroundIconSize,
  margin,
  children,
  noFill
}) => {
  const body = text || children;
  return (
    <StyledIconButton
      variant={variant}
      margin={margin}
      square={square}
      onClick={disabled ? () => ({}) : onClick}
      style={style}
      size={size}
      width={width}
      height={height}
      disabled={disabled}
      text={text}
      iconName={iconName}
      noFill={noFill}>
      {iconName && (
        <Icon
          name={iconName}
          width={iconWidth}
          height={iconHeight}
          size={iconSize}
          color={iconColor}
          backgroundSize={backgroundIconSize}
        />
      )}
      {body && (
        <IconButtonText bigText={bigText} iconName={iconName}>
          {body}
        </IconButtonText>
      )}
    </StyledIconButton>
  );
};

IconButton.defaultProps = {
  onClick: () => {},
  size: 24
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(iconButtonVariants)),
  iconName: PropTypes.oneOf(Object.values(icons)),
  disabled: PropTypes.bool,
  size: PropTypes.number
};
