import { createSearchParams as createQueryParams } from 'react-router-dom';
import { defaultQueryParams } from 'common/enums/queryParams';
import { topNavigationTabs } from 'common/enums/navigation';
import { addressStringFilters, hiddenFilters } from 'common/enums/filters';

/**
 * generates params for query (url / address) string
 */
export const getExtendedDefaultQueryParams = (extendedQueryParams = {}) => {
  const queryParams = {
    ...defaultQueryParams,
    ...extendedQueryParams
  };

  return createQueryParams(queryParams).toString();
};

/**
 * generates default params for menu item
 */
export const getDefaultNavigationParams = ({ menuItem } = {}) => {
  if (!menuItem) {
    return defaultQueryParams;
  }

  const tabItem = topNavigationTabs[menuItem.TYPE]?.DEFAULT;

  const menuFilters = {
    menu_item: menuItem.TYPE,
    ...(tabItem?.TYPE ? { tab_id: tabItem.TYPE } : {})
  };

  if (menuItem.NO_FILTERS) {
    return menuFilters;
  }

  return {
    ...defaultQueryParams,
    ...(menuItem.SORTING_PARAMS || {}),
    ...(tabItem?.SORTING_PARAMS || {}),
    ...menuFilters
  };
};

/**
 * replace spaces with commas
 */
export const formatSearchTerm = (searchTerm) => encodeURIComponent(searchTerm.replace(/[\s]{1,}/g, ','));

export const groupFilters = (filters) => {
  const {
    menu_item,
    tab_id,
    sorting_direction,
    sorting_column,
    page,
    search,
    metric_type,
    period_type,
    [addressStringFilters.PROVIDER_FULL_NAME.TYPE]: providerFullName,
    ...customFilters
  } = filters;
  return {
    coreNavigationFilters: {
      menu_item,
      ...(tab_id ? { tab_id } : {}),
      ...(metric_type ? { metric_type } : {}),
      ...(period_type ? { period_type } : {}),
      ...(providerFullName ? { [addressStringFilters.PROVIDER_FULL_NAME.TYPE]: providerFullName } : {}),
      sorting_direction,
      sorting_column
    },
    commonFilters: {
      page: page || 1,
      ...(search ? { search } : {})
    },
    customFilters
  };
};

export const getFilledHiddenFilters = (filters) =>
  hiddenFilters.reduce(
    (accumulator, filter) =>
      filters[filter.TYPE] ? { ...accumulator, [filter.TYPE]: filters[filter.TYPE] || '' } : accumulator,
    {}
  );
