import styled from 'styled-components';
import styles from 'constants/styles';
import { StyledCheckbox } from 'components/Checkbox/styled';
import checkboxCheckedGreen from 'static/images/checkbox-checked-green.svg';

export const DropdownMultiSelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const SelectorLabel = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin-right: 8px;
`;

export const SelectorContainer = styled.div`
  position: relative;
`;

export const SelectedValuesContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.INPUT_BG};
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  min-height: 32px;
  min-width: 0;
  width: ${({ width }) => width || 160}px;
`;

export const SelectedValues = styled.div`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  white-space: nowrap;
  overflow: hidden;
  text-transform: ellipsis;
`;

export const DropdownMenu = styled.div`
  background: ${({ theme }) => theme.colors.DROPDOWN_MENU};
  border-radius: 4px;
  position: absolute;
  top: 0;
  min-width: ${({ width }) => width || 250}px;
  z-index: 10;
  overflow: hidden;
  filter: ${({ theme }) => theme.filter.THIN_DARK};
  ${({ right }) => right && `right: ${right}`};
`;

export const SelectAllOptionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  ${({ theme }) => theme.darkMode && `background: ${theme.colors.DARK_2};`}
  ${({ hasExtraOptions }) => !hasExtraOptions && `border-bottom: 1px solid rgba(0, 0, 0, 0.1)`};
`;

export const SelectAllOption = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;

export const SelectAllOptionText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  padding-left: 6px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin-right: 10px;
`;

export const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButton = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
`;

export const DropdownMenuItems = styled.div`
  overflow: overlay;
  min-width: 166px;
  max-height: 244px;
  z-index: 10;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.darkMode && `border-bottom: 1px solid ${theme.colors.DARK_4};`}
  height: 40px;
  padding: 5px 0;
  margin: 0 16px;
  position: relative;
  &:hover:after {
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
  }
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    label {
      cursor: pointer;
    }`};
  ${({ isExtraOption, extrasLength, selected, theme }) =>
    isExtraOption
      ? `
    :nth-child(${extrasLength}) {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      padding: 5px 16px;
      border-bottom: unset;
      margin: 0;
      ${theme.darkMode && `background: ${theme.colors.DARK_2};`}
      div {
        color: ${theme.colors.DARK_GREY};
      }
    }`
      : `
    &:hover {
      background: ${theme.colors.SELECT_ITEM};
      margin: 0;
      padding: 5px 16px;
      ${StyledCheckbox} {
      ${
        theme.darkMode &&
        `  background: ${
          selected ? `url(${checkboxCheckedGreen}) ${theme.colors.DARK_2} center no-repeat` : theme.colors.DARK_2
        };`
      }
        background-size: 9.33px 7.33px;
      }
    }`}
`;

export const MenuItemImage = styled.div`
  margin-right: 6px;
`;

export const MenuItemText = styled.div`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.MENU_ITEM_TEXT};
`;
