import { formatters } from 'utils';
import { conversationTypes } from 'common/enums/chat';
import {
  pageTypes,
  notificationActions,
  notificationIcons,
  notificationTypes,
  redirectNotificationTypes
} from 'common/enums/notification';
import routes from 'constants/routes';

export const getNotificationRedirectType = ({ action, type, is_negative }) => {
  if (action === notificationActions.CHAT) {
    return redirectNotificationTypes.CHAT;
  }

  switch (type) {
    case notificationTypes.COMMUNICATION:
      return redirectNotificationTypes.COMMUNICATION;
    case notificationTypes.REMINDER:
      return redirectNotificationTypes.REMINDER;
    case notificationTypes.NOTE:
      return redirectNotificationTypes.NOTE;
    case notificationTypes.REVIEW:
      return is_negative ? redirectNotificationTypes.NEGATIVE_REVIEW : redirectNotificationTypes.POSITIVE_REVIEW;
    default:
      break;
  }

  return is_negative ? redirectNotificationTypes.NEGATIVE : redirectNotificationTypes.POSITIVE;
};

const getNotificationTarget = ({ conversation_type, job_id, user_id, type }) => {
  if (type === notificationTypes.COMMUNICATION) {
    return `SMS text User ID ${user_id}`;
  }

  if (type === notificationTypes.USER) {
    return `User Support User ID ${user_id}`;
  }

  if (user_id && [notificationTypes.REMINDER, notificationTypes.NOTE].includes(notificationTypes)) {
    return `User ID ${user_id}`;
  }

  if (Object.values(conversationTypes).includes(conversation_type)) {
    const chatTargets = {
      [conversationTypes.DIRECT]: `Direct Chat User ID ${user_id}`,
      [conversationTypes.JOB_GROUP]: `Job Support ID ${job_id}`,
      [conversationTypes.SUPPORT]: `User Support User ID ${user_id}`
    };

    return chatTargets[conversation_type];
  }

  return `Job ID ${job_id}`;
};

export const getFormattedNotification = ({ id: notificationId, title, data, action, body, type, date_created }) => {
  // notification.data.id is job_id
  const { id: job_id, user_id, conversation_type, ...otherNotificationData } = JSON.parse(data);
  const notificationData = { conversation_type, job_id, user_id, ...otherNotificationData };

  const redirectType = getNotificationRedirectType({ action, type, is_negative: notificationData.is_negative });
  const target = getNotificationTarget({ conversation_type, job_id, user_id, type });
  const isJobNotification = type === notificationTypes.JOB || conversation_type === conversationTypes.JOB_GROUP;
  const hasTextNotificationType = [
    notificationTypes.REMINDER,
    notificationTypes.POSITIVE_REVIEW,
    notificationTypes.NEGATIVE_REVIEW
  ].includes(redirectType);
  const hasConversationType =
    Object.values(conversationTypes).includes(conversation_type) || type === notificationTypes.COMMUNICATION;

  return {
    notificationType: type,
    iconName: notificationIcons[redirectType],
    isJobNotification,
    notificationId: notificationId || notificationData.notification_id,
    parsedData: notificationData,
    redirectType,
    target,
    text: hasConversationType || hasTextNotificationType ? body : null,
    time: formatters.humanizedDate(date_created),
    title
  };
};

export const getCurrentPageType = ({ pathname, filters, job_id, user_id }) => {
  const splittedPathname = pathname.split('/');
  const lastIndex = splittedPathname.length - 1;
  const pathnameId = splittedPathname[lastIndex];
  const pathnameInstance = `/${splittedPathname[lastIndex - 1]}`;

  const isCurrentUser = user_id === Number(pathnameId);
  if (isCurrentUser) {
    switch (pathnameInstance) {
      case routes.AGENT:
        return pageTypes.CURRENT_AGENT;
      case routes.CUSTOMER:
        return pageTypes.CURRENT_CUSTOMER;
      case routes.PROVIDER:
        return pageTypes.CURRENT_PROVIDER;
      default:
        break;
    }
  }

  if (routes.SERVICE === pathnameInstance && job_id === Number(pathnameId)) {
    return pageTypes.CURRENT_JOB;
  }

  if ([routes.SERVICE, `${routes.SERVICE}/`].includes(pathname)) {
    return pageTypes.JOB_LIST;
  }

  const isCurrentFilterUser = filters?.user_id === String(user_id);
  const isCommunicationPage = [routes.COMMUNICATION, `${routes.COMMUNICATION}/`].includes(pathname);
  if (isCurrentFilterUser && isCommunicationPage) {
    return pageTypes.CURRENT_COMMUNICATION_CHAT;
  }

  if (isCommunicationPage) {
    return pageTypes.COMMUNICATION;
  }

  return pageTypes.OTHER;
};

export const isMatchGlobalFilters = ({ data, filters }) => {
  if (filters.admins.length && data.owner_id && !filters.admins.some(({ id }) => id === data.owner_id)) {
    return false;
  }

  if (
    filters.locations.length &&
    data.operational_area_id !== undefined &&
    !filters.locations.some((operational_area_id) => operational_area_id === data.operational_area_id)
  ) {
    return false;
  }

  return !data.is_test || filters.is_test;
};
