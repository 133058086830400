import React, { Fragment } from 'react';

import { useTheme } from 'hooks';
import { userGroups } from 'common/enums/user';
import { LoadingDots, Tooltip, UserName, Icon, LoadingSpinner } from 'components';
import { formatDateShortTimeStampSmall } from 'utils';
import { getInfo, getEmptyStateText } from './helpers';

import { formatters } from 'utils';
import { adminStatuses } from 'common/enums/job';
import styles from 'constants/styles';
import {
  InfoRow,
  Wrapper,
  Info,
  Tag,
  ServiceWrapper,
  ServiceInfoRow,
  ServiceRightBlock,
  TotalValue,
  ServiceLeftBlock,
  GrayTitle,
  MainColumn,
  LoadMoreButton,
  AssignPro,
  ScheduledText,
  Divider,
  DateTime,
  DobbyCash,
  EmptyServiceLists,
  EmptyText,
  nameTitleCSS,
  InfoJob
} from './styled';

import { openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { useDispatch } from 'hooks';
import { getAdminStatusNameByCode, getAdminStatusColorByCode, getTimeWindow } from 'common/helpers/job';
import { jobTypesByTab } from 'common/enums/job';

const UserServiceList = ({
  list = {},
  type,
  userGroupId,
  onJobClick = () => {},
  onLoadMore = () => {},
  customerId,
  hasJobs
}) => {
  const loadMoreButton = list.count > list.list.length;
  const theme = useTheme();
  const dispatch = useDispatch();

  if (list.list.length) {
    return (
      <Wrapper>
        {list.list.map((service, i) => {
          const isCustomerType = userGroupId === userGroups.CUSTOMER.TYPE;
          const statusName = getAdminStatusNameByCode(service.admin_status);
          const time = getTimeWindow({
            datetime_visit: service.datetime_visit,
            datetime_window_end: service.datetime_window_end
          });
          const handleToggleProAssignModal = () => {
            dispatch(
              openModal(modalTypes.ASSIGN_PROVIDER_MAP, {
                jobId: service?.id,
                serviceArea: service?.service_area
              })
            );
          };
          const isScheduled = service.admin_status === adminStatuses.SCHEDULED.TYPE;
          const isUnfulfilled = type !== jobTypesByTab.ACTIVE;
          const provider = service?.service_provider?.user;
          const providerName = provider?.given_name + ' ' + provider?.family_name;
          const isLast = list.list?.length - 1 === i;
          return (
            <Fragment key={service.id}>
              <ServiceWrapper
                onClick={(e) =>
                  isCustomerType && !service?.service_provider_id && !isUnfulfilled
                    ? handleToggleProAssignModal()
                    : onJobClick({ id: service.id, type }, e)
                }
                onContextMenu={(e) => onJobClick({ id: service.id, type }, e)}>
                <ServiceInfoRow>
                  <ServiceLeftBlock>
                    <MainColumn>
                      <InfoRow margin={'0'}>
                        <GrayTitle marginRight={6}>{service.id} - </GrayTitle>

                        {isCustomerType ? (
                          service?.service_provider_id ? (
                            <UserName
                              textStyle={nameTitleCSS}
                              pro_stage={service?.service_provider?.pro_stage}
                              given_name={providerName.trim() ? providerName : `ID_${service.service_provider_id}`}
                            />
                          ) : (
                            <AssignPro>{isUnfulfilled ? 'No Pro' : 'Assign a Pro'}</AssignPro>
                          )
                        ) : (
                          <GrayTitle>{service?.user?.full_name || `ID_${service?.user_id}`}</GrayTitle>
                        )}
                      </InfoRow>
                      <DateTime>Requested: {formatDateShortTimeStampSmall(service?.date_created)}</DateTime>
                      <DateTime>{getInfo(type, service)}</DateTime>
                      <InfoRow>
                        <InfoJob backgroundColor={styles.colors.ASSIGNMENT_GREEN}>
                          {service.job_category_name || '-'}
                        </InfoJob>
                        {isScheduled ? (
                          <Info
                            fontSize={12}
                            color={
                              service.is_past_scheduled
                                ? styles.colors.GRAY
                                : getAdminStatusColorByCode(service.admin_status)
                            }>
                            {service.is_past_scheduled && 'Past'} Scheduled
                            <ScheduledText>{time}</ScheduledText>
                          </Info>
                        ) : (
                          type !== jobTypesByTab.PAID && (
                            <Info color={getAdminStatusColorByCode(service.admin_status)}>{statusName}</Info>
                          )
                        )}
                      </InfoRow>
                      {Boolean(service?.tag_list?.length) && (
                        <InfoRow margin="8px 0 0 0">
                          {service?.tag_list.map(({ id, name }) => (
                            <Tag key={id}>{name}</Tag>
                          ))}
                        </InfoRow>
                      )}
                    </MainColumn>
                  </ServiceLeftBlock>
                  <div>
                    <ServiceRightBlock>
                      <div>
                        <TotalValue>
                          {service.amount_total_paid ? formatters.dollarCurrencyDB(service.amount_total_paid) : '$0.00'}
                        </TotalValue>
                        {Boolean(service.amount_total_dobby_cash_bonus || service.amount_customer_reward) && (
                          <DobbyCash>
                            -{' '}
                            {formatters.dollarCurrencyDB(
                              (service.amount_customer_reward || 0) + (service.amount_total_dobby_cash_bonus || 0)
                            )}
                          </DobbyCash>
                        )}
                        {Boolean(service.amount_dobby_cashback) && (
                          <DobbyCash>+ {formatters.dollarCurrencyDB(service.amount_dobby_cashback || 0)}</DobbyCash>
                        )}
                      </div>
                      {/* Change to total discount */}
                      <Icon name="arrowRight" margin="2.5px 0 0" color={theme.colors.DARK_GREY} width={9} height={18} />
                    </ServiceRightBlock>
                  </div>
                </ServiceInfoRow>
              </ServiceWrapper>
              <Divider marginBottom={isLast ? '4px' : '0'} />
            </Fragment>
          );
        })}
        {loadMoreButton && (
          <LoadMoreButton onClick={list.loading ? null : onLoadMore}>
            {list.loading ? <LoadingDots /> : 'Load more'}
          </LoadMoreButton>
        )}
      </Wrapper>
    );
  }

  return (
    <EmptyServiceLists>
      {list.loading ? (
        <LoadingSpinner text="Please wait" type="logo" />
      ) : (
        <>
          <Icon name="emptyBox" color={theme.colors.DARK_GREY_3} size={160} />
          <EmptyText>{getEmptyStateText({ customerId, type, hasJobs })}</EmptyText>
        </>
      )}
    </EmptyServiceLists>
  );
};

export default UserServiceList;
