import React, { useEffect, useState } from 'react';
import { Icon, LoadingSpinner, StyledTooltip } from 'components';
import {
  CollapseButton,
  HeaderRow,
  Row,
  Table,
  RowItem,
  LoadingWrapper,
  EmptyText,
  Wrapper
} from '../DobbyCashTable/styled';
import dates from 'constants/dates';
import { Collapse } from '@mui/material';
import { formatters } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyRowItem, UserRole } from '../DobbyCashTable/components';
import { getAgentCharges } from 'store/actions';
import { selectAgentCharges, selectAgentChargesLoading } from 'store/reducers/agent';
import { authors } from 'common/enums/job';
import { TooltipWrapper } from './styled';

const AmountTooltip = 'Charged amount as particular homeowner has spent equal gift amount';

export const ChargesTable = ({ userId, isAgent = false }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const chargesList = useSelector(selectAgentCharges);
  const isLoading = useSelector(selectAgentChargesLoading);

  useEffect(() => {
    dispatch(getAgentCharges(userId));
  }, [userId]);

  return (
    <Wrapper>
      <Collapse sx={{ overflowY: 'auto', maxHeight: 360 }} in={checked}>
        <Table>
          <thead>
            <HeaderRow>
              <RowItem header>Date</RowItem>
              <RowItem header charges flex flexGap={5}>
                Amount
                <StyledTooltip title={AmountTooltip} maxWidth={200} color={`#20292D`} arrow placement="top-start">
                  <TooltipWrapper>
                    <Icon name="info" size={12} />
                  </TooltipWrapper>
                </StyledTooltip>
              </RowItem>
              <RowItem header charges>
                HO
              </RowItem>
              <RowItem header>Stripe link</RowItem>
            </HeaderRow>
          </thead>
          <tbody>
            {!isLoading &&
              chargesList?.map(({ id, date_created, amount, user_id, homeowner_id, stripe_link }, i, arr) => (
                <Row isLast={i === arr.length - 1} key={id}>
                  <RowItem width={80}>
                    {Boolean(date_created) ? formatters.dateWithTimezone(date_created, dates.DATETIME_FULL_YEAR) : '-'}
                  </RowItem>
                  <CurrencyRowItem showPrefix={false} amount={amount} />
                  <RowItem>
                    <UserRole group_id={authors.HO} id={homeowner_id} />
                  </RowItem>
                  <RowItem>
                    <a href={stripe_link} target="_blank">
                      {stripe_link}
                    </a>
                  </RowItem>
                </Row>
              ))}
          </tbody>
        </Table>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        ) : (
          !chargesList?.length && <EmptyText>No charge records yet</EmptyText>
        )}
      </Collapse>
      <CollapseButton onClick={() => setChecked(!checked)}>
        <Icon name="collapse" rotate={checked ? 0 : 180} />

        {checked ? 'Collapse' : 'Charges'}
      </CollapseButton>
    </Wrapper>
  );
};
