export const typographyVariants = {
  H1: 'heading1',
  H2: 'heading2',
  H3: 'heading3',
  H4: 'heading4',
  TEXT: 'text',
  D1: 'description1',
  D2: 'description2',
  C1: 'caption1',
  C2: 'caption2'
};
