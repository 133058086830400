import React from 'react';
import { useController } from 'react-hook-form';

import styles from 'constants/styles';
import messages from 'constants/messages';
import { Dropdown } from 'components';

import { FlexColumn, Label, Error } from './styled';

const Select = ({
  label,
  name,
  control,
  rules = {},
  error = {},
  width = 160,
  placeholder,
  fill,
  options,
  type = 'square',
  withSearch = false,
  onSearch = null,
  onCustomChange,
  style = {},
  disabled,
  hasDisabledOpacity,
  ...props
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  });

  const onSelectChange = (target) => {
    onChange(target);
    if (onCustomChange) {
      onCustomChange(target);
    }
  };

  return (
    <FlexColumn hasDisabledOpacity={hasDisabledOpacity}>
      {label && <Label>{label}</Label>}
      <Dropdown
        value={{ id: value }}
        placeholder={placeholder}
        type={type}
        fill={fill}
        options={options}
        containerBackground={styles.colors.LIGHT_GRAY_2}
        onChange={onSelectChange}
        width={width}
        valueRef={ref}
        withSearch={withSearch}
        onSearch={onSearch}
        style={style}
        disabled={disabled || hasDisabledOpacity}
        hasDisabledOpacity={hasDisabledOpacity}
        {...props}
        {...inputProps}
      />
      {(invalid && isTouched) ||
        (Boolean(error.type) && (
          <Error>{error.message || messages.formErrors[error.type?.toUpperCase() ?? 'REQUIRED']}</Error>
        ))}
    </FlexColumn>
  );
};

export default Select;
