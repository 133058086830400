import React, { Fragment } from 'react';
import { LoadingDots } from 'components';
import { Divider, LoadMoreButton } from './styled';
import Referral from './Referral';

const CustomerReferralsList = ({ onLoadMore, referrals }) => {
  if (!referrals.list?.length) {
    return null;
  }

  return (
    <div>
      {Boolean(referrals.list.length) &&
        referrals.list.map((referral) => (
          <Fragment key={referral.id}>
            <Referral referral={referral} />
            <Divider />
          </Fragment>
        ))}
      {referrals.list.length < referrals.count && (
        <LoadMoreButton onClick={onLoadMore}>{referrals.loading ? <LoadingDots /> : 'Load More'}</LoadMoreButton>
      )}
    </div>
  );
};

export default CustomerReferralsList;
