import styled from 'styled-components';

export const CircleBackground = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.BLACK};
  background-color: ${({ theme }) => theme.colors.DARK_YELLOW};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  padding: 2px 1px;
  height: 34px;
  width: 34px;
  position: absolute;
  left: -12px;
  bottom: -6px;
`;
