import styled from 'styled-components';

export const Divider = styled.div`
  margin: 1.5px 0;
`;

export const Cell = styled.div`
  color: ${({ isCurrentColumn, theme }) => (isCurrentColumn ? theme.colors.DARK_YELLOW : theme.colors.DARK_GREY)};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  display: flex;
  align-items: center;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`}px;
`;

export const SortIcons = styled.div`
  flex-direction: column;
  min-width: 15px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 6px;
`;

export const SkillCell = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  height: 34px;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-left: 5px;
`;
