import React from 'react';

const IconThumbUp = ({ size, color = '#FFFFFF' }) => (
  <svg
    width={size * 1.1}
    height={size}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1525 5.29403C10.3321 5.4736 10.4356 5.71709 10.4356 5.9784C10.4356 6.35536 10.2199 6.68625 9.89864 6.84799C9.9656 6.98147 10.0008 7.12886 10.0008 7.28278C10.0008 7.66017 9.78472 7.99106 9.46341 8.1528C9.56297 8.35106 9.59209 8.57933 9.5421 8.80587C9.44472 9.2463 9.03036 9.56544 8.55687 9.56544H4.56589C4.28066 9.56544 3.83283 9.47544 3.47892 9.30848V4.64836L3.53109 4.62184L4.7833 1.90916V0.434786C4.7833 0.368711 4.8133 0.306101 4.8646 0.264789C4.86491 0.264555 4.86537 0.264198 4.866 0.263723C4.89304 0.243064 5.21127 0 5.65287 0C6.15593 0 6.95725 0.796099 6.95725 1.73917C6.95725 2.33396 6.74421 3.0744 6.61116 3.47833H9.29688C9.8821 3.47833 10.3799 3.91139 10.4308 4.46401C10.4591 4.77401 10.3556 5.07186 10.1525 5.29403ZM0 5.43464C0 4.8355 0.487838 4.34767 1.08698 4.34767H3.04355V9.77996C2.86136 9.91737 2.63615 9.99997 2.39136 9.99997H1.08698C0.487838 9.99997 0 9.51213 0 8.91299V5.43464Z"
      fill={color}
    />
  </svg>
);

export default IconThumbUp;
