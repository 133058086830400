import React from 'react';
import styles from 'constants/styles';

const IconGoBack = ({ size = 24, color = styles.colors.BLACK }) => {
  return (
    <svg
      width={size}
      height={size * 0.7}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.797377 9.01495C0.61435 8.83421 0.5 8.5817 0.5 8.30339C0.5 8.01954 0.618897 7.76268 0.808229 7.58127L7.5966 0.792894C7.98713 0.40237 8.62029 0.40237 9.01082 0.792894C9.40134 1.18342 9.40134 1.81658 9.01082 2.20711L3.91454 7.30339L22.793 7.30339C23.3453 7.30339 23.793 7.7511 23.793 8.30339C23.793 8.85567 23.3453 9.30339 22.793 9.30339L3.91424 9.30339L9.01082 14.4C9.40134 14.7905 9.40134 15.4237 9.01082 15.8142C8.62029 16.2047 7.98713 16.2047 7.5966 15.8142L0.797377 9.01495Z"
        fill={color}
      />
    </svg>
  );
};

export default IconGoBack;
