import React from 'react';
import { connect } from 'react-redux';

import { deleteProviderReview, closeModal, getProviderReviews, getCustomerReviews } from 'store/actions';
import { selectModalData, selectModalType } from 'store/reducers/modal';
import { selectDeleteReviewLoading } from 'store/reducers/provider';

import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import { ModalText, BoldTextWrapper } from './styled';

const DeleteReviewModal = ({
  confirmationData,
  openedModalType,
  deleteProviderReview,
  deleteReviewLoading,
  closeModal,
  getProviderReviews,
  getCustomerReviews,
  userId,
  isCustomersReviews
}) => {
  const onConfirmReviewDelete = () =>
    deleteProviderReview({
      reviewId: confirmationData.reviewId,
      service_demand_id: confirmationData.service_demand_id,
      resultModalType: modalTypes.DELETE_REVIEW_RESULT
    });

  const onModalClose = () => closeModal(openedModalType);

  const onConfirmationModalClose = () => {
    if (isCustomersReviews) {
      getCustomerReviews({ page: 1, reset: true, customerId: userId });
    } else {
      getProviderReviews({ page: 1, reset: true, providerId: userId });
    }
    onModalClose();
  };

  return (
    <>
      <ModalConfirmation
        modalType={modalTypes.DELETE_REVIEW}
        title={
          confirmationData.service_demand_id
            ? `Remove review for job ID ${confirmationData.service_demand_id}?`
            : `Remove external review?`
        }
        text={
          <ModalText>
            <BoldTextWrapper>Current review won’t be available anymore.</BoldTextWrapper>
          </ModalText>
        }
        hideModal={onModalClose}
        primaryButton={{
          title: 'Cancel',
          disabled: deleteReviewLoading,
          onClick: onModalClose
        }}
        secondaryButton={{
          title: 'Remove',
          loading: deleteReviewLoading,
          disabled: deleteReviewLoading,
          onClick: onConfirmReviewDelete
        }}
      />
      <ModalConfirmation
        modalType={modalTypes.DELETE_REVIEW_RESULT}
        title={
          confirmationData.deleteReviewError
            ? ''
            : `Review has removed${
                confirmationData.service_demand_id ? ` for job ID ${confirmationData.service_demand_id}` : ''
              }`
        }
        text={
          confirmationData.deleteReviewError ? (
            <ModalText>
              <BoldTextWrapper>Something went wrong. Please, try again later</BoldTextWrapper>
            </ModalText>
          ) : null
        }
        hideModal={onConfirmationModalClose}
        secondaryButton={{
          title: 'OK',
          onClick: onConfirmationModalClose
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  confirmationData: selectModalData(state),
  openedModalType: selectModalType(state),
  deleteReviewLoading: selectDeleteReviewLoading(state)
});

const mapDispatchToProps = {
  deleteProviderReview,
  closeModal,
  getProviderReviews,
  getCustomerReviews
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReviewModal);
