import styled, { css } from 'styled-components';
import styles from 'constants/styles';
import 'react-image-lightbox/style.css';

export const AttachmentButton = styled.div`
  margin-right: 13px;
  width: 23px;
  padding: 0 0 1px;
  cursor: pointer;
`;

export const SystemMessageText = styled.div`
  background-color: ${({ theme }) => theme.colors.DARK_3};
  padding: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin: 10px 0 15px 0;
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 1.31;
  font-weight: 300;
  margin: 0;
  word-break: break-word;
  color: ${({ color }) => color || styles.colors.BLACK};
  padding: 0;
  white-space: pre-wrap;
`;

export const ChatPlaceholder = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  color: ${({ theme }) => theme.colors.DARK_GREY_3};
  text-align: center;

  span {
    max-width: 280px;
    padding-top: 13px;
  }
`;

const mediaStyle = css`
  width: 181px;
  height: ${({ type }) => (type === 'vertical' ? 256 : 120)}px;
  margin: 2px 2px 0;
  border-radius: 15px;
  object-fit: cover;
`;

export const MessageImagePreview = styled.img`
  ${mediaStyle}
`;

export const StyledReactPlayer = styled.video`
  ${mediaStyle}
`;

export const Time = styled(Text)`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  color: ${styles.colors.DARK_GREY};
`;

export const ChatContainer = styled.div`
  flex: 1;
  height: ${`calc(100vh - ${styles.layout.headerHeight + styles.layout.tabsHeaderHeight}px)`};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const LoadingMessageAttachmentContainer = styled.span`
  margin: 0 5px 0 0;
  div {
    max-width: 16px;
    max-height: 16px;
    align-self: center;
  }
`;

export const PlayButtonWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.BLACK}80;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const MediaMessageInnerWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  cursor: pointer;
  border-radius: 15px;

  ${({ noThumb }) =>
    noThumb &&
    `
    border: 1px solid red;
  `}
`;

export const MediaMessageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;
