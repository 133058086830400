import React from 'react';

const IconOk = ({ size = 18, width, height, type = 'short', color = '#273444', strokeWidth = 2 }) => {
  return type === 'short' ? (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8333 6.25L7.875 12.2083L5.16667 9.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width={size} height={size * 0.833} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1633 0.155811C11.4915 0.408534 11.5527 0.879452 11.2999 1.20763L5.16665 9.17219C5.03758 9.3398 4.84372 9.44511 4.63286 9.46215C4.422 9.4792 4.21375 9.40641 4.05942 9.26171L0.237006 5.67766C-0.0651572 5.39434 -0.0804325 4.91971 0.202888 4.61755C0.486208 4.31538 0.960836 4.30011 1.263 4.58343L4.48248 7.60214L10.1115 0.29244C10.3642 -0.0357421 10.8351 -0.0969132 11.1633 0.155811Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOk;
