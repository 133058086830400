import React from 'react';
import routes from 'constants/routes';
import { dobbyCashEntity } from 'common/enums/job';

const ActionReference = ({ reference_id, reference_entity, service_request_id, related_user }) => {
  if (!reference_id) {
    return '';
  }
  if (reference_entity === dobbyCashEntity.PAYMENT) {
    return (
      <a href={`${routes.SERVICE}/${service_request_id}`} target="_blank">
        ID{reference_id}
      </a>
    );
  }

  if (reference_entity === dobbyCashEntity.JOB) {
    return (
      <a href={`${routes.SERVICE}/${reference_id}`} target="_blank">
        ID{reference_id}
      </a>
    );
  }

  if (reference_entity === dobbyCashEntity.HO) {
    return (
      <a href={`${routes.CUSTOMER}/${reference_id}`} target="_blank">
        {related_user.full_name || 'HO'} ({reference_id})
      </a>
    );
  }
  if (reference_entity === dobbyCashEntity.PRO) {
    return (
      <a href={`${routes.PROVIDER}/${reference_id}`} target="_blank">
        {related_user.full_name || 'Pro'} ({reference_id})
      </a>
    );
  }
  return '';
};

export default ActionReference;
