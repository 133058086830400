import React from 'react';

import { redirectNotificationTypes } from 'common/enums/notification';
import { Icon } from 'components';
import {
  NotificationWrapper,
  NotificationHeading,
  NotificationLeftHeadingWrapper,
  NotificationHeader,
  NotificationHeaderText,
  NotificationHeaderTime,
  NotificationTarget,
  IconWrapper,
  NotificationText,
  NotificationTitle
} from './styled';

const Notification = ({ notification, onClick, transparent, width, padding, hasAnimation }) => {
  const { redirectType, title, time, target, text, iconName } = notification;

  const handleNotificationClick = () => onClick(notification);

  return (
    <NotificationWrapper
      transparent={transparent}
      padding={padding}
      width={width}
      hasAnimation={hasAnimation}
      onClick={handleNotificationClick}>
      <NotificationHeading>
        <NotificationLeftHeadingWrapper>
          <IconWrapper padding={redirectType === redirectNotificationTypes.NEGATIVE ? '5px 7px' : '5px 4px'}>
            <Icon name={iconName} />
          </IconWrapper>
          <NotificationHeader>
            <NotificationHeaderText transparent={transparent}>
              <NotificationTitle children={title} />
            </NotificationHeaderText>
            <NotificationHeaderTime>{time}</NotificationHeaderTime>
          </NotificationHeader>
        </NotificationLeftHeadingWrapper>
        <NotificationTarget transparent={transparent}>{target}</NotificationTarget>
      </NotificationHeading>
      {text && <NotificationText>{text}</NotificationText>}
    </NotificationWrapper>
  );
};

export default Notification;
