export const dataTypes = {
  SELECT: 'SELECT',
  DATE: 'DATE',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  CHECKBOX: 'CHECKBOX'
};

export const ownerReportFields = {
  GEOGRAPHY: {
    NAME: 'Geography',
    TYPE: 'operational_area_id',
    DATA_TYPE: dataTypes.SELECT
  },
  OWNER: {
    NAME: 'Owner',
    TYPE: 'owner_id',
    DATA_TYPE: dataTypes.SELECT
  },
  DATETIME_CREATED_FROM: {
    NAME: 'Request Date',
    TYPE: 'date_created_gte',
    DATA_TYPE: dataTypes.DATE
  },
  DATETIME_CREATED_TO: {
    NAME: 'Request Date',
    TYPE: 'date_created_lte',
    DATA_TYPE: dataTypes.DATE
  },
  DATETIME_AS_OF: {
    NAME: 'As of Date',
    TYPE: 'as_of_date',
    DATA_TYPE: dataTypes.DATE
  },
  EXCLUDE_INSPECTIONS: {
    NAME: 'Exclude Inspections',
    TYPE: 'exclude_inspections',
    DATA_TYPE: dataTypes.CHECKBOX
  },
  EXCLUDE_DUPLICATES: {
    NAME: 'Remove Duplicates From Calculations',
    TYPE: 'exclude_duplicates',
    DATA_TYPE: dataTypes.CHECKBOX
  }
};
