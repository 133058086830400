import React from 'react';
import styles from 'constants/styles';

const IconArrowFillRight = ({ color = styles.colors.WHITE, width = 6, height = 10 }) => (
  <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8.58579L0 1.41421C0 0.523309 1.07714 0.0771425 1.70711 0.707107L5.29289 4.29289C5.68342 4.68342 5.68342 5.31658 5.29289 5.70711L1.70711 9.29289C1.07714 9.92286 0 9.47669 0 8.58579Z"
      fill={color}
    />
  </svg>
);

export default IconArrowFillRight;
