import React from 'react';
import moment from 'moment-timezone';

import { LoadingDots, DropdownMenu } from 'components';

import {
  CardBorder,
  FlexContainer,
  FlexColumn,
  TitleText,
  FlexRow,
  ControlRow,
  DropDownWrapper,
  DelimiterBlock,
  SummaryTitle,
  FieldTitle,
  FieldValue
} from './styled';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import dates from 'constants/dates';

const PromoCode = ({ promoCode = {}, isLoading = false, onUpdate, onModalOpen, onUserClick }) => {
  const dispatch = useDispatch();
  if (isLoading) {
    return (
      <CardBorder>
        <LoadingDots />
      </CardBorder>
    );
  }

  return (
    <CardBorder isActive={promoCode.is_active}>
      <FlexContainer>
        <FlexColumn>
          <FlexRow>
            <ControlRow>
              <TitleText margin="0">{promoCode.referral_code.toUpperCase()}</TitleText>
            </ControlRow>
            <ControlRow>
              <TitleText margin="0">{`-$${promoCode.amount / 100}`}</TitleText>
              <DropDownWrapper>
                <DropdownMenu
                  menuType="transparent"
                  options={[
                    ...(promoCode.is_active
                      ? [
                          {
                            id: 1,
                            name: 'Edit Reward',
                            onClick: () => onModalOpen()
                          }
                        ]
                      : []),
                    {
                      id: 2,
                      name: promoCode.is_active ? 'Disable' : 'Enable',
                      onClick: () =>
                        onUpdate({ referral_code: promoCode.referral_code, is_active: !promoCode.is_active })
                    },
                    ...(!promoCode.datetime_expiry
                      ? [
                          {
                            id: 3,
                            name: 'Expire',
                            onClick: () => {
                              if (promoCode?.count_pending_payments > 0) {
                                dispatch(
                                  openModal(modalTypes.RESULT, {
                                    title: `The “${promoCode.referral_code}” can’t be expired.`,
                                    text: `The “${promoCode.referral_code}” has been attached to invoice(s).`
                                  })
                                );
                              } else {
                                dispatch(
                                  openModal(modalTypes.EXPIRE_PROMO_CODE, {
                                    referralCode: promoCode.referral_code
                                  })
                                );
                              }
                            }
                          }
                        ]
                      : [])
                  ]}
                  placeholder="Actions"
                  margin="0 0 0 7px"
                />
              </DropDownWrapper>
            </ControlRow>
          </FlexRow>
          <FlexRow>
            <FieldTitle>{promoCode.description}</FieldTitle>
          </FlexRow>
          <DelimiterBlock />
          <FlexRow>
            <FlexColumn>
              <FieldTitle>Reward Preview</FieldTitle>
              <FieldValue>{promoCode.title ? promoCode.title : 'Empty title'} </FieldValue>
            </FlexColumn>
            {promoCode.datetime_expiry ? (
              <FlexColumn>
                <FieldTitle>Valid</FieldTitle>
                <FieldValue>
                  {moment(promoCode.datetime_expiry)
                    .add(moment(promoCode.datetime_expiry).utcOffset(), 'minutes')
                    .format(dates.DATETIME_ISO)}
                </FieldValue>
              </FlexColumn>
            ) : null}
          </FlexRow>
          <DelimiterBlock />
          <FlexRow>
            <SummaryTitle>
              Number of times
              <br /> attached reward
            </SummaryTitle>
            <TitleText withHover margin={'0'} onClick={() => onUserClick(promoCode)}>
              {promoCode.count_used || 0}
            </TitleText>
          </FlexRow>
        </FlexColumn>
      </FlexContainer>
    </CardBorder>
  );
};

export default PromoCode;
