import styled from 'styled-components';
import styles from 'constants/styles';

export const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.MODAL_BG};
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme, background }) => background || theme.colors.MODAL};
  padding: ${({ smallPaddings, middlePaddings, bundleModal }) =>
    bundleModal ? '10px 25px 10px' : middlePaddings ? '24px 24px 14px' : smallPaddings ? '20px 21px' : '40px 42px'};
  ${({ padding }) => padding && `padding: ${padding}`};
  margin: ${({ margin }) => margin || '10px'};
  ${({ marginTop }) => !isNaN(marginTop) && `margin-top: ${marginTop}px`};
  ${({ marginLeft }) => !isNaN(marginLeft) && `margin-left: ${marginLeft}px`};
  ${({ marginRight }) => !isNaN(marginRight) && `margin-right: ${marginRight}px`};
  ${({ marginBottom }) => !isNaN(marginBottom) && `margin-bottom: ${marginBottom}px`};
  border-radius: ${({ borderRadius }) => borderRadius ?? '4px'};
  ${({ maxWidth, bundleModal }) => (maxWidth && !bundleModal ? `max-width: ${maxWidth}px` : null)};
  min-width: ${({ minWidth }) => minWidth}px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : `calc(100vh - ${styles.layout.headerHeight}px)`)};
  overflow: ${({ overflowMode = 'overlay' }) => overflowMode};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isFullscreen, marginLeft, marginRight }) =>
    isFullscreen &&
    `
    align-self: flex-end;
    min-width: calc(100vw - ${marginLeft || 0}px - ${marginRight || 0}px);
    max-width: calc(100vw - ${marginLeft || 0}px - ${marginRight || 0}px);
    min-height: 100%;
    `};

  p {
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  }
`;

export const CloseButton = styled.button`
  position: relative;
  align-self: center;
  border: none;
  box-shadow: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: 0;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY};

  &:focus {
    outline: 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
  padding: 16px 24px;
  width: 100%;
  background: ${({ theme }) => theme.colors.DARK_3};
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  border-radius: 4px 4px 0 0;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  ${({ isFullscreen }) => isFullscreen && 'flex-grow: 0'};
  width: 100%;
  ${({ withoutHeading }) => withoutHeading && 'flex-direction: row-reverse'};
`;
