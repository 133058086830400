import React from 'react';
import PropTypes from 'prop-types';

import { fieldTypes } from 'common/enums/form';
import messages from 'constants/messages';
import { StyledInput, InputRow, CloseButton, Label, FlexColumn, Error } from './styled';

const HookInput = ({
  label,
  name,
  register,
  rules = {},
  error = {},
  inputConfig = {},
  reset,
  fieldType,
  wrapperStyle = {},
  placeholder,
  width,
  isHideErrorText,
  renderTooltip,
  ...props
}) => (
  <FlexColumn>
    {label && <Label>{label}</Label>}
    <InputRow closeButton={Boolean(reset)} style={wrapperStyle}>
      <StyledInput
        autoComplete="off"
        width={width}
        {...props}
        {...inputConfig}
        {...fieldType}
        bigRightPadding={Boolean(reset)}
        {...register(name, rules)}
        placeholder={placeholder}
      />
      {Boolean(reset) && <CloseButton onClick={reset} />}
      {Boolean(renderTooltip) && renderTooltip()}
    </InputRow>

    {Boolean(error.type && !isHideErrorText) && (
      <Error>{error.message || messages.formErrors[error.type?.toUpperCase()]}</Error>
    )}
  </FlexColumn>
);

HookInput.propTypes = {
  fieldType: PropTypes.object
};

HookInput.defaultProps = {
  fieldType: fieldTypes.TEXT,
  size: 'small'
};

export default HookInput;
