import { useState, useRef, useEffect } from 'react';

const useHover = () => {
  const [value, setValue] = useState(false);
  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  const ref = useRef();

  useEffect(() => {
    const node = ref.current;
    if (!node) {
      return;
    }
    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return [ref, value];
};

export default useHover;
