import styled from 'styled-components';
import styles from 'constants/styles';

export const MessageFormContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${styles.colors.BLACK};
  max-width: 100%;
  position: relative;
`;

export const MessageFormInputSection = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
  width: 100%;
`;

export const MessageInput = styled.textarea`
  height: 125px;
  outline: 0 none;
  text-align: left;
  background-color: #353d41;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  padding: 12px 16px 12px 16px;
  width: calc(100% - 24px);
  resize: none;
  flex: 1;
  margin: 16px 12px 24px 12px;
`;

export const MessageButtons = styled.div`
  float: right;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 28px 24px 28px;
  gap: 16px;
`;

export const MessageAuthor = styled.div`
  color: ${styles.colors.GRAY};
  font-size: 14px;
  padding: 16px 28px;
  font-weight: 400;
  background: ${({ theme }) => theme.colors.DARK_2};
`;
