import React from 'react';
import styles from 'constants/styles';

const IconMicrophone = ({ size = 24, color = styles.colors.BLACK }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11C15 12.66 13.66 14 12 14C10.34 14 9 12.66 9 11V5C9 3.34 10.34 2 12 2C13.66 2 15 3.34 15 5V11ZM12 4C11.45 4 11 4.45 11 5V11C11 11.55 11.45 12 12 12C12.55 12 13 11.55 13 11V5C13 4.45 12.55 4 12 4Z"
        fill={color}
      />
      <path
        d="M12 16C14.76 16 17 13.76 17 11H19C19 14.53 16.39 17.43 13 17.92V21H11V17.92C7.61 17.43 5 14.53 5 11H7C7 13.76 9.24 16 12 16Z"
        fill={color}
      />
    </svg>
  );
};

export default IconMicrophone;
