import Checkbox from './Checkbox';
import DatePickerMui from './DatePickerMui/DatePicker';
import DateTimePicker from './DateTimePicker/DateTimePicker';
import DateRangePicker from './DateRangePicker';
import Input from './Input';
import { ControlledTextarea, Textarea } from './Textarea';
import { RichTextEditor } from './RichTextEditor/RichTextEditor';
import Select from './Select';
import Radio from './Radio';
import SelectMultiple from './SelectMultiple';

export default class HookForm {
  static Checkbox = Checkbox;
  static ControlledTextarea = ControlledTextarea;
  static DatePickerMui = DatePickerMui;
  static DateTimePicker = DateTimePicker;
  static DateRangePicker = DateRangePicker;
  static Input = Input;
  static Radio = Radio;
  static RichTextEditor = RichTextEditor;
  static Select = Select;
  static SelectMultiple = SelectMultiple;
  static Textarea = Textarea;
}
