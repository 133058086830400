import { EXCLUDE_PREVIEW_LINK } from 'common/constants/chat';

export const filterMessagesWithLinksPreview = (messageList) =>
  messageList
    .filter((message) => message.link_list.some(({ preview_url }) => !preview_url.startsWith(EXCLUDE_PREVIEW_LINK)))
    .flatMap((message) =>
      message.link_list
        .filter(({ preview_url }) => !preview_url.startsWith(EXCLUDE_PREVIEW_LINK))
        .map((link) => ({ ...link, message_id: message._id }))
    );
