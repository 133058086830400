import styled from 'styled-components';

export const Question = styled.div`
  padding: 21px 16px;
  &:hover {
    background: ${({ theme }) => theme.colors.DARK_GREY_3};
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AnswerDate = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin: 10px 0;
  text-transform: uppercase;
`;

export const Answer = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const ViewMore = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;
export const Submitted = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;

export const ViewDetails = styled.div`
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

export const AnswerWrapper = styled.div`
  max-height: 48px;
  overflow: hidden;
`;
