import React from 'react';

const IconCopy = (props) => {
  const { size = 20, color = '#FFFFFF' } = props;

  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x={size / 2 - size / 8}
        y={size / 8}
        width={size / 2 + size / 8}
        height={size / 2 + size / 8}
        fill={color}></rect>
      <rect
        x={size / 8}
        y={size / 2 - size / 8}
        width={size / 2 + size / 8}
        height={size / 2 + size / 8}
        fill={color}></rect>
    </svg>
  );
};

export default IconCopy;
