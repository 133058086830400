import React from 'react';

import MessageDay from './MessageDay';
import MessageSystem from './MessageSystem';
import MessageUser from './MessageUser';

import { MessageFlexRow } from './styled';

const MessageRow = (props) => {
  const { message = {}, prevMessage = {}, overwriteComponents = {} } = props;
  const { messageSystem, ...messageOverwrites } = overwriteComponents;

  return (
    <MessageFlexRow>
      <MessageDay message={message} prevMessage={prevMessage} />
      {message.system ? (
        (messageSystem ? messageSystem : MessageSystem)({ message })
      ) : (
        <MessageUser {...props} overwriteComponents={messageOverwrites} />
      )}
    </MessageFlexRow>
  );
};

export default MessageRow;
