import React from 'react';
import AsyncSelect from 'react-select/async';

import { getFullName } from 'common/helpers/user';
import { Avatar, Icon } from 'components';
import styles from 'constants/styles';
import { debounceWithPromise } from 'utils';

import { NameWithOptions, Label, SelectWrapper, Option, OptionPhoto, OptionText, InvalidMessage } from './styled';

const asyncSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginRight: 7,
    borderRadius: 4,
    cursor: 'pointer'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.14,
    color: styles.colors.DARK_GREY_2
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: 600,
    fontSize: 12,
    color: styles.colors.WHITE
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    zIndex: 3
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: styles.colors.DARK_GREY_3,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: styles.colors.DARK_4
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: 600,
    fontSize: 12,
    color: styles.colors.BLACK
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontWeight: 600,
    fontSize: 12,
    color: styles.colors.BLACK
  })
};

const formatOptionLabel = (option) => (
  <Option>
    <OptionPhoto>
      <Avatar avatarSize={16} url={option.user?.profile_image_url} alt="Avatar" size={32} />
    </OptionPhoto>
    <OptionText>{getFullName(option?.user || {})}</OptionText>
  </Option>
);

const AsyncSelectWrapper = ({
  disabled,
  label,
  placeholder = 'Find user by name/ID',
  initialValue = {},
  searchValueList = () => {},
  onSelectedValueChange = () => {},
  isTestTasksVisible = true,
  isValid = true,
  invalidMessage = '',
  isDisabled
}) => {
  const asyncSearchFunction = debounceWithPromise(searchValueList, 500);
  return (
    <NameWithOptions alignItems="center" justifyContent="space-between">
      <Label>{label}</Label>
      {disabled ? (
        formatOptionLabel(initialValue)
      ) : (
        <SelectWrapper disabled={isDisabled}>
          <AsyncSelect
            placeholder={placeholder}
            classNamePrefix="react-select"
            cacheOptions
            defaultValue={initialValue.id ? initialValue : null}
            defaultOptions={initialValue.id ? [initialValue] : false}
            loadOptions={(inputValue) => asyncSearchFunction({ search: inputValue, is_test: isTestTasksVisible })}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => getFullName(option?.user || {})}
            formatOptionLabel={formatOptionLabel}
            onChange={onSelectedValueChange}
            components={{
              DropdownIndicator: () => <Icon name="arrowFillBottom" />
            }}
            styles={asyncSelectStyles}
          />
        </SelectWrapper>
      )}

      {!isValid ? <InvalidMessage>{invalidMessage}</InvalidMessage> : null}
    </NameWithOptions>
  );
};

export default AsyncSelectWrapper;
