import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
`;

export const dividerStyle = {
  marginBottom: 27,
  width: '100%'
};
export const FieldWrapper = styled.div`
  label {
    color: ${({ theme }) => theme.colors.DARK_GREY};
  }
  fieldset {
    border-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    &:hover {
      border-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    }
  }
`;
