import styled from 'styled-components';

export const ChevronDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid ${({ theme }) => theme.colors.WHITE};
`;

export const ChevronUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid ${({ theme }) => theme.colors.WHITE};
`;

export const DropdownContainer = styled.div`
  margin: 10px 0 0 0;
  color: ${({ theme }) => theme.colors.WHITE};

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
