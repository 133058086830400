import React from 'react';

const IconTime = ({ size = 19, color = '#9297A5' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2077 9.49984C17.2077 13.757 13.7565 17.2082 9.49935 17.2082C5.24215 17.2082 1.79102 13.757 1.79102 9.49984C1.79102 5.24264 5.24215 1.7915 9.49935 1.7915C13.7565 1.7915 17.2077 5.24264 17.2077 9.49984ZM18.2077 9.49984C18.2077 14.3093 14.3088 18.2082 9.49935 18.2082C4.68987 18.2082 0.791016 14.3093 0.791016 9.49984C0.791016 4.69036 4.68987 0.791504 9.49935 0.791504C14.3088 0.791504 18.2077 4.69036 18.2077 9.49984ZM9.4292 6.54965C9.4292 6.27351 9.20535 6.04965 8.9292 6.04965C8.65306 6.04965 8.4292 6.27351 8.4292 6.54965V10.8878C8.4292 11.1639 8.65306 11.3878 8.9292 11.3878H12.301C12.5772 11.3878 12.801 11.1639 12.801 10.8878C12.801 10.6116 12.5772 10.3878 12.301 10.3878H9.4292V6.54965Z"
      fill={color}
    />
  </svg>
);

export default IconTime;
