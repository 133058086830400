import routes from 'constants/routes';

export const userGroups = {
  CUSTOMER: {
    TYPE: 1,
    ROUTE: routes.CUSTOMER,
    APP_NAME: 'Dobby',
    ALIAS: 'HO'
  },
  PROVIDER: {
    TYPE: 2,
    ROUTE: routes.PROVIDER,
    APP_NAME: 'Dobby Pro',
    ALIAS: 'Pro'
  },
  ADMIN: {
    TYPE: 3,
    ROUTE: routes.ADMIN,
    APP_NAME: 'Gryffindor',
    ALIAS: 'Admin'
  },
  AGENT: {
    TYPE: 4,
    ROUTE: routes.AGENT,
    APP_NAME: 'Dobby Partner',
    ALIAS: 'Agent'
  }
};

export const platforms = {
  IOS: {
    TYPE: 1,
    NAME: 'iOS'
  },
  ANDROID: {
    TYPE: 2,
    NAME: 'Android'
  }
};

export const userDataTypes = {
  AVERAGE_RESPONSE_TIME: 1,
  AVERAGE_WORDS_NUMBER: 2
};

export const oneClickActions = {
  IS_ACTIVE: 'activation',
  IS_TEST: 'markAsTest',
  IS_TEAM_MEMEBER: 'markAsTeamMember',
  IS_CREATE_JOB_ENABLED: 'isCreateJobEnabled',
  IS_REMIND_PAYMENT: 'isRemindPayment',
  IS_TROUBLED: 'isTroubled',
  IS_NON_ICP: 'isNonICP',
  REMOVE_IS_TROUBLED: 'removeIsTroubled',
  REMOVE_IS_NON_ICP: 'removeIsNonICP'
};

export const homeownerTags = {
  INACTIVE: {
    TYPE: 1,
    EMOJI: '😴 ',
    TAB_NAME: 'Inactive',
    DESCRIPTION: 'Install base that have never submitted a job'
  },
  ACTIVE: {
    TYPE: 2,
    EMOJI: '🙂 ',
    TAB_NAME: 'Active Homeowners',
    DESCRIPTION: 'Submitted their 1st job (does not matter how many jobs submitted, 0 completed jobs)'
  },
  PAID: {
    TYPE: 3,
    EMOJI: '😃 ',
    TAB_NAME: 'Paid',
    DESCRIPTION: 'Completed and paid 1 or more jobs'
  },
  REPEAT: {
    TYPE: 4,
    EMOJI: '🤩 ',
    TAB_NAME: 'Repeat Homeowners',
    DESCRIPTION: 'Completed and paid 2 or more jobs'
  },
  LOYAL: {
    TYPE: 5,
    EMOJI: '😍 ',
    TAB_NAME: ' Super Users',
    DESCRIPTION: 'Completed and paid 3 or more jobs'
  },
  TROUBLED_MANUAL: {
    TYPE: 6,
    EMOJI: '🧦 ',
    TAB_NAME: 'Sara Todd',
    DESCRIPTION: 'Completely manual tag, cause community friction, will eventually become CS blocked'
  },
  NON_ICP: {
    TYPE: 7,
    EMOJI: '🙃 ',
    TAB_NAME: 'Non-ICP',
    DESCRIPTION: '3 or more jobs unfulfilled (exclude duplicates) AND no completed jobs, can also be manual in'
  }
};

export const homeownerCohorts = {
  FIRST_TIME: {
    TYPE: 1
  },
  ACTIVATED: {
    TYPE: 2
  },
  REPEAT: {
    TYPE: 3
  },
  SUPER: {
    TYPE: 4
  }
};

export const providerStages = {
  ONE_CLICK_BOOKING: {
    TYPE: 0,
    EMOJI: '🏅 ',
    TITLE: '1-click booking',
    DESCRIPTION: `1-click booking - got 5+ positive reviews within the first 45 days after got “activated“`
  },
  NEW_HO_PRO: {
    TYPE: 1,
    EMOJI: '❤️ ',
    TITLE: 'NewHO-pro',
    DESCRIPTION: 'NewHO-pro - 1 completed job + positive review'
  },
  NEW_PRO: {
    TYPE: 2,
    EMOJI: '👶 ',
    TITLE: 'New pro',
    DESCRIPTION: `New pro - have not completed first(or following) job+ positive review, yet + have signed up(activated) in last 60 days`
  },
  TROUBLED_PRO: {
    TYPE: 3,
    EMOJI: '⚠️ ',
    TITLE: 'Troubled',
    DESCRIPTION: `Troubled - got a negative review OR signed up 60+ days ago without a positive experience`
  }
};

export const providerCohorts = {
  DEFAULT: {
    TYPE: 'DEFAULT',
    TITLE: 'N/A',
    FIELDS: {
      is_core: false,
      is_parked: false
    }
  },
  CORE: {
    TYPE: 'CORE',
    TITLE: 'Core',
    FIELDS: {
      is_core: true,
      is_parked: false
    }
  },
  PARKED: {
    TYPE: 'PARKED',
    TITLE: 'Parked',
    FIELDS: {
      is_core: false,
      is_parked: true
    }
  }
};
