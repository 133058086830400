import styled from 'styled-components';
import styles from 'constants/styles';

export const ModalTitle = styled.h3`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  margin: 9px 0 0;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ModalText = styled.p`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT} !important;
  white-space: pre-wrap;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 0;
  word-break: break-word;
`;

export const ModalIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color = styles.colors.GREEN }) => color};
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  button {
    margin-top: 10px;
  }

  display: flex;
  column-gap: 24px;
`;

export const IconWrapper = styled.div`
  background: ${styles.colors.LIGHT_GRAY};
  border-radius: 50%;
  max-width: 16px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
`;
