import React from 'react';
import { Field } from 'redux-form';
import { StyledTextarea, Label, FlexColumn } from './styled';

const FieldComponent = (props) => <StyledTextarea {...props} {...props.input} />;

const Textarea = ({ label, ...props }) => (
  <FlexColumn>
    {label && <Label>{label}</Label>}
    <Field component={FieldComponent} {...props} />
  </FlexColumn>
);

export default Textarea;
