import styled from 'styled-components';
import styles from 'constants/styles';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const ViewerShadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: ${styles.colors.SHADOW_BLACK};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewerWrapper = styled.div`
  width: 615px;
  max-width: calc(100vw - 40px);
  height: 100%;
  position: relative;

  .rpv-core__page-layer {
    overflow-x: hidden;
  }
`;

export const ToolbarItem = styled.div`
  padding: 0 2px;
`;

export const SeparateToolbarItem = styled(ToolbarItem)`
  margin-left: auto;
`;

export const InputToolbarItem = styled(ToolbarItem)`
  width: 4rem;
`;

export const TextToolbarItem = styled.div`
  padding: 0 2px 0 8px;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
`;
