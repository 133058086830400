export default {
  ADMIN: '/manager',
  ANALYTIC_METRICS: '/analytics',
  ANALYTIC_SNAPSHOT: '/analytics/snapshots',
  AGENT_LOCATION: '/real_estate/location',
  COGNITO: '/cognito',
  SERVICE_REQUEST: '/service_request',
  SUPPORT_REQUEST: '/support_request',
  PREVIEW: '/preview',
  SERVICE: '/service_demand',
  SIDEBAR_COUNTERS: '/dashboard/counters',
  SERVICE_AREA: '/service_area',
  PAYMENT: '/payment',
  PAYMENT_PAYOUT: '/payment/payout',
  MEDIA: '/media',
  USER: '/user',
  COMMUNICATION_SMS: '/twilio/sms',
  CUSTOMER: '/customer',
  LOCATION: '/location',
  CUSTOMER_LOCATION: '/customer/location',
  SERVICE_PROVIDER: '/service_provider',
  PRO_ASSIGN_JOB: '/pro_assign_job_card',
  REAL_ESTATE_AGENT: '/real_estate/agent',
  BUNDLE_SERVICES: '/bundle_services',
  GET_UNREAD_MESSAGED_BY_CATEGORIES: '/conversation/message/admin_unread',
  INSPECTION_REPORT: '/inspection/report',
  NOTIFICATION: '/notification/persistent/',
  REVIEW: '/service_review',
  DUPLICATE_TASK: '/duplicate',
  REFERRAL: '/referral/connect',
  PROMO_CODE: '/referral/promo',
  REWARD: '/referral/reward',
  TWILIO_VOICE: '/twilio/voice',
  QUOTATION: '/quotation',
  UNBUNDLE: '/unbundle',
  SKILL: '/skill',
  PROVIDER_SKILL: '/provider/skill',
  DOBBY_CASH_BALANCE: '/dobby_cash/balance',
  DOBBY_CASH_TOPUP: '/dobby_cash/bonus_topup',
  DOBBY_CASH_REDUCE: '/dobby_cash/bonus_reduce',
  DOBBY_CASH_TRANSACTIONS: '/dobby_cash/transaction',
  ASK: '/app/ask',
  CHAT_EXTEND_OR_CLOSE: '/chat/extend_or_close',
  CAMPAIGN: '/campaign',
  CAMPAIGN_EXECUTE: '/campaign/execute',
  CAMPAIGN_PUSH: '/campaign/push',
  ARTICLE: '/article',
  AGENT_CHARGES: '/payment/agent_charge'
};
