import { createSearchParams as createQueryParams } from 'react-router-dom';
import { sidebarMenuItems } from 'common/enums/navigation';
import { userGroups } from 'common/enums/user';

import { useDispatch, useReloadTable, useRouter } from 'hooks';
import { getBadges, refreshTaskInfo, getUserNoteList } from 'store/actions';
import { pageTypes, redirectNotificationTypes, notificationTypes, wsEvents } from 'common/enums/notification';
import { getDefaultNavigationParams } from 'common/helpers/queryParams';
import { deletePersistentNotification } from 'store/actions/notification';
import { setActiveMenuItem, saveLocationSearch, toggleNotificationsPopup } from 'store/actions/navigation';
import { changeActiveTab } from 'store/actions/taskChat';
import { getCurrentPageType } from 'common/helpers/notification';
import { getExtendedDefaultQueryParams } from 'common/helpers/queryParams';

const useNotifications = () => {
  const dispatch = useDispatch();
  const { handleReloadTable } = useReloadTable();
  const { navigate, pathname, queryParams } = useRouter();
  const filters = queryParams.getAll();

  const handleCloseNotificationsPopup = () => {
    dispatch(toggleNotificationsPopup(false));
  };

  const getNotificationNavigateOptions = ({ job_id, user_id, group_id, notificationType }) => {
    if (notificationType === notificationTypes.COMMUNICATION) {
      return {
        menuItem: sidebarMenuItems.COMMUNICATION,
        queryParams: {
          user_id,
          menu_item: sidebarMenuItems.COMMUNICATION.TYPE
        }
      };
    }

    if (job_id) {
      return {
        menuItem: sidebarMenuItems.ALL_JOBS,
        details_id: job_id
      };
    }

    const userPathname = Object.values(userGroups).find(({ TYPE }) => TYPE === group_id).ROUTE;
    const menuItem = Object.values(sidebarMenuItems).find(
      ({ ROUTE, IS_DEFAULT }) => userPathname === ROUTE && IS_DEFAULT
    );

    if (menuItem) {
      return {
        menuItem,
        details_id: user_id
      };
    }

    return {};
  };

  const handleBulkNotificationsDelete = ({ parsedData, notificationId, redirectType }) => {
    const { job_id, user_id, conversation_type } = parsedData;

    dispatch(
      deletePersistentNotification({
        ...(job_id ? { request_id: job_id } : {}),
        ...(!job_id && redirectType === redirectNotificationTypes.CHAT ? { user_id, conversation_type } : {}),
        ...(!job_id && redirectType !== redirectNotificationTypes.CHAT ? { notification_id: notificationId } : {})
      })
    );
  };

  const handleNotificationNavigate = (formattedNotification) => {
    const { parsedData, notificationId, redirectType, notificationType } = formattedNotification;
    const { group_id, job_id, user_id, is_payment } = parsedData;

    if (notificationType !== notificationTypes.COMMUNICATION) {
      handleBulkNotificationsDelete({ parsedData, notificationId, redirectType });
    }

    const { menuItem, details_id, queryParams } = getNotificationNavigateOptions({
      group_id,
      job_id,
      user_id,
      notificationType
    });

    if (!menuItem?.TYPE) {
      return;
    }

    const defaultNavigationParams = getDefaultNavigationParams({ menuItem });
    const queryParamsString = queryParams
      ? getExtendedDefaultQueryParams({ ...defaultNavigationParams, ...queryParams })
      : '';
    const newPathname = `${menuItem.ROUTE}${details_id ? `/${details_id}` : ''}${
      queryParamsString ? `?${queryParamsString}` : ''
    }`;
    navigate(newPathname, {
      replace: false,
      state: { openByNotif: true }
    });

    const currentPageType = getCurrentPageType({ pathname, filters, user_id, job_id });
    if (
      [
        pageTypes.CURRENT_AGENT,
        pageTypes.CURRENT_CUSTOMER,
        pageTypes.CURRENT_PROVIDER,
        pageTypes.CURRENT_COMMUNICATION_CHAT,
        pageTypes.COMMUNICATION
      ].includes(currentPageType)
    ) {
      window.location.reload();
    }

    dispatch(setActiveMenuItem(menuItem));

    dispatch(
      saveLocationSearch({
        menuItemType: menuItem.TYPE,
        locationSearch: queryParamsString || createQueryParams(defaultNavigationParams).toString()
      })
    );

    if (job_id) {
      dispatch(changeActiveTab(is_payment ? 3 : 0));
    }
  };

  /**
   * Try to use general info updating handlers here based on wsEventType
   */
  const handleBackgroundReload = ({
    activeMenuItemType,
    isJobNotification,
    redirectType,
    job_id,
    user_id,
    wsEventType,
    parsedWsEventMessage,
    parsedWsEventMessageData
  }) => {
    const jobId =
      parsedWsEventMessage?.type === notificationTypes.INVOICE ? parsedWsEventMessageData.service_request_id : job_id;
    const currentPageType = getCurrentPageType({
      pathname,
      filters,
      user_id,
      job_id: jobId
    });

    const hasReloadTable = wsEventType?.RELOAD_PAGES.some(({ MENU_ITEM, CURRENT_PAGE_TYPES }) => {
      if (MENU_ITEM && MENU_ITEM === activeMenuItemType) {
        return true;
      }

      return Boolean(CURRENT_PAGE_TYPES) && CURRENT_PAGE_TYPES.includes(currentPageType);
    });

    if ([pageTypes.CURRENT_AGENT, pageTypes.CURRENT_CUSTOMER, pageTypes.CURRENT_PROVIDER].includes(currentPageType)) {
      dispatch(getUserNoteList({ userId: user_id }));
    }

    if (
      (isJobNotification || wsEventType?.TYPE === wsEvents.INVOICE_ADMIN_PENDING.TYPE) &&
      currentPageType === pageTypes.CURRENT_JOB &&
      redirectType !== redirectNotificationTypes.CHAT
    ) {
      dispatch(refreshTaskInfo({ task_id: jobId, silent: true, isAllInfo: true }));
      return;
    }

    if (hasReloadTable || (isJobNotification && currentPageType === pageTypes.JOB_LIST)) {
      // it calls only if reloader defined for current pathname
      handleReloadTable({ newRequestFilters: { silent: true } });
      return;
    }
  };

  const handleSidebarBadgesReload = () => {
    dispatch(getBadges());
  };

  return {
    handleBackgroundReload,
    handleSidebarBadgesReload,
    handleCloseNotificationsPopup,
    handleNotificationNavigate
  };
};

export default useNotifications;
