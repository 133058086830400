import { useCallback } from 'react';
import { createSearchParams as createQueryParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useLoadLastPage, useRouter } from 'hooks';
import { getCSVFile } from 'store/actions';
import { setActiveMenuItem, saveLocationSearch } from 'store/actions/navigation';

import { DEFAULT_UTC_OFFSET } from 'common/constants/analytics';
import { analyticMetrics, defaultAnalyticsParams } from 'common/enums/analytics';
import { csvFileTypes } from 'common/enums/app';
import { apiPageSizes } from 'common/enums/queryParams';
import { columnNames } from 'common/enums/sortings';
import { topNavigationTabs } from 'common/enums/navigation';
import { prepareCustomFilters } from 'common/helpers/httpRequest';
import { findMenuItem } from 'common/helpers/navigation';
import { getSortingQueryParam } from 'common/helpers/sortings';
import { getDefaultNavigationParams, groupFilters } from 'common/helpers/queryParams';
import { useActiveMenuItem } from 'navigation/hooks';

/**
 * Core hook for table view with filters
 * @param {String} csvFileType
 * @param {String} instanceIdFieldName
 * @param {String} instanceRoute
 * @param {Number} limit
 * @param {String} listRoute
 * @param {Function} onFetchList
 * @param {String} siblingPaginationType
 * @param {Function} handleCustomRowClick handles both custom & extra action click
 * @param {Function} hasExtraActionOnRowClick check if execute handleCustomRowClick in handleRowClick & handleRowContextClick
 * @param {Function} hasCustomRowClick check if execute handleCustomRowClick instead of handleRowClick
 * @param {Number} totalCount
 * @returns {Object} filters & set of handlers: sorting, pagination, list reload, table row click, download csv
 */
const useFilters = ({
  csvFileType = csvFileTypes.TASK,
  instanceIdFieldName = columnNames.ID,
  instanceRoute,
  limit = apiPageSizes.DEFAULT,
  listRoute,
  onFetchList,
  siblingPaginationType,
  handleCustomRowClick,
  hasCustomRowClick,
  hasActiveMenuItemChange,
  hasExtraActionOnRowClick,
  totalCount
}) => {
  const { navigate, queryParams } = useRouter();
  const dispatch = useDispatch();
  const { activeMenuItem } = useActiveMenuItem();
  const filters = queryParams.getAll();

  const getTab = useCallback(
    ({ menuItemType, tabId }) => {
      const tabs = topNavigationTabs[menuItemType || activeMenuItem.TYPE];
      return tabs ? Object.values(tabs)?.find(({ TYPE }) => TYPE === tabId) : null;
    },
    [activeMenuItem.TYPE]
  );

  const handleFetchList = useCallback(
    ({ navigationOptions, ...newFilters } = {}) => {
      const { coreNavigationFilters, commonFilters, customFilters } = groupFilters(filters);

      const navigationParams = {
        ...getDefaultNavigationParams({ menuItem: activeMenuItem }),
        ...coreNavigationFilters,
        ...commonFilters,
        ...customFilters,
        ...newFilters
      };
      const tabItem = getTab({ menuItemType: navigationParams.menu_item, tabId: navigationParams.tab_id });

      const metricType = Number(filters.metric_type);
      const currentMetric = Object.values(analyticMetrics).find(({ TYPE }) => TYPE === metricType);

      const requestParams = {
        ...commonFilters,
        ...(activeMenuItem.PARAMS || {}),
        ...(tabItem?.PARAMS || {}),
        ...customFilters,
        ...newFilters,
        limit,
        ...(currentMetric?.REQUEST_PARAMS || {}),
        ...(currentMetric ? defaultAnalyticsParams : {}),
        order_by: getSortingQueryParam({
          columnName: navigationParams?.sorting_column,
          direction: navigationParams?.sorting_direction
        }),
        hasSavingQueryParams: activeMenuItem.ROUTE === listRoute,
        menuItemType: activeMenuItem.TYPE,
        locationSearch: createQueryParams(navigationParams).toString()
      };

      queryParams.setAll(navigationParams, navigationOptions);
      onFetchList(requestParams);
    },
    [filters, activeMenuItem, getTab, limit, listRoute, queryParams, onFetchList]
  );

  useLoadLastPage({ count: totalCount, onFetchList: handleFetchList });

  const handleChangePage = useCallback(
    (page = 1) => {
      handleFetchList({ page });
    },
    [handleFetchList]
  );

  const handleChangeSorting = useCallback(
    (sortingOptions) => (event) => {
      event.stopPropagation();

      handleFetchList({
        sorting_direction: sortingOptions.direction,
        sorting_column: sortingOptions.columnName
      });
    },
    [handleFetchList]
  );

  const handleRowClick = useCallback(
    (instance) => (e) => {
      const route = `${instanceRoute}/${instance[instanceIdFieldName]}`;

      if (hasExtraActionOnRowClick && hasExtraActionOnRowClick(instance)) {
        handleCustomRowClick(instance);
      }

      // If command + left click, open item in new tab without focus on it
      if (e.type === 'click' && e.metaKey) {
        window.open(route, '_blank');
        return;
      }

      if (hasCustomRowClick && hasCustomRowClick(instance)) {
        handleCustomRowClick(instance);
        return;
      }

      navigate(route, {
        ...(siblingPaginationType
          ? {
              state: {
                siblingPaginationType,
                payment: instance,
                ...(instanceIdFieldName ? { siblingIdName: instanceIdFieldName } : {})
              }
            }
          : {})
      });

      // Currently uses only for click on Homeowmer in Promo Code > Homeowners
      if (hasActiveMenuItemChange) {
        const newMenuItem = findMenuItem({ pathname: instanceRoute, type: filters.menu_item });
        const defaultParams = getDefaultNavigationParams({ menuItem: newMenuItem });
        dispatch(setActiveMenuItem(newMenuItem));
        dispatch(
          saveLocationSearch({
            menuItemType: newMenuItem.TYPE,
            locationSearch: createQueryParams(defaultParams).toString()
          })
        );
      }
    },
    [
      instanceRoute,
      instanceIdFieldName,
      hasActiveMenuItemChange,
      hasExtraActionOnRowClick,
      hasCustomRowClick,
      navigate,
      siblingPaginationType,
      handleCustomRowClick,
      filters.menu_item,
      dispatch
    ]
  );

  const handleRowContextClick = useCallback(
    (instance) => (e) => {
      e.stopPropagation();
      e.preventDefault();
      const route = `${instanceRoute}/${instance[instanceIdFieldName]}`;

      if (hasExtraActionOnRowClick && hasExtraActionOnRowClick(instance)) {
        handleCustomRowClick(instance);
      }

      // If right clicked or control + left click - open item in new tab with focus
      if (e.type === 'click' && e.ctrlKey) {
        window.open(route, '_blank');
        return;
      }
      if (e.type === 'contextmenu') {
        window.open(route, '_blank').focus();
        return;
      }
    },
    [instanceRoute, instanceIdFieldName, hasExtraActionOnRowClick, handleCustomRowClick]
  );

  const handleDownloadCSV = useCallback(() => {
    const { customFilters } = groupFilters(filters);
    const tabItem = getTab({ tabId: filters.tab_id });

    const metricType = Number(filters.metric_type);
    const currentMetric = Object.values(analyticMetrics).find(({ TYPE }) => TYPE === metricType);

    const csvFilters = {
      utc_offset: DEFAULT_UTC_OFFSET,
      search: filters.search,
      ...(activeMenuItem.PARAMS || {}),
      ...(tabItem?.PARAMS || {}),
      ...customFilters,
      ...(currentMetric?.REQUEST_PARAMS || {}),
      ...(currentMetric ? defaultAnalyticsParams : {}),
      order_by: getSortingQueryParam({
        columnName: filters.sorting_column,
        direction: filters.sorting_direction
      })
    };
    dispatch(
      getCSVFile({
        filters: prepareCustomFilters(csvFilters),
        csvFileType
      })
    );
  }, [activeMenuItem.PARAMS, csvFileType, dispatch, filters, getTab]);

  return {
    filters,
    handleFetchList,
    handleChangePage,
    handleChangeSorting,
    handleRowClick,
    handleRowContextClick,
    handleDownloadCSV
  };
};

export default useFilters;
