// styled components for Counter
// use the same styles as in src/components/HookForm
import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin-right: 10px;
  max-width: 150px;
`;

export const CounterInput = styled.input`
  width: 40px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
  border: none;
  border-radius: 4px;
  outline: none;
  margin-right: 10px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.BORDER_HOVER};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.PRIMARY};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

export const CounterButton = styled.button`
  width: 32px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ICON_BTN_HOVER};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.TEXT_DISABLED};
    cursor: not-allowed;
  }
`;

export const Error = styled.div`
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.INPUT_ERROR};
  margin-top: 4px;
`;
