import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import messages from 'constants/messages';
import { ColumnWrapper, CounterWrapper, Label, CounterInput, CounterButton, Error } from './styled';

const Counter = ({ label, name, control, rules = {}, error = {}, defaultValue, min = 1, max = 100, ...props }) => {
  const {
    field: { ref, onChange, value, ...inputProps }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  useEffect(() => {
    if (value < min || value > max) {
      onChange(Math.max(min, Math.min(max, value)));
    }
  }, [min, max]);

  const onValueChange = (event) => {
    const value = event.target.value;
    if (value >= min && value <= max) {
      onChange(event);
    }
    if (value === '') {
      onChange(event);
    }
  };

  const onIncrease = () => {
    if (value < max) {
      const intValue = parseInt(value);
      onChange(intValue + 1);
    }
  };

  const onDecrease = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  return (
    <ColumnWrapper>
      <CounterWrapper>
        {label && <Label>{label}</Label>}
        <CounterInput ref={ref} onChange={onValueChange} value={value} {...props} {...inputProps} />
        <CounterButton onClick={onDecrease}>-</CounterButton>
        <CounterButton onClick={onIncrease}>+</CounterButton>
      </CounterWrapper>
      {error && <Error>{messages.formErrors[error?.type?.toUpperCase()]}</Error>}
    </ColumnWrapper>
  );
};

export default Counter;
