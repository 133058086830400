import { useEffect } from 'hooks';

export const useConfirmCloseForm = ({ isFormDirty }) => {
  const confirmPageClose = (event) => {
    event.preventDefault();
    // browser doesn't use this wording, yet it should be set;
    event.returnValue = 'Are you sure you want to close?';
    return event.returnValue;
  };

  useEffect(() => {
    if (isFormDirty) {
      window.addEventListener('beforeunload', confirmPageClose);
    } else {
      window.removeEventListener('beforeunload', confirmPageClose);
    }
    return () => {
      window.removeEventListener('beforeunload', confirmPageClose);
    };
  }, [isFormDirty]);
};
