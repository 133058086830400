export const autoResizeTextArea = (input) => {
  const offset = input.offsetHeight - input.clientHeight;
  input.style.height = 'auto';
  input.style.height = `${input.scrollHeight + offset + 1}px`;
};

export const getMessagesOverwrites = ({ actions, sendButton, ...others }) => others;

export const isUserDifferent = (firstUser, secondUser) => {
  if (!firstUser || !firstUser._id || !secondUser || !secondUser._id) {
    return true;
  }

  return firstUser._id !== secondUser._id;
};
