import styled from 'styled-components';

export const CollapseButton = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 700;
  line-height: 16px;
  height: 49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.DARK_2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_2};

  &:hover {
    color: ${({ theme }) => theme.colors.WHITE};
    background: ${({ theme }) => theme.colors.DARK_GREY_3};

    svg {
      * {
        stroke: ${({ theme }) => theme.colors.WHITE};
      }
    }
  }
`;

export const HeaderRow = styled.tr``;
export const Row = styled.tr`
  color: ${({ theme }) => theme.colors.WHITE};
  height: 64px;
  ${({ isLast }) => isLast && 'td {border-bottom: unset}'};
  &:hover {
    background: ${({ theme }) => theme.colors.DARK_GREY_3};
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const RowItem = styled.td`
  padding: 0 8px;

  ${({ charges }) => charges && 'min-width: 100px;'};
  ${({ width }) => width && `width: ${width}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ flex, flexGap }) =>
    flex &&
    `
      display: flex;
      align-items: center;
      gap: ${flexGap || 4}px;
    `};
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_2};
  ${({ header, theme }) =>
    header &&
    `
  position: sticky;
  border-top: 1px solid ${theme.colors.DARK_2};

  top: 0px;
  z-index: 2;
  background:${theme.colors.DARK_GREY_8};
  color: #97a0a0;
  height: 42px
  `};

  a {
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
`;

export const EmptyText = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  text-align: center;
  font-weight: 700;
  line-height: 16px;
  padding: 25px;
`;

export const Wrapper = styled.div`
  margin: 20px 0;
`;
