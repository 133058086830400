import styled from 'styled-components';

export const Pill = styled.div`
  flex: 1;
  padding: 4px 24px;
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0 8px 8px 0;
  white-space: nowrap;
  text-align: center;
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
