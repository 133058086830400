import React from 'react';
import styles from 'constants/styles';

const IconChatFill = ({ size = 24, color = styles.colors.WHITE }) => {
  return (
    <svg width={size} height={size * 0.9} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5961 9.33601C11.8225 9.71677 12 10 12 10C12 10 11.6834 9.95719 11.2344 9.88746C10.3588 9.75149 8.97992 9.51314 8.46379 9.29024C7.69055 9.59722 6.82402 9.76919 5.9089 9.76919C2.64551 9.76919 0 7.58228 0 4.88459C0 2.18691 2.64551 0 5.9089 0C9.1723 0 11.8178 2.18691 11.8178 4.88459C11.8178 5.93894 11.4137 6.91526 10.7265 7.71349C10.893 8.12566 11.2894 8.82029 11.5961 9.33601ZM3 4C3 3.72386 3.22386 3.5 3.5 3.5H8.5C8.77614 3.5 9 3.72386 9 4C9 4.27614 8.77614 4.5 8.5 4.5H3.5C3.22386 4.5 3 4.27614 3 4ZM3.5 5.5C3.22386 5.5 3 5.72386 3 6C3 6.27614 3.22386 6.5 3.5 6.5H7.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5H3.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconChatFill;
