export const inputTypes = {
  NUMBER: 'number',
  TEXT: 'text'
};

export const formatterTypes = {
  DATE_EXECUTION: 'DATE_EXECUTION',
  INVOICE_TEXT: 'INVOICE_TEXT',
  DATETIME_EXECUTION_LOCAL: 'DATETIME_EXECUTION_LOCAL',
  TEXT: 'text',
  COLOR: 'color',
  TRIM_TEXT: 'TRIM_TEXT'
};

export const fieldTypes = {
  CURRENCY: {
    placeholder: '1,234.56',
    formatterName: 'currencyInput',
    type: formatterTypes.TEXT
  },
  DISCOUNT_CURRENCY: {
    formatterName: 'dollarCurrencyInput',
    placeholder: 'Amount',
    type: inputTypes.TEXT
  },
  DOLLAR_CURRENCY: {
    formatterName: 'dollarCurrencyInput',
    placeholder: '$1',
    type: inputTypes.TEXT
  },
  DOLLAR_CURRENCY_ZERO: {
    formatterName: 'dollarCurrencyInputZero',
    placeholder: '$0',
    type: inputTypes.TEXT
  },
  DOLLAR_TOTAL_CURRENCY: {
    formatterName: 'dollarCurrencyInput',
    placeholder: '$ Total',
    type: inputTypes.TEXT
  },
  INVOICE_CURRENCY: {
    formatterName: 'dollarCurrencyInput',
    placeholder: '$1,123.00',
    type: formatterTypes.TEXT
  },
  INVOICE_TEXT: {
    formatterName: formatterTypes.INVOICE_TEXT
  },
  PERCENT: {
    formatterName: 'percent',
    placeholder: '0 %',
    type: inputTypes.TEXT
  },
  DATETIME_EXECUTION_LOCAL: {
    formatterName: formatterTypes.DATETIME_EXECUTION_LOCAL
  },
  DATE_EXECUTION: {
    formatterName: formatterTypes.DATE_EXECUTION
  },
  COLOR: {
    formatterName: formatterTypes.COLOR
  },
  LINK: {
    formatterName: formatterTypes.TRIM_TEXT
  },
  TEXT: {}
};
