const DATE_FORMATS = {
  DATE: 'MM/DD/YYYY',
  DATE_SHORT: 'MM/DD/YY',
  DATETIME: 'MM/DD/YY [at] hh:mm a',
  DATETIME_FULL_DAY: 'ddd, MMM Do, ha',
  DATETIME_FULL_YEAR: 'MM/DD/YYYY [at] hh:mm a',
  DATETIME_FULL_YEAR_A: 'MM/DD/YYYY [at] hh:mm A',
  DATETIME_FULL_YEAR_A_2: 'MM/DD/YYYY hh:mm A',
  DATETIME_FULL_YEAR_SECONDS: 'MM/DD/YYYY [at] hh:mm:ss a',
  DATETIME_FULL_HUMAN: 'ddd MMM Do [at] h:mm a',
  DATETIME_ISO: 'YYYY-MM-DD[T]HH:mm:ss',
  DATETIME_ISO_NO_SS: 'YYYY-MM-DD[T]HH:mm',
  DATETIME_FULL: 'DD/MM/YYYY HH:mm:ss',
  DATE_ISO: 'YYYY-MM-DD',
  DATE_MAP_MARKER: 'MMM Do',
  TIME: 'hh:mm A',
  TIME_24: 'HH:mm',
  TIME_PICKER_LABEL: 'h:mm A',
  // analytics date formats
  MONTH_NAME: 'MMMM',
  MONTH_NAME_SHORT: 'MMM',
  DATE_METRIC: 'D MMMM',
  DATE_METRIC_FULL: 'D MMM YYYY',
  DATE_METRIC_SHORT: 'D MMM',
  DAY: 'D',
  YEAR: 'YYYY'
};

export default DATE_FORMATS;

export const PERIODS = {
  YEAR: 365,
  HALF_YEAR: 182,
  QUARTER: 91,
  MONTH: 30
};
