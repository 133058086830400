import styled from 'styled-components';
import styles from 'constants/styles';

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: ${({ size }) => size * 1.8}px;
  width: ${({ size }) => size * 2}px;
  height: ${({ size }) => size + 4}px;
  background: ${({ checked, theme, BGUnchecked }) =>
    checked ? theme.colors.SWITCH_BG_CHECKED : BGUnchecked ? BGUnchecked : theme.colors.SWITCH_BG_UNCHECKED};
  border-radius: ${({ size }) => size * 2}px;
  position: relative;
  transition: background-color 0.2s;
`;

export const Span = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
  transition: 0.2s;
  background: ${({ theme }) => theme.colors.SWITCH_BTN};
  box-shadow: ${styles.boxShadows.THIN_DARK_2};

  ${({ checked }) =>
    checked
      ? `
    left: calc(100% - 2px);
    transform: translateX(-100%);
  `
      : ''}
`;
