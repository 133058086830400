import styled from 'styled-components';
import styles from 'constants/styles';

export const AvatarWrapper = styled.div`
  position: relative;
  ${({ size }) => size && `width: ${size}px;`}
`;

export const Background = styled.div`
  background: ${({ theme }) => theme.colors.AVATAR_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ size = 20, width }) => width || size}px;
  height: ${({ size = 20, height }) => height || size}px;
  border-radius: ${({ width, height }) => (width || height ? '2px' : '50%')};
  margin: auto;
  overflow: hidden;
`;

export const Image = styled.img`
  width: ${({ size = 20, width }) => width || size}px;
  height: ${({ size = 20, height }) => height || size}px;
  object-fit: cover;
`;

export const AbsoluteIcon = styled.div`
  position: absolute;
  display: block;
  line-height: ${({ size }) => size}px;
`;

export const AbsoluteTopIcon = styled(AbsoluteIcon)`
  top: 0;
  right: 0;
  ${({ isCircle }) =>
    !isCircle &&
    `
    top: -9px;
    right: -9px;
  `}
`;

export const AbsoluteBottomIcon = styled(AbsoluteIcon)`
  bottom: -2px;
  right: -2px;
`;

export const PlaceholderText = styled.span`
  font-size: 12px;
  line-height: 1.7;
  text-align: center;
  color: ${({ theme }) => theme.colors.DARK_GREY};

  cursor: pointer;
  &:hover {
    ${({ clickable, theme }) =>
      clickable &&
      `
        color: ${theme.colors.DARK_GREEN};
      `}
  }
`;
