import { createSearchParams as createQueryParams } from 'react-router-dom';
import { formatDateRange } from 'utils';

export const generateQueryParams = ({ exactSearch, ...queryParams } = {}) => {
  const filteredQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(([_, value]) => value || value === 0)
  );

  const { search, menu_item, sorting_direction, sorting_column, tab_id, ...filters } = filteredQueryParams;

  const searchParams = {
    ...(Boolean(search) && { search: exactSearch ? search : search.replace(/[\s]{1,}/g, ',') }),
    ...filters
  };

  return `?${createQueryParams(searchParams)}`;
};

export const prepareCustomFilters = (filters) => {
  const {
    admin_status = null,
    date_created_gte = null,
    date_created_lte = null,
    datetime_canceled_gte = null,
    datetime_canceled_lte = null,
    datetime_completed_gte = null,
    datetime_completed_lte = null,
    original_date_created_lte = null,
    original_date_created_gte = null,
    is_warning_enabled = null,
    is_unread_message = null,
    utc_offset,
    ...otherFilters
  } = filters;

  const [startCreatedDate, endCreatedDate] = formatDateRange(date_created_gte, date_created_lte, utc_offset);
  const [startCompletedDate, endCompletedDate] = formatDateRange(
    datetime_completed_gte,
    datetime_completed_lte,
    utc_offset
  );
  const [startCancelledDate, endCancelledDate] = formatDateRange(
    datetime_canceled_gte,
    datetime_canceled_lte,
    utc_offset
  );

  return {
    admin_status,
    warning_value_gte: is_warning_enabled ? 0 : null,
    is_unread_message: is_unread_message ? 1 : null,
    date_created_gte: date_created_gte ? startCreatedDate : null,
    date_created_lte: date_created_lte ? endCreatedDate : null,
    original_date_created_gte: original_date_created_gte ? original_date_created_gte : null,
    original_date_created_lte: original_date_created_lte ? original_date_created_lte : null,
    datetime_completed_gte: datetime_completed_gte ? startCompletedDate : null,
    datetime_completed_lte: datetime_completed_lte ? endCompletedDate : null,
    datetime_canceled_gte: datetime_canceled_gte ? startCancelledDate : null,
    datetime_canceled_lte: datetime_canceled_lte ? endCancelledDate : null,
    ...otherFilters
  };
};
