import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ hasDisabledOpacity }) => hasDisabledOpacity && `opacity: 0.3;`}

  margin-left: 24px;
  &:first-child {
    margin: 0;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${styles.colors.GRAY};
  margin-bottom: 5px;
`;

export const Error = styled(Label)`
  color: ${styles.colors.RED};
  margin-top: 5px;
`;
