import React from 'react';
import { useComponentVisible } from 'hooks';
import { Icon } from 'components';
import { DropdownContainer, Dropdown, DropdownMenuItem, DropdownItemText, Divider, Actions } from './styled';
import { useTheme } from 'styled-components';

const DropdownMenuChatActions = ({ hasRightPosition, onEdit, onDelete, editingDisabled, isCurrentUser, width }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggle = () => {
    setIsComponentVisible((prevVisible) => !prevVisible);
  };
  const theme = useTheme();
  return (
    <DropdownContainer ref={ref}>
      <Actions background={`${isCurrentUser ? theme.colors.DARK_1 : theme.colors.MESSAGE_OPS}1A`} onClick={toggle}>
        <Icon
          name={isComponentVisible ? 'close2' : 'dots'}
          color={isCurrentUser && !hasRightPosition ? theme.colors.DARK_1 : theme.colors.MESSAGE_OPS}
          size={isComponentVisible ? 6.83 : 11}
        />
      </Actions>
      {isComponentVisible && !editingDisabled && (
        <Dropdown isCurrentUser={isCurrentUser} width={width}>
          <DropdownMenuItem key="1" onClick={onEdit}>
            <Icon name="editMessage" />
            <DropdownItemText>Edit message</DropdownItemText>
          </DropdownMenuItem>
          <Divider />
          <DropdownMenuItem key="2" onClick={onDelete}>
            <Icon name="trash" />
            <DropdownItemText>Delete message</DropdownItemText>
          </DropdownMenuItem>
        </Dropdown>
      )}
      {isComponentVisible && editingDisabled && (
        <Dropdown isCurrentUser={isCurrentUser} width={width}>
          <DropdownMenuItem key="2" onClick={onDelete}>
            <Icon name="trash" size={13} />
            <DropdownItemText>Delete message</DropdownItemText>
          </DropdownMenuItem>
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default DropdownMenuChatActions;
