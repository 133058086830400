export const buttonSourceNames = {
  HISTORY_BUTTON: {
    TYPE: 'history button',
    NAME: 'History button'
  },
  HOW_IT_WORKS: {
    TYPE: 'how it works button',
    NAME: 'How it works button'
  },
  MAIN_BUTTON: {
    TYPE: 'main button',
    NAME: 'Start a job button'
  },
  INSPECTION_BUTTON: {
    TYPE: 'inspection button',
    NAME: 'Inspection button'
  },
  ONE_CLICK_BOOKING: {
    TYPE: 'one-click booking',
    NAME: 'One-click Booking'
  }
};

export const requestSources = {
  BUTTON: 0,
  CAMPAIGN: 1,
  INSPECTION_REPORT: 2,
  SPECTORA: 3,
  REMINDER: 4,
  ARTICLE: 5,
  CHECKLIST: 6,
  BANNER: 7,
  OTHER: 8,
  GRYFFINDOR: 9,
  AI: 10
};

export const cohortTypes = {
  ALL_USERS: 1,
  WORKING_AREA: 2,
  RECIPIENTS_LIST: 3,
  CSV_FILE: 4
};

export const allInstallBase = {
  id: 'ALL',
  name: 'ALL',
  value: 'ALL',
  label: 'All Install Base'
};

export const campaignNavigationTypes = {
  INTERNAL_LINK: {
    TYPE: 1,
    TITLE: 'Deep link'
  },
  EXTERNAL_LINK: {
    TYPE: 2,
    TITLE: 'External link'
  },
  HOME_PAGE: {
    TYPE: 3,
    TITLE: 'Home Page'
  },
  JOB_CREATION: {
    TYPE: 4,
    TITLE: 'Job creation'
  },
  ARTICLE: {
    TYPE: 5,
    TITLE: 'Article'
  },
  SUPPORT_CHAT: {
    // doesn't exist on the BE
    TYPE: 6,
    TITLE: 'Support Chat'
  },
  REDIRECT_LINK: {
    // doesn't exist on the BE
    TYPE: 7,
    TITLE: 'Redirect link'
  }
};
