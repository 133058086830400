import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { FlexOne, Text, BlackSpan } from './styled';

const Placeholder = ({ iconName = '', hasError, searchWord = '' }) => {
  const handleRenderText = ({ searchWord, hasError }) => {
    if (hasError) {
      return (
        <Text>
          Something went wrong.
          <br />
          Please contact the Engineering Team
        </Text>
      );
    }

    if (searchWord) {
      return (
        <Text>
          Nothing found by <BlackSpan>“{searchWord}”</BlackSpan>
          <br />
          Please use other keywords
        </Text>
      );
    }

    return <Text>No matches found</Text>;
  };

  return (
    <FlexOne>
      {iconName && <Icon name={iconName} backgroundSize={200} />}
      {handleRenderText({ searchWord, hasError })}
    </FlexOne>
  );
};

Placeholder.propTypes = {
  iconName: PropTypes.string,
  searchWord: PropTypes.string
};

export default Placeholder;
