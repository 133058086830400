import React from 'react';
import { Spinner, SpinnerDot } from './styled';

const LoadingDots = ({ left = 0, top = 0, color }) => (
  <Spinner left={left}>
    <SpinnerDot top={top} color={color} />
    <SpinnerDot top={top} color={color} />
    <SpinnerDot top={top} color={color} />
    <SpinnerDot top={top} color={color} />
  </Spinner>
);
export default LoadingDots;
