import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const icons = ReactQuill.Quill.import('ui/icons');

icons['italic'] = `<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33594 3.04688L3.60645 13H2.37598L4.10547 3.04688H5.33594Z" fill="white"/>
<line y1="12.5" x2="7" y2="12.5" stroke="white"/>
<line x1="1" y1="3.5" x2="8" y2="3.5" stroke="white"/>
</svg>
`;
icons['underline'] = `<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.84961 0.046875H8.16211V6.78027C8.16211 7.52767 7.99577 8.14974 7.66309 8.64648C7.3304 9.14323 6.88835 9.51693 6.33691 9.76758C5.79004 10.0137 5.19531 10.1367 4.55273 10.1367C3.87826 10.1367 3.26758 10.0137 2.7207 9.76758C2.17839 9.51693 1.74772 9.14323 1.42871 8.64648C1.11426 8.14974 0.957031 7.52767 0.957031 6.78027V0.046875H2.2627V6.78027C2.2627 7.2998 2.3584 7.72819 2.5498 8.06543C2.74121 8.40267 3.00781 8.65332 3.34961 8.81738C3.69596 8.98145 4.09701 9.06348 4.55273 9.06348C5.01302 9.06348 5.41406 8.98145 5.75586 8.81738C6.10221 8.65332 6.37109 8.40267 6.5625 8.06543C6.75391 7.72819 6.84961 7.2998 6.84961 6.78027V0.046875Z" fill="white"/>
<path d="M0 11.3672H9.07812V12.0508H0V11.3672Z" fill="white"/>
</svg>
`;
icons['bold'] = `<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.70312 5.34473H1.18066L1.16699 4.28516H3.45703C3.83529 4.28516 4.16569 4.22135 4.44824 4.09375C4.73079 3.96615 4.94954 3.78385 5.10449 3.54688C5.264 3.30534 5.34375 3.01823 5.34375 2.68555C5.34375 2.32096 5.27311 2.02474 5.13184 1.79688C4.99512 1.56445 4.7832 1.39583 4.49609 1.29102C4.21354 1.18164 3.85352 1.12695 3.41602 1.12695H1.47461V10H0.155273V0.046875H3.41602C3.92643 0.046875 4.38216 0.0992839 4.7832 0.204102C5.18424 0.304362 5.52376 0.463867 5.80176 0.682617C6.08431 0.89681 6.2985 1.17025 6.44434 1.50293C6.59017 1.83561 6.66309 2.23438 6.66309 2.69922C6.66309 3.10938 6.55827 3.48079 6.34863 3.81348C6.139 4.1416 5.84733 4.41048 5.47363 4.62012C5.10449 4.82975 4.67155 4.96419 4.1748 5.02344L3.70312 5.34473ZM3.6416 10H0.661133L1.40625 8.92676H3.6416C4.06087 8.92676 4.41634 8.85384 4.70801 8.70801C5.00423 8.56217 5.22982 8.3571 5.38477 8.09277C5.53971 7.82389 5.61719 7.50716 5.61719 7.14258C5.61719 6.77344 5.55111 6.45443 5.41895 6.18555C5.28678 5.91667 5.07943 5.70931 4.79688 5.56348C4.51432 5.41764 4.14974 5.34473 3.70312 5.34473H1.82324L1.83691 4.28516H4.40723L4.6875 4.66797C5.16602 4.70898 5.57161 4.8457 5.9043 5.07812C6.23698 5.30599 6.48991 5.59766 6.66309 5.95312C6.84082 6.30859 6.92969 6.70052 6.92969 7.12891C6.92969 7.7487 6.79297 8.27279 6.51953 8.70117C6.25065 9.125 5.87012 9.44857 5.37793 9.67188C4.88574 9.89062 4.30697 10 3.6416 10Z" fill="white"/>
</svg>
`;

export const Div = styled.div`
  position: relative;

  .ql-editor::before {
    font-size: 14px;
    line-height: 1.14;
    margin-top: 5px;
    color: #676d70 !important;
  }
  button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    &:hover {
      line {
        stroke: #ffc25f !important;
      }
      path {
        fill: #ffc25f !important;
      }
    }
  }
  .ql-active {
    line {
      stroke: #ffc25f !important;
    }
    path {
      fill: #ffc25f !important;
    }
  }
  .ql-container {
    background: #353d41;
    border-radius: 0px 0px 4px 4px;
    border: unset;
  }
  .ql-editor {
    min-height: 404px;
    font-size: 14px !important;
    p {
      font-size: 14px !important;
      color: #fff !important;
    }
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
  }

  .ql-toolbar {
    background: #434b4e;
    border-radius: 4px 4px 0px 0px;
    border: unset;
    padding: 5px 10px;
  }
`;
export const Inner = styled.div`
  p {
    color: white !important;
    margin-bottom: 0;
  }
`;

export const QuillLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
  color: #97a0a0;
`;

export const RecommendedText = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: #676d70;
  margin-top: 4px;
  text-align: right;
`;
