import React from 'react';
import styles from 'constants/styles';

const IconTrash = ({ size = 16, color = styles.colors.WHITE }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9099 3.08582H14.5064C14.7791 3.08582 15 3.27687 15 3.51253C15 3.7482 14.7791 3.93924 14.5064 3.93924H13.2099V12.013C13.2099 13.6627 11.6629 15 9.75459 15H6.24534C4.33704 15 2.79005 13.6627 2.79005 12.013V3.93924H1.49361C1.221 3.93924 1 3.7482 1 3.51253C1 3.27687 1.221 3.08582 1.49361 3.08582H5.09068C5.34739 1.89859 6.55245 1 8.00031 1C9.44817 1 10.6532 1.89859 10.9099 3.08582ZM9.89632 3.08582C9.65909 2.37405 8.90003 1.85342 8.00031 1.85342C7.10059 1.85342 6.34153 2.37405 6.1043 3.08582H9.89632ZM3.77727 4.12789V12.013C3.77727 13.1914 4.88227 14.1466 6.24534 14.1466H9.75459C11.1176 14.1466 12.2226 13.1914 12.2226 12.013V4.12789H3.77727ZM6.69314 6.71585C6.96577 6.71585 7.18676 6.90689 7.18676 7.14256V11.1321C7.18676 11.3677 6.96577 11.5588 6.69314 11.5588C6.42053 11.5588 6.19953 11.3677 6.19953 11.1321V7.14256C6.19953 6.90689 6.42053 6.71585 6.69314 6.71585ZM9.97936 7.14256C9.97936 6.90689 9.7584 6.71585 9.48579 6.71585C9.21318 6.71585 8.99218 6.90689 8.99218 7.14256V11.1321C8.99218 11.3677 9.21318 11.5588 9.48579 11.5588C9.7584 11.5588 9.97936 11.3677 9.97936 11.1321V7.14256Z"
      fill={color}
    />
  </svg>
);

export default IconTrash;
