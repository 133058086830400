import React, { Fragment } from 'react';

import { searchHighlightTypes } from 'common/enums/app';
import { BlockWrapper, Text } from './styled';
import { formatPhone } from 'utils';

const entityMap = {
  '&': '\\&',
  '<': '\\<',
  '>': '\\>',
  '"': '\\"',
  "'": "\\'",
  '/': '\\/',
  '`': '\\`',
  '=': '\\=',
  '+': '\\+',
  '(': '\\(',
  ')': '\\)'
};

const escapeText = (string = '') =>
  // eslint-disable-next-line no-useless-escape
  string.replace(/[&<>"'`=+()\/]/g, (s) => entityMap[s]);

const getHighlightedText = ({ item: text, highlight, type }) => {
  const highlightCleaned = highlight.replace(/[\s,]/g, ' ').trim().toLowerCase();
  const highlightRegex =
    type === searchHighlightTypes.EXACT ? highlightCleaned : highlightCleaned.replace(/(\s+)/g, '|');
  const highlightedArray = highlightRegex.split('|').filter((word) => word.trim() !== '');
  const parts = highlightCleaned
    ? text.split(
        new RegExp(`${type === searchHighlightTypes.FULL_TEXT ? '' : '^'}(${escapeText(highlightRegex)})`, 'gi')
      )
    : [text];

  return { parts, highlightedArray };
};
const HighlightedText = ({
  text,
  textArray,
  isEllipsis,
  rows = 1,
  highlight = '',
  type,
  capitalized = false,
  textStyle = '',
  disabled
}) => {
  if (!text && !textArray?.length) return null;
  if (type === searchHighlightTypes.PHONE_NUMBER) {
    return <HighlightPhoneNumber text={text} highlight={highlight} disabled={disabled} textStyle={textStyle} />;
  }

  return (
    <BlockWrapper isEllipsis={isEllipsis} rows={rows}>
      {(textArray || [text])
        .filter((item) => item?.trim())
        .map((item, textIndex) => {
          const { parts, highlightedArray } = getHighlightedText({ item, highlight, type });
          return (
            <Fragment key={`text-${textIndex}`}>
              {Boolean(textIndex) && ' '}
              {parts.map((part, partIndex) => {
                return (
                  <Text
                    key={partIndex}
                    capitalized={capitalized}
                    isHighlighted={highlightedArray.includes(part.toLowerCase())}
                    textStyle={textStyle}
                    disabled={disabled}>
                    {part}
                  </Text>
                );
              })}
            </Fragment>
          );
        })}
    </BlockWrapper>
  );
};

// TODO: (unassigned) add handling case when user enter digits in the middle of number
const HighlightPhoneNumber = ({ text = '', highlight = '', textStyle }) => {
  const formattedHighlight = formatPhone(highlight.trim());
  const formattedPhone = formatPhone(text);
  const textMatchFormattedHighlight =
    formattedHighlight && highlight.startsWith('+', 0) && text.startsWith(highlight, 0);
  const highlightCleaned = highlight.replace(/[\s,]/g, ' ').trim().toLowerCase();
  const highlightRegex = highlightCleaned.replace(/(\s+)/g, '|');
  const highlightedArray = highlightRegex.split('|').filter((word) => word.trim() !== '');
  const parts =
    highlightCleaned !== ''
      ? textMatchFormattedHighlight
        ? formattedPhone.split(new RegExp(`^(${escapeText(formattedHighlight)})`, 'gi'))
        : formattedPhone.split(new RegExp(`^(${escapeText(highlightRegex)})`, 'gi'))
      : [formattedPhone];

  return (
    <>
      {parts.map((part, i) => (
        <Text
          key={i}
          textStyle={textStyle}
          isHighlighted={textMatchFormattedHighlight ? part === formattedHighlight : highlightedArray.includes(part)}>
          {part}
        </Text>
      ))}
    </>
  );
};

export default HighlightedText;
