import React from 'react';
import styles from 'constants/styles';

const IconMicrophoneCross = ({ size = 24, color = styles.colors.BLACK }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0006 5C11.0006 4.45 11.4506 4 12.0006 4C12.5506 4 13.0006 4.45 13.0006 5V10.17L14.8206 11.99C14.9306 11.68 15.0006 11.35 15.0006 11V5C15.0006 3.34 13.6606 2 12.0006 2C10.3406 2 9.00063 3.34 9.00063 5V6.17L11.0006 8.17V5ZM2.81062 2.81L1.39062 4.22L13.0406 15.87C12.7106 15.95 12.3606 16 12.0006 16C9.24062 16 7.00063 13.76 7.00063 11H5.00063C5.00063 14.53 7.61062 17.43 11.0006 17.92V21H13.0006V17.92C13.5706 17.84 14.1206 17.68 14.6406 17.46L19.7806 22.6L21.1906 21.19L2.81062 2.81ZM19.0006 11H17.0006C17.0006 11.91 16.7406 12.75 16.3106 13.48L17.7706 14.94C18.5718 13.7823 19.0009 12.4079 19.0006 11Z"
        fill={color}
      />
    </svg>
  );
};

export default IconMicrophoneCross;
