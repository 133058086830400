import React from 'react';

const IconCheckIn = ({ size = 17, color = '#282A2B' }) => (
  <svg width={size} height={size * 0.8} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 15.365234,0.00976563 A 1,1 0 0 0 14.705078,0.39453125 L 7.3984375,9.984375 1.7070312,4.2929687 a 1,1 0 0 0 -1.41406245,0 1,1 0 0 0 0,1.4140625 L 6.7929687,12.207031 A 1.0001,1.0001 0 0 0 8.2949219,12.105469 L 16.294922,1.6054687 A 1,1 0 0 0 16.105469,0.20507813 1,1 0 0 0 15.365234,0.00976563 Z"
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCheckIn;
