import styled from 'styled-components';
import styles from 'constants/styles';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.INPUT_BG};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  display: inline-block;
  outline: 0 none;
  width: 100%;
  padding: 8px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }

  ${({ invalid, theme }) =>
    invalid &&
    `
  border: 1px solid ${theme.colors.INPUT_ERROR};
`}

  ${({ width }) =>
    Boolean(width) &&
    `
  width: ${width}px;
`};

  &:active,
  &:focus {
    outline: 0 none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
    border: none;

    ${({ invalid, theme }) =>
      invalid &&
      `
  border: 1px solid ${theme.colors.INPUT_ERROR};
`}
  }

  &[disabled],
  &[readOnly] {
    color: ${styles.colors.GRAY};
    box-shadow: none;
    background: ${styles.colors.LIGHT_GRAY_2};
  }
`;

export const FieldValue = styled.div`
  padding: ${({ isInputShown }) => (isInputShown ? '0' : '4px 0')};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  position: relative;
  ${({ isEmptyValue }) =>
    isEmptyValue &&
    `
      color: ${styles.colors.LIGHT_GRAY};
      font-weight: 600;
      &:hover {
          cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
          ${({ disabled }) => !disabled && `color: ${styles.colors.DARK_GREEN_3}`};
      }
    `}

  div {
    display: flex;
  }
`;

export const SpinnerContainer = styled.div`
  padding: 7px 0;
  background: ${styles.colors.LIGHT_GRAY_2};
  border-radius: 4px;
  width: 115px;
  display: flex;
  justify-content: 'center';
  align-items: center;
`;

export const IconMargin = styled.div`
  ${({ left }) => left && `margin-left: ${left}px`};
  ${({ top }) => top && `margin-top: ${top}px`};
  div {
    margin: 0;
  }
`;

export const EditableValue = styled.div`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
`;

export const SearchListWrapper = styled.div`
  max-height: 147px;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 36px;
  left: 0px;
  min-width: 225px;

  background: ${({ theme }) => theme.colors.DROPDOWN_MENU};

  box-shadow: ${({ theme }) => theme.boxShadows.THIN_DARK_3};
  border-radius: 4px;
  div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
  }
`;

export const SearchValue = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 16px 13px 0;

  position: relative;
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER} !important;
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    margin-left: 0px;
    padding-left: 16px;
  }
  &:hover:after {
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
  }
`;

export const SearchValueText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  width: 100%;
  color: ${({ theme }) => theme.colors.SELECT_ITEM_TEXT};
`;
