import React from 'react';
import ReactQuill from 'react-quill';
import { useController } from 'react-hook-form';
import { Div, Inner, QuillLabel, RecommendedText } from './styled';

const toolbarOptions = [
  ['bold', 'italic', 'underline' /* , 'strike' */] // toggled buttons
  // ['blockquote', 'code-block'],
  // ['link', 'image', 'video', 'formula'],

  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  // [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction

  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  // [{ 'font': [] }],
  // [{ 'align': [] }],

  // ['clean']                                         // remove formatting button
];

export const RichTextEditor = ({
  control,
  rules = {},
  name,
  renderTooltip,
  hasContentPreview,
  title,
  scrollingContainer
}) => {
  const {
    field: { onChange, value }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  });

  const modules = {
    toolbar: toolbarOptions
  };

  return (
    <div>
      {Boolean(title) && <QuillLabel>{title}</QuillLabel>}
      <Div>
        <ReactQuill
          placeholder="Enter message"
          value={value}
          onChange={onChange}
          modules={modules}
          scrollingContainer={scrollingContainer}
          formats={toolbarOptions[0]}
        />
        {Boolean(renderTooltip) && renderTooltip()}
      </Div>
      <RecommendedText>Unlimited</RecommendedText>

      {hasContentPreview && <Inner style={{ color: 'white !important' }} dangerouslySetInnerHTML={{ __html: value }} />}
    </div>
  );
};
