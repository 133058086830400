import styled from 'styled-components';
import styles from 'constants/styles';
import checkboxChecked from 'static/images/checkbox-checked.svg';
import checkboxCheckedRed from 'static/images/checkbox-checked-red.svg';
import checkboxCheckedGreen from 'static/images/checkbox-checked-green.svg';

export const StyledCheckbox = styled.div`
  background: ${({ checkboxBackground }) => checkboxBackground};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-box;
  padding: ${({ small }) => (small ? '8px' : '12px')};
  left: 0;
  top: 0;
  vertical-align: middle;
  position: absolute;
  ${({ theme }) => !theme.darkMode && `border: 1px solid ${theme.colors.LIGHT_GRAY};`}
`;

export const CheckboxInput = styled.input`
  width: 1px;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  left: 0;
  top: 0;
  position: absolute;

  &:focus:not([disabled]) + ${StyledCheckbox} {
    box-shadow: 0 0 0 0.1em ${styles.colors.GRAY};
  }

  &:checked + ${StyledCheckbox} {
    background: url(${({ red, green }) => (red ? checkboxCheckedRed : green ? checkboxCheckedGreen : checkboxChecked)})
      ${({ checkboxBackground }) => checkboxBackground} center no-repeat;
    ${({ small }) => small && 'background-size: 9.33px 7.33px;'}
  }
  &:hover:not([disabled]) + ${StyledCheckbox} {
    cursor: pointer;
  }
`;

export const CheckboxLabel = styled.label`
  span {
    margin: 6px 0 6px ${({ smallSize }) => (smallSize ? 24 : 30)}px;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme, labelTextColor }) => labelTextColor || theme.colors.INVOICE_TEXT};
    &:hover:not([disabled]) {
      cursor: pointer;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: ${({ small }) => (small ? '14px' : '24px')};
  align-items: center;
  ${({ wrapperCSS }) => wrapperCSS}
`;
