import { useEffect, useMemo, useRouter } from 'hooks';
import { apiPageSizes } from 'common/enums/queryParams';

/***
 * this hook loads last available page when user navigates back
 * and there's no records on requested page anymore
 */
const useLoadLastPage = ({ count, pageSize = apiPageSizes.DEFAULT, onFetchList }) => {
  const { queryParams } = useRouter();
  const filters = queryParams.getAll();
  const lastPage = useMemo(() => Math.ceil(count / pageSize), [count, pageSize]);

  useEffect(() => {
    if (!filters.page || !count || lastPage >= parseInt(filters.page)) {
      return;
    }
    onFetchList({ page: lastPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPage]);
};

export default useLoadLastPage;
