import styled from 'styled-components';
import styles from 'constants/styles';

export const InputRow = styled.div`
  width: 100%;
  display: flex;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const PseudoInput = styled.div`
  display: flex;
  flex: 1;
  background: ${styles.colors.LIGHT_GRAY_2};
  border-radius: 4px;
  border: none;
  color: ${styles.colors.DARK_BLUE};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-right: 16px;
  padding: 4px 8px;
  cursor: pointer;

  ${({ hasPlaceholder }) => hasPlaceholder && `color: ${styles.colors.LIGHT_GRAY};`}

  ${({ width }) => `
    width: ${width || 120}px;
    max-width: ${width || 120}px;
  `}
`;

export const DatePickerWrapper = styled.div`
  .datepicker-wrapper {
    position: absolute;
    right: 18px;
    top: -24px;
  }
  .materialui-daterange-picker-MuiPaper-elevation5-15 {
    box-shadow: none;
    border: 1px solid ${styles.colors.LIGHT_GRAY};
    border-radius: 4px;
  }
  .materialui-daterange-picker-MuiList-root-257 {
    width: 125px;
  }
`;
