import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: overlay;
  height: calc(100vh - ${styles.layout.headerHeight + styles.layout.tabsHeaderHeight}px) !important;
`;

export const EmptyServiceLists = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  position: relative;
`;

export const EmptyText = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.WHITE};
`;
