import React from 'react';

import { openModal } from 'store/actions';

import { Question, Header, Title, AnswerWrapper, Submitted, AnswerDate, Answer, ViewMore, ViewDetails } from './styled';
import { useTheme, useDispatch, useEffect, useRef, useState } from 'hooks';
import { StyledTooltip, Icon } from 'components';
import modalTypes from 'constants/modalTypes';
import { formatDateShortTimeStamp } from 'utils/transformers';

const QuestionComponent = (props) => {
  const { question, answer, date_created, service_request_id } = props;
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  const theme = useTheme();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const handleOpenDetails = () => {
    window.open(`/service/${service_request_id}`, '_blank');
  };
  const handleViewMore = () => {
    dispatch(openModal(modalTypes.QUESTION_DETAILS, props));
  };
  return (
    <Question>
      <Header>
        <Title>{question}</Title>

        {Boolean(service_request_id) && (
          <StyledTooltip
            dark
            arrow
            placement="top-end"
            title={<ViewDetails onClick={handleOpenDetails}>View job details</ViewDetails>}>
            <Submitted>
              <Icon name="okOutlined" size={16} backgroundSize={16} color={theme.colors.DARK_GREEN_2} strokeWidth={2} />
              <div>Submitted</div>
            </Submitted>
          </StyledTooltip>
        )}
      </Header>
      <AnswerDate>{formatDateShortTimeStamp(date_created)}</AnswerDate>
      <AnswerWrapper>
        <Answer ref={ref}>{answer || '-'}</Answer>
      </AnswerWrapper>
      {height > 48 && <ViewMore onClick={handleViewMore}>View more</ViewMore>}
    </Question>
  );
};

export default QuestionComponent;
