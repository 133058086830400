import { generateSpecialtiesListOptions, generateServiceAreaListOptions } from 'common/helpers/user';

// Replace undefined with null so that dirtyFields works properly
export const prepareInitialValues = ({ initialValues, isProvider, isCustomer, serviceAreaList }) => {
  const values = {};
  Object.keys(initialValues).forEach((key) => {
    if (initialValues[key] === undefined) {
      values[key] = '';
      return;
    }

    values[key] = initialValues[key];
  });

  if (isProvider) {
    const categoryOptions = generateSpecialtiesListOptions();
    // single select
    values.primary_category = categoryOptions.find((option) => values.primary_category?.id === option.id);
    // multi select
    values.secondary_category = categoryOptions.filter((option) =>
      values.secondary_category?.find((item) => item.id === option.id)
    );
    values.category = categoryOptions.filter((option) => values.category?.find((item) => item.id === option.id));

    const areaOptions = generateServiceAreaListOptions(serviceAreaList);

    values.service_area_list = areaOptions.filter((option) =>
      values.service_area_list.find((area) => area === option.name)
    );

    values.auto_assign_service_area = areaOptions.filter((option) =>
      values.auto_assign_service_area?.find((area) => area === option.value)
    );

    values.one_click_service_area = areaOptions.filter((option) =>
      values.one_click_service_area?.find((area) => area === option.value)
    );
  }

  if (isCustomer) {
    values.estimate_value = (values.estimate_value || '').toString();
    values.year_built = (values.year_built || '').toString();
    values.sold_date = (values.sold_date || '').toString();
    values.square = (values.square || '').toString();
  }

  return values;
};
