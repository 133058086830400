import routes from 'constants/routes';
import { adminStatuses, jobTags } from 'common/enums/job';
import { providerStages, homeownerCohorts, homeownerTags, userGroups, providerCohorts } from 'common/enums/user';
import { sortingDirections, columnNames } from 'common/enums/sortings';

export const sidebarMenuItems = {
  JOB_MANAGEMENT: {
    NAME: 'JOB MANAGEMENT',
    TYPE: 'job_management'
  },
  MY_JOBS: {
    COUNTER_NAME: 'my_jobs',
    NAME: 'My Jobs',
    ROUTE: routes.PRIORITY_TASKS,
    TYPE: 'my_jobs',
    HAS_SEARCH: true,
    HAS_TABS: false,
    HAS_WARNING_COUNTERS: false,
    IS_DEFAULT: true,
    PARAMS: {
      is_my_jobs: 1,
      is_open: 1
    }
  },
  TROUBLED: {
    COUNTER_NAME: 'sara_todd',
    NAME: 'Troubled',
    ROUTE: routes.PRIORITY_TASKS,
    TYPE: 'sara_todd',
    HAS_SEARCH: true,
    HAS_TABS: false,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      tag_id: jobTags.TROUBLED.TYPE
    }
  },
  GRINGOTTS: {
    COUNTER_NAME: 'priority',
    NAME: 'Gringotts',
    ROUTE: routes.PRIORITY_TASKS,
    TYPE: 'priority',
    HAS_SEARCH: true,
    HAS_TABS: true,
    HAS_WARNING_COUNTERS: false,
    IS_DEFAULT: true,
    PARAMS: {
      tag_task: 1
    }
  },
  OWLERY: {
    COUNTER_NAME: 'owlery',
    NAME: 'Owlery',
    ROUTE: routes.SERVICE,
    TYPE: 'owlery',
    HAS_SEARCH: true,
    HAS_TABS: true,
    HAS_WARNING_COUNTERS: false
  },
  INVOICED: {
    COUNTER_NAME: 'invoiced',
    NAME: 'Invoiced',
    ROUTE: routes.SERVICE,
    TYPE: 'invoiced',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      was_invoiced: 1,
      is_invoice_moderated: 1
    }
  },
  PAYMENTS: {
    COUNTER_NAME: 'payments',
    NAME: 'Payments',
    ROUTE: routes.PAYMENTS,
    TYPE: 'payments',
    HAS_SEARCH: true,
    HAS_TABS: true,
    PARAMS: {
      is_paid: 1
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_PAID
    }
  },
  INVOICE_ADMIN_PENDING: {
    NAME: 'Not Validated',
    ROUTE: routes.INVOICE_PENDING,
    TYPE: 'invoice_pending',
    HAS_SEARCH: true,
    HAS_TABS: true,
    COUNTER_NAME: 'not_validated',
    PARAMS: {
      is_moderated: 0
    }
  },
  JOB_STAGES: {
    NAME: 'JOB STAGES',
    TYPE: 'job_stages'
  },
  REQUESTED: {
    COUNTER_NAME: 'requested',
    NAME: 'Requested',
    ROUTE: routes.SERVICE,
    TYPE: 'requested',
    HAS_SEARCH: true,
    HAS_TABS: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.REQUESTED.TYPE
    }
  },
  ASSIGNED: {
    COUNTER_NAME: 'assigned',
    NAME: 'Assigned',
    ROUTE: routes.SERVICE,
    TYPE: 'assigned',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.ASSIGNED.TYPE
    }
  },
  ACCEPTED: {
    COUNTER_NAME: 'accepted',
    NAME: 'Accepted',
    ROUTE: routes.SERVICE,
    TYPE: 'accepted',
    HAS_SEARCH: true,
    HAS_TABS: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.ACCEPTED.TYPE
    }
  },
  SCHEDULED: {
    COUNTER_NAME: 'scheduled',
    NAME: 'Scheduled',
    ROUTE: routes.SERVICE,
    TYPE: 'scheduled',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.SCHEDULED.TYPE,
      is_past_scheduled: 0
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_VISIT_CURRENT,
      sorting_direction: sortingDirections.ASCENDING
    }
  },
  PAST_SCHEDULED: {
    COUNTER_NAME: 'past scheduled',
    NAME: 'Past Scheduled',
    ROUTE: routes.SERVICE,
    TYPE: 'past_scheduled',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.SCHEDULED.TYPE,
      is_past_scheduled: 1
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_VISIT_CURRENT,
      sorting_direction: sortingDirections.ASCENDING
    }
  },
  COMPLETED: {
    COUNTER_NAME: 'completed',
    NAME: 'Completed',
    ROUTE: routes.SERVICE,
    TYPE: 'completed',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: true,
    PARAMS: {
      admin_status: adminStatuses.COMPLETED.TYPE
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_COMPLETED
    }
  },
  DIVIDER: {
    TYPE: 'divider'
  },
  PAID: {
    NAME: 'Paid',
    ROUTE: routes.SERVICE,
    TYPE: 'paid',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: false,
    PARAMS: {
      admin_status: adminStatuses.PAID.TYPE
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_COMPLETED
    }
  },
  UNFULFILLED: {
    NAME: 'Unfulfilled',
    ROUTE: routes.SERVICE,
    TYPE: 'unfulfilled',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: false,
    PARAMS: {
      admin_status: adminStatuses.UNFULFILLED.TYPE
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_CANCELLED
    }
  },
  ALL_JOBS: {
    NAME: 'All Jobs',
    ROUTE: routes.SERVICE,
    TYPE: 'all_jobs',
    HAS_SEARCH: true,
    HAS_WARNING_COUNTERS: false,
    IS_DEFAULT: true
  },
  CRM: {
    NAME: 'CRM',
    TYPE: 'crm'
  },
  HOMEOWNERS: {
    NAME: 'Homeowners',
    IS_DEFAULT: true,
    ROUTE: routes.CUSTOMER,
    TYPE: 'homeowner',
    HAS_SEARCH: true,
    HAS_TABS: true
  },
  HO_COHORT_FIRST_TIME: {
    NAME: '1st-time users',
    IS_DEFAULT: true,
    ROUTE: routes.CUSTOMER,
    TYPE: 'ho_cohort_first_time',
    HAS_SEARCH: true,
    HAS_TABS: true,
    PARAMS: {
      cohort_value: homeownerCohorts.FIRST_TIME.TYPE
    }
  },
  HO_COHORT_ACTIVATED: {
    NAME: 'Activated',
    IS_DEFAULT: true,
    ROUTE: routes.CUSTOMER,
    TYPE: 'ho_cohort_activated',
    HAS_SEARCH: true,
    HAS_TABS: true,
    PARAMS: {
      cohort_value: homeownerCohorts.ACTIVATED.TYPE
    }
  },
  HO_COHORT_REPEAT: {
    NAME: 'Repeat',
    IS_DEFAULT: true,
    ROUTE: routes.CUSTOMER,
    TYPE: 'ho_cohort_repeat',
    HAS_SEARCH: true,
    HAS_TABS: true,
    PARAMS: {
      cohort_value: homeownerCohorts.REPEAT.TYPE
    }
  },
  HO_COHORT_SUPER: {
    NAME: 'Superuser',
    IS_DEFAULT: true,
    ROUTE: routes.CUSTOMER,
    TYPE: 'ho_cohort_super',
    HAS_SEARCH: true,
    HAS_TABS: true,
    PARAMS: {
      cohort_value: homeownerCohorts.SUPER.TYPE
    }
  },
  PROVIDERS: {
    NAME: 'Service Providers',
    IS_DEFAULT: true,
    ROUTE: routes.PROVIDER,
    TYPE: 'pros',
    HAS_SEARCH: true,
    HAS_TABS: true
  },
  AGENTS: {
    NAME: 'Agents',
    IS_DEFAULT: true,
    ROUTE: routes.AGENT,
    TYPE: 'agents',
    HAS_SEARCH: true,
    HAS_TABS: true
  },
  // Analytics tab disabled
  // ANALYTICS: {
  //   NAME: 'Analytics',
  //   IS_DEFAULT: true,
  //   HAS_TABS: true,
  //   ROUTE: routes.ANALYTICS,
  //   NO_FILTERS: true,
  //   IS_HEADER: true,
  //   TYPE: 'analytics'
  // },
  // REWARDS: {
  //   NAME: 'Rewards',
  //   IS_DEFAULT: true,
  //   IS_HEADER: true,
  //   TYPE: 'rewards',
  //   ROUTE: routes.PROMO_CODES
  // },
  COMMUNICATION: {
    NAME: 'Communication',
    COUNTER_NAME: 'communication',
    IS_DEFAULT: true,
    IS_HEADER: true,
    TYPE: 'communication',
    ROUTE: routes.COMMUNICATION
  },
  MARKETING: {
    NAME: 'MARKETING',
    TYPE: 'marketing'
  },
  CAMPAIGNS: {
    NAME: 'Campaigns',
    IS_DEFAULT: true,
    TYPE: 'campaigns',
    ROUTE: routes.CAMPAIGNS,
    HAS_SEARCH: true,
    HAS_TABS: true
  }
};

export const storybookMenuItems = {
  ICONS: {
    TYPE: 'icons',
    ROUTE: '/storybook/icons',
    NAME: 'Icons'
  },
  COLORS: {
    TYPE: 'colors',
    ROUTE: '/storybook/colors',
    NAME: 'Colors'
  },
  TYPOGRAPHY: {
    TYPE: 'typographyy',
    ROUTE: '/storybook/typography',
    NAME: 'Typography'
  }
};

export const navigationTabs = {
  ALL: {
    HEADER_TITLE: 'All',
    TYPE: 'all'
  },
  ACTIVE: {
    HEADER_TITLE: ' ',
    HEADER_PREFIX: 'Install base',
    HEADER_PREFIX_TOOLTIP:
      'ALL users that have added their address (finished registration), not blocked, have not deleted the app, not batch-uploaded, within the operating service areas(User badges - but not MECE, precedence super > repeat > paid > active, inactive)',
    TYPE: 'active',
    PARAMS: {
      is_cognito_active: true,
      is_full_active: true, // is_full_active = is_active + is_api_active
      is_pre_filled: false
    }
  },
  NOT_ACTIVATED: {
    HEADER_TITLE: 'Not activated',
    TYPE: 'not_activated',
    PARAMS: {
      is_active: false,
      is_api_active: true,
      is_cognito_active: true,
      is_pre_filled: false
    }
  },
  BLOCKED: {
    HEADER_TITLE: 'Blocked',
    TYPE: 'blocked',
    PARAMS: {
      is_active: false,
      is_api_active: false,
      is_cognito_active: true,
      is_pre_filled: false
    }
  },
  BLOCKED_NOT_ACTIVE: {
    HEADER_TITLE: ' ',
    HEADER_PREFIX: 'CS Blocked',
    HEADER_PREFIX_TOOLTIP:
      "CS generated removal, cannot access the app, you can't create another profile with this phone number. CS can remove the block",
    TYPE: 'blocked_not_active',
    PARAMS: {
      is_cognito_active: true,
      is_full_active: false,
      is_pre_filled: false
    }
  },
  DEPOSIT_PAYMENTS: {
    HEADER_TITLE: 'Deposit',
    TYPE: 'deposit_payments',
    PARAMS: {
      is_final_payment: 0
    }
  },
  FINAL_PAYMENTS: {
    HEADER_TITLE: 'Final',
    TYPE: 'final_payments',
    PARAMS: {
      is_final_payment: 1
    }
  },
  INVITED: {
    HEADER_TITLE: 'Invited',
    TYPE: 'invited',
    PARAMS: {
      is_cognito_active: false,
      is_full_active: false,
      is_pre_filled: true
    }
  },
  REMOVED: {
    HEADER_TITLE: ' ',
    HEADER_PREFIX: 'Self-Deleted',
    HEADER_PREFIX_TOOLTIP:
      'When homeowners delete their account from their profile info (not necessarily app deletion), need to delete all their information/data instantly',
    TYPE: 'removed',
    PARAMS: {
      is_cognito_active: false,
      is_pre_filled: false
    }
  },
  REFERRAL: {
    HEADER_TITLE: 'Referrals',
    TYPE: 'referrals',
    SORTING_PARAMS: {
      sorting_column: columnNames.SOURCE_USER_ID
    }
  },
  REVIEW: {
    HEADER_TITLE: 'Reviews',
    TYPE: 'reviews',
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_CREATED
    }
  },
  SNAPSHOT: {
    HEADER_TITLE: 'Snapshot',
    TYPE: 'snapshot',
    NO_FILTERS: true
  },
  CHARTS: {
    HEADER_TITLE: 'Charts',
    TYPE: 'charts',
    NO_FILTERS: true
  },
  QUOTED_JOBS: {
    COUNTER_NAME: 'quoted_jobs',
    TYPE: 'quoted_jobs',
    HEADER_TITLE: jobTags.QUOTED_JOBS.NAME,
    PARAMS: {
      tag_id: jobTags.QUOTED_JOBS.TYPE
    }
  },
  LARGE: {
    COUNTER_NAME: 'large',
    TYPE: 'large',
    HEADER_TITLE: jobTags.LARGE.NAME,
    PARAMS: {
      tag_id: jobTags.LARGE.TYPE
    }
  },
  PAYMENT: {
    COUNTER_NAME: 'payment',
    TYPE: 'payment',
    HEADER_TITLE: jobTags.PAYMENT.NAME,
    PARAMS: {
      tag_id: jobTags.PAYMENT.TYPE
    }
  },
  LOVE_LETTER: {
    COUNTER_NAME: 'love letter',
    TYPE: 'love_letter',
    HEADER_TITLE: jobTags.LOVE_LETTER.NAME,
    PARAMS: {
      tag_id: jobTags.LOVE_LETTER.TYPE
    }
  },
  PASSABLE: {
    COUNTER_NAME: 'passable',
    TYPE: 'passable',
    HEADER_TITLE: 'Passable',
    PARAMS: {
      is_passable: 1
    }
  },
  WARNING_NO_COMMUNICATION: {
    COUNTER_NAME: 'no communication',
    TYPE: 'ew_no_communication',
    HEADER_TITLE: 'No Communication',
    PARAMS: {
      warning_type: 2
    }
  },
  WARNING_NOT_SCHEDULED: {
    COUNTER_NAME: 'not scheduled',
    TYPE: 'ew_not_scheduled',
    HEADER_TITLE: 'Not scheduled',
    PARAMS: {
      warning_type: 1
    }
  },
  QUOTED: {
    COUNTER_NAME: 'quoted',
    TYPE: 'quoted',
    HEADER_TITLE: 'Quoted',
    PARAMS: {
      is_quoted: 1
    }
  },
  INVOICE_ADMIN_PENDING_QUOTE: {
    COUNTER_NAME: 'not_validated_quotes',
    TYPE: 'not_validated_quotes',
    HEADER_TITLE: 'Quotes',
    PARAMS: {
      is_accepted: -1
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_CREATED
    }
  },
  INVOICE_ADMIN_PENDING_PAYMENT: {
    COUNTER_NAME: 'not_validated_payments',
    TYPE: 'not_validated_payments',
    HEADER_TITLE: 'Payments',
    PARAMS: {
      is_paid: 0
    },
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_CREATED
    }
  },
  HO_TAG_INACTIVE: {
    COUNTER_NAME: 'ho_inactive',
    TYPE: 'ho_inactive',
    HEADER_TITLE: homeownerTags.INACTIVE.TAB_NAME,
    HEADER_PREFIX: homeownerTags.INACTIVE.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.INACTIVE.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.INACTIVE.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_ACTIVE: {
    COUNTER_NAME: 'ho_active',
    TYPE: 'ho_active',
    HEADER_TITLE: homeownerTags.ACTIVE.TAB_NAME,
    HEADER_PREFIX: homeownerTags.ACTIVE.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.ACTIVE.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.ACTIVE.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_PAID: {
    COUNTER_NAME: 'ho_paid',
    TYPE: 'ho_paid',
    HEADER_TITLE: homeownerTags.PAID.TAB_NAME,
    HEADER_PREFIX: homeownerTags.PAID.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.PAID.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.PAID.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_REPEAT: {
    COUNTER_NAME: 'ho_repeat',
    TYPE: 'ho_repeat',
    HEADER_TITLE: homeownerTags.REPEAT.TAB_NAME,
    HEADER_PREFIX: homeownerTags.REPEAT.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.REPEAT.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.REPEAT.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_LOYAL: {
    COUNTER_NAME: 'ho_loyal',
    TYPE: 'ho_loyal',
    HEADER_TITLE: homeownerTags.LOYAL.TAB_NAME,
    HEADER_PREFIX: homeownerTags.LOYAL.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.LOYAL.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.LOYAL.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_NON_ICP: {
    COUNTER_NAME: 'ho_non_icp',
    TYPE: 'ho_non_icp',
    HEADER_TITLE: homeownerTags.NON_ICP.TAB_NAME,
    HEADER_PREFIX: homeownerTags.NON_ICP.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.NON_ICP.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.NON_ICP.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_TAG_SARA_TODD: {
    COUNTER_NAME: 'ho_troubled',
    TYPE: 'ho_troubled',
    HEADER_TITLE: homeownerTags.TROUBLED_MANUAL.TAB_NAME,
    HEADER_PREFIX: homeownerTags.TROUBLED_MANUAL.EMOJI,
    HEADER_PREFIX_TOOLTIP: homeownerTags.TROUBLED_MANUAL.DESCRIPTION,
    PARAMS: {
      ho_stage: homeownerTags.TROUBLED_MANUAL.TYPE,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_DORMANT: {
    COUNTER_NAME: 'ho_dormant',
    TYPE: 'ho_dormant',
    HEADER_TITLE: ' ',
    HEADER_PREFIX: 'Dormant',
    HEADER_PREFIX_TOOLTIP: 'Any paid homeowners who have not submitted a job in the last in the 90 days',
    PARAMS: {
      is_dormant: 1,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  HO_EARLY_PROMISE: {
    COUNTER_NAME: 'ho_early_promise',
    TYPE: 'ho_early_promise',
    HEADER_TITLE: ' ',
    HEADER_PREFIX: 'Early Promise',
    HEADER_PREFIX_TOOLTIP:
      '2 jobs completed within 60 days of 1st job submission, OR spend $2,290 in first 60 days first job submission',
    PARAMS: {
      is_early_promise: 1,
      is_cognito_active: true,
      is_full_active: true,
      is_pre_filled: false
    }
  },
  WARNING_ASSIGNMENT: {
    COUNTER_NAME: 'assignment',
    TYPE: 'ew_assignment',
    HEADER_TITLE: 'Assignment',
    PARAMS: {
      warning_type: 0
    }
  },
  SKILLS: {
    TYPE: 'SKILLS',
    HEADER_TITLE: 'Skills'
  },
  WARNING_REJECTED_OR_TIMEOUT: {
    COUNTER_NAME: 'timedout or rejected',
    TYPE: 'ew_rejected',
    HEADER_TITLE: 'Rejected/Timed-out',
    PARAMS: {
      warning_type: 7
    }
  },
  AR: {
    COUNTER_NAME: 'AR',
    TYPE: 'AR',
    HEADER_TITLE: jobTags.AR.NAME,
    PARAMS: {
      tag_id: jobTags.AR.TYPE
    }
  },
  // TROUBLED: {
  //   COUNTER_NAME: 'sara_todd',
  //   TYPE: 'sara_todd',
  //   HEADER_TITLE: jobTags.TROUBLED.NAME,
  //   PARAMS: {
  //     tag_id: jobTags.TROUBLED.TYPE
  //   }
  // },
  ACCEPTED: {
    COUNTER_NAME: 'accepted',
    TYPE: 'accepted',
    HEADER_TITLE: jobTags.ACCEPTED.NAME,
    SORTING_PARAMS: {
      sorting_column: columnNames.LAST_MESSAGE,
      sorting_direction: sortingDirections.ASCENDING
    },
    PARAMS: {
      tag_id: jobTags.ACCEPTED.TYPE
    }
  },
  PAST_SCHEDULED: {
    COUNTER_NAME: 'past_scheduled',
    TYPE: 'past_scheduled',
    HEADER_TITLE: jobTags.PAST_SCHEDULED.NAME,
    PARAMS: {
      tag_id: jobTags.PAST_SCHEDULED.TYPE
    }
  },
  HOLD: {
    COUNTER_NAME: 'on_hold',
    TYPE: 'on_hold',
    HEADER_TITLE: 'On-Hold',
    SORTING_PARAMS: {
      sorting_column: columnNames.DATETIME_HOLD,
      sorting_direction: sortingDirections.ASCENDING
    },
    PARAMS: {
      tag_id: jobTags.HOLD.TYPE
    }
  },
  OWLERY: {
    TYPE: 'owlery',
    HEADER_TITLE: 'All',
    PARAMS: {
      priority_task: 1
    }
  },
  ONE_CLICK_BOOKING: {
    HEADER_TITLE: providerStages.ONE_CLICK_BOOKING.TITLE,
    HEADER_PREFIX: providerStages.ONE_CLICK_BOOKING.EMOJI,
    HEADER_PREFIX_TOOLTIP: providerStages.ONE_CLICK_BOOKING.DESCRIPTION,
    TYPE: 'one-click-booking',
    PARAMS: {
      pro_stage: providerStages.ONE_CLICK_BOOKING.TYPE
    }
  },
  PRO_COHORT_CORE: {
    HEADER_TITLE: providerCohorts.CORE.TITLE,
    TYPE: 'pro-cohort-core',
    PARAMS: {
      is_core: true
    }
  },
  PRO_COHORT_PARKED: {
    HEADER_TITLE: providerCohorts.PARKED.TITLE,
    TYPE: 'pro-cohort-parked',
    PARAMS: {
      is_parked: true
    }
  },
  NEW_HO_PRO: {
    HEADER_TITLE: providerStages.NEW_HO_PRO.TITLE,
    HEADER_PREFIX: providerStages.NEW_HO_PRO.EMOJI,
    HEADER_PREFIX_TOOLTIP: providerStages.NEW_HO_PRO.DESCRIPTION,
    TYPE: 'new-ho-pro',
    PARAMS: {
      pro_stage: providerStages.NEW_HO_PRO.TYPE
    }
  },
  NEW_PRO: {
    HEADER_TITLE: providerStages.NEW_PRO.TITLE,
    HEADER_PREFIX: providerStages.NEW_PRO.EMOJI,
    HEADER_PREFIX_TOOLTIP: providerStages.NEW_PRO.DESCRIPTION,
    TYPE: 'new-pro',
    PARAMS: {
      pro_stage: providerStages.NEW_PRO.TYPE
    }
  },
  TROUBLED_PRO: {
    HEADER_TITLE: providerStages.TROUBLED_PRO.TITLE,
    HEADER_PREFIX: providerStages.TROUBLED_PRO.EMOJI,
    HEADER_PREFIX_TOOLTIP: providerStages.TROUBLED_PRO.DESCRIPTION,
    TYPE: 'troubled-pro',
    PARAMS: {
      pro_stage: providerStages.TROUBLED_PRO.TYPE
    }
  },
  FUTURE_EVENT: {
    COUNTER_NAME: 'future_event',
    TYPE: 'future_event',
    HEADER_TITLE: 'Future',
    PARAMS: {
      is_executed: false
    }
  },
  PAST_EVENT: {
    COUNTER_NAME: 'past_event',
    TYPE: 'past_event',
    HEADER_TITLE: 'Past',
    PARAMS: {
      is_executed: true
    }
  }
};

export const homeownerTagsTabs = {
  HO_TAG_INACTIVE: navigationTabs.HO_TAG_INACTIVE,
  HO_TAG_ACTIVE: navigationTabs.HO_TAG_ACTIVE,
  HO_TAG_PAID: navigationTabs.HO_TAG_PAID,
  HO_TAG_REPEAT: navigationTabs.HO_TAG_REPEAT,
  HO_TAG_LOYAL: navigationTabs.HO_TAG_LOYAL,
  HO_TAG_NON_ICP: navigationTabs.HO_TAG_NON_ICP,
  HO_TAG_SARA_TODD: navigationTabs.HO_TAG_SARA_TODD
};

export const homeownerCohortTabs = {
  DEFAULT: navigationTabs.ACTIVE,
  ...homeownerTagsTabs
};

export const topNavigationTabs = {
  [sidebarMenuItems.GRINGOTTS.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    PAYMENT: navigationTabs.PAYMENT,
    AR: navigationTabs.AR,
    LARGE: navigationTabs.LARGE,
    // TROUBLED: navigationTabs.TROUBLED,
    LOVE_LETTER: navigationTabs.LOVE_LETTER
  },
  [sidebarMenuItems.OWLERY.TYPE]: {
    DEFAULT: navigationTabs.OWLERY,
    ACCEPTED: navigationTabs.ACCEPTED,
    PAST_SCHEDULED: navigationTabs.PAST_SCHEDULED,
    QUOTED_JOBS: navigationTabs.QUOTED_JOBS,
    HOLD: navigationTabs.HOLD
  },
  [sidebarMenuItems.PAYMENTS.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    DEPOSIT_PAYMENTS: navigationTabs.DEPOSIT_PAYMENTS,
    FINAL_PAYMENTS: navigationTabs.FINAL_PAYMENTS
  },
  [sidebarMenuItems.ACCEPTED.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    PASSABLE: navigationTabs.PASSABLE,
    WARNING_NO_COMMUNICATION: navigationTabs.WARNING_NO_COMMUNICATION,
    WARNING_NOT_SCHEDULED: navigationTabs.WARNING_NOT_SCHEDULED
  },
  [sidebarMenuItems.REQUESTED.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    WARNING_ASSIGNMENT: navigationTabs.WARNING_ASSIGNMENT,
    WARNING_REJECTED_OR_TIMEOUT: navigationTabs.WARNING_REJECTED_OR_TIMEOUT
  },
  [sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE]: {
    DEFAULT: navigationTabs.INVOICE_ADMIN_PENDING_QUOTE,
    PAYMENT: navigationTabs.INVOICE_ADMIN_PENDING_PAYMENT
  },
  [sidebarMenuItems.HOMEOWNERS.TYPE]: {
    DEFAULT: navigationTabs.ACTIVE,
    ...homeownerTagsTabs,
    HO_DORMANT: navigationTabs.HO_DORMANT,
    HO_EARLY_PROMISE: navigationTabs.HO_EARLY_PROMISE,
    BLOCKED_NOT_ACTIVE: navigationTabs.BLOCKED_NOT_ACTIVE,
    REMOVED: navigationTabs.REMOVED,
    INVITED: navigationTabs.INVITED,
    ALL: navigationTabs.ALL,
    REFERRAL: {
      ...navigationTabs.REFERRAL,
      PARAMS: {
        target_group_id: userGroups.CUSTOMER.TYPE
      }
    }
  },
  [sidebarMenuItems.HO_COHORT_FIRST_TIME.TYPE]: homeownerCohortTabs,
  [sidebarMenuItems.HO_COHORT_ACTIVATED.TYPE]: homeownerCohortTabs,
  [sidebarMenuItems.HO_COHORT_REPEAT.TYPE]: homeownerCohortTabs,
  [sidebarMenuItems.HO_COHORT_SUPER.TYPE]: homeownerCohortTabs,
  [sidebarMenuItems.PROVIDERS.TYPE]: {
    DEFAULT: navigationTabs.ACTIVE,
    PRO_COHORT_CORE: navigationTabs.PRO_COHORT_CORE,
    PRO_COHORT_PARKED: navigationTabs.PRO_COHORT_PARKED,
    ONE_CLICK_BOOKING: navigationTabs.ONE_CLICK_BOOKING,
    NEW_HO_PRO: navigationTabs.NEW_HO_PRO,
    NEW_PRO: navigationTabs.NEW_PRO,
    TROUBLED_PRO: navigationTabs.TROUBLED_PRO,
    NOT_ACTIVATED: navigationTabs.NOT_ACTIVATED,
    INVITED: navigationTabs.INVITED,
    REFERRAL: {
      ...navigationTabs.REFERRAL,
      PARAMS: {
        target_group_id: userGroups.PROVIDER.TYPE
      }
    },
    ALL: navigationTabs.ALL,
    BLOCKED: navigationTabs.BLOCKED,
    REMOVED: navigationTabs.REMOVED,
    REVIEW: {
      ...navigationTabs.REVIEW,
      PARAMS: {
        group_id: userGroups.PROVIDER.TYPE
      }
    },
    SKILLS: navigationTabs.SKILLS
  },
  [sidebarMenuItems.AGENTS.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    ACTIVE: navigationTabs.ACTIVE,
    BLOCKED_NOT_ACTIVE: navigationTabs.BLOCKED_NOT_ACTIVE,
    INVITED: navigationTabs.INVITED,
    REMOVED: navigationTabs.REMOVED
  },
  [sidebarMenuItems.CAMPAIGNS.TYPE]: {
    DEFAULT: navigationTabs.ALL,
    FUTURE_EVENT: navigationTabs.FUTURE_EVENT,
    PAST_EVENT: navigationTabs.PAST_EVENT
  }
  // [sidebarMenuItems.ANALYTICS.TYPE]: {
  //   DEFAULT: navigationTabs.SNAPSHOT,
  //   CHARTS: navigationTabs.CHARTS
  // }
};

export const siblingPaginationTypes = {
  AGENT: 'AGENT',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_JOB: 'CUSTOMER_JOB',
  CUSTOMER_JOB_REVIEW: 'CUSTOMER_JOB_REVIEW',
  DEFAULT: 'DEFAULT',
  JOB: 'JOB',
  PAYMENT_JOB: 'PAYMENT_JOB',
  QUOTATION_JOB: 'QUOTATION_JOB',
  PROVIDER: 'PROVIDER',
  PROVIDER_JOB: 'PROVIDER_JOB',
  PROVIDER_JOB_REVIEW: 'PROVIDER_JOB_REVIEW',
  REFERRAL_CUSTOMER: 'REFERRAL_CUSTOMER',
  REFERRAL_PROVIDER: 'REFERRAL_PROVIDER',
  REVIEW_JOB: 'REVIEW_JOB',
  SKILLS: 'SKILLS',
  CAMPAIGNS: 'CAMPAIGNS'
};
