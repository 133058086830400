import React from 'react';
import { useController } from 'react-hook-form';
import { normalizers, formatters } from 'utils';
import { fieldTypes } from 'common/enums/form';
import messages from 'constants/messages';
import { Icon } from 'components';
import styles from 'constants/styles';
import { StyledInput, InputRow, CloseButton, Error, Label, FlexColumn, FlexRow } from './styled';
import { useTheme } from 'hooks';

const ControlledHookInput = ({
  name,
  control,
  label,
  placeholder,
  inputConfig = {},
  fieldType = {},
  reset,
  rules = {},
  wrapperStyle = {},
  style = {},
  isHideErrorText,
  lengthInfoPrefix,
  maxLength,
  width,
  customPlaceholder,
  readOnly,
  onCustomChange,
  renderCustomIcon,
  renderTooltip,
  renderNotice
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { error, invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  });

  const transform = {
    output: normalizers[fieldType?.formatterName] || normalizers.default,
    input: formatters[fieldType?.formatterName] || formatters.default
  };

  const handleChange = (e) => {
    if (onCustomChange) {
      onCustomChange({
        transformedValue: transform.output(e.target.value),
        value: e.target.value
      });
    }
    onChange(transform.output(e.target.value));
  };
  const maxInputLength = maxLength || rules?.maxLength;
  const hasMaxLengthLimitReached = Boolean(maxInputLength) && value?.length >= maxInputLength;

  const theme = useTheme();
  return (
    <FlexColumn style={wrapperStyle}>
      {label && <Label>{label}</Label>}
      <InputRow>
        <StyledInput
          autoComplete="off"
          ref={ref}
          style={style}
          reset={reset}
          width={width}
          placeholder={placeholder}
          {...(rules?.maxLength ? { maxLength: rules.maxLength } : {})}
          {...inputProps}
          {...inputConfig}
          {...fieldType}
          onChange={handleChange}
          value={transform.input(value)}
          invalid={invalid && isTouched}
          {...(customPlaceholder ? { placeholder: customPlaceholder } : {})}
          readOnly={readOnly}
        />
        {Boolean(reset && value) && (
          <CloseButton>
            <Icon
              backgroundSize={16}
              backgroundColor={theme.colors.DARK_3}
              name="closeFill"
              onClick={() => onChange('')}
            />
          </CloseButton>
        )}
        {Boolean(renderCustomIcon) && <CloseButton>{renderCustomIcon()}</CloseButton>}
        {Boolean(renderTooltip) && renderTooltip()}
      </InputRow>
      <FlexRow>
        {Boolean(error?.type && !isHideErrorText) ? (
          <Error>{error.message || messages.formErrors[error.type?.toUpperCase()]}</Error>
        ) : (
          <div />
        )}
        {Boolean(maxInputLength) && (
          <Label margin={'5px 0'} color={hasMaxLengthLimitReached ? styles.colors.RED : null} small>
            {lengthInfoPrefix}
            {lengthInfoPrefix ? ' (' : ''}
            {value?.length}/{maxInputLength}
            {lengthInfoPrefix ? ')' : ''}
          </Label>
        )}
      </FlexRow>
      {Boolean(renderNotice) && renderNotice()}
    </FlexColumn>
  );
};

ControlledHookInput.defaultProps = {
  fieldType: fieldTypes.TEXT,
  size: 'small'
};

export default ControlledHookInput;
