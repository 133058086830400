import React from 'react';

import styles from 'constants/styles';
import { cellTypes } from 'common/enums/table';
import { clipTooltipText } from 'utils';
import { platforms } from 'common/enums/user';
import { getAppNameByGroupId } from 'containers/UserDetails/helpers/user';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import {
  Avatar,
  HighlightedText,
  Tooltip,
  Icon,
  LoadingSpinner,
  UserName,
  IconButton,
  StyledTooltip
} from 'components';
import {
  Button,
  CurrentLabel,
  EllipsisText,
  IconWrapper,
  Image,
  Label,
  LabelList,
  LatestVersionMark,
  Mark,
  RadioButton,
  ReviewFlex,
  RichText,
  RichTextHeader,
  SubText,
  TooltipText,
  TooltipWrapper,
  Skill
} from './styled';
import { useDispatch } from 'react-redux';
import { getHomeownerTags } from 'common/helpers/user';

const labelColors = {
  0: styles.colors.WHITE,
  1: styles.colors.DARK_GREEN,
  2: styles.colors.DARK_YELLOW,
  3: styles.colors.DARK_RED,
  4: styles.colors.DARK_BLUE_3,
  5: styles.colors.WHITE,
  6: styles.colors.DARK_VIOLET
};

const cells = {
  [cellTypes.AVATAR]: ({ value: { icon, url } }) => {
    return <Avatar url={url} alt="Avatar" size={32} iconSize={15} icon={icon} />;
  },
  [cellTypes.BUTTON]: ({ value: { isDisabled, isLoading, onClick, buttonTitle } }) => {
    return (
      <Button disabled={isDisabled} onClick={onClick}>
        {isLoading ? <LoadingSpinner color="dark" /> : buttonTitle}
      </Button>
    );
  },
  [cellTypes.PLATFORM_VERSION]: ({ value: { platform } }) => {
    return (
      <>
        <div>
          {platform && platform.platform_type
            ? `${Object.values(platforms).find(({ TYPE }) => TYPE === platform.platform_type)?.NAME || ''} ${
                platform.platform_version ? platform.platform_version : ''
              }`
            : '-'}{' '}
          {platform && platform.platform_type ? (
            <LatestVersionMark isLatest={platform.is_platform_latest}>
              {`(${platform.is_platform_latest ? 'Latest' : 'Not Latest'})`}
            </LatestVersionMark>
          ) : null}
          <br />
          {Boolean(platform?.model) && platform.model}
        </div>
      </>
    );
  },
  [cellTypes.APP_VERSION]: ({ value: { platform, userGroupId } }) => {
    const appName = getAppNameByGroupId(userGroupId);
    return (
      <>
        <div>
          {platform && platform.platform_type
            ? `${(platform.app_version && appName) || ''}${'\n'}${platform.app_version || ''}${
                platform.app_build_number ? ` (#${platform.app_build_number})` : ''
              }`
            : '-'}{' '}
          {platform && platform.platform_type ? (
            <LatestVersionMark isLatest={platform.is_app_latest}>
              {`(${platform.is_app_latest ? 'Latest' : 'Not Latest'})`}
            </LatestVersionMark>
          ) : null}
        </div>
      </>
    );
  },
  [cellTypes.CHECKBOX]: ({ value: { isTarget, isChecked } }) => {
    if (isTarget) {
      return <CurrentLabel>Current</CurrentLabel>;
    }
    return (
      <RadioButton selected={isChecked}>
        {isChecked && <Icon color={styles.colors.WHITE} name="ok" type="rectangle" size={10} />}
      </RadioButton>
    );
  },
  [cellTypes.IMAGE]: ({ value: { imageSrc } }) => {
    // TODO: (refactoring) replace with Icon component
    return <Image src={imageSrc} />;
  },
  [cellTypes.LABEL]: ({
    value: { color, text, subText, hasTooltip, description, maxWidth = 160, isEllipsis, alignLeft }
  }) => {
    if (!text) {
      return '';
    }

    if (hasTooltip) {
      return (
        <Tooltip
          minWidth={210}
          left={2}
          bottom={31}
          title={
            <>
              <TooltipText>{clipTooltipText(description, 0, 5)}</TooltipText>
              <TooltipText>{clipTooltipText(description, 5)}</TooltipText>
            </>
          }
          wrappedComponent={
            <Label color={color} maxWidth={maxWidth} alignLeft={alignLeft}>
              {text}
            </Label>
          }
        />
      );
    }

    if (subText) {
      return (
        <Label color={color} maxWidth={maxWidth} isEllipsis={isEllipsis} hasSubText alignLeft={alignLeft}>
          {text}
          <br />
          <SubText color={color}>{subText}</SubText>
        </Label>
      );
    }

    return (
      <Label color={color} maxWidth={maxWidth} isEllipsis={isEllipsis} alignLeft={alignLeft}>
        {text}
      </Label>
    );
  },
  [cellTypes.LABEL_LIST]: ({ value: { labels } }) => {
    if (!labels) {
      return null;
    }

    return (
      <LabelList>
        {labels.map(({ TYPE, NAME }) => (
          <Label key={TYPE} textColor={labelColors[TYPE]} listItem>
            {NAME}
          </Label>
        ))}
      </LabelList>
    );
  },
  [cellTypes.TEXT]: ({
    searchTerm,
    value: {
      text,
      textArray,
      hasHighlight,
      highlightType = 'default',
      hasMark,
      markColor,
      description,
      textColor,
      overflow,
      display,
      stage
    }
  }) => {
    if (!text && !textArray?.length) {
      return '';
    }

    if (hasMark) {
      return (
        <Tooltip
          minWidth={250}
          bottom={20}
          left={-4}
          title={description}
          wrappedComponent={
            <TooltipWrapper>
              <Mark color={markColor} />
              {hasHighlight ? (
                <EllipsisText overflow={overflow}>
                  <HighlightedText
                    text={text}
                    textArray={textArray}
                    capitalized
                    highlight={searchTerm}
                    type={highlightType}
                  />
                </EllipsisText>
              ) : (
                <EllipsisText>{text}</EllipsisText>
              )}
            </TooltipWrapper>
          }
        />
      );
    }
    if (hasHighlight && stage !== undefined) {
      const retentionPrefix = getHomeownerTags({ stage });
      return (
        <TooltipWrapper>
          <EllipsisText overflow={overflow}>
            <HighlightedText text={text} textArray={textArray} highlight={searchTerm} type={highlightType} />
          </EllipsisText>
          <StyledTooltip title={retentionPrefix.DESCRIPTION} arrow placement="top-start">
            <div>{retentionPrefix.EMOJI}</div>
          </StyledTooltip>
        </TooltipWrapper>
      );
    }
    if (hasHighlight) {
      return (
        <EllipsisText overflow={overflow}>
          <HighlightedText text={text} textArray={textArray} highlight={searchTerm} type={highlightType} />
        </EllipsisText>
      );
    }

    return (
      <EllipsisText overflow={overflow} textColor={textColor} display={display}>
        {text}
      </EllipsisText>
    );
  },
  [cellTypes.RICH_TEXT]: ({ value: { datetime, text, secondText } }) => {
    if (!text) {
      return '';
    }

    return (
      <>
        {Boolean(datetime) && <RichTextHeader>{datetime}</RichTextHeader>}
        {Boolean(secondText) && <RichTextHeader>{secondText}</RichTextHeader>}
        <RichText>{text}</RichText>
      </>
    );
  },
  [cellTypes.HEADED_TEXT]: ({ value: { header, text } }) => {
    if (!header) {
      return '';
    }
    return (
      <>
        <RichTextHeader color={styles.colors.BLACK}>{header}</RichTextHeader>
        {Boolean(text) && <SubText color={styles.colors.BLACK}>{text}</SubText>}
      </>
    );
  },

  [cellTypes.SKILLS]: ({ value: { skill }, searchTerm }) => {
    const dispatch = useDispatch();
    return (
      <Skill>
        <IconButton
          noFill
          iconName="editMessage"
          onClick={() => dispatch(openModal(modalTypes.UPDATE_SKILL, { skill }))}
        />
        <EllipsisText>
          <HighlightedText text={skill.name} highlight={searchTerm} />
        </EllipsisText>
      </Skill>
    );
  },
  [cellTypes.REVIEW]: ({ value: { isPositive, text, isEllipsis } }) => {
    return (
      <ReviewFlex>
        <IconWrapper>
          <Icon name={isPositive ? 'thumbUpOutlined' : 'thumbDownOutlined'} size={20} />
        </IconWrapper>
        <HighlightedText text={text} highlight={''} rows={3} isEllipsis={isEllipsis} />
      </ReviewFlex>
    );
  },
  [cellTypes.USER_NAME]: ({ value: { pro_stage, stage, given_name, family_name, hasHighlight }, searchTerm }) => {
    return (
      <UserName
        searchTerm={hasHighlight ? searchTerm : ''}
        isEllipsis
        stage={stage}
        given_name={given_name}
        pro_stage={pro_stage}
        family_name={family_name}
      />
    );
  }
};

const Cell = ({ type = cellTypes.TEXT, value = {}, searchTerm }) => {
  return cells[type]({ value, searchTerm });
};

export default Cell;
