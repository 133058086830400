import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import messages from 'constants/messages';
import { Label, FlexRow, ToggleButton, Error } from './styled';

const ToggleButtonGroup = ({
  label,
  name,
  control,
  row,
  exclusive,
  isRequired,
  defaultValue,
  rules = {},
  error = {},
  options,
  ...props
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  useEffect(() => {
    if (defaultValue && value === undefined) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const onValueChange = (event, newValue) => {
    if (isRequired && newValue === null) {
      return;
    }
    onChange(newValue);
  };

  return (
    <FlexRow>
      {label && <Label>{label}</Label>}
      <MuiToggleButtonGroup onChange={onValueChange} aria-label={label} exclusive value={value} row={row}>
        {options.map(({ value, name, disabled = false }) => (
          <ToggleButton key={value} value={value} disabled={disabled} {...props} {...inputProps}>
            {name}
          </ToggleButton>
        ))}
      </MuiToggleButtonGroup>
      {Boolean(messages.formErrors[error?.type?.toUpperCase()]) && Boolean(options.length) && (
        <Error>{messages.formErrors[error?.type?.toUpperCase()]}</Error>
      )}
    </FlexRow>
  );
};

export default ToggleButtonGroup;
