import React from 'react';
import { connect } from 'react-redux';

import modalTypes from 'constants/modalTypes';
import { IconButton } from 'components';
import { openModal } from 'store/actions';

const AddReward = ({ userId, openModal }) => {
  if (!userId) {
    return null;
  }

  const onIconClick = () => openModal(modalTypes.REWARD, { userId });

  return <IconButton onClick={onIconClick} iconName="plus" />;
};

const mapDispatchToProps = {
  openModal
};

export default connect(null, mapDispatchToProps)(AddReward);
