import moment from 'moment-timezone';

import dates from 'constants/dates';
import styles, { twoStoryLabelTextStyle } from 'constants/styles';
import { TIME_PICKER_MINUTES_STEP, DEFAULT_VISIT_WINDOW } from 'constants/visit';

import { filterTypes, jobFilters, hiddenFilters } from 'common/enums/filters';
import { adminStatuses, authors, jobTypesByTab } from 'common/enums/job';
import { sidebarMenuItems } from 'common/enums/navigation';
import { formatters } from 'utils';

export const getTimeWindow = ({ datetime_visit, datetime_window_end, isIcon }) => {
  const endValue =
    datetime_window_end ||
    moment(datetime_visit)
      .add(TIME_PICKER_MINUTES_STEP * DEFAULT_VISIT_WINDOW, 'minutes')
      .format(dates.DATETIME_ISO);
  return formatters.timeWindow({
    startValue: datetime_visit,
    endValue,
    isDayOfWeek: true,
    isIcon,
    isAddUtcOfffset: true
  });
};

export const getFilledJobFilters = (filters) =>
  jobFilters.reduce(
    (accumulator, jobFilter) =>
      filters[jobFilter.TYPE] ? { ...accumulator, [jobFilter.TYPE]: filters[jobFilter.TYPE] || '' } : accumulator,
    {}
  );

export const getAdminStatusNameByCode = (statusCode) =>
  Object.values(adminStatuses).find((status) => status.TYPE === statusCode)?.NAME || '';

export const getAdminStatusColorByCode = (statusCode) =>
  Object.values(adminStatuses).find((status) => status.TYPE === statusCode)?.COLOR || '';

export const prepareStageTableCell = ({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled }) => {
  const isScheduledStage = admin_status === adminStatuses.SCHEDULED.TYPE;
  const scheduledDate = isScheduledStage && getTimeWindow({ datetime_visit, datetime_window_end });
  const style = {
    paddingRight: 10,
    ...(scheduledDate ? twoStoryLabelTextStyle : {})
  };

  if (isScheduledStage && is_past_scheduled) {
    return {
      color: styles.colors.GRAY,
      style,
      subText: scheduledDate,
      text: sidebarMenuItems.PAST_SCHEDULED.NAME
    };
  }
  return {
    color: Object.values(adminStatuses).find((status) => status.TYPE === admin_status)?.COLOR || styles.colors.BLACK,
    style,
    subText: scheduledDate,
    text: getAdminStatusNameByCode(admin_status)
  };
};

export const prepareEmptyJobFilters = () => jobFilters.reduce((o, key) => ({ ...o, [key.TYPE]: '' }), {});

const transformFilterValue = ({ value, dataType }) => (value || dataType === filterTypes.SELECT ? value : '');

export const prepareFiltersFromValues = ({ filters, values }) => {
  return filters.reduce((accumulator, filter) => {
    if (!values[filter.TYPE]) {
      return accumulator;
    }
    return {
      ...accumulator,
      [filter.TYPE]: transformFilterValue({
        value: values[filter.TYPE],
        dataType: filter.DATA_TYPE
      })
    };
  }, {});
};

export const prepareJobFiltersFormValues = (values) => prepareFiltersFromValues({ filters: jobFilters, values });

export const prepareHiddenFiltersFormValues = (values) => prepareFiltersFromValues({ filters: hiddenFilters, values });

export const getAuthorByType = ({ author, type }) => {
  switch (type) {
    case authors.HO: {
      return `HO - ${author || 'Homeowner'}`;
    }
    case authors.PRO: {
      return `Pro - ${author || 'Provider'}`;
    }
    case authors.OPS: {
      return `Ops - ${author || 'Member'}`;
    }
    case authors.AUTO: {
      return 'Auto';
    }
    default: {
      return '';
    }
  }
};

export const getJobRequestParams = (type) => {
  switch (type) {
    case jobTypesByTab.ACTIVE:
      return {
        is_open: 1,
        fieldName: 'openServiceList'
      };
    // case jobTypesByTab.RECENTLY_COMPLETED:
    //   return {
    //     is_open: null,
    //     is_recently_completed: true,
    //     fieldName: 'recentlyCompletedServiceList'
    //   };
    case jobTypesByTab.CANCELLED:
      return {
        is_open: null,
        // is_job_history: true,
        admin_status: `${adminStatuses.UNFULFILLED.TYPE},${adminStatuses.BUNDLED.TYPE}`,
        fieldName: 'cancelledServiceList'
      };
    case jobTypesByTab.PAID:
      return {
        is_open: null,
        // is_job_history: true,
        admin_status: `${adminStatuses.PAID.TYPE}`,
        fieldName: 'closedServiceList'
      };
    default:
      return {};
  }
};
