import styled from 'styled-components';

export const Picker = styled.div`
  position: relative;
`;

export const Swatch = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #353d41;
  ${({ disabled }) => !disabled && `margin-left: 8px; cursor: pointer;`}
  ${({ hasValidColor }) =>
    !hasValidColor &&
    `div:before,
  div:after {
    content: '';
    position: absolute;
    bottom: calc(50% - 0.5px);
    left: -4px;
    width: 40px;
    height: 1px;
    background: #676d70;
  }
  div:before {
    transform: rotate(45deg);
  }
  div:after {
    transform: rotate(-45deg);
  }`}
`;

export const Popover = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
`;
