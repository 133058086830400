import styled from 'styled-components';
import styles from 'constants/styles';

export const RightSidebarShadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: ${styles.colors.SHADOW_BLACK};
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const RightSidebarContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${styles.colors.WHITE};
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.25);
  min-width: 384px;
  height: 100vh;
`;

export const RightSidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;
  padding: 18px 24px 0 24px;
`;

export const RightSidebarHeaderText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
`;
