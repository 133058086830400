import styled from 'styled-components';
import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
  .${tooltipClasses.tooltip} {
    background: ${({ theme, dark }) => (dark ? theme.colors.DARK_2 : theme.colors.WHITE)};
    filter: ${({ theme }) => theme.filter.THIN_DARK};
    box-shadow: ${({ theme }) => theme.boxShadows.DARK};
    border-radius: 8px;
    padding: 10px;
    max-width: ${({ maxWidth }) => maxWidth || 600}px;
    border: 1px solid ${({ theme, dark }) => (dark ? theme.colors.DARK_2 : theme.colors.WHITE)};
    color: ${({ dark, theme, color }) => (color ? color : dark ? theme.colors.WHITE : theme.colors.DARK_1)};
    ${({ tooltipStyle }) => tooltipStyle};
  }
  .MuiTooltip-arrow {
    color: ${({ theme, dark }) => (dark ? theme.colors.DARK_2 : theme.colors.WHITE)};
  }
`;

export default StyledTooltip;
