import React from 'react';
import PropTypes from 'prop-types';

import { TabWrapper } from './styled';

const Tab = ({ isActive, children }) => {
  return <TabWrapper isActive={isActive}>{children}</TabWrapper>;
};

Tab.propTypes = {
  children: PropTypes.node.isRequired
};

export default Tab;
