import React, { Component } from 'react';
import PropTypes from 'prop-types';
import icons from './icons';
import { Background, Image } from './styled';
import { icons as iconVariants } from 'common/enums/icons';
class Icon extends Component {
  render() {
    const {
      src,
      name,
      size,
      color,
      strokeWidth,
      backgroundSize,
      backgroundColor,
      clickable,
      type,
      border,
      rotate,
      width,
      secondColor,
      height,
      onClick = null,
      style,
      margin
    } = this.props;
    const IconClass = icons[name];

    return (
      <Background
        backgroundColor={backgroundColor}
        backgroundSize={backgroundSize || size}
        border={border}
        clickable={clickable}
        name={name}
        width={width}
        height={height}
        onClick={onClick}
        style={style}
        margin={margin}>
        {src ? (
          <Image src={src} size={size} color={color} />
        ) : IconClass ? (
          <IconClass
            type={type}
            size={size}
            width={width}
            height={height}
            color={color}
            secondColor={secondColor}
            strokeWidth={strokeWidth}
            rotate={rotate}
          />
        ) : null}
      </Background>
    );
  }
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.oneOf(Object.values(iconVariants)),
  size: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundSize: PropTypes.number,
  backgroundColor: PropTypes.string
};

Icon.defaultProps = {
  src: '',
  name: ''
};

export default Icon;
