import React from 'react';

const IconVideoCall = ({ size = 16, color = '#D6D9E5' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 640 640"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M124.244 164.444c-35.927 0-65.051 27.858-65.051 62.222v186.667c0 34.366 29.124 62.221 65.051 62.221h227.679c35.928 0 65.051-27.856 65.051-62.221v-186.667c0-34.364-29.123-62.222-65.051-62.222h-227.679zM26.667 226.667c0-51.547 43.687-93.333 97.577-93.333h227.679c53.891 0 97.576 41.787 97.576 93.333v24.452l131.371-59.15c15.101-6.799 32.464 3.739 32.464 19.704v197.885c0 15.176-15.824 25.699-30.696 20.413l-133.139-47.307v30.669c0 51.547-43.686 93.333-97.576 93.333h-227.679c-53.89 0-97.577-41.787-97.577-93.333v-186.667zM449.499 349.474l131.309 46.659v-169.751l-131.309 59.122v63.971z"
        fill={color}
      />
    </svg>
  );
};

export default IconVideoCall;
