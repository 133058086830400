import styles from 'constants/styles';

export const noteTypes = {
  service: 'Job',
  homeowner: 'Homeowner',
  provider: 'Pro'
};

export const reportTypes = [
  {
    id: 0,
    value: 0,
    label: 'Other'
  },
  {
    id: 1,
    value: 1,
    label: 'Spectora'
  },
  {
    id: 2,
    value: 2,
    label: 'Kelly Davis'
  },
  {
    id: 4,
    value: 4,
    label: 'Inspector Pro (A to Z)'
  }
];

export const earlyWarnings = [
  {
    id: 0,
    name: 'Assignment',
    color: styles.colors.DARK_YELLOW,
    description: 'between request submission and assignment/pro acceptance'
  },
  {
    id: 1,
    name: 'Not Scheduled',
    color: styles.colors.DARK_RED,
    description: 'since the job assignment'
  },
  {
    id: 2,
    name: 'Communication',
    color: styles.colors.DARK_GREEN,
    description: 'since the last communication from HO/ PRO'
  },
  {
    id: 3,
    name: 'Complete Job',
    color: styles.colors.YELLOW,
    description: 'since complete job + no payment initiated'
  },
  {
    id: 4,
    name: 'Payment',
    color: styles.colors.DARK_RED,
    description: 'since invoice sent'
  },
  {
    id: 5,
    name: 'Pro Review',
    color: styles.colors.DARK_YELLOW,
    description: 'since job was paid'
  },
  {
    id: 6,
    name: 'Check in',
    color: styles.colors.DARK_YELLOW,
    description: 'since complete visit + no job closed'
  },
  {
    id: 7,
    name: 'Timed-out/Rejected',
    color: styles.colors.DARK_RED,
    description: 'since assignment timed-out or PRO rejected'
  }
];

export const remainderHours = [
  {
    id: 0,
    name: '0h'
  },
  {
    id: 1,
    name: '1h'
  },
  {
    id: 2,
    name: '2h'
  },
  {
    id: 3,
    name: '3h'
  },
  {
    id: 6,
    name: '6h'
  },
  {
    id: 12,
    name: '12h'
  },
  {
    id: 24,
    name: '24h'
  },
  {
    id: 48,
    name: '48h'
  }
];

export const unfulfilledGroup = {
  OTHER: 11
};

export const unfulfilledGroupList = [
  {
    id: 1,
    value: 1,
    name: 'Scheduling',
    color: '#DCE9D5'
  },
  {
    id: 2,
    value: 2,
    name: 'Pricing',
    color: '#FDF2D0'
  },
  {
    id: 3,
    value: 3,
    name: 'No Pro available',
    color: '#D3E1F1'
  },
  {
    id: 4,
    value: 4,
    name: 'Negative experience',
    color: '#EECDCD'
  },
  {
    id: 5,
    value: 5,
    name: 'Unresponsive before Pro appointment/quote stage',
    color: styles.colors.WHITE
  },
  {
    id: 6,
    value: 6,
    name: 'Testing',
    color: '#F8E6D0'
  },
  {
    id: 7,
    value: 7,
    name: 'Duplicate Request',
    color: '#E6D2DB'
  },
  {
    id: 8,
    value: 8,
    name: 'Job Submitted by Mistake',
    color: '#F1F1F1'
  },
  {
    id: 9,
    value: 9,
    name: 'Changes Mind',
    color: '#D8D2E7'
  },
  {
    id: 10,
    value: 10,
    name: 'Issue No Longer Exists',
    color: '#CFE5DF'
  },
  {
    id: 11,
    value: 11,
    name: 'Other',
    color: '#F1F1F1'
  }
];

const unfulfilledReasonList = [
  {
    id: 1,
    value: 1,
    groupId: 1,
    name: 'We could not work with their timeline'
  },
  {
    id: 2,
    value: 2,
    groupId: 1,
    name: 'We had to schedule too far out for them'
  },
  {
    id: 3,
    value: 3,
    groupId: 1,
    name: 'They found a Pro who could come sooner'
  },
  {
    id: 4,
    value: 4,
    groupId: 2,
    name: 'HO clearly states that pricing is too high and they went with another Pro'
  },
  {
    id: 5,
    value: 5,
    groupId: 2,
    name: 'They go unresponsive after receiving the quote'
  },
  {
    id: 6,
    value: 6,
    groupId: 3,
    name: 'We do not have a Pro to fulfill this type of job '
  },
  {
    id: 7,
    value: 7,
    groupId: 3,
    name: 'We do not have a Pro in this service area'
  },
  {
    id: 8,
    value: 8,
    groupId: 4,
    name: 'Based on feedback from homeowner'
  },
  {
    id: 9,
    value: 9,
    groupId: 5,
    name: 'Homeowner does not communicate with us at all after submitting a ticket'
  },
  {
    id: 10,
    value: 10,
    groupId: 5,
    name: 'Homeowner stops communicating with us before Pro visits'
  },
  {
    id: 11,
    value: 11,
    groupId: 5,
    name: 'Homeowner does not respond to any form of nudges/communication (call, text, in-app chat, email, etc.)'
  },
  {
    id: 12,
    value: 12,
    groupId: 6,
    name:
      'This request was a result of investor/engineering testing that for some reason was not ' +
      'considered a test job'
  },
  {
    id: 13,
    value: 13,
    groupId: 7,
    name:
      'We duplicated this request and the homeowner has decided to move forward with the other DobbyPRO.\n' +
      'If homeowner does not move forward with either DobbyPRO, please select the reason code that represents ' +
      'the unfulfilled reason for each request'
  },
  {
    id: 14,
    value: 14,
    groupId: 8,
    name: 'Job Submitted by Mistake'
  },
  {
    id: 15,
    value: 15,
    groupId: 9,
    name: 'A homeowner changes mind'
  },
  {
    id: 16,
    value: 16,
    groupId: 10,
    name: 'Issue No Longer Exists'
  },
  {
    id: 17,
    value: 17,
    groupId: 11,
    name: 'Other'
  }
];

export default {
  earlyWarnings,
  remainderHours,
  unfulfilledGroup,
  unfulfilledGroupList,
  unfulfilledReasonList
};
