import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import moment from 'moment-timezone';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

import dates from 'constants/dates';
import messages from 'constants/messages';
import { Label, Wrapper, DatePickerOpenLabel, DatePickerWrapper, ArrowDown, Error } from './styled';

const DatePicker = ({
  label,
  name,
  control,
  isRequired,
  maxDate,
  defaultValue,
  rules = {},
  errors = {},
  options,
  ...props
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [date, setDate] = useState(value);

  const handleChange = (newValue) => {
    const midDayTime = newValue.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    setDate(moment(midDayTime));
    onChange(moment(midDayTime));
    setIsDatePickerOpen(false);
  };

  const handleClose = () => {
    setIsDatePickerOpen(false);
  };

  if (!isDatePickerOpen) {
    return (
      <Wrapper>
        {label && <Label>{label}</Label>}
        <DatePickerOpenLabel onClick={() => setIsDatePickerOpen(true)}>
          {date ? moment(date).format(dates.DATETIME_FULL_HUMAN) : 'Select date'}
          <ArrowDown />
        </DatePickerOpenLabel>
        {Boolean(messages.formErrors[errors?.type?.toUpperCase()]) && Boolean(options.length) && (
          <Error>{messages.formErrors[errors?.type?.toUpperCase()]}</Error>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <DatePickerWrapper onClick={handleClose}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <StaticDatePicker
            value={value}
            onChange={handleChange}
            renderInput={() => null}
            showDaysOutsideCurrentMonth={true}
            disableCloseOnSelect
            reduceAnimations
            disablePast
            maxDate={maxDate}
            displayStaticWrapperAs="desktop"
            views={['day']}
            {...props}
          />
        </LocalizationProvider>
        {Boolean(messages.formErrors[errors?.type?.toUpperCase()]) && Boolean(options.length) && (
          <Error>{messages.formErrors[errors?.type?.toUpperCase()]}</Error>
        )}
      </DatePickerWrapper>
    </Wrapper>
  );
};

export default DatePicker;
