import React from 'react';

const IconPackage = props => {
  const { size = 20 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.0002 26.873L42.4062 50.5542L46.0002 92.0001L81.7204 80.1594V38.7137L46.0002 26.873Z"
        fill="#999999"
      />
      <path
        d="M10.2793 38.7137V80.1594L45.9996 92.0001V26.873L10.2793 38.7137Z"
        fill="#C1C1C1"
      />
      <path d="M43.3047 0H48.6953V18H43.3047V0Z" fill="#D2D2D2" />
      <path
        d="M17.9531 8.23682L22.6214 5.5415L31.6214 21.1295L26.9531 23.8248L17.9531 8.23682Z"
        fill="#D2D2D2"
      />
      <path
        d="M60.375 21.1313L69.375 5.54333L74.0433 8.23864L65.0433 23.8267L60.375 21.1313Z"
        fill="#D2D2D2"
      />
      <path
        d="M35.7203 66.8825L46 50.5539L10.2797 38.7134L0 55.0419L35.7203 66.8825Z"
        fill="#F7F8FB"
      />
      <path
        d="M56.2797 66.8825L46 50.5539L81.7203 38.7134L92 55.0419L56.2797 66.8825Z"
        fill="#D2D2D2"
      />
    </svg>
  );
};

export default IconPackage;
