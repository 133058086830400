import React from 'react';

import styles from 'constants/styles';
import { Icon, IconButton, UserName } from 'components';
import {
  ReviewWrapper,
  ReviewHeader,
  DeleteIconWrapper,
  HeaderLeftBlock,
  ProviderFullNameWrapper,
  ReviewTime,
  HeaderRightBlock,
  GradeIconWrapper,
  ReviewedTask,
  ReviewBody,
  ReviewPhoto,
  ReviewComment,
  nameStyleCSS
} from './styled';

const reviewIconStyles = {
  positive: {
    name: 'thumbUpOutlined',
    color: styles.colors.DARK_GREEN_2
  },
  negative: {
    name: 'thumbDownOutlined',
    color: styles.colors.DARK_RED
  }
};

const Review = ({
  onReviewNavigate,
  onReviewDelete,
  type,
  title,
  time,
  service_demand_id,
  comment,
  imageUrl,
  pro_stage
}) => {
  return (
    <ReviewWrapper onClick={onReviewNavigate} onContextMenu={onReviewNavigate} disabled={!service_demand_id}>
      <ReviewHeader>
        <HeaderLeftBlock>
          <GradeIconWrapper>
            <Icon iconSize={24} size={24} name={reviewIconStyles[type].name} color={reviewIconStyles[type].color} />
          </GradeIconWrapper>
          <ProviderFullNameWrapper>
            <UserName pro_stage={pro_stage} given_name={title} textStyle={nameStyleCSS} />
            <ReviewTime>{time}</ReviewTime>
          </ProviderFullNameWrapper>
        </HeaderLeftBlock>
        {/* Currently id of external reviews are hardcoded, they can't be removed with API */}
        {Boolean(service_demand_id) && (
          <HeaderRightBlock>
            <DeleteIconWrapper onClick={onReviewDelete}>
              <IconButton iconName="trash" size={24} />
            </DeleteIconWrapper>
            <ReviewedTask>Job ID {service_demand_id}</ReviewedTask>
          </HeaderRightBlock>
        )}
      </ReviewHeader>
      <ReviewBody>
        {imageUrl && (
          <ReviewPhoto>
            <img src={imageUrl} alt="Review" />
          </ReviewPhoto>
        )}
        <ReviewComment>{comment}</ReviewComment>
      </ReviewBody>
    </ReviewWrapper>
  );
};

export default Review;
