import React from 'react';
import moment from 'moment-timezone';

import MuiTextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateTimePicker from '@mui/lab/StaticDateTimePicker';
import MuiDateTimePicker from '@mui/lab/DateTimePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import { Line, LoadingDots } from 'components';

import { ContentWrapper, dividerStyle, FieldWrapper } from './styled';
import styled, { useTheme } from 'styled-components';

const DateTimePicker = ({ value, setValue, shouldDisableDate, loading, disabled }) => {
  const onChange = (newValue) => {
    setValue(newValue);
  };

  const commonProps = {
    minDateTime: moment(),
    renderInput: (params) => (
      <FieldWrapper>
        <MuiTextField {...params} />
      </FieldWrapper>
    ),

    value,
    minutesStep: 30,
    ampm: true,
    ampmInClock: true,
    showDaysOutsideCurrentMonth: false,
    disableCloseOnSelect: true,
    loading,
    disabled
  };
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ContentWrapper>
        <Line />
        <StaticDateTimePicker
          displayStaticWrapperAs="desktop"
          renderLoading={() => <LoadingDots />}
          onChange={onChange}
          {...commonProps}
        />
      </ContentWrapper>
      <Line lineStyle={dividerStyle} />
      <MuiDateTimePicker
        shouldDisableDate={shouldDisableDate}
        label="Selected date & time"
        onChange={onChange}
        disableOpenPicker={true}
        readOnly
        InputProps={{ style: { color: theme.colors.WHITE } }}
        {...commonProps}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
