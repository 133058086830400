import React, { useState } from 'react';

import InputMessage from './InputMessage';
import { IconButton } from 'components';
import { InputContainer, InputSectionWrapper } from './styled';

const InputToolBar = ({ placeholder, onMessageSend, overwriteComponents = {}, maxLength }) => {
  const { actions } = overwriteComponents;
  const [inputText, setInputText] = useState('');

  const onSendButtonClick = (e) => {
    const message = { text: inputText, createdAt: new Date() };
    onMessageSend([message]);
    setInputText('');
  };

  return (
    <InputContainer>
      <InputSectionWrapper>
        {actions &&
          actions({
            text: inputText,
            changeText: setInputText
          })}
        <InputMessage placeholder={placeholder} value={inputText} onChange={setInputText} maxLength={maxLength} />

        <IconButton
          onClick={onSendButtonClick}
          variant="primary"
          iconName="send"
          disabled={!inputText}
          size={32}
          iconSize={16}
        />
      </InputSectionWrapper>
    </InputContainer>
  );
};

export default InputToolBar;
