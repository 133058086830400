import { Amplify, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import uploadMedia from 'services/media/UploadMedia';
import api_constants from 'constants/api';
import { csvFileTypes } from 'common/enums/app';

const generateFilename = ({ prefix }) => `${prefix}_${new Date().toISOString()}.csv`;

export const getCsvFileProps = ({ csvFileType }) => {
  switch (csvFileType) {
    case csvFileTypes.REFERRAL:
      return {
        endpoint: api_constants.REFERRAL,
        filenameGenerator: ({ target_group_id }) => generateFilename({ prefix: `referrals_group_${target_group_id}` })
      };
    case csvFileTypes.TASK:
      return {
        endpoint: api_constants.SERVICE,
        filenameGenerator: () => generateFilename({ prefix: 'tasks' })
      };
    case csvFileTypes.CUSTOMER:
      return {
        endpoint: api_constants.CUSTOMER,
        filenameGenerator: () => generateFilename({ prefix: 'customers' })
      };
    case csvFileTypes.PAYMENT:
      return {
        endpoint: api_constants.PAYMENT,
        filenameGenerator: () => generateFilename({ prefix: 'payments' })
      };
    case csvFileTypes.SERVICE_PROVIDER:
      return {
        endpoint: api_constants.SERVICE_PROVIDER,
        filenameGenerator: () => generateFilename({ prefix: 'providers' })
      };
    default:
      return {
        endpoint: '',
        filenameGenerator: () => ''
      };
  }
};

export const getMediaIds = async ({ files, sub_folder = null, hasMediaType }) => {
  if (!files) {
    return [];
  }
  const filePromises = files.map(({ file, media_type }) =>
    uploadMedia({ file, media_type, mime_type: file.type, sub_folder, hasMediaType })
  );
  const results = await Promise.allSettled(filePromises);
  const successfulResults = results.filter(
    ({ status, value }) => status === 'fulfilled' && (hasMediaType ? value?.media_id : value)
  );
  return successfulResults.map(({ value }) => value);
};

export const getUpdatedMediaIds = async ({ files, sub_folder = null, hasMediaType }) => {
  if (!files) {
    return [];
  }

  const uploadedFilesIds = files
    .filter((file) => Boolean(file.media_id))
    .map((file) => (hasMediaType ? { media_id: file.media_id, media_type: file.media_type } : file.media_id));
  const newFiles = files.filter((file) => file.media_id === undefined);

  let newFileIds = [];
  if (newFiles.length) {
    newFileIds = await getMediaIds({ files: newFiles, sub_folder, hasMediaType });
  }

  return [...newFileIds, ...uploadedFilesIds];
};

export const getUrlFileExtension = (url) => {
  const lastDotIndex = url.lastIndexOf('.');
  const isIndexInUrl = lastDotIndex !== -1 && lastDotIndex < url.length - 1;
  return isIndexInUrl ? url.substring(lastDotIndex, url.length)?.toLowerCase() : '';
};

const getS3ObjectPath = ({ isPublic, objectKey }) => {
  const currentConfig = Amplify.configure();
  const { bucket, customPrefix } = currentConfig?.Storage?.AWSS3 || {};
  const prefix = isPublic ? customPrefix.public : '';
  return `https://${bucket}.s3.amazonaws.com/${prefix}${objectKey}`;
};

/**
 * Uploads public file to s3 bucket
 * @param {String} customPrefix - path inside the s3 public bucket
 * @param {Object} file - file to upload
 * @returns {String} uploaded file url
 */
export const uploadPublicFile = async ({ customPrefix, file }) => {
  try {
    const uniqueKey = `${customPrefix}${uuidv4()}${getUrlFileExtension(file.name)}`;

    const result = await Storage.put(uniqueKey, file, {
      contentType: file.type,
      level: 'public',
      acl: 'public-read'
    });

    if (!result.key) {
      return;
    }

    const url = getS3ObjectPath({
      isPublic: true,
      objectKey: result.key
    });
    return url;
  } catch (err) {
    console.log('result', err);
    return;
  }
};
