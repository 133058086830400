import styled from 'styled-components';
import styles from 'constants/styles';

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
