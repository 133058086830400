import IconAttachment from './IconAttachment';
import IconAvatar from './IconAvatar';
import IconArrowFillRight from './IconArrowFillRight';
import IconCalendar from './IconCalendar';
import IconCall from './IconCall';
import IconCall2 from './IconCall2';
import IconCallEnd from './IconCallEnd';
import IconCallEnd2 from './IconCallEnd2';
import IconCheckIn from './IconCheckIn';
import IconCopy from './IconCopy';
import IconClose from './IconClose';
import IconClose2 from './IconClose2';
import IconComment from './IconComment';
import IconDots from './IconDots';
import IconDownload from './IconDownload';
import IconEdit from './IconEdit';
import IconEditFill from './IconEditFill';
import IconEditMessage from './IconEditMessage';
import IconFile from './IconFile';
import IconFilter from './IconFilter';
import IconPackage from './IconPackage';
import IconArrow from './IconArrow';
import IconOk from './IconOk';
import IconInfo from './IconInfo';
import IconMediaPlay from './IconMediaPlay';
import IconMicrophone from './IconMicrophone';
import IconMicrophone2 from './IconMicrophone2';
import IconMicrophoneCross from './IconMicrophoneCross';
import IconMicrophoneCross2 from './IconMicrophoneCross2';
import IconSwitch from './IconSwitch';
import IconSend from './IconSend';
import IconPlus from './IconPlus';
import IconFeather from './IconFeather';
import IconTriangle from './IconTriangle';
import IconEmptyBox from './IconEmptyBox';
import IconEmptyList from './IconEmptyList';
import IconBlocked from './IconBlocked';
import IconGoBack from './IconGoBack';
import IconChat from './IconChat';
import IconChatFill from './IconChatFill';
import IconFilters from './IconFilters';
import IconTrash from './IconTrash';
import IconBell from './IconBell';
import IconThumbUp from './IconThumbUp';
import IconThumbDown from './IconThumbDown';
import IconTime from './IconTime';
import IconTimeFill from './IconTimeFill';
import IconVideoCall from './IconVideoCall';
import IconPenFill from './IconPenFill';
import IconLogo from './IconLogo';
import { icons as icon } from 'common/enums/icons';
import IconBellFill from './IconBellFill';
import IconThumbUpOutlined from './IconThumbUpOutlined';
import IconThumbDownOutlined from './IconThumbDownOutlined';
import IconOkOutlined from './IconOkOutlined';
import IconCloseOutlined from './IconCloseOutlined';
import IconChatOutlined from './IconChatOutlined';
import IconCloseFill from './IconCloseFill';
import IconArrowFillBottom from './IconArrowFillBottom';
import IconCloseFill2 from './IconCloseFill2';
import IconOkFill from './IconOkFill';
import IconArrowFillLeft from './IconArrowFillLeft';
import IconArrowRight from './IconArrowRight';
import IconArrowLeft from './IconArrowLeft';
import IconLoadMore from './IconLoadMore';
import IconAdd from './IconAdd';
import IconCloseFill3 from './IconCloseFill3';
import IconCloseOutlined2 from './IconCloseOutlined2';
import IconInfoOutlined from './IconInfoOutlined';
import IconLeftArrow from './IconLeftArrow';
import IconCollapse from './IconCollapse';

const icons = {
  [icon.ATTACHMENT]: IconAttachment,
  [icon.AVATAR]: IconAvatar,
  [icon.ADD]: IconAdd,
  [icon.ARROW_FILL_RIGHT]: IconArrowFillRight,
  [icon.ARROW_FILL_BOTTOM]: IconArrowFillBottom,
  [icon.ARROW_FILL_LEFT]: IconArrowFillLeft,
  [icon.ARROW_RIGHT]: IconArrowRight,
  [icon.ARROW_LEFT]: IconArrowLeft,
  [icon.COMMENT]: IconComment,
  [icon.FILE]: IconFile,
  [icon.FILTER]: IconFilter,
  [icon.CALENDAR]: IconCalendar,
  [icon.COPY]: IconCopy,
  [icon.CLOSE]: IconClose,
  [icon.CLOSE2]: IconClose2,
  [icon.CLOSE_FILL]: IconCloseFill,
  [icon.CLOSE_FILL2]: IconCloseFill2,
  [icon.CLOSE_FILL3]: IconCloseFill3,
  [icon.CLOSE_OUTLINED]: IconCloseOutlined,
  [icon.CLOSE_OUTLINED2]: IconCloseOutlined2,
  [icon.DOTS]: IconDots,
  [icon.DOWNLOAD]: IconDownload,
  [icon.EDIT]: IconEdit,
  [icon.EDIT_MESSAGE]: IconEditMessage,
  [icon.EDIT_FILL]: IconEditFill,
  [icon.LOGO]: IconLogo,
  [icon.LOAD_MORE]: IconLoadMore,
  [icon.LEFT_ARROW]: IconLeftArrow,
  [icon.PEN_FILL]: IconPenFill,
  [icon.PACKAGE]: IconPackage,
  [icon.ARROW]: IconArrow,
  [icon.OK]: IconOk,
  [icon.OK_FILL]: IconOkFill,
  [icon.OK_OUTLINED]: IconOkOutlined,
  [icon.INFO_OUTLINED]: IconInfoOutlined,
  [icon.INFO]: IconInfo,
  [icon.SEND]: IconSend,
  [icon.SWITCH]: IconSwitch,
  [icon.FEATHER]: IconFeather,
  [icon.TRIANGLE]: IconTriangle,
  [icon.EMPTY_BOX]: IconEmptyBox,
  [icon.PLUS]: IconPlus,
  [icon.BLOCKED]: IconBlocked,
  [icon.EMPTY_LIST]: IconEmptyList,
  [icon.GO_BACK]: IconGoBack,
  [icon.CHAT]: IconChat,
  [icon.CHAT_FILL]: IconChatFill,
  [icon.CHAT_OUTLINED]: IconChatOutlined,
  [icon.FILTERS]: IconFilters,
  [icon.TRASH]: IconTrash,
  [icon.BELL]: IconBell,
  [icon.BELL_FILL]: IconBellFill,
  [icon.THUMB_UP]: IconThumbUp,
  [icon.THUMB_UP_OUTLINED]: IconThumbUpOutlined,
  [icon.THUMB_DOWN]: IconThumbDown,
  [icon.THUMB_DOWN_OUTLINED]: IconThumbDownOutlined,
  [icon.TIME]: IconTime,
  [icon.TIME_FILL]: IconTimeFill,
  [icon.VIDEO_CALL]: IconVideoCall,
  [icon.CHECK_IN]: IconCheckIn,
  [icon.CALL]: IconCall,
  [icon.CALL2]: IconCall2,
  [icon.CALL_END]: IconCallEnd,
  [icon.CALL_END2]: IconCallEnd2,
  [icon.COLLAPSE]: IconCollapse,
  [icon.MEDIA_PLAY]: IconMediaPlay,
  [icon.MICROPHONE]: IconMicrophone,
  [icon.MICROPHONE2]: IconMicrophone2,
  [icon.MICROPHONE_CROSS]: IconMicrophoneCross,
  [icon.MICROPHONE_CROSS2]: IconMicrophoneCross2
};

export default icons;
