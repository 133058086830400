import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.DARK_4};
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
