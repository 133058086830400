import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
`;

export const basicGridRow = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  justify-content: space-between;
  column-gap: 40px;
  row-gap: 24px;
`;

export const CampaignPushInfo = styled.div`
  ${basicGridRow}
  grid-template-columns: 1fr 3fr 1fr 1fr 100px;
`;

export const BannerInfo = styled.div`
  ${basicGridRow}
  grid-template-columns: 1fr 1fr 3fr 2fr 2fr 2fr;
`;

export const ArticleInfo = styled.div`
  ${basicGridRow}
  grid-template-columns: 1fr 6fr 5fr;
`;

export const ArticleCTAInfo = styled.div`
  ${basicGridRow}
  grid-template-columns: 1fr 1fr ${({ hasJobCreationNavigation }) =>
    hasJobCreationNavigation ? '2fr 2fr 5fr' : '8fr 1fr'};
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  background-color: #434b4e;
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;

export const Box = styled.div`
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: #97a0a0;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

export const InfoText = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.colors.DARK_GREEN : '#f7f7f7')};

  & > span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const InfoLink = styled.a`
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREEN};
  word-break: break-all;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const HTMLPreview = styled.div`
  p {
    color: white !important;
    margin-bottom: 0;
    font-size: 14px;
  }
`;
