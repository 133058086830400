import React from 'react';
import { formatters } from 'utils';
import { RowItem } from '../../styled';
import { useTheme } from 'styled-components';
import { Prefix } from './styled';

const CurrencyRowItem = ({ amount, showPrefix = true }) => {
  const { colors } = useTheme();

  let currency = formatters.dollarCurrencyDB(amount);
  let color = colors.WHITE;
  let prefix = '';

  if (amount < 0) {
    prefix = '-';
    currency = formatters.dollarCurrencyDB(amount * -1);
    color = colors.DARK_RED;
  } else if (amount > 0) {
    prefix = '+';
    color = colors.DARK_GREEN;
  }

  if (!showPrefix) {
    color = colors.WHITE;
  }

  return (
    <RowItem color={color}>
      {showPrefix && <Prefix>{prefix}</Prefix>}
      {currency}
    </RowItem>
  );
};

export default CurrencyRowItem;
