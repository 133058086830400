import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { useEffect, useState } from 'hooks';
import { selectCustomerInstance } from 'store/reducers/customer';

import { getUserQuestions } from 'store/actions';

import { Wrapper, LoadMoreButton, EmptyPlaceholder, Divider, EmptyList } from './styled';

import { Icon, LoadingSpinner, LoadingDots } from 'components';

import { selectAgentInstance } from 'store/reducers/agent';
import { selectUserQuestions } from 'store/reducers/user';

import { Question } from './components';
import { getFullName } from 'common/helpers/user';

const Questions = ({ hasAgent }) => {
  const { loading, list, count } = useSelector(selectUserQuestions);

  const [page, setPage] = useState(1);
  const instance = useSelector(hasAgent ? selectAgentInstance : selectCustomerInstance);

  useEffect(() => {
    dispatch(getUserQuestions({ page: 1, userId: instance?.id }));
  }, []);

  useEffect(() => {
    if (page > 1) {
      dispatch(getUserQuestions({ page, userId: instance?.id }));
    }
  }, [page]);

  const loadMoreReviews = () => {
    setPage(page + 1);
  };
  const dispatch = useDispatch();

  if (loading && page === 1) {
    return (
      <EmptyList>
        <LoadingSpinner text="Please wait" type="logo" />
      </EmptyList>
    );
  }

  return (
    <Wrapper>
      {list?.length ? (
        list.map((question, i) => {
          return (
            <Fragment key={i}>
              <Question {...question} />
              <Divider />
            </Fragment>
          );
        })
      ) : (
        <EmptyList>
          <EmptyPlaceholder>No questions from {getFullName(instance?.user)}</EmptyPlaceholder>
        </EmptyList>
      )}
      {list?.length < count && (
        <LoadMoreButton onClick={loadMoreReviews} disabled={loading}>
          {page > 1 && loading ? (
            <LoadingDots />
          ) : (
            <>
              <Icon name="loadMore" backgroundSize={16} />
              <div>Load More</div>
            </>
          )}
        </LoadMoreButton>
      )}
    </Wrapper>
  );
};

export default Questions;
