// React/Redux
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Store
import { getCustomerReviews, getProviderReviews, deleteProviderReview, openModal, closeModal } from 'store/actions';
import {
  selectProviderReviews,
  selectProviderReviewsLoading,
  selectProviderReviewsCount,
  selectProviderInstance
} from 'store/reducers/provider';
import {
  selectCustomerInstance,
  selectCustomerReviews,
  selectCustomerReviewsLoading,
  selectCustomerReviewsCount
} from 'store/reducers/customer';
// Constants
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';
import modalTypes from 'constants/modalTypes';
// Components
import { Review, LoadingSpinner, LoadingDots, Icon } from 'components';
import DeleteReviewModal from './DeleteReviewModal';
import { Wrapper, ReviewDivider, LoadingWrapper, LoadMoreButton, EmptyList, EmptyPlaceholder } from './styled';

const Reviews = ({
  isCustomersReviews,

  providerId,
  providerInstance,
  providerReviews,
  providerReviewsLoading,
  providerReviewsCount,

  customerInstance,
  customerReviews,
  customerReviewsLoading,
  customerReviewsCount,

  getProviderReviews,
  getCustomerReviews,
  openModal
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const reviews = isCustomersReviews ? customerReviews : providerReviews;
  const reviewsCount = isCustomersReviews ? customerReviewsCount : providerReviewsCount;
  const reviewsLoading = isCustomersReviews ? customerReviewsLoading : providerReviewsLoading;
  const userInstance = isCustomersReviews ? customerInstance : providerInstance;
  const userId = isCustomersReviews ? customerInstance.id : providerId;

  useEffect(() => {
    if (isCustomersReviews) {
      getCustomerReviews({ page: 1, customerId: userId, reset: true });
    } else {
      getProviderReviews({ page: 1, providerId: userId, reset: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page > 1) {
      if (isCustomersReviews) {
        getCustomerReviews({ page, customerId: userId });
      } else {
        getProviderReviews({ page, providerId: userId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onReviewNavigate = (job_id) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!job_id) {
      return;
    }

    const route = `${routes.SERVICE}/${job_id}`;
    if (e.type === 'click' && (e.ctrlKey || e.metaKey)) {
      window.open(route, '_blank');
      return;
    }
    if (e.type === 'contextmenu') {
      window.open(route, '_blank').focus();
      return;
    }
    navigate(route, {
      state: {
        hasLoadMoreButton: true,
        rootId: isCustomersReviews ? customerInstance.id : providerId,
        siblingIdName: 'service_demand_id',
        siblingPaginationType: isCustomersReviews
          ? siblingPaginationTypes.CUSTOMER_JOB_REVIEW
          : siblingPaginationTypes.PROVIDER_JOB_REVIEW
      }
    });
  };

  const onReviewDelete =
    ({ reviewId, service_demand_id }) =>
    (event) => {
      event.stopPropagation();
      openModal(modalTypes.DELETE_REVIEW, {
        reviewId,
        service_demand_id,
        isCustomersReviews
      });
    };

  if (reviewsLoading && page === 1) {
    return (
      <LoadingWrapper>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingWrapper>
    );
  }

  const loadMoreReviews = () => {
    setPage(page + 1);
  };

  return (
    <Wrapper>
      {reviews.length ? (
        reviews.map((review, i, arr) => {
          const isLast = arr.length - 1 === i;
          return (
            <Fragment key={review.id}>
              <Review
                {...review}
                onReviewDelete={onReviewDelete({
                  reviewId: review.id,
                  service_demand_id: review.service_demand_id
                })}
                onReviewNavigate={onReviewNavigate(review.service_demand_id)}
              />
              {!isLast && <ReviewDivider />}
            </Fragment>
          );
        })
      ) : (
        <EmptyList>
          <EmptyPlaceholder>
            No reviews left {isCustomersReviews ? 'from' : 'for'} {userInstance?.user?.given_name}{' '}
            {userInstance?.user?.family_name} ({userInstance?.id}) yet
          </EmptyPlaceholder>
        </EmptyList>
      )}
      {reviews.length < reviewsCount && (
        <LoadMoreButton onClick={loadMoreReviews}>
          {page > 1 && reviewsLoading ? (
            <LoadingDots />
          ) : (
            <>
              <Icon name="loadMore" backgroundSize={16} />
              Load More
            </>
          )}
        </LoadMoreButton>
      )}
      <DeleteReviewModal userId={userId} isCustomersReviews={isCustomersReviews} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  providerReviews: selectProviderReviews(state),
  providerReviewsLoading: selectProviderReviewsLoading(state),
  providerReviewsCount: selectProviderReviewsCount(state),
  providerInstance: selectProviderInstance(state),

  customerInstance: selectCustomerInstance(state),
  customerReviews: selectCustomerReviews(state),
  customerReviewsLoading: selectCustomerReviewsLoading(state),
  customerReviewsCount: selectCustomerReviewsCount(state)
});

const mapDispatchToProps = {
  getProviderReviews,
  getCustomerReviews,
  deleteProviderReview,
  openModal,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
