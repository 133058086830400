import styled, { css } from 'styled-components';

export const DeleteIconWrapper = styled.div`
  margin-right: 16px;
  display: none;
`;

export const ReviewedTask = styled.div`
  padding 4px 24px;
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const ReviewWrapper = styled.div`
  width: 100%;
  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    ${ReviewedTask} {
      background: ${({ theme }) => theme.colors.DARK_4} !important;
    }
    ${DeleteIconWrapper} {
      display: block;
    }

    & + div {
      margin: 0;
    }
  }
`;

export const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 0 16px;
`;

export const GradeIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

export const HeaderLeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ProviderFullNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewTime = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 10px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const HeaderRightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ReviewBody = styled.div`
  padding: 12px 16px 17px 53px;
  display: flex;
  align-items: flex-start;
`;

export const ReviewPhoto = styled.div`
  border-radius: 4px;
  width: 64px;
  height: 64px;
  overflow: hidden;
  margin-right: 7px;
  img {
    height: auto;
  }
`;

export const ReviewComment = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  width: 85%;
`;

export const nameStyleCSS = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;
