import styled from 'styled-components';

export const Label = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.GRAY};
  margin-bottom: 5px;
`;

export const TagsInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  background: #353d41;
  border-radius: 4px;
  min-height: 80px;
  max-width: 372px;
  input {
    background: unset;
    border: unset;
    color: white;
    outline: none;
    width: 100px;
    height: 24px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 12px;
  gap: 16px;
  background: #2c3537;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
`;

export const TagsInfo = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #676d70;
  margin-top: 4px;
  text-align: right;
  max-width: 372px;
  white-space: pre-wrap;
`;
