import React from 'react';

const IconFilters = ({
  size = 24,
  color = '#282A2B',
  backgroundColor = 'white'
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.5C2 6.22386 2.22386 6 2.5 6H21.5C21.7761 6 22 6.22386 22 6.5C22 6.77614 21.7761 7 21.5 7H2.5C2.22386 7 2 6.77614 2 6.5ZM2 12.5C2 12.2239 2.22386 12 2.5 12H21.5C21.7761 12 22 12.2239 22 12.5C22 12.7761 21.7761 13 21.5 13H2.5C2.22386 13 2 12.7761 2 12.5ZM2.5 18C2.22386 18 2 18.2239 2 18.5C2 18.7761 2.22386 19 2.5 19H21.5C21.7761 19 22 18.7761 22 18.5C22 18.2239 21.7761 18 21.5 18H2.5Z"
      fill={color}
    />
    <path
      d="M10 6.5C10 7.88071 8.88071 9 7.5 9C6.11929 9 5 7.88071 5 6.5C5 5.11929 6.11929 4 7.5 4C8.88071 4 10 5.11929 10 6.5Z"
      fill={color}
    />
    <path
      d="M14 12.5C14 13.8807 12.8807 15 11.5 15C10.1193 15 9 13.8807 9 12.5C9 11.1193 10.1193 10 11.5 10C12.8807 10 14 11.1193 14 12.5Z"
      fill={color}
    />
    <path
      d="M20 18.5C20 19.8807 18.8807 21 17.5 21C16.1193 21 15 19.8807 15 18.5C15 17.1193 16.1193 16 17.5 16C18.8807 16 20 17.1193 20 18.5Z"
      fill={color}
    />
    <path
      d="M9 6.5C9 7.32843 8.32843 8 7.5 8C6.67157 8 6 7.32843 6 6.5C6 5.67157 6.67157 5 7.5 5C8.32843 5 9 5.67157 9 6.5Z"
      fill={backgroundColor}
    />
    <path
      d="M13 12.5C13 13.3284 12.3284 14 11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5Z"
      fill={backgroundColor}
    />
    <path
      d="M19 18.5C19 19.3284 18.3284 20 17.5 20C16.6716 20 16 19.3284 16 18.5C16 17.6716 16.6716 17 17.5 17C18.3284 17 19 17.6716 19 18.5Z"
      fill={backgroundColor}
    />
  </svg>
);

export default IconFilters;
