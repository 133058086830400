import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { apiPageSizes } from 'common/enums/queryParams';
import { debounce } from 'utils';

import { PaginationContainer, PageNumber, PageControl, ButtonWrapper, NavPageNumbers, CurrentPage } from './styled';
import { IconButton } from 'components';

class Pagination extends Component {
  state = {
    currentPage: 1
  };

  componentDidMount() {
    this.setState({
      currentPage: this.props.currentPage
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPage !== prevProps.currentPage && !isNaN(this.props.currentPage)) {
      this.setState({ currentPage: this.props.currentPage });
    }
  }

  onChangePageDebounced = debounce((value) => this.props.onPageChange(value), 500);

  onPageChange = (value) => {
    this.setState({ currentPage: value, inputValue: value }, () => this.onChangePageDebounced(value));
  };

  render() {
    const { pageSize, count, loading } = this.props;
    const { currentPage } = this.state;
    const onPageChange = this.onPageChange;
    const pageCount = Math.ceil(count / pageSize);
    return (
      <PaginationContainer>
        <PageNumber>
          {/* <PageInput disabled readOnly value={50} />  */}
          {count > pageSize
            ? currentPage === pageCount
              ? count - pageSize * (pageCount - 1)
              : pageSize
            : count} of {count || 0}
        </PageNumber>
        <PageControl>
          {pageCount > 1 && (
            <ButtonWrapper>
              <IconButton
                disabled={loading}
                onClick={() => onPageChange(1)}
                text="First"
                square
                height={24}
                width={52}
              />
            </ButtonWrapper>
          )}
          {pageCount > 1 && (
            <IconButton
              iconName="arrowFillLeft"
              onClick={() => (loading ? {} : onPageChange(Math.max(1, currentPage - 1)))}
            />
          )}
          {currentPage > 2 && (
            <NavPageNumbers disabled={loading} onClick={() => onPageChange(1)}>
              1
            </NavPageNumbers>
          )}
          {currentPage > 3 && <NavPageNumbers disabled>...</NavPageNumbers>}

          {currentPage > 1 && (
            <NavPageNumbers disabled={loading} onClick={() => onPageChange(Math.max(1, currentPage - 1))}>
              {currentPage - 1}
            </NavPageNumbers>
          )}
          {!isNaN(currentPage) && (
            <CurrentPage active disabled>
              {currentPage}
            </CurrentPage>
          )}
          {currentPage < pageCount && (
            <NavPageNumbers disabled={loading} onClick={() => onPageChange(Math.max(1, currentPage + 1))}>
              {currentPage + 1}
            </NavPageNumbers>
          )}

          {currentPage < pageCount - 2 && <NavPageNumbers disabled>...</NavPageNumbers>}
          {currentPage < pageCount - 1 && (
            <NavPageNumbers disabled={loading} onClick={() => onPageChange(pageCount)}>
              {pageCount}
            </NavPageNumbers>
          )}
          {pageCount > 1 && (
            <IconButton
              iconName="arrowFillRight"
              onClick={() => (loading ? {} : onPageChange(Math.min(pageCount, currentPage + 1)))}
            />
          )}
          {pageCount > 1 && (
            <ButtonWrapper>
              <IconButton
                square
                height={24}
                width={52}
                disabled={loading}
                onClick={() => onPageChange(pageCount)}
                text="Last"
              />
            </ButtonWrapper>
          )}
        </PageControl>
      </PaginationContainer>
    );
  }
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  pageSize: apiPageSizes.DEFAULT,
  count: 0,
  currentPage: 1,
  onPageChange: () => {}
};

export default Pagination;
