import { capitalize } from 'common/helpers/transformers';
import { homeownerTags, providerStages, providerCohorts, userGroups } from 'common/enums/user';
import { providerSpecialties } from 'common/enums/job';

export const getGroupAlias = ({ group_id }) => Object.values(userGroups).find(({ TYPE }) => TYPE === group_id)?.ALIAS;

export const getHomeownerTags = ({ stage }) => Object.values(homeownerTags).find(({ TYPE }) => TYPE === stage);

export const getProviderStage = ({ pro_stage }) => Object.values(providerStages).find(({ TYPE }) => TYPE === pro_stage);

export const getFullName = ({ given_name = '', family_name = '', id = null, is_active = true, group_id }) =>
  [given_name, family_name, group_id == 4 ? '(RE)' : '', id ? `(${id})` : '', !is_active ? '(Inactive)' : '']
    .filter(Boolean)
    .reduce((fullName, namePart) => fullName + ' ' + capitalize(namePart).trim(), '')
    .trim();

export const mapSkillsToOptions = ({ skills, ownSkills }) =>
  skills.length
    ? skills?.map((item) => {
        const skill = ownSkills ? item?.skill : item;
        const { id, name, ...data } = skill || {};
        return {
          id,
          name,
          label: name,
          value: id,
          ...data
        };
      })
    : [];

export const getCRMLinkByGroupId = ({ group_id = userGroups.AGENT.TYPE, id, pathname = '' }) => {
  const route = Object.values(userGroups).find(({ TYPE }) => TYPE === group_id).ROUTE;
  return `${pathname}${route}/${id}`;
};

export const mapFieldsToProviderCohort = ({ is_parked, is_core }) => {
  if (is_parked) {
    return providerCohorts.PARKED.TYPE;
  }

  if (is_core) {
    return providerCohorts.CORE.TYPE;
  }

  return providerCohorts.DEFAULT.TYPE;
};

export const checkProviderAccess = ({
  is_active,
  is_api_active,
  is_cognito_active,
  is_pre_filled,
  pro_stage,
  pro_cohort
}) => {
  const hasProStage = Object.values(providerStages).some(({ TYPE }) => TYPE === pro_stage);
  const hasParked = pro_cohort === providerCohorts.PARKED.TYPE;
  const hasActiveState = !is_pre_filled && is_api_active && is_active && is_cognito_active;
  const hasAutoAreaCategoryDisabled = hasParked || !hasActiveState;

  return {
    hasProStage,
    hasParked,
    hasActiveState,
    hasAutoAreaCategoryDisabled
  };
};

export const generateServiceAreaListOptions = (service_areas) =>
  service_areas.map(({ id, alternative_name }) => ({
    id: id,
    value: id,
    name: alternative_name,
    label: alternative_name
  }));

export const generateSpecialtiesListOptions = () =>
  providerSpecialties.map(({ id, name }) => ({
    id: id,
    value: id,
    name: name,
    label: name
  }));
