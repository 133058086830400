import React from 'react';
import PropTypes from 'prop-types';
import ControlledInput from './ControlledHookInput';
import HookInput from './HookInput';
import { fieldTypes } from 'common/enums/form';

const Input = ({
  name,
  register,
  control,
  inputConfig = {},
  fieldType,
  reset,
  wrapperStyle,
  placeholder,
  width,
  onCustomChange,
  ...otherProps
}) => {
  if (control) {
    return (
      <ControlledInput
        name={name}
        inputConfig={inputConfig}
        control={control}
        reset={reset}
        {...otherProps}
        onCustomChange={onCustomChange}
        fieldType={fieldType}
        wrapperStyle={wrapperStyle}
        placeholder={placeholder}
        width={width}
      />
    );
  }

  return (
    <HookInput
      name={name}
      register={register}
      inputConfig={inputConfig}
      reset={reset}
      fieldType={fieldType}
      wrapperStyle={wrapperStyle}
      placeholder={placeholder}
      width={width}
      {...otherProps}
    />
  );
};

Input.propTypes = {
  fieldType: PropTypes.object
};

Input.defaultProps = {
  fieldType: fieldTypes.TEXT,
  size: 'small'
};

export default Input;
