import React from 'react';
import { useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';

import { useCallback, useMemo } from 'hooks';
import { Icon, HighlightedText } from 'components';

import { FlexColumn, Label, getSelectMultipleStyles } from './styled';

const SelectMultiple = ({
  options,
  excludeOptions,
  name,
  label,
  control,
  isMulti = true,
  maxCount = 100,
  rules = {},
  error = {},
  placeholder,
  onCustomChange,
  defaultValue,
  marginLeft,
  loadOptions,
  disabled,
  hasDisabledOpacity,
  overlap,
  ...props
}) => {
  const {
    field: { ref, onChange, value, ...inputProps }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });
  const theme = useTheme();
  const onSelectChange = (target) => {
    onChange(target);
    if (onCustomChange) {
      onCustomChange(target);
    }
  };

  const selectableOptions = useMemo(() => {
    if (excludeOptions) {
      return options.filter((option) => !excludeOptions.includes(option.value));
    }
    return options;
  }, [options, excludeOptions]);

  const DropdownIcon = (props) => (
    <components.DropdownIndicator {...props}>
      <Icon name="arrowFillBottom" backgroundSize={16} />
    </components.DropdownIndicator>
  );

  const ClearIcon = (props) => (
    <components.ClearIndicator {...props}>
      <Icon name="closeFill" size={16} />
    </components.ClearIndicator>
  );

  const MultiValueRemoveIcon = (props) => (
    <components.MultiValueRemove {...props}>
      <Icon name="closeFill" size={16} />
    </components.MultiValueRemove>
  );

  const renderInputPlaceholder = useCallback(
    (props) => {
      const isSingleValue = !Array.isArray(value);

      if (isSingleValue) {
        return <components.Input {...props} placeholder={''} />;
      }

      const validItemsCount = value?.filter((item) => item !== undefined)?.length || 0;
      const isMaxCountReached = validItemsCount >= maxCount;
      return (
        <components.Input
          {...props}
          placeholder={validItemsCount === 0 ? '' : !isMaxCountReached ? 'Add more' : 'Items limit reached'}
        />
      );
    },
    [value, maxCount]
  );

  const renderOption = useCallback((props) => {
    return (
      <components.Option {...props}>
        <HighlightedText text={props.label} highlight={props.selectProps.inputValue} />
      </components.Option>
    );
  }, []);

  const defaultOptions = {
    components: {
      DropdownIndicator: DropdownIcon,
      ClearIndicator: ClearIcon,
      MultiValueRemove: MultiValueRemoveIcon,
      Input: renderInputPlaceholder,
      Option: renderOption
    },
    placeholder: placeholder,
    name: name,
    isMulti: isMulti,
    maxCount: maxCount,
    styles: getSelectMultipleStyles(theme),
    defaultValue: defaultValue,
    onChange: onSelectChange,
    isOptionDisabled: () => isMulti && value?.length >= maxCount,
    isDisabled: disabled || hasDisabledOpacity,
    ...props,
    ...inputProps
  };

  return (
    <FlexColumn marginLeft={marginLeft} hasDisabledOpacity={hasDisabledOpacity} overlap={overlap}>
      {label && <Label>{label}</Label>}
      {loadOptions ? (
        <AsyncSelect defaultOptions loadOptions={loadOptions} {...defaultOptions} />
      ) : (
        <Select options={selectableOptions} {...defaultOptions} value={value} />
      )}
    </FlexColumn>
  );
};

export default SelectMultiple;
