import React, { lazy } from 'react';
import { useDispatch, useEffect, useSelector, useState } from 'hooks';

import { getAvatarOriginal } from 'store/actions/runtimeCache';
import { selectAvatarOriginalByUserId } from 'store/reducers/runtimeCache';
import { getUserIcon } from 'utils';
import { Avatar } from 'components';

import { UserAvatarWrapper } from './styled';

const Lightbox = lazy(() => import('react-image-lightbox'));

export const AvatarLightbox = ({
  profile_image_original,
  profile_image_thumbnail,
  is_cognito_active,
  is_api_active,
  is_active,
  is_pre_filled,
  id,
  onRenderLeftIcon
}) => {
  const dispatch = useDispatch();
  const { avatarOriginal } = useSelector((state) => ({
    avatarOriginal: selectAvatarOriginalByUserId(state, id)
  }));

  useEffect(() => {
    if (profile_image_original && id) {
      dispatch(getAvatarOriginal(profile_image_original, id));
    }
  }, [profile_image_original, id, dispatch]);

  const [isLightbox, setLightbox] = useState(false);

  const handleToggleAvatarLightbox = () => {
    if (!avatarOriginal || !profile_image_original) {
      return;
    }
    setLightbox((value) => !value);
  };

  return (
    <>
      <UserAvatarWrapper onClick={handleToggleAvatarLightbox} disabled={!avatarOriginal || !profile_image_original}>
        <Avatar
          alt="User Avatar"
          avatarSize={26}
          hasDelay
          icon={getUserIcon({
            is_cognito_active,
            is_api_active,
            is_active,
            is_pre_filled
          })}
          maxRetryAttempts={1}
          size={64}
          url={profile_image_thumbnail}
          onRenderLeftIcon={onRenderLeftIcon}
        />
      </UserAvatarWrapper>
      {isLightbox && <Lightbox mainSrc={avatarOriginal} onCloseRequest={handleToggleAvatarLightbox} />}
    </>
  );
};
