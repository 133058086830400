import React from 'react';
import styles from 'constants/styles';

const IconEditMessage = ({ size = 14, color = '#F7F7F7' }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.95833 2.625H2.625V11.375H11.375V7.875"
      stroke={color}
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 8.75L5.66176 6.69118L10.6029 1.75L12.25 3.39706L7.30882 8.33824L5.25 8.75Z"
      stroke={color}
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEditMessage;
