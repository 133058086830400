import React from 'react';
import styles from 'constants/styles';

const IconCallEnd = ({ size = 24, color = styles.colors.BLACK }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.08798 15.1605C5.82267 14.4258 6.65895 13.8193 7.56845 13.3616C8.01767 13.1409 8.32463 12.6243 8.3191 12.2099L8.64982 9.84208C11.0499 9.11259 12.9773 9.11228 15.3542 9.84144L15.6797 12.1442C15.6794 12.6575 15.962 13.1243 16.4239 13.3655C17.338 13.8258 18.1742 14.4316 18.9092 15.1666C19.4402 15.6976 20.3041 15.6976 20.8351 15.1667L23.0388 12.9629C23.5697 12.432 23.5697 11.568 23.0388 11.0371C16.9529 4.95116 7.05053 4.95118 0.964608 11.0371C0.433672 11.568 0.433673 12.432 0.96461 12.9629L3.1622 15.1605C3.69314 15.6914 4.55708 15.6914 5.08798 15.1605Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCallEnd;
