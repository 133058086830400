import React, { useCallback, useState, useEffect } from 'react';
import { debounce } from 'utils';

import { Icon } from 'components';
import { SearchInputWrapper, StyledSearchInput, SearchInputReset } from './styled';

const SearchInput = ({ onSearch, searchType, placeholder = 'Search', defaultSearch = '', isFullWidth, hideClear }) => {
  const [searchText, setSearchText] = useState(defaultSearch);

  const onSearchDebounced = useCallback(
    debounce((nextValue) => onSearch(nextValue), 750),
    [onSearch]
  );

  useEffect(() => {
    if (searchText && !defaultSearch) {
      setSearchText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  const onInputChange = (event) => {
    const { value: nextValue } = event.target;

    setSearchText(nextValue);
    onSearchDebounced(nextValue);
  };

  const onReset = () => {
    setSearchText('');
    onSearchDebounced('');
  };

  return (
    <SearchInputWrapper isFullWidth={isFullWidth}>
      <StyledSearchInput
        placeholder={placeholder}
        value={searchText}
        onChange={onInputChange}
        isFullWidth={isFullWidth}
      />
      {searchText && !hideClear && (
        <SearchInputReset onClick={onReset}>
          <Icon name="closeFill" backgroundSize={16} />
        </SearchInputReset>
      )}
    </SearchInputWrapper>
  );
};

export default SearchInput;
