import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { apiPageSizes } from 'common/enums/queryParams';
import { getAgentLocationList } from 'store/actions';
import { selectActivatedLocationList, selectNotActivatedLocationList } from 'store/reducers/agent';

import { Icon, AgentLocationList, LoadingSpinner } from 'components';
import { EmptyServiceLists, EmptyText, FlexOneContainer } from './styled';

const AgentLocationLists = ({ agentId, activeList, notActiveList, getAgentLocationList, openInstance }) => {
  const getList = ({ agent_id, page = 1, is_active }) => {
    getAgentLocationList({
      agent_id,
      is_active,
      page,
      limit: apiPageSizes.DEFAULT
    });
  };
  useEffect(() => {
    getList({
      agent_id: agentId,
      is_active: true
    });
    getList({
      agent_id: agentId,
      is_active: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  return (
    <FlexOneContainer>
      <AgentLocationList
        openInstance={openInstance}
        onLoadMore={() =>
          getList({
            agent_id: agentId,
            is_active: true,
            page: activeList.page + 1
          })
        }
        locations={activeList}
        title="Active Homeowners"
        isActive={true}
      />
      <AgentLocationList
        openInstance={openInstance}
        onLoadMore={() =>
          getList({
            agent_id: agentId,
            is_active: false,
            page: activeList.page + 1
          })
        }
        locations={notActiveList}
        title="Not Active Homeowners"
        isActive={false}
      />
      {!activeList.list.length && !notActiveList.list.length && (
        <EmptyServiceLists>
          {activeList.loading || notActiveList.loading ? (
            <LoadingSpinner text="Please wait" type="logo" />
          ) : (
            <>
              {' '}
              <Icon name="emptyBox" size={160} />
              <EmptyText>
                The Agent <br />
                hasn’t invited any homeowner, yet
              </EmptyText>
            </>
          )}
        </EmptyServiceLists>
      )}
    </FlexOneContainer>
  );
};

AgentLocationLists.propTypes = {
  getAgentLocationList: PropTypes.func.isRequired,
  activeList: PropTypes.object.isRequired,
  notActiveList: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  activeList: selectActivatedLocationList(state),
  notActiveList: selectNotActivatedLocationList(state)
});

const mapDispatchToProps = {
  getAgentLocationList
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentLocationLists);
