import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'components';
import { modalIconProps } from 'common/enums/modal';
import { selectModalType } from 'store/reducers/modal';

import { ModalTitle, ModalText, ModalIcon, ButtonsWrapper, IconWrapper } from './styled';

const ModalConfirmation = ({
  visible = false,
  modalType,
  openedModalType,
  hideModal,
  text,
  textStyle,
  title,
  primaryButton,
  secondaryButton,
  iconProps: {
    backgroundColor,
    backgroundSize = modalIconProps.DEFAULT.backgroundSize,
    color = modalIconProps.DEFAULT.color,
    name,
    size = modalIconProps.DEFAULT.size
  } = {},
  closeButton,
  padding,
  maxWidth = 504,
  minWidth,
  children
}) => (
  <Modal
    modalType={modalType}
    visible={modalType ? modalType === openedModalType : visible}
    hideModal={hideModal}
    padding={padding}
    maxWidth={maxWidth}
    minWidth={minWidth}
    smallPaddings={false}>
    {Boolean(closeButton) && (
      <IconWrapper onClick={closeButton.onClick} disabled={!(primaryButton?.loading || secondaryButton?.loading)}>
        <Icon
          name="close2"
          size={8}
          backgroundSize={16}
          clickable={!(primaryButton?.loading || secondaryButton?.loading)}
        />
      </IconWrapper>
    )}
    {Boolean(name) && (
      <ModalIcon color={backgroundColor} size={backgroundSize}>
        <Icon name={name} size={size} color={color} strokeWidth={2.6} />
      </ModalIcon>
    )}
    {Boolean(title) && <ModalTitle>{title}</ModalTitle>}
    {Boolean(text) && typeof text === 'string' ? <ModalText style={textStyle}>{text}</ModalText> : text}
    {children}
    {Boolean(secondaryButton || primaryButton) && (
      <ButtonsWrapper>
        {Boolean(primaryButton) && (
          <Button
            title={primaryButton?.title || 'Cancel'}
            onClick={primaryButton.onClick}
            loading={primaryButton.loading}
            disabled={primaryButton.disabled || secondaryButton?.disabled}
            variant="primary"
          />
        )}
        {Boolean(secondaryButton) && (
          <Button
            title={secondaryButton?.title || 'OK'}
            variant={secondaryButton?.type}
            onClick={secondaryButton.onClick}
            loading={secondaryButton.loading}
            disabled={secondaryButton.disabled || primaryButton?.disabled}
          />
        )}
      </ButtonsWrapper>
    )}
  </Modal>
);

ModalConfirmation.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired
};

ModalConfirmation.defaultProps = {
  hideModal: () => {},
  visible: false
};

const mapStateToProps = (state) => ({
  openedModalType: selectModalType(state)
});

export default connect(mapStateToProps)(ModalConfirmation);
