import styled from 'styled-components';

export const Text = styled.span`
  color: ${({ disabled, theme }) => disabled ? theme.colors.TEXT_DISABLED : theme.colors.TEXT};
  ${({ theme, isHighlighted }) => isHighlighted && `color: ${theme.colors.DARK_YELLOW} !important;`}
  ${({ capitalized }) => capitalized && `text-transform: capitalize;`}
  ${({ textStyle }) => textStyle}
`;

export const BlockWrapper = styled.span`
  display: block;
  ${({ isEllipsis, rows }) =>
    isEllipsis &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${rows};
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    `}
`;
