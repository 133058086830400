import { searchPrefixes } from 'common/enums/app';

export const joinWithSemicolon = (list) => list.filter(Boolean).join('; ');

export const capitalize = (string) => (string ? string[0]?.toUpperCase() + string.slice(1) : '');

export const prepareSearchForHighlightText = ({ search = '' }) => {
  const searchPrefix = Object.values(searchPrefixes).find(({ PREFIX }) => search.startsWith(PREFIX));
  const pureSearchValue =
    searchPrefix?.IS_SEARCH_TERM_PREFIX || !searchPrefix
      ? search
      : search.substring(searchPrefix.PREFIX.length, search.length);

  return {
    searchPrefixType: searchPrefix,
    pureSearchValue
  };
};

export const prepareSearchForBackendRequest = ({ search }) => {
  const { searchPrefixType, pureSearchValue } = prepareSearchForHighlightText({ search });

  return {
    searchPrefixType: searchPrefixType,
    preparedSearchValue: searchPrefixType?.HAS_PREFIX_BACKEND_EXCLUDE ? pureSearchValue : search
  };
};
