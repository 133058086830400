import React from 'react';

import { RotatedSvg } from './styled';

const IconArrow = ({ size = 17, rotate = 0, color = '#282A2B' }) => (
  <RotatedSvg
    width={size}
    height={(size * 7) / 17}
    rotate={rotate}
    viewBox="0 0 17 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6116 1.80158C16.0543 1.47132 16.1454 0.844751 15.8151 0.402086C15.4849 -0.0405783 14.8583 -0.131706 14.4156 0.198546L8.38027 4.70127L1.93474 0.32086C1.47796 0.0104278 0.856009 0.129069 0.545577 0.585852C0.235146 1.04263 0.353786 1.66459 0.81057 1.97502L7.83283 6.74737C8.16439 6.9727 8.58298 6.97196 8.90628 6.77993C8.9649 6.75206 9.02165 6.71803 9.07566 6.67774L15.6116 1.80158Z"
      fill={color}
    />
  </RotatedSvg>
);

export default IconArrow;
