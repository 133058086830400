import React from 'react';
import styles from 'constants/styles';

const IconChat = ({ size = 24, color = styles.colors.YELLOW }) => {
  return (
    <svg
      width={size}
      height={size * 0.9}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.974 16.6667L19.38 16.2015C20.4023 15.0305 20.9782 13.6307 20.9782 12.1431C20.9782 8.37935 17.1543 5 11.9891 5C6.82387 5 3 8.37935 3 12.1431C3 15.9068 6.82387 19.2861 11.9891 19.2861C13.4129 19.2861 14.754 19.0222 15.9436 18.5565L16.3241 18.4075L16.7 18.5676C16.936 18.6681 17.3932 18.7961 18.0145 18.9332C18.6144 19.0655 19.3026 19.1928 19.9594 19.3042C20.082 19.325 20.2032 19.3452 20.3223 19.3647C19.9003 18.641 19.4447 17.8159 19.208 17.2381L18.974 16.6667ZM21.6033 19.564C21.9861 20.1987 22.2862 20.6709 22.2862 20.6709C22.2862 20.6709 21.7509 20.5995 20.9919 20.4833C19.5118 20.2566 17.1807 19.8593 16.3082 19.4877C15.001 19.9994 13.5361 20.2861 11.9891 20.2861C6.47227 20.2861 2 16.6403 2 12.1431C2 7.64577 6.47227 4 11.9891 4C17.5059 4 21.9782 7.64577 21.9782 12.1431C21.9782 13.9007 21.295 15.5284 20.1334 16.8591C20.4148 17.5462 21.0849 18.7042 21.6033 19.564ZM6.81273 13.5602C6.81273 13.2841 7.03659 13.0602 7.31273 13.0602H14.6585C14.9346 13.0602 15.1585 13.2841 15.1585 13.5602C15.1585 13.8363 14.9346 14.0602 14.6585 14.0602H7.31273C7.03659 14.0602 6.81273 13.8363 6.81273 13.5602ZM7.31273 9.9512C7.03659 9.9512 6.81273 10.1751 6.81273 10.4512C6.81273 10.7273 7.03659 10.9512 7.31273 10.9512H17.2337C17.5099 10.9512 17.7337 10.7273 17.7337 10.4512C17.7337 10.1751 17.5099 9.9512 17.2337 9.9512H7.31273Z"
        fill={color}
      />
    </svg>
  );
};

export default IconChat;
