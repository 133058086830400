import styled from 'styled-components';

export const TabHeader = styled.li`
  align-items: center;
  display: inline-flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  justify-content: center;
  padding: 0 22px;
  white-space: nowrap;
  position: relative;

  span {
    white-space: nowrap;
    padding-right: 4px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.colors.TABS_TEXT};
    &:after {
      content: '';
      width: 100%;
      height: 6px;
      background: ${theme.colors.DARK_GREEN_2};
      border-radius: 4px;
      bottom: 0;
      left: 0;
      position: absolute;
    }`}

  &:hover {
    color: ${({ theme }) => theme.colors.TABS_TEXT};
    &:after {
      content: '';
      width: 100%;
      height: 6px;
      background: ${({ theme }) => theme.colors.DARK_GREEN_2};
      border-radius: 4px;
      bottom: 0;
      left: 0;
      position: absolute;
    }
    cursor: pointer;
    margin-bottom: 0px;
  }
`;

export const TabList = styled.ul`
  ${({ theme }) => !theme.darkMode && `border-bottom: 1px solid ${theme.colors.LIGHT_GRAY};`}
  display: flex;
  flex-direction: row;
  height: 50px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  scroll-snap-type: x proximity;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;

export const TabWrapper = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex: 1;
  margin: 0;
  padding: 0;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  &:focus {
    outline: none;
  }
`;
