import React from 'react';
import Linkify from 'react-linkify';

import routes from 'constants/routes';
import { EXCLUDE_PREVIEW_LINK } from 'common/constants/chat';
import { useTheme } from 'hooks';
import styles from 'constants/styles';
import modalTypes from 'constants/modalTypes';

import { getTimeWindow } from 'common/helpers/job';
import { getMessageAuthor } from '../../helpers';

import { Icon } from 'components';
import {
  ButtonLink,
  EmptyCircle,
  ItemLinkPreview,
  MessageName,
  MessageTitle,
  MessageWrapper,
  PlainSelectElement,
  Text,
  TextMessageWrapper,
  VideoCallText,
  VideoCallTextWrapper,
  JobIdText
} from './styled';

const Message = ({
  isCurrentUser,
  serviceId,
  serviceInstance: { user_id: task_user_id } = {},
  currentMessage: { _id, action, action_is_active, action_data, text, author_id, author_name, user = {}, link_list }
}) => {
  const theme = useTheme();
  if (['schedule_visit', 'schedule_options'].includes(action)) {
    const options = action === 'schedule_visit' ? action_data : action_data?.window_options;
    return (
      <MessageWrapper>
        <MessageName>{text}</MessageName>
        {options.map((slot) => (
          <div key={slot.id}>
            <PlainSelectElement selected={slot.is_selected}>
              {slot.is_selected ? <Icon name="okFill" backgroundSize={16} /> : <EmptyCircle />}
              <TextMessageWrapper marginSize={8}>
                <Text>
                  {getTimeWindow({
                    datetime_visit: slot.datetime_visit,
                    datetime_window_end: slot.datetime_window_end
                  })}
                </Text>
              </TextMessageWrapper>
            </PlainSelectElement>
          </div>
        ))}
      </MessageWrapper>
    );
  }

  if (action === 'selected_visit') {
    const { message } = action_data || {};
    return (
      <MessageWrapper>
        <MessageName>{message || `Woohoo! We have a date 😎`}</MessageName>
        <TextMessageWrapper>
          <Text isCurrentUser={isCurrentUser}>{getTimeWindow({ datetime_visit: text, isIcon: true })}</Text>
        </TextMessageWrapper>
      </MessageWrapper>
    );
  }

  if (action === 'scheduled_window') {
    const { datetime_visit, datetime_window_end } = action_data || {};
    return (
      <MessageWrapper>
        <MessageName>{text || `Woohoo! We have a date 😎`}</MessageName>
        <TextMessageWrapper>
          <Text isCurrentUser={isCurrentUser}>
            {getTimeWindow({ datetime_visit, datetime_window_end, isIcon: true })}
          </Text>
        </TextMessageWrapper>
      </MessageWrapper>
    );
  }

  if (action === 'video_call') {
    return (
      <MessageWrapper>
        <TextMessageWrapper>
          <VideoCallTextWrapper>
            <Icon
              name="videoCall"
              size={16}
              color={isCurrentUser ? theme.colors.VIDEO_CALL_TEXT_CURRENT : theme.colors.VIDEO_CALL_TEXT}
            />
            <VideoCallText color={isCurrentUser ? theme.colors.VIDEO_CALL_TEXT_CURRENT : theme.colors.VIDEO_CALL_TEXT}>
              {`${author_name || (author_id === task_user_id ? 'Homeowner' : 'Pro')} has initiated a video call`}
            </VideoCallText>
          </VideoCallTextWrapper>
        </TextMessageWrapper>
      </MessageWrapper>
    );
  }

  if (action === 'article') {
    const { url, html, title, buttonName } = action_data || {};
    return (
      <MessageWrapper>
        <TextMessageWrapper>
          <MessageTitle>{title}</MessageTitle>
          <Text isCurrentUser={isCurrentUser}>{text}</Text>
          {Boolean(url) ? (
            <ButtonLink onClick={() => this.handleUrlPress(url)}>{buttonName || 'LEARN MORE'}</ButtonLink>
          ) : Boolean(html) ? (
            <ButtonLink
              onClick={() =>
                this.props.openModal(modalTypes.HTML_MODAL, {
                  html: html,
                  maxWidth: 300
                })
              }>
              {buttonName || 'LEARN MORE'}
            </ButtonLink>
          ) : (
            <></>
          )}
        </TextMessageWrapper>
      </MessageWrapper>
    );
  }

  const name = getMessageAuthor(author_id, author_name);
  const { full_name, service_request_id } = action_data || {};
  const isJobSupport = Boolean(action === 'job_support' && service_request_id);
  return (
    <MessageWrapper isCurrentUser={isCurrentUser} key={_id}>
      {Boolean(name || user._id === 'support') && <MessageName>{name}</MessageName>}
      {isJobSupport && (
        <TextMessageWrapper>
          <JobIdText isCurrentUser={isCurrentUser}>
            Job ID:{' '}
            <a href={`${routes.SERVICE}/${service_request_id}`} target="_blank">
              {service_request_id}
            </a>
          </JobIdText>
        </TextMessageWrapper>
      )}
      <TextMessageWrapper>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}>
          <Text isCurrentUser={isCurrentUser}>{text}</Text>
        </Linkify>
      </TextMessageWrapper>

      {Boolean(action && action === 'profile' && action_data) && <Text>{full_name}</Text>}
      {(link_list.filter(({ preview_url }) => !preview_url?.startsWith(EXCLUDE_PREVIEW_LINK)) || []).map((item) => (
        <ItemLinkPreview key={item.id}>
          <a href={item.preview_url} target="_blank" rel="noopener noreferrer">
            {item.preview_url}
          </a>
          <div onClick={(e) => item.preview_url && this.handleUrlPress(item.preview_url)}>
            {item.preview_title ? <Text color={styles.colors.WINE}>{item.preview_title}</Text> : null}
            {item.preview_header ? <Text>{item.preview_header}</Text> : null}
            {item.preview_description ? <Text color={styles.colors.GRAY}>{item.preview_description}</Text> : null}
            {item.preview_image_url ? <img alt="" src={item.preview_image_url} height={150} /> : null}
          </div>
        </ItemLinkPreview>
      ))}
    </MessageWrapper>
  );
};

export { Message };
