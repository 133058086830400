import React from 'react';

import { useDispatch, useReloadTable, useSelector } from 'hooks';

import styles from 'constants/styles';
import { operationalAreaIDs } from 'common/enums/location';

import { setFilteredGeoLocationList } from 'store/actions';
import { selectServiceListLoading } from 'store/reducers/service';
import { selectCustomerListLoading } from 'store/reducers/customer';
import { selectProviderDashboardLoading } from 'store/reducers/provider';
import { selectReviewIsLoading } from 'store/reducers/review';
import { selectFilteredGeoLocationList } from 'store/reducers/app';

import { DropdownMultiSelect } from 'components';

const GeoLocationSelect = ({ right }) => {
  const dispatch = useDispatch();
  const { handleReloadTable } = useReloadTable();

  const filteredGeoLocationList = useSelector(selectFilteredGeoLocationList);
  const jobsLoading = useSelector(selectServiceListLoading);
  const customersLoading = useSelector(selectCustomerListLoading);
  const providersLoading = useSelector(selectProviderDashboardLoading);
  const reviewsLoading = useSelector(selectReviewIsLoading);

  const geoLocationsList = Object.values(operationalAreaIDs).map(({ NAME, TYPE }) => ({ id: TYPE, name: NAME }));

  const selectedGeoLocationsList = filteredGeoLocationList.length
    ? filteredGeoLocationList.map((id) => geoLocationsList.find((location) => location?.id === id))
    : [];

  const hasLoading = jobsLoading || customersLoading || providersLoading || reviewsLoading;

  const transformOptionText = (option) => `${option?.name}`;

  /**
   * Loads tasks list and notifications for selected locations IDs
   * @param {Array} locationIds - list of selected locations simplified objects
   */
  const applyGeoLocationFilter = (locationIds) => {
    const geoLocationFilter = locationIds.length ? { operational_area_id: locationIds.join(',') } : {};
    handleReloadTable({
      newRequestFilters: geoLocationFilter,
      hasUpdateNotificationList: true,
      hasUpdateBadges: true
    });
  };

  /**
   * Simplifies objects from param array, stores them in persistent Redux reducer and calls for lists reload
   * @param {Array} selectedLocationsList - list of selected locations rich objects
   */
  const handleApplySelection = (selectedLocationsList = []) => {
    const locationsIDList = selectedLocationsList.length ? selectedLocationsList.map(({ id }) => id) : [];
    dispatch(setFilteredGeoLocationList(locationsIDList));
    applyGeoLocationFilter(locationsIDList);
  };

  return (
    <DropdownMultiSelect
      options={geoLocationsList}
      selectedOptions={selectedGeoLocationsList}
      hasExtraOptions={false}
      onApplySelection={handleApplySelection}
      isDisabled={!geoLocationsList.length}
      isLoading={hasLoading}
      selectPlaceholderText={'Select Location'}
      selectAllOptionText={'All Locations'}
      selectedValuesBeforeTruncate={4}
      transformSelectedValuesText={transformOptionText}
      transformMenuOptionsText={transformOptionText}
      selectedValuesColor={styles.colors.BLACK}
      selectedValuesBackgroundColor={styles.colors.LIGHT_GRAY_2}
      dropdownOptionsColor={styles.colors.BLACK}
      dropdownSelectedOptionsColor={styles.colors.RED}
      hasImages={false}
      hasCloseButton
      applyOnItemSelection={false}
      selectedValuesWidth={170}
      dropdownOptionsWidth={250}
      dropdownOptionsHeight={50}
      right={right}
    />
  );
};

export default GeoLocationSelect;
