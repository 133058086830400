import styled from 'styled-components';
import styles from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.WHITE};
  height: calc(100vh - ${styles.layout.headerHeight}px);
  font-size: 24px;
  font-weight: bold;
`;
