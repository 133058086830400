import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxInput, CheckboxLabel, CheckboxWrapper, StyledCheckbox } from './styled';
import styles from 'constants/styles';
import { useTheme } from 'hooks';

const Checkbox = ({
  onChange,
  disabled = false,
  red = false,
  green = false,
  checked = false,
  label = '',
  labelColor = styles.colors.BLACK,
  smallSize = false,
  smallLabelFont = false,
  labelFontWeight = 600,
  name,
  labelTextColor,
  checkboxBackground,
  withoutBorders = false,
  wrapperCSS = ''
}) => {
  const theme = useTheme();
  const backgroundColor = checkboxBackground || theme.colors.CHECKBOX_BG;
  return (
    <CheckboxWrapper small={smallSize} wrapperCSS={wrapperCSS}>
      <CheckboxLabel
        smallSize={smallSize}
        smallLabelFont={smallLabelFont}
        labelColor={labelColor}
        labelFontWeight={labelFontWeight}
        labelTextColor={labelTextColor}>
        <CheckboxInput
          {...(name ? { name } : {})}
          checked={checked}
          disabled={disabled}
          red={red}
          green={green}
          type="checkbox"
          onChange={onChange}
          small={smallSize}
          checkboxBackground={backgroundColor}
        />
        <StyledCheckbox small={smallSize} checkboxBackground={backgroundColor} withoutBorders={withoutBorders} />
        <span disabled={disabled}>{label}</span>
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  checkboxBackground: PropTypes.string
};

export default Checkbox;
