/* eslint-disable no-template-curly-in-string */
import { campaignNavigationTypes, cohortTypes } from 'common/enums/campaigns';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: '*required'
  }
});

export const addRewardResolver = yup.object({
  amount_charge: yup
    .string()
    .required()
    .test('REQUIRED', '*required', (value) => Boolean(parseFloat(value))),
  title: yup
    .string()
    .max(30)
    .trim()
    .when('showNotification', ([showNotification], schema) => (showNotification ? schema.required() : schema)),
  notification_body: yup.string().max(170).trim()
});

export const getReduceRewardSchema = ({ currentBalance }) => {
  return yup.object({
    amount_charge: yup
      .string()
      .required()
      .test('REQUIRED', '*required', (value) => Boolean(parseFloat(value)))
      .test('MAX_AMOUNT_LIMIT', (value, ctx) => {
        if (currentBalance && parseFloat(value) > currentBalance) {
          return ctx.createError({ message: `maximum allowed amount to remove is $${currentBalance}` });
        }
        return true;
      })
  });
};

export const createCampaignResolver = {
  // we need cohort_type to be on the same level with service_area_id_list & recipients_list
  // for when condition to have its value
  1: yup.object({
    campaign_push: yup
      .object({
        template_title: yup.string().trim().required(),
        template_body: yup.string().trim().required()
      })
      .required(),
    execution: yup
      .object({
        cohort_type: yup.number().oneOf(Object.values(cohortTypes)),
        datetime_execute: yup.string().required(),
        service_area_id_list: yup
          .array()
          .when('cohort_type', ([cohort_type], schema) =>
            cohort_type === cohortTypes.WORKING_AREA ? schema.required().min(1) : schema
          ),
        recipients_list: yup
          .array()
          .when('cohort_type', ([cohort_type], schema) =>
            cohort_type === cohortTypes.RECIPIENTS_LIST ? schema.required().min(1) : schema
          ),
        csv_cohort_name: yup
          .string()
          .when('cohort_type', ([cohort_type], schema) =>
            cohort_type === cohortTypes.CSV_FILE ? schema.required() : schema
          )
      })
      .required()
  }),
  2: yup.object({
    article: yup
      .object({
        service_area_id_list: yup.array().min(1),
        title: yup.string().trim().required(),
        background_color: yup
          .string()
          .trim()
          .required()
          .test('VALID_COLOR', (value, ctx) => {
            if (/^#([0-9A-F]{3}){1,2}$/i.test(value)) {
              return true;
            }
            return ctx.createError({ message: `Enter a valid color` });
          }),
        article_title: yup.string().trim().required(),
        source_link: yup.string().trim().required(),
        description_html: yup.string().trim().required(),
        is_button_hidden: yup.boolean(),
        cta: yup.string().required(),
        navigation_type: yup
          .number()
          .oneOf(Object.values(campaignNavigationTypes).map(({ TYPE }) => TYPE))
          .when('is_button_hidden', ([is_button_hidden], schema) => (is_button_hidden ? schema : schema.required())),
        redirect_link: yup
          .string()
          .when(['is_button_hidden', 'navigation_type'], ([is_button_hidden, navigation_type], schema) =>
            !is_button_hidden && navigation_type === campaignNavigationTypes.REDIRECT_LINK.TYPE
              ? schema.trim().required()
              : schema.nullable()
          ),
        button_title: yup
          .string()
          .when(['is_button_hidden'], ([is_button_hidden], schema) =>
            !is_button_hidden ? schema.trim().required() : schema
          ),
        navigation_payload: yup
          .object({})
          .when(['is_button_hidden', 'navigation_type'], ([is_button_hidden, navigation_type], schema) => {
            if (is_button_hidden || navigation_type !== campaignNavigationTypes.JOB_CREATION.TYPE) {
              return schema;
            }

            return schema.shape({
              request_source_name: yup.string().trim().required(),
              job_type: yup.number().nullable(),
              job_category_id: yup.number().nullable(),
              job_description: yup.string()
            });
          })
      })
      .required()
  })
};

export const sendTestCampainEventResolver = yup.object({
  test_recipient_ids: yup.array().required().min(1)
});
