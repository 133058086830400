import React, { Fragment } from 'react';
import Location from './Location';
import { LoadingDots } from 'components';
import { Wrapper, Divider, LoadMoreButton, Title } from './styled';

const AgentLocationList = ({ openInstance, onLoadMore, locations, title, isActive }) => {
  if (!locations.list.length) {
    return null;
  }

  return (
    <Wrapper>
      {Boolean(title) && <Title>{title}</Title>}
      {Boolean(locations.list.length) &&
        locations.list.map((location) => (
          <Fragment key={location.id}>
            <Location
              location={location}
              isActive={isActive}
              openInstance={() => openInstance({ customerId: location.user_id })}
            />
            <Divider />
          </Fragment>
        ))}
      {locations.list.length < locations.count && (
        <LoadMoreButton onClick={onLoadMore}>{locations.loading ? <LoadingDots /> : 'Load More'}</LoadMoreButton>
      )}
    </Wrapper>
  );
};

export default AgentLocationList;
