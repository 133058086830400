import React from 'react';
import { Field } from 'redux-form';

import styles from 'constants/styles';

import { Dropdown } from 'components';
import { Label, FlexColumn } from './styled';

const StyledDropdown = ({ input, options, width = 160, placeholder, valuePlaceholder }) => {
  const getNameById = (id) => options.find((option) => option.id === id)?.name || '';

  return (
    <Dropdown
      value={{
        id: input.value,
        name: getNameById(input.value)
      }}
      placeholder={placeholder}
      valuePlaceholder={valuePlaceholder}
      type="square"
      fill
      width={width}
      options={options}
      containerBackground={styles.colors.LIGHT_GRAY_3}
      onChange={input.onChange}
    />
  );
};

const SelectWithLabel = ({ label, name, ...rest }) => {
  return (
    <FlexColumn>
      <Label>{label}</Label>
      <Field component={StyledDropdown} name={name} {...rest} />
    </FlexColumn>
  );
};

export default SelectWithLabel;
