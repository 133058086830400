import React, { useRef, useEffect } from 'react';

import { autoResizeTextArea } from './helpers/functions';

import { StyledInputMessage } from './styled';

const InputMessage = ({ value, onChange = () => null, placeholder = 'Message', maxLength }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef) {
      autoResizeTextArea(inputRef.current);
    }
  }, [value]);

  const onInputChange = (e) => {
    const input = e.target;
    onChange(input.value);
    autoResizeTextArea(input);
  };

  return (
    <StyledInputMessage
      ref={inputRef}
      value={value}
      onChange={onInputChange}
      placeholder={placeholder}
      rows={1}
      maxLength={maxLength}
    />
  );
};

export default InputMessage;
