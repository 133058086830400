export default {
  formErrors: {
    REQUIRED: '*required',
    EMAIL: 'Invalid email',
    PASSWORD: 'Invalid format',
    PASSWORDS_NOT_EQUAL: "Don't match",
    PHONE_NUMBER: 'Invalid number',
    MINIMUM_SYMBOLS_REQUIRED: 'Enter at least 3 symbols',
    LATIN: 'Use latin letters',
    NUMBER: 'Use numbers',
    MONTH: 'Invalid month',
    DAY: 'Invalid day',
    YEAR: 'Invalid year',
    NOT_A_LINK: 'Invalid link',
    LOOM_EMBED_LINK: "Link should start with 'https://www.loom.com/embed/'",
    HOMEOWNER_REQUIRED: `Please pick a homeowner`,
    MAXLENGTH: 'Too many characters'
  },
  requstSucceed: {
    CANCEL_VISIT_SUCCESS: 'Visit is cancelled',
    PROMO_CODE_DISABLED_TEXT: 'Reward code was disabled',
    PROMO_CODE_TEXT:
      'Now you can share it with your homeowners, or attach manually in the homeowner’s profile section.',
    REWARD_CREATED_TEXT: 'Homeowner will be notified automatically',
    UPDATE_MESSAGE_TITLE: 'Can’t Edit Message',
    UPDATE_MESSAGE_TEXT:
      'Something went wrong and the message could not be edited. Please reload the page and try again',
    DELETE_MESSAGE_TITLE: 'Can’t Delete Message',
    DELETE_MESSAGE_TEXT:
      'Something went wrong and the message could not be deleted. Please reload the page and try again'
  },
  messageQuantityOnPage: 50
};
