import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'hooks';
import { Icon } from 'components';
import { getAvatar } from 'store/actions/runtimeCache';
import { selectAvatar } from 'store/reducers/runtimeCache';

import { AbsoluteBottomIcon, AbsoluteTopIcon, AvatarWrapper, Background, Image, PlaceholderText } from './styled';
import styles from 'constants/styles';

// global var
// TODO: (Veniamin) try with Thunk middleware
// issue: redux update on chat render cause many calls
var pathRequested = [];

const Avatar = ({
  url,
  src,
  size,
  width,
  height,
  iconColor,
  icon = null,
  iconSize,
  placeholderText = '',
  clickable = false,
  onCloseButtonClick = null,
  avatarSize = 10,
  backgroundStyle = {},
  maxRetryAttempts = 0,
  hasDelay,
  onRenderLeftIcon
}) => {
  const retryAttempts = useRef(maxRetryAttempts);
  const avatarMap = useSelector(selectAvatar);
  const dispatch = useDispatch();

  const handleDownloadAvatar = useCallback(() => {
    if (url && avatarMap[url]) {
      const pathIndex = pathRequested.findIndex((item) => item === url);
      if (pathIndex !== -1) {
        pathRequested.splice(pathIndex, 1);
      }
    }

    // Download with cache in redux
    if (url && avatarMap[url] === undefined && pathRequested.findIndex((item) => item === url) === -1) {
      pathRequested.push(url);
      dispatch(getAvatar({ url, retryAttempts: retryAttempts.current, hasDelay }));
      retryAttempts.current = retryAttempts.current - 1;
    }
  }, [avatarMap, dispatch, hasDelay, retryAttempts, url]);

  useEffect(() => {
    if (url) {
      retryAttempts.current = maxRetryAttempts;
      handleDownloadAvatar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <AvatarWrapper size={size}>
      <Background size={size} width={width} height={height} style={backgroundStyle}>
        {src || avatarMap[url] ? (
          <Image src={src || avatarMap[url]} size={size} width={width} height={height} alt="avatar" />
        ) : placeholderText ? (
          <PlaceholderText clickable={clickable}>{placeholderText}</PlaceholderText>
        ) : (
          <Icon name="avatar" size={avatarSize} backgroundSize={24} />
        )}
      </Background>
      {icon !== null && (
        <AbsoluteBottomIcon size={iconSize || 18}>
          <Icon
            {...icon}
            size={iconSize * icon.sizeCoeff || 18 * icon.sizeCoeff}
            backgroundSize={iconSize || 18}
            color={iconColor}
          />
        </AbsoluteBottomIcon>
      )}
      {Boolean(onCloseButtonClick) && (
        <AbsoluteTopIcon onClick={onCloseButtonClick} size={18} isCircle={!width && !height}>
          <Icon
            name={'close2'}
            backgroundColor={styles.colors.BLACK}
            backgroundSize={18}
            border={true}
            size={10}
            color={styles.colors.WHITE}
          />
        </AbsoluteTopIcon>
      )}
      {onRenderLeftIcon && onRenderLeftIcon()}
    </AvatarWrapper>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Avatar;
