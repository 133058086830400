import React from 'react';


const IconComment = (props) => {
  const { size = 20, color = '#D6D9E5' } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1786 0H1.82143C0.81548 0 0 0.815487 0 1.82144V11.5358C0 12.5418 0.81548 13.3573 1.82143 13.3573H3.97679L3.64652 16.3262C3.6097 16.6595 3.85004 16.9595 4.18334 16.9963C4.35545 17.0153 4.52749 16.9599 4.6562 16.8441L8.53097 13.3573H15.1786C16.1845 13.3573 17 12.5418 17 11.5358V1.82144C17 0.815487 16.1845 0 15.1786 0Z" 
      fill={color}/>
    </svg>
  );
}

export default IconComment;
