import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useMemo } from 'hooks';
import { PopoverColorPicker, MediaUploader, MediaGallery } from 'components';
import { formatLocalDateTimeStamp, formatUTCDateTimeStamp } from 'utils/transformers';
import { getCohortValue } from 'common/helpers/campaigns';
import { jobCategories, jobTypes } from 'common/enums/job';
import { campaignNavigationTypes, cohortTypes } from 'common/enums/campaigns';
import {
  Wrapper,
  CampaignPushInfo,
  BannerInfo,
  ArticleInfo,
  ArticleCTAInfo,
  Divider,
  Box,
  InfoTitle,
  InfoText,
  InfoLink,
  SectionTitle,
  HTMLPreview
} from './styled';

export const CampaignDetails = ({
  article,
  articleServiceAreaList,
  campaignPush,
  cohortType,
  execution,
  executionServiceAreaList,
  hasExecuted,
  hasPreview,
  bannerPicture,
  postPicture,
  renderActions
}) => {
  const navigationType = Object.values(campaignNavigationTypes).find(
    ({ TYPE }) => TYPE === Number(article.navigation_type)
  );
  const hasJobCreationNavigation = navigationType?.TYPE === campaignNavigationTypes.JOB_CREATION.TYPE;
  const bannerRemoteMedia = article.image_preview_source
    ? [article.image_preview_source].map((url) => ({
        media: {
          path: url,
          thumbnail_path: url,
          mime_type: 'image/*'
        },
        id: uuidv4(),
        media_id: uuidv4()
      }))
    : [];
  const articleRemoteMedia = article.image_source
    ? [article.image_source].map((url) => ({
        media: {
          path: url,
          thumbnail_path: url,
          mime_type: 'image/*'
        },
        id: uuidv4(),
        media_id: uuidv4()
      }))
    : [];

  const link = useMemo(() => {
    switch (navigationType?.TYPE) {
      case campaignNavigationTypes.INTERNAL_LINK.TYPE:
        return article.internal_link;
      case campaignNavigationTypes.EXTERNAL_LINK.TYPE:
        return article.external_link;
      case campaignNavigationTypes.REDIRECT_LINK.TYPE:
        return article.redirect_link;
      default:
        return '';
    }
  }, [navigationType?.TYPE, article.internal_link, article.external_link, article.redirect_link]);

  return (
    <Wrapper>
      <SectionTitle>Push to the app</SectionTitle>
      <CampaignPushInfo>
        <div>
          <InfoTitle>Heading</InfoTitle>
          <InfoText>{campaignPush?.template_title || '-'}</InfoText>
        </div>
        <div>
          <InfoTitle>Description</InfoTitle>
          <InfoText>{campaignPush?.template_body || '-'}</InfoText>
        </div>
        <div>
          <InfoTitle>Execution Date</InfoTitle>
          <InfoText isHighlighted={!hasExecuted}>
            {execution?.datetime_execute ? formatLocalDateTimeStamp(execution?.datetime_execute) : '-'}
          </InfoText>
        </div>
        <div>
          <InfoTitle>Cohort</InfoTitle>
          <InfoText>
            {getCohortValue({
              cohort_type: cohortType,
              service_areas: executionServiceAreaList,
              recipients_list: execution?.recipients_list,
              csv_cohort_name: execution?.csv_cohort_name,
              csv_cohort_path: execution?.csv_cohort_path
            }) || '-'}
          </InfoText>
        </div>
        {Boolean(renderActions) && renderActions()}
      </CampaignPushInfo>
      <Divider />
      <SectionTitle>Banner</SectionTitle>
      <BannerInfo>
        <div>
          {Boolean(bannerPicture) && (
            <MediaUploader label="Banner Picture" files={bannerPicture} maxAmount={1} disabled />
          )}
          {!hasPreview && (
            <>
              <InfoTitle>Banner Picture</InfoTitle>
              <MediaGallery
                media={bannerRemoteMedia}
                mediaUrls={article.image_preview_source ? [article.image_preview_source] : []}
              />
            </>
          )}
        </div>
        <div>
          <InfoTitle>Banner BG color</InfoTitle>
          <InfoText>
            <PopoverColorPicker color={article?.background_color} disabled hasValidColor />
          </InfoText>
        </div>
        <div>
          <InfoTitle>Source Link</InfoTitle>
          {/* make it a clickable link */}
          {article.source_link && !article.source_link.includes('https://dobbyhome.com/app/') ? (
            <InfoText>
              <InfoLink
                href={`https://dobbyhome.com/app/${article.source_link}`}
                rel="noopener noreferrer"
                target="_blank">
                https://dobbyhome.com/app/{article.source_link}
              </InfoLink>
            </InfoText>
          ) : (
            <InfoText>
              <InfoLink
                href={article.source_link}
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: 'underline' }}>
                {article.source_link || '-'}
              </InfoLink>
            </InfoText>
          )}
        </div>
        <div>
          <InfoTitle>Banner Title</InfoTitle>
          <InfoText>{article.title}</InfoText>
        </div>
        <div>
          <InfoTitle>Banner Button Name</InfoTitle>
          <InfoText>{article.cta}</InfoText>
        </div>
        <div>
          <InfoTitle>Area to Display</InfoTitle>
          <InfoText>
            {cohortType == cohortTypes.ALL_USERS
              ? 'All Install Base'
              : articleServiceAreaList?.includes('ALL') || articleServiceAreaList?.length === 0
              ? 'All Install Base'
              : getCohortValue({
                  cohort_type: cohortTypes.WORKING_AREA,
                  service_areas: articleServiceAreaList
                })}
          </InfoText>
        </div>
      </BannerInfo>
      <Divider />
      <SectionTitle>Post</SectionTitle>
      <ArticleInfo>
        <div>
          {Boolean(postPicture) && <MediaUploader label="Top Picture" files={postPicture} maxAmount={1} disabled />}
          {!hasPreview && (
            <>
              <InfoTitle>Top Picture</InfoTitle>
              <MediaGallery media={articleRemoteMedia} mediaUrls={article.image_source ? [article.image_source] : []} />
            </>
          )}
        </div>
        <div>
          <InfoTitle>Title</InfoTitle>
          <InfoText>{article.article_title}</InfoText>
        </div>
      </ArticleInfo>
      <Box margin="24px 0">
        <InfoTitle>Content</InfoTitle>
        <InfoText>
          <HTMLPreview
            style={{ color: 'white !important' }}
            dangerouslySetInnerHTML={{ __html: article.description_html }}
          />
        </InfoText>
      </Box>
      {!article.is_button_hidden && (
        <>
          <Divider />
          <SectionTitle>CTA Button</SectionTitle>
          <ArticleCTAInfo hasJobCreationNavigation={hasJobCreationNavigation}>
            <div>
              <InfoTitle>Title</InfoTitle>
              <InfoText>{article.button_title || '-'}</InfoText>
            </div>
            <div>
              <InfoTitle>Navigation</InfoTitle>
              <InfoText>
                {([
                  campaignNavigationTypes.INTERNAL_LINK.TYPE,
                  campaignNavigationTypes.EXTERNAL_LINK.TYPE,
                  campaignNavigationTypes.REDIRECT_LINK.TYPE
                ].includes(navigationType.TYPE)
                  ? campaignNavigationTypes.REDIRECT_LINK.TITLE
                  : navigationType?.TITLE) || '-'}
              </InfoText>
            </div>
            {hasJobCreationNavigation && (
              <>
                <div>
                  <InfoTitle>Job type</InfoTitle>
                  <InfoText>
                    {Object.values(jobTypes).find(({ id }) => id === article.navigation_payload?.job_type)?.name ?? '-'}
                  </InfoText>
                </div>
                <div>
                  <InfoTitle>Job category</InfoTitle>
                  <InfoText>
                    {Object.values(jobCategories).find(({ id }) => id === article.navigation_payload?.job_category_id)
                      ?.name ?? '-'}
                  </InfoText>
                </div>
              </>
            )}
            {[
              campaignNavigationTypes.INTERNAL_LINK.TYPE,
              campaignNavigationTypes.EXTERNAL_LINK.TYPE,
              campaignNavigationTypes.REDIRECT_LINK.TYPE
            ].includes(navigationType.TYPE) && (
              <>
                <div>
                  <InfoTitle>{campaignNavigationTypes.REDIRECT_LINK.TITLE}</InfoTitle>
                  {link ? (
                    <InfoLink href={article.internal_link} rel="noopener noreferrer" target="_blank">
                      {link}
                    </InfoLink>
                  ) : (
                    <InfoText>-</InfoText>
                  )}
                </div>
              </>
            )}
          </ArticleCTAInfo>
          {hasJobCreationNavigation && (
            <>
              <Box margin="25px 0">
                <InfoTitle>Job description</InfoTitle>
                <InfoText>{article.navigation_payload?.job_description || '-'}</InfoText>
              </Box>
              <Box margin="25px 0">
                <InfoTitle>Request source</InfoTitle>
                <InfoText>{article.navigation_payload?.request_source_name ?? '-'}</InfoText>
              </Box>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};
