import styled from 'styled-components';
import styles from 'constants/styles';

export const TabsWrapper = styled.div`
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme, bg }) => bg || theme.colors.TABS_BG};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  &:focus {
    outline: none;
  }
`;

export const TabList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  flex-direction: row;

  height: 50px;
  ${({ theme }) => !theme.darkMode && `border-bottom: 1px solid ${styles.colors.LIGHT_GRAY};`}
  overflow-x: scroll;
  scroll-snap-type: x proximity;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  ${({ border, theme }) => border && `border-bottom: 1px solid ${theme.colors.DARK_2}`};
`;

export const TabHeader = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  position: relative;
  color: ${({ theme }) => theme.colors.DARK_GREY};

  ${({ isActive, theme }) =>
    isActive &&
    `
  color: ${theme.colors.TABS_TEXT};
  &:after {
    content: '';
    width: 100%;
    height: 6px;
    background: ${theme.colors.DARK_GREEN_2};
    border-radius: 4px;
    bottom: 0;
    left: 0;
    position: absolute;
  }`}

  &:hover {
    color: ${({ theme }) => theme.colors.TABS_TEXT};
    &:after {
      content: '';
      width: 100%;
      height: 6px;
      background: ${({ theme }) => theme.colors.DARK_GREEN_2};
      border-radius: 4px;
      bottom: 0;
      left: 0;
      position: absolute;
    }
    cursor: pointer;
    margin-bottom: 0px;
  }
`;

export const UnreadMessagesMark = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.DARK_2};
  background: ${({ theme }) => theme.colors.DARK_GREEN_2};
  margin-right: 6px;
  margin-left: -14px;
`;

export const TabWrapper = styled.div`
  margin: 0;
  padding: 0;
  flex: 1;

  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
`;

export const Wrapper = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  overflow: auto;
  position: relative;
  ${({ theme }) => theme.darkMode && `background: ${theme.colors.DARK_3}`}
`;

export const EmptySpace = styled.div`
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.DARK_2};`}
  flex: 1;
`;

export const Button = styled.div`
  background: linear-gradient(90deg, #262e30 0%, rgba(38, 46, 48, 0) 100%);
  padding: 0 22px 0 12px;
  position: absolute;
  top: 0;
  z-index: 999;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ right }) => (right ? `transform: rotate(180deg); right: 0;` : `left: 0;`)}
`;
