export const cellTypes = {
  AVATAR: 'avatar',
  APP_VERSION: 'appVersion',
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  HEADED_TEXT: 'headedText',
  IMAGE: 'image',
  MESSAGE: 'message',
  LABEL: 'label',
  LABEL_LIST: 'labelList',
  TEXT: 'text',
  PLATFORM_VERSION: 'platformVersion',
  REVIEW: 'review',
  RICH_TEXT: 'richText',
  USER_NAME: 'userName',
  SKILLS: 'skills'
};
