import React from 'react';
import styles from 'constants/styles';

const IconArrowFillLeft = ({ color = styles.colors.WHITE, width = 6, height = 10 }) => (
  <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 8.58579V1.41421C6 0.523309 4.92286 0.0771425 4.29289 0.707107L0.707107 4.29289C0.316582 4.68342 0.316582 5.31658 0.707107 5.70711L4.29289 9.29289C4.92286 9.92286 6 9.47669 6 8.58579Z"
      fill={color}
    />
  </svg>
);

export default IconArrowFillLeft;
