import React from 'react';

const IconEdit = props => {
  const { size = 18, color = '#282A2B' } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M13.2515 1.99489C13.9113 1.33504 14.9811 1.33504 15.641 1.99489L16.0815 2.43547C16.7404 3.09576 16.7404 4.16466 16.0815 4.82495L9.38316 11.5236C9.33726 11.5695 9.28035 11.6027 9.21787 11.62L6.03771 12.501C5.90732 12.5371 5.7677 12.5003 5.67193 12.4046C5.5763 12.3089 5.53949 12.1692 5.57571 12.0389L6.45659 8.8587C6.4739 8.79622 6.50704 8.73932 6.55295 8.69341L13.2515 1.99489ZM12.8533 3.45509L7.37105 8.93746L9.13896 10.7055L14.6213 5.22315L12.8533 3.45509ZM8.43042 11.0588L7.01788 9.64615L6.47654 11.6002L8.43042 11.0588ZM15.1524 4.69222L15.5506 4.29402C15.9165 3.92721 15.9165 3.33336 15.5506 2.9664L15.1099 2.52582C14.7434 2.1593 14.1491 2.1593 13.7826 2.52582L13.3842 2.92402L15.1524 4.69222ZM15.0389 11.2887C15.0389 11.0813 15.207 10.9132 15.4143 10.9132C15.6217 10.9132 15.7898 11.0813 15.7898 11.2887V14.6225C15.7886 15.6589 14.9488 16.4987 13.9125 16.4998H3.37732C2.34098 16.4987 1.50117 15.6589 1.5 14.6225V4.83814C1.50117 3.8018 2.34098 2.96199 3.37732 2.96082H6.71104C6.91842 2.96082 7.0865 3.12904 7.0865 3.33628C7.0865 3.54367 6.91842 3.71175 6.71104 3.71175H3.37732C2.75546 3.71248 2.25166 4.21642 2.25093 4.83814V14.6224C2.25166 15.2442 2.75546 15.7482 3.37732 15.7488H13.9125C14.5343 15.7482 15.0381 15.2442 15.0389 14.6224V11.2887Z"
      />
    </svg>
  );
};

export default IconEdit;
