import React from 'react';

import { MessageImageContainer, MessageImageWrapper, MessageImageImage } from './styled';

const MessageImage = ({ message = { text: '' } }) => {
  return (
    <MessageImageContainer>
      <MessageImageWrapper>
        <MessageImageImage src={message.image} />
      </MessageImageWrapper>
    </MessageImageContainer>
  );
};

export default MessageImage;
