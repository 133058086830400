import { jobTypesByTab } from 'common/enums/job';
import { formatDateShortTimeStampSmall } from 'utils';
import { adminStatuses } from 'common/enums/job';

export const getInfo = (type, { datetime_paid, datetime_canceled, admin_status }) => {
  switch (type) {
    case jobTypesByTab.ACTIVE:
      return 'Paid: -';
    case jobTypesByTab.PAID:
      return `Paid: ${formatDateShortTimeStampSmall(datetime_paid)}`;
    case jobTypesByTab.CANCELLED: {
      if (admin_status === adminStatuses.BUNDLED.TYPE) {
        return `Bundled: ${formatDateShortTimeStampSmall(datetime_canceled)}`;
      } else {
        return `Canceled: ${formatDateShortTimeStampSmall(datetime_canceled)}`;
      }
    }
    default:
      return '';
  }
};
