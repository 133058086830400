import styled from 'styled-components';
import styles from 'constants/styles';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  height: ${styles.layout.paginationContainerHeight}px;
  background-color: ${({ theme }) => theme.colors.FOOTER};
  ${({ theme }) => !theme.darkMode && `border-top: 1px solid ${theme.colors.LIGHT_GRAY};`}
`;

export const PageNumber = styled.div`
  margin: 12px 0 12px 24px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.PAGE_NUMBER};
`;

export const PageControl = styled.div`
  margin: 5px 20px;
  display: flex;
  flex-direction: flex-end;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin: 0 8px;
`;

export const PageInput = styled.input`
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  width: 60px;
  height: 24px;
  margin: 0 7px 0 13px;
  padding: 3px 10px;

  color: ${styles.colors.BLACK};
`;

export const NavPageNumbers = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  margin: 0 6px;
  color: ${({ theme }) => theme.colors.PAGE_NUMBER};
  cursor: pointer;
`;

export const CurrentPage = styled.div`
  padding: 4px 12px;
  ${({ theme }) =>
    theme.darkMode ? `background: ${theme.colors.DARK_GREY_3}` : `border: 1px solid ${theme.colors.LIGHT_GRAY}`};

  margin: 0 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.PAGE_NUMBER};
  cursor: pointer;
`;

// PaginationFullScreen
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ hasFilters, hasNavigationTabs }) => `
    height: calc(100vh - ${styles.layout.headerHeight}px - ${
    (hasFilters ? styles.layout.filtersHeaderHeight : 0) + (hasNavigationTabs ? styles.layout.filtersHeaderHeight : 0)
  }px);
  `}
`;

export const FlexOne = styled.div`
  display: flex;
  flex: 1;
  overflow: overlay;
  position: relative;
  ${({ hasFilters, hasNavigationTabs }) => `
    height: calc(100vh - ${styles.layout.headerHeight}px - ${styles.layout.paginationContainerHeight}px - ${
    (hasFilters ? styles.layout.filtersHeaderHeight : 0) + (hasNavigationTabs ? styles.layout.filtersHeaderHeight : 0)
  }px);
  `}
`;
