import React from 'react';

import { MessageSystemContainer, MessageSystemWrapper, MessageSystemText } from './styled';

const MessageSystem = ({ message = { system: true, text: '' } }) => {
  return (
    <MessageSystemContainer>
      <MessageSystemWrapper>
        <MessageSystemText>{message.text}</MessageSystemText>
      </MessageSystemWrapper>
    </MessageSystemContainer>
  );
};

export default MessageSystem;
