import React from 'react';
import PropTypes from 'prop-types';
import { typographyVariants } from 'common/enums/typography';
import { StyledTypography } from './styled';

export const Typography = ({ style = {}, variant, children, ...props }) => {
  return (
    <StyledTypography style={style} variant={variant} {...props}>
      {children}
    </StyledTypography>
  );
};

Text.defaultProps = {
  children: ''
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(typographyVariants))
};
