import styled from 'styled-components';
import styles from 'constants/styles';

export const AbsoluteCloseButton = styled.div`
  position: absolute;
  top: -3px;
  right: 3px;
  display: none;
`;

export const RewardWrapper = styled.div`
  margin: 0px 10px 5px 0;
  width: fit-content;

  &:hover {
    ${AbsoluteCloseButton} {
      display: block;
    }
  }
`;

export const RewardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  background ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
`;
