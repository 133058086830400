import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { singletonHook } from 'react-singleton-hook';
import { Auth, Signer } from 'aws-amplify';
import useWebSocket from 'react-use-websocket';

import { environment as selectEnvironment } from 'store/reducers/app';
import { environments } from '../common/enums/app';

const useSocket = () => {
  const appEnv = useSelector(selectEnvironment);

  let SOCKET_URL;

  switch (appEnv) {
    case environments.PRODUCT.TYPE:
      SOCKET_URL = 'wss://79bbkehc06.execute-api.us-east-1.amazonaws.com/product';
      break;
    case environments.STAGING.TYPE:
      SOCKET_URL = 'wss://9sf6kxxu2m.execute-api.us-east-1.amazonaws.com/staging';
      break;
    case environments.DEVELOP.TYPE:
      SOCKET_URL = 'wss://cquc8dwg66.execute-api.eu-central-1.amazonaws.com/develop';
      break;
    default:
      SOCKET_URL = 'wss://79bbkehc06.execute-api.us-east-1.amazonaws.com/product';
  }

  const getSocketUrl = useCallback(async () => {
    const credentials = await Auth.currentCredentials();

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken
    };

    const signedSocketUrl = Signer.signUrl(SOCKET_URL, accessInfo);

    return signedSocketUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getWebSocket, sendJsonMessage } = useWebSocket(getSocketUrl, {
    // shouldReconnect: (closeEvent) => {
    //   return didUnmount.current === false;
    // },
    // reconnectAttempts: 10,
    // reconnectInterval: 3000
  });

  useEffect(() => {
    const KEEP_ALIVE_INTERVAL = 180000;
    const keepAliveInterval = setInterval(() => {
      sendJsonMessage({
        route: '$default'
      });
    }, KEEP_ALIVE_INTERVAL);
    return () => {
      clearInterval(keepAliveInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return getWebSocket();
};

export default singletonHook(null, useSocket);
