import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { store, persistor } from 'store';
import { RootNavigation } from 'navigation';
import Modals from 'modals';
import { ThemeProvider } from 'containers';

const App = () => (
  <Provider store={store}>
    <ThemeProvider>
      <Router>
        <PersistGate loading={null} persistor={persistor}>
          <RootNavigation />
          <SingletonHooksContainer />
          <Modals />
        </PersistGate>
      </Router>
    </ThemeProvider>
  </Provider>
);

export default App;
