import React from 'react';
import styles from 'constants/styles';

const IconFile = ({ size = 20, color = styles.colors.WHITE }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.20898 1.4165H4.25065C3.87493 1.4165 3.51459 1.56576 3.24892 1.83144C2.98324 2.09711 2.83398 2.45745 2.83398 2.83317V14.1665C2.83398 14.5422 2.98324 14.9026 3.24892 15.1682C3.51459 15.4339 3.87493 15.5832 4.25065 15.5832H12.7506C13.1264 15.5832 13.4867 15.4339 13.7524 15.1682C14.0181 14.9026 14.1673 14.5422 14.1673 14.1665V6.37484L9.20898 1.4165Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.20898 1.4165V6.37484H14.1673" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconFile;
