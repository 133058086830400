import React from 'react';
import MuiRadioGroup from '@mui/material/RadioGroup';
import BaseRadio from '@mui/material/Radio';
import messages from 'constants/messages';
import { RadioCheckedIcon, RadioIcon } from './styled';
import { Label, FlexColumn, StyledFormControlLabel, Error } from './styled';

const Radio = ({ label, options, value, onChange, error, row, gap, ...props }) => {
  return (
    <FlexColumn row={row} gap={gap}>
      {label && <Label>{label}</Label>}
      <MuiRadioGroup onChange={onChange} aria-label={label} value={value} row={row}>
        {options.map(({ value, name, disabled = false }) => (
          <StyledFormControlLabel
            key={value}
            value={value}
            label={name}
            row={row ? 'true' : null}
            disabled={disabled}
            control={<BaseRadio disableRipple checkedIcon={<RadioCheckedIcon />} icon={<RadioIcon />} {...props} />}
          />
        ))}
      </MuiRadioGroup>
      {Boolean(messages.formErrors[error.type?.toUpperCase()]) && Boolean(options.length) && (
        <Error>{messages.formErrors[error.type?.toUpperCase()]}</Error>
      )}
    </FlexColumn>
  );
};

export default Radio;
