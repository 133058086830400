import React, { useEffect, useRef, useState } from 'react';

import MessageRow from './MessageRow';

import { MessagesScrollWrapper, MessagesContainer } from './styled';

const Messages = ({
  currentUser = {},
  isMessagesLoading = [],
  messages = [],
  onMessagesEnd = () => {},
  keyExtractor = (item) => item._id,
  overwriteComponents = {}
}) => {
  const scrollRef = useRef(null);
  const [scrollEventLock, setScrollEventLock] = useState(true);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [prevMessageCount, setPrevMessageCount] = useState(0);
  const [lastMessageId, setLastMessageId] = useState(0);

  const handleScroll = (event) => {
    const containerHeight = event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    const reversedToTop = event.target.scrollTop;

    const toTop = scrollHeight + reversedToTop - containerHeight;
    if (!scrollEventLock && toTop < 200) {
      onMessagesEnd();
      setScrollEventLock(true);
      setPrevScrollHeight(scrollRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    setPrevMessageCount(messages.length);
    if (messages.length === 0) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'instant'
      });
      return;
    }

    if (prevMessageCount < messages.length) {
      if (lastMessageId && lastMessageId !== messages[0]._id) {
        // New message sent from support
        setLastMessageId(messages[0]._id);
        if (messages[0].user?._id === 'support') {
          scrollRef.current.scrollTo({
            top: 0,
            behavior: 'instant'
          });
        }
      } else {
        if (messages.length > 50) {
          // Pagination update
          scrollRef.current.scrollTo({
            top: -prevScrollHeight + scrollRef.current.clientHeight,
            behavior: 'instant'
          });
        }
        setScrollEventLock(false);
      }
    }
    setLastMessageId(messages[0]._id);
  }, [messages]);

  const { emptyMessageList = null, loadingIndicator = null, ...messageRowOverwrites } = overwriteComponents;

  return (
    <MessagesScrollWrapper ref={scrollRef} onScroll={handleScroll}>
      {isMessagesLoading && loadingIndicator ? loadingIndicator() : null}
      {messages.length === 0 && emptyMessageList ? emptyMessageList() : null}
      <MessagesContainer>
        {messages.map((item, index, messages) => (
          <MessageRow
            key={keyExtractor(item)}
            currentUser={currentUser}
            prevMessage={messages[index + 1]}
            message={item}
            nextMessage={messages[index - 1]}
            overwriteComponents={messageRowOverwrites}
          />
        ))}
      </MessagesContainer>
    </MessagesScrollWrapper>
  );
};

export default Messages;
