import React from 'react';
import styles from 'constants/styles';

const IconBlocked = ({ size = 8, color = styles.colors.WHITE }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="8" height="2" rx="1" fill={color} />
    </svg>
  );
};

export default IconBlocked;
