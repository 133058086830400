import styled from 'styled-components';
import styles from 'constants/styles';

export const StyledCloseButton = styled.button`
  border: none;
  box-shadow: none;
  width: ${({ size }) => size || 18}px;
  min-width: ${({ size }) => size || 18}px;
  height: ${({ size }) => size || 18}px;
  border-radius: 50%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY};

  &:focus {
    outline: 0;
  }
`;
