export const sortingDirections = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending'
};

export const columnNames = {
  ADDRESS: 'address',
  AMOUNT_TOTAL_CHARGE: 'amount_total_charge',
  AMOUNT_CUSTOMER_PAID: 'amount_customer_paid',
  AMOUNT_ESTIMATED: 'expected_value',
  AMOUNT_INVOICED: 'amount_invoiced',
  AMOUNT_PAID: 'amount_total_paid',
  AMOUNT_TOTAL_EARNED: 'amount_total_earned',
  APP_VERSION: 'app_version',
  AREA_LIST: 'area_list',
  ARTICLE_AREA_TO_DISPLAY: 'article_service_areas',
  AUTO_ASSIGN_AREAS: 'auto_assign_service_area_list',
  AUTO_ASSIGN_CATEGORY: 'auto_assign_service_id',
  AVATAR: 'avatar',
  AVERAGE_JOB_VALUE: 'amount_average_earned',
  CHECKBOX: 'checkbox',
  COMPANY_NAME: 'company_name',
  COUNT_TASK_ACCEPTED: 'count_accepted',
  COUNT_TASK_ASSIGNMENTS_SENT: 'count_assigned',
  COUNT_TASK_ONGOING: 'count_jobs_ongoing',
  COUNT_TASK_COMPLETED: 'count_jobs_completed',
  COUNT_TASK_SCHEDULED: 'count_jobs_scheduled',
  COUNT_TASK_UNFULFILLED: 'count_jobs_unfulfilled',
  DATETIME_ACCEPTED: 'datetime_accepted',
  COHORT: 'cohort',
  DATETIME_CANCELLED: 'datetime_canceled', // admin_status 5,6
  DATETIME_CREATED: 'date_created', // admin_status 0, 1, 2, null
  DATETIME_CREATED_INVOICE_PENDING: 'datetime_created_invoice_pending',
  DATETIME_COMPLETED: 'datetime_completed', // admin_status 3,4
  DATETIME_HOLD: 'date_end_hold', // admin_status 7
  DAYS_DELAY: 'days_delayed',
  DAYS_SINCE_SIGN_UP: 'days_since_sign_up',
  DAYS_SINCE_LAST_REQUESTED: 'days_since_last_requested',
  DAYS_SINCE_LAST_COMPLETED: 'days_since_last_completed',
  DAYS_SINCE_LAST_OPEN_APP: 'days_since_last_open_app',
  DAYS_SINCE_LAST_ACTIVE: 'days_since_last_active',
  DATETIME_EXECUTE: 'datetime_execute',
  DATETIME_LAST_ACTIVITY: 'date_login',
  DATETIME_PAID: 'datetime_paid',
  DATETIME_VISIT: 'datetime_visit', // tab all, admin_status null
  DATETIME_VISIT_CURRENT: 'datetime_visit_current', // tab scheduled, admin_status 2
  DATETIME_QUOTED: 'datetime_quote',
  DAYS_SINCE_SIGNUP: 'days_since_sign_up',
  DOWNLOAD_CSV_BUTTON: 'download_csv_button',
  EMAIL: 'email',
  FAMILY_NAME: 'family_name',
  GIVEN_NAME: 'given_name',
  HO_FAMILY_NAME: 'customer_family_name',
  HO_GIVEN_NAME: 'customer_given_name',
  HO_ID: 'customer_id',
  ID: 'id',
  INVITED_BY: 'lead_source',
  INVOICE_TYPE: 'invoice_type',
  IS_FINAL_PAYMENT: 'is_final_payment',
  IS_WARNING_CHECKED: 'is_warning_checked',
  IS_ACTIVE: 'is_active',
  IS_STRIPE_CONNECTED: 'stripe_is_connected',
  IS_QUOTED: 'is_quoted',
  JOB_ID: 'service_request_id',
  JOB_CATEGORY_NAME: 'job_category_name',
  JOB_TYPE_NAME: 'job_type_name',
  JOBS_CREATED: 'jobs_created',
  LAST_NOTE: 'last_note',
  LAST_MESSAGE: 'last_message',
  LAST_HO_MESSAGE_DATETIME: 'last_ho_message_datetime',
  LIFETIME_MARGIN: 'lifetime_margin',
  LIFETIME_SPEND: 'amount_total_spent',
  SKILL_NAME: 'skill_name',
  NEW_MESSAGES: 'new_messages',
  NAME: 'name',
  NOTIFICATIONS_PERMISSION: 'device_notification_status',
  ONE_CLICK_AREAS: 'one_click_service_area_list',
  ONE_CLICK_CATEGORY: 'one_click_service_id',
  OPERATIONAL_AREA_ID: 'operational_area_id',
  PHONE_NUMBER: 'phone_number',
  PLATFORM_VERSION: 'platform_version',
  POSITION: 'position',
  PRO_ASSIGNMENT_RATE: 'pro_assignment_rate',
  PRIMARY_CATEGORY: 'primary_service_id',
  SECONDARY_CATEGORY: 'secondary_service_id',
  PRO_FAMILY_NAME: 'provider_family_name',
  PRO_GIVEN_NAME: 'provider_given_name',
  PRO_ID: 'provider_id',
  SERVICE_ACCEPTED: 'service_accepted',
  SERVICE_ASSIGNED: 'service_assigned',
  SERVICE_COMPLETED: 'service_completed',
  SERVICE_CURRENT_ADMIN_STATUS: 'service_current_admin_status',
  SERVICE_ONGOING: 'service_ongoing',
  STAGE: 'admin_status',
  SOURCE_USER_ID: 'source_user_id',
  SOURCE_USER_NAME: 'source_user_name',
  SOURCE_USER_GROUP_ID: 'source_user_group_id',
  TAG_NAME: 'tag_name',
  TAG_LIST: 'tag_list',
  TARGET_JOB_COUNT_ACTIVE: 'count_active',
  TARGET_JOB_COUNT_COMPLETED: 'count_completed',
  TARGET_USER_NAME: 'user_name',
  TARGET_USER_ID: 'user_id',
  TITLE: 'title',
  TOTAL_PAID: 'amount_total_paid',
  TOTAL_SENT: 'total_sent',
  TOTAL_VIEWS: 'total_views',
  UNFULFILLED_REASON: 'unfulfilled_reason',
  WARNING: 'warning_value',
  WILL_WORK_AGAIN: 'will_work_again'
};

// TODO: (refactoring) move custom filters to the backend logic!
export const sortingNames = {
  [columnNames.ADDRESS]: {
    [sortingDirections.ASCENDING]: `${columnNames.ADDRESS}`,
    [sortingDirections.DESCENDING]: `-${columnNames.ADDRESS}`
  },
  [columnNames.AMOUNT_CUSTOMER_PAID]: {
    [sortingDirections.ASCENDING]: `${columnNames.AMOUNT_CUSTOMER_PAID},${columnNames.DATETIME_PAID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AMOUNT_CUSTOMER_PAID},-${columnNames.DATETIME_PAID}`
  },
  [columnNames.AMOUNT_PAID]: {
    [sortingDirections.ASCENDING]: `${columnNames.AMOUNT_PAID},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AMOUNT_PAID},-${columnNames.ID}`
  },
  [columnNames.AMOUNT_ESTIMATED]: {
    [sortingDirections.ASCENDING]: `${columnNames.AMOUNT_ESTIMATED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AMOUNT_ESTIMATED},-${columnNames.ID}`
  },
  [columnNames.AMOUNT_INVOICED]: {
    [sortingDirections.ASCENDING]: `${columnNames.AMOUNT_INVOICED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AMOUNT_INVOICED},-${columnNames.ID}`
  },
  [columnNames.AMOUNT_TOTAL_EARNED]: {
    [sortingDirections.ASCENDING]: `${columnNames.AMOUNT_TOTAL_EARNED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AMOUNT_TOTAL_EARNED},-${columnNames.ID}`
  },
  [columnNames.APP_VERSION]: {
    [sortingDirections.ASCENDING]: `${columnNames.APP_VERSION},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.APP_VERSION},-${columnNames.ID}`
  },
  [columnNames.AVERAGE_JOB_VALUE]: {
    [sortingDirections.ASCENDING]: `${columnNames.AVERAGE_JOB_VALUE},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.AVERAGE_JOB_VALUE},-${columnNames.ID}`
  },
  [columnNames.JOB_CATEGORY_NAME]: {
    [sortingDirections.ASCENDING]: `${columnNames.JOB_CATEGORY_NAME},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.JOB_CATEGORY_NAME},-${columnNames.ID}`
  },
  [columnNames.COMPANY_NAME]: {
    [sortingDirections.ASCENDING]: `${columnNames.COMPANY_NAME}`,
    [sortingDirections.DESCENDING]: `-${columnNames.COMPANY_NAME}`
  },
  [columnNames.LAST_HO_MESSAGE_DATETIME]: {
    [sortingDirections.ASCENDING]: `${columnNames.LAST_HO_MESSAGE_DATETIME}`,
    [sortingDirections.DESCENDING]: `-${columnNames.LAST_HO_MESSAGE_DATETIME}`
  },
  [columnNames.LIFETIME_MARGIN]: {
    [sortingDirections.ASCENDING]: `${columnNames.LIFETIME_MARGIN},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.LIFETIME_MARGIN},-${columnNames.ID}`
  },
  [columnNames.LIFETIME_SPEND]: {
    [sortingDirections.ASCENDING]: `${columnNames.LIFETIME_SPEND},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.LIFETIME_SPEND},-${columnNames.ID}`
  },
  [columnNames.LAST_MESSAGE]: {
    [sortingDirections.ASCENDING]: `${columnNames.LAST_MESSAGE}`,
    [sortingDirections.DESCENDING]: `-${columnNames.LAST_MESSAGE}`
  },
  // back requires different naming to sort this field

  [columnNames.COUNT_TASK_ACCEPTED]: {
    [sortingDirections.ASCENDING]: `${columnNames.SERVICE_ACCEPTED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.SERVICE_ACCEPTED},-${columnNames.ID}`
  },
  [columnNames.COUNT_TASK_ASSIGNMENTS_SENT]: {
    [sortingDirections.ASCENDING]: `${columnNames.SERVICE_ASSIGNED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.SERVICE_ASSIGNED},-${columnNames.ID}`
  },
  [columnNames.COUNT_TASK_COMPLETED]: {
    [sortingDirections.ASCENDING]: `${columnNames.SERVICE_COMPLETED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.SERVICE_COMPLETED},-${columnNames.ID}`
  },
  [columnNames.COUNT_TASK_ONGOING]: {
    [sortingDirections.ASCENDING]: `${columnNames.SERVICE_ONGOING},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.SERVICE_ONGOING},-${columnNames.ID}`
  },
  [columnNames.COUNT_TASK_UNFULFILLED]: {
    [sortingDirections.ASCENDING]: `${columnNames.COUNT_TASK_UNFULFILLED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.COUNT_TASK_UNFULFILLED},-${columnNames.ID}`
  },
  [columnNames.DATETIME_ACCEPTED]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_ACCEPTED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_ACCEPTED},-${columnNames.ID}`
  },
  [columnNames.DATETIME_CANCELLED]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_CANCELLED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_CANCELLED},-${columnNames.ID}`
  },
  [columnNames.DATETIME_CREATED]: {
    [sortingDirections.ASCENDING]: columnNames.DATETIME_CREATED,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_CREATED}`
  },
  [columnNames.DATETIME_CREATED_INVOICE_PENDING]: {
    [sortingDirections.ASCENDING]: columnNames.DATETIME_CREATED_INVOICE_PENDING,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_CREATED_INVOICE_PENDING}`
  },
  [columnNames.DATETIME_COMPLETED]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_COMPLETED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_COMPLETED},-${columnNames.ID}`
  },
  [columnNames.DATETIME_EXECUTE]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_EXECUTE},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_EXECUTE},-${columnNames.ID}`
  },
  [columnNames.DAYS_DELAY]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_CREATED}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_CREATED}`
  },
  [columnNames.DAYS_SINCE_SIGN_UP]: {
    [sortingDirections.ASCENDING]: `-${columnNames.DAYS_SINCE_SIGN_UP},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `${columnNames.DAYS_SINCE_SIGN_UP},-${columnNames.ID}`
  },
  [columnNames.DAYS_SINCE_LAST_REQUESTED]: {
    [sortingDirections.ASCENDING]: `-${columnNames.DAYS_SINCE_LAST_REQUESTED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `${columnNames.DAYS_SINCE_LAST_REQUESTED},-${columnNames.ID}`
  },
  [columnNames.DAYS_SINCE_LAST_COMPLETED]: {
    [sortingDirections.ASCENDING]: `${columnNames.DAYS_SINCE_LAST_COMPLETED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DAYS_SINCE_LAST_COMPLETED},-${columnNames.ID}`
  },
  [columnNames.DAYS_SINCE_LAST_ACTIVE]: {
    [sortingDirections.ASCENDING]: `-${columnNames.DAYS_SINCE_LAST_ACTIVE},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `${columnNames.DAYS_SINCE_LAST_ACTIVE},-${columnNames.ID}`
  },
  [columnNames.DATETIME_HOLD]: {
    [sortingDirections.ASCENDING]: columnNames.DATETIME_HOLD,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_HOLD}`
  },
  [columnNames.DATETIME_PAID]: {
    [sortingDirections.ASCENDING]: columnNames.DATETIME_PAID,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_PAID}`
  },
  [columnNames.DATETIME_VISIT]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_VISIT},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_VISIT},-${columnNames.ID}`
  },
  [columnNames.DATETIME_VISIT_CURRENT]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_VISIT_CURRENT},-${columnNames.DATETIME_VISIT},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_VISIT_CURRENT},${columnNames.DATETIME_VISIT},-${columnNames.ID}`
  },
  [columnNames.DATETIME_QUOTED]: {
    [sortingDirections.ASCENDING]: `${columnNames.DATETIME_QUOTED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.DATETIME_QUOTED},-${columnNames.ID}`
  },
  [columnNames.EMAIL]: {
    [sortingDirections.ASCENDING]: `${columnNames.EMAIL}`,
    [sortingDirections.DESCENDING]: `-${columnNames.EMAIL}`
  },
  [columnNames.JOB_ID]: {
    [sortingDirections.ASCENDING]: `${columnNames.JOB_ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.JOB_ID}`
  },
  [columnNames.HO_ID]: {
    [sortingDirections.ASCENDING]: `${columnNames.HO_ID},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.HO_ID},-${columnNames.ID}`
  },
  [columnNames.HO_GIVEN_NAME]: {
    [sortingDirections.ASCENDING]: `${columnNames.HO_GIVEN_NAME},${columnNames.HO_FAMILY_NAME},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.HO_GIVEN_NAME},-${columnNames.HO_FAMILY_NAME},-${columnNames.ID}`
  },
  [columnNames.ID]: {
    [sortingDirections.ASCENDING]: columnNames.ID,
    [sortingDirections.DESCENDING]: `-${columnNames.ID}`
  },
  [columnNames.INVITED_BY]: {
    [sortingDirections.ASCENDING]: columnNames.INVITED_BY,
    [sortingDirections.DESCENDING]: `-${columnNames.INVITED_BY}`
  },
  [columnNames.IS_FINAL_PAYMENT]: {
    [sortingDirections.ASCENDING]: `${columnNames.IS_FINAL_PAYMENT},${columnNames.DATETIME_PAID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.IS_FINAL_PAYMENT},-${columnNames.DATETIME_PAID}`
  },
  [columnNames.IS_ACTIVE]: {
    [sortingDirections.ASCENDING]: columnNames.IS_ACTIVE,
    [sortingDirections.DESCENDING]: `-${columnNames.IS_ACTIVE}`
  },
  [columnNames.IS_QUOTED]: {
    [sortingDirections.ASCENDING]: `${columnNames.IS_QUOTED},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.IS_QUOTED},-${columnNames.ID}`
  },
  [columnNames.IS_STRIPE_CONNECTED]: {
    [sortingDirections.ASCENDING]: columnNames.IS_STRIPE_CONNECTED,
    [sortingDirections.DESCENDING]: `-${columnNames.IS_STRIPE_CONNECTED}`
  },
  [columnNames.PHONE_NUMBER]: {
    [sortingDirections.ASCENDING]: `${columnNames.PHONE_NUMBER}`,
    [sortingDirections.DESCENDING]: `-${columnNames.PHONE_NUMBER}`
  },
  [columnNames.POSITION]: {
    [sortingDirections.ASCENDING]: `${columnNames.POSITION}`,
    [sortingDirections.DESCENDING]: `-${columnNames.POSITION}`
  },
  [columnNames.PRO_ID]: {
    [sortingDirections.ASCENDING]: `${columnNames.PRO_ID},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.PRO_ID},-${columnNames.ID}`
  },
  [columnNames.PRO_GIVEN_NAME]: {
    [sortingDirections.ASCENDING]: `${columnNames.PRO_GIVEN_NAME},${columnNames.PRO_FAMILY_NAME},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.PRO_GIVEN_NAME},-${columnNames.PRO_FAMILY_NAME},-${columnNames.ID}`
  },
  [columnNames.STAGE]: {
    [sortingDirections.ASCENDING]: `${columnNames.STAGE},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.STAGE},-${columnNames.ID}`
  },
  [columnNames.SOURCE_USER_ID]: {
    [sortingDirections.ASCENDING]: columnNames.SOURCE_USER_ID,
    [sortingDirections.DESCENDING]: `-${columnNames.SOURCE_USER_ID}`
  },
  [columnNames.SOURCE_USER_NAME]: {
    [sortingDirections.ASCENDING]: columnNames.SOURCE_USER_NAME,
    [sortingDirections.DESCENDING]: `-${columnNames.SOURCE_USER_NAME}`
  },
  [columnNames.SOURCE_USER_GROUP_ID]: {
    [sortingDirections.ASCENDING]: columnNames.SOURCE_USER_GROUP_ID,
    [sortingDirections.DESCENDING]: `-${columnNames.SOURCE_USER_GROUP_ID}`
  },
  [columnNames.TAG_LIST]: {
    [sortingDirections.ASCENDING]: `${columnNames.TAG_NAME},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `-${columnNames.TAG_NAME},-${columnNames.ID}`
  },
  [columnNames.TARGET_JOB_COUNT_ACTIVE]: {
    [sortingDirections.ASCENDING]: columnNames.TARGET_JOB_COUNT_ACTIVE,
    [sortingDirections.DESCENDING]: `-${columnNames.TARGET_JOB_COUNT_ACTIVE}`
  },
  [columnNames.TARGET_JOB_COUNT_COMPLETED]: {
    [sortingDirections.ASCENDING]: columnNames.TARGET_JOB_COUNT_COMPLETED,
    [sortingDirections.DESCENDING]: `-${columnNames.TARGET_JOB_COUNT_COMPLETED}`
  },
  [columnNames.TARGET_USER_NAME]: {
    [sortingDirections.ASCENDING]: columnNames.TARGET_USER_NAME,
    [sortingDirections.DESCENDING]: `-${columnNames.TARGET_USER_NAME}`
  },
  [columnNames.TARGET_USER_ID]: {
    [sortingDirections.ASCENDING]: columnNames.TARGET_USER_ID,
    [sortingDirections.DESCENDING]: `-${columnNames.TARGET_USER_ID}`
  },
  [columnNames.TITLE]: {
    [sortingDirections.ASCENDING]: columnNames.TITLE,
    [sortingDirections.DESCENDING]: `-${columnNames.TITLE}`
  },
  [columnNames.TOTAL_PAID]: {
    [sortingDirections.ASCENDING]: columnNames.TOTAL_PAID,
    [sortingDirections.DESCENDING]: `-${columnNames.TOTAL_PAID}`
  },
  [columnNames.GIVEN_NAME]: {
    [sortingDirections.ASCENDING]: `${columnNames.GIVEN_NAME},${columnNames.FAMILY_NAME}`,
    [sortingDirections.DESCENDING]: `-${columnNames.GIVEN_NAME},-${columnNames.FAMILY_NAME}`
  },
  [columnNames.WARNING]: {
    [sortingDirections.ASCENDING]: `-${columnNames.IS_WARNING_CHECKED},${columnNames.WARNING},${columnNames.ID}`,
    [sortingDirections.DESCENDING]: `${columnNames.IS_WARNING_CHECKED},-${columnNames.WARNING},-${columnNames.ID}`
  }
};
