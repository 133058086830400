import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexOne = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px 0 50px;
`;

export const Text = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;

  color: ${styles.colors.LIGHT_GRAY};
`;

export const BlackSpan = styled.span`
  color: ${styles.colors.LIGHT_GRAY};
`;
