import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { useClickOutside, useRef, useState, useCallback } from 'hooks';
import { Picker, Popover, Swatch } from './styled';

export const PopoverColorPicker = ({ color, onChange, disabled, hasValidColor, style = {} }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Picker>
      <Swatch
        style={{
          ...style,
          ...(hasValidColor ? { backgroundColor: color } : {})
        }}
        hasValidColor={hasValidColor}
        disabled={disabled}
        onClick={disabled ? null : () => toggle(true)}>
        <div />
      </Swatch>
      {isOpen && (
        <Popover ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </Popover>
      )}
    </Picker>
  );
};
