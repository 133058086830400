import { invoiceTypes } from 'common/enums/invoice';

export const getInvoiceType = ({ isDeposit, is_final_payment, is_quotation }) => {
  if (is_quotation) {
    return invoiceTypes.QUOTATION;
  }
  if (isDeposit) {
    return invoiceTypes.DEPOSIT;
  }
  return is_final_payment ? invoiceTypes.FINAL : invoiceTypes.PARTIAL;
};

export const getInvoiceTypeParam = ({ paramName = 'label', ...params }) => getInvoiceType(params)[paramName];
