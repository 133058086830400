import React from 'react';
import { useComponentVisible, useEffect, useMemo, useRef, useState } from 'hooks';

import { Icon } from 'components';

import {
  DropdownContainer,
  DropdownValueContainer,
  DropdownValue,
  DropdownMenu,
  DropdownMenuHeader,
  DropdownMenuDescription,
  SearchWrapper,
  SearchInput,
  DropdownMenuItem,
  DropdownItemText,
  DropdownItems
} from './styled';

const Dropdown = ({
  hasHeaderHidden,
  value: defaultValue,
  options = [],
  isOpen = false,
  centered,
  placeholder = 'Select Stage',
  placeholderColor,
  valuePlaceholder,
  withPlaceholderPrefix,
  onChange,
  disabled = false,
  hasDisabledOpacity,
  valueColor,
  containerBackground,
  type = 'rounded',
  width = 166,
  fill,
  withSearch = false,
  onSearch = null,
  valueRef = null,
  dropdownMenuStyle,
  scrollToItemBlock = 'end',
  style = {},
  searchPlaceholder,
  maxHeight
}) => {
  const [value, setValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState('');
  const itemsRef = useRef([]);

  useEffect(() => {
    if (defaultValue?.id === '') {
      onChangeValue({ id: null })();
    } else {
      setValue(defaultValue);
    }
  }, [defaultValue?.id]);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(isOpen);

  useEffect(() => {
    if (isComponentVisible && itemsRef?.current[value.id]) {
      itemsRef.current[value.id].scrollIntoView({
        block: scrollToItemBlock
      });
    }
  }, [isComponentVisible, value.id, scrollToItemBlock]);

  const toggle = () => setIsComponentVisible((prevVisible) => !prevVisible);

  const onChangeValue = (value) => () => {
    setValue(value);
    onChange(value?.id);
    if (isComponentVisible) {
      toggle();
    }
  };

  const onChangeSearchValue = (e) => {
    const changedValue = e.target.value;
    setSearchValue(changedValue);
    onSearch && onSearch(changedValue);
  };

  const filteredValue = useMemo(() => options.find((item) => item.id === value?.id)?.name, [options, value]);
  const plceholderPrefixValue =
    withPlaceholderPrefix && filteredValue ? `${placeholder} ${filteredValue}` : valuePlaceholder;
  const selectedValuePlaceholder = filteredValue || plceholderPrefixValue || placeholder;

  return (
    <DropdownContainer ref={ref} disabled={disabled}>
      <DropdownValueContainer
        ref={valueRef}
        fill={fill ? fill.toString() : undefined}
        background={containerBackground}
        onClick={disabled ? null : toggle}
        disabled={disabled}
        type={type}
        width={width}
        style={style}>
        <DropdownValue color={value?.id || value?.id === 0 ? valueColor : placeholderColor} disabled={disabled}>
          {selectedValuePlaceholder}
        </DropdownValue>
        {(!disabled || hasDisabledOpacity) && <Icon name="arrowFillBottom" backgroundSize={17} />}
      </DropdownValueContainer>
      {isComponentVisible && (
        <DropdownMenu type={type} width={width} style={dropdownMenuStyle} hasHeaderHidden={hasHeaderHidden}>
          {!withSearch && !hasHeaderHidden && (
            <DropdownMenuHeader>
              <DropdownMenuDescription>{placeholder}</DropdownMenuDescription>

              <Icon name="closeFill2" onClick={toggle} size={16} />
            </DropdownMenuHeader>
          )}
          {withSearch && (
            <SearchWrapper>
              <SearchInput placeholder={searchPlaceholder} value={searchValue} onChange={onChangeSearchValue} />
            </SearchWrapper>
          )}
          <DropdownItems maxHeight={maxHeight} width={width}>
            {options.map((option) => (
              <DropdownMenuItem
                disabled={option.disabled || option.id === value.id}
                ref={(el) => (itemsRef.current[option.id] = el)}
                key={option.id}
                onClick={option.disabled ? null : onChangeValue(option)}>
                <DropdownItemText centered={centered} selected={option.id === value.id}>
                  {option.name}
                </DropdownItemText>
                {option.id === value.id && <Icon name="okOutlined" size={16} />}
              </DropdownMenuItem>
            ))}
          </DropdownItems>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
