import React from 'react';
import styles from 'constants/styles';

const IconBellFill = ({ width = 15, height = 18, color = styles.colors.WHITE }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7117 12.8311C14.0648 12.0959 12.8544 10.9898 12.8544 7.36667C12.8544 4.61479 11.0303 2.41188 8.57076 1.87142V1.13333C8.57076 0.507521 8.09129 0 7.5 0C6.90871 0 6.42924 0.507521 6.42924 1.13333V1.87142C3.96965 2.41188 2.14555 4.61479 2.14555 7.36667C2.14555 10.9898 0.935176 12.0959 0.288303 12.8311C0.0874113 13.0595 -0.00165095 13.3326 2.31491e-05 13.6C0.00370617 14.1808 0.43462 14.7333 1.0748 14.7333H13.9252C14.5654 14.7333 14.9966 14.1808 15 13.6C15.0016 13.3326 14.9126 13.0592 14.7117 12.8311Z"
        fill={color}
      />
      <path
        d="M7.49927 17.8668C8.68185 17.8668 9.64111 16.8521 9.64111 15.6001H5.35742C5.35742 16.8521 6.31668 17.8668 7.49927 17.8668Z"
        fill={color}
      />
    </svg>
  );
};

export default IconBellFill;
