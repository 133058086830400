import React from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';

import { HeaderCell, Cell } from './components';
import {
  Table as UITable,
  TableHead as UITableHead,
  TableRow as UITableRow,
  HeadCell as UIHeadCell,
  TableBody as UITableBody,
  TableCell as UITableCell,
  HeaderCellInnerWrapper
} from './styled';

const Table = ({
  columns,
  data,
  hasRowClickDisabled,
  onSort,
  onRowClick,
  onRowContextClick,
  searchTerm,
  sortingDirection,
  sortingColumnName,
  noClick
}) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn: {
      onSort,
      searchTerm,
      Header: ({ column }) => {
        return (
          <HeaderCell
            columnName={column.id}
            title={column.title}
            hasSorting={column.hasSorting}
            sortingDirection={sortingDirection}
            onSort={onSort}
            sortingColumnName={sortingColumnName}
            maxWidth={column.maxWidth}
          />
        );
      },
      Cell: ({ value, column: { cellType, searchTerm } }) => {
        return <Cell type={value?.cellType ?? cellType} value={value} searchTerm={searchTerm} />;
      }
    }
  });

  const handleRowClick = (row) => (event) => {
    const isDisabled = Boolean(hasRowClickDisabled && hasRowClickDisabled(row));

    if (isDisabled) {
      return;
    }

    onRowClick(row)(event);
  };

  return (
    <UITable {...getTableProps()}>
      <UITableHead>
        <UITableRow>
          {headers.map((column, idx) => (
            <UIHeadCell
              {...column.getHeaderProps()}
              key={idx}
              // clickable={column.hasSorting}
              // onClick={column.hasSorting ? onSort : null}
              minWidth={column.minWidth}
              {...(column.width ? { width: column.width } : {})}>
              <HeaderCellInnerWrapper>{column.render('Header')}</HeaderCellInnerWrapper>
            </UIHeadCell>
          ))}
        </UITableRow>
      </UITableHead>

      <UITableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          const clickProps = noClick
            ? {}
            : {
                onClick: handleRowClick(row.original),
                onContextMenu: onRowContextClick ? onRowContextClick(row.original) : null
              };
          prepareRow(row);
          const isDisabled = Boolean(hasRowClickDisabled && hasRowClickDisabled(row.original));
          return (
            <UITableRow
              {...row.getRowProps()}
              {...clickProps}
              disabled={isDisabled}
              hasRowHighlight={row.original?.hasRowHighlight}>
              {row.cells.map((cell) => (
                <UITableCell {...cell.getCellProps()} style={cell.value?.style}>
                  {cell.render('Cell')}
                </UITableCell>
              ))}
            </UITableRow>
          );
        })}
      </UITableBody>
    </UITable>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default Table;
