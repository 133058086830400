import React from 'react';
import { useController } from 'react-hook-form';

import Form from '../../Form';

const Radio = ({ label, name, control, rules = {}, error = {}, options, onCustomChange, ...props }) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  });

  const onRadioChange = (event) => {
    onChange(event);
    if (onCustomChange) {
      onCustomChange(event.target.value);
    }
  };

  // Update Form.Radio with React.forwardRef in case of "ref" needed
  return <Form.Radio label={label} options={options} value={value} onChange={onRadioChange} error={error} {...props} />;
};

export default Radio;
