import React from 'react';
import styles from 'constants/styles';
const IconInfoOutlined = ({ size = 17, color = styles.colors.DARK_3 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="8.5" fill={color} />
      <g clipPath="url(#clip0_11174_77698)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.4987 5.66732C9.2811 5.66732 9.91536 5.06814 9.91536 4.27325C9.91536 3.47836 9.2811 2.83398 8.49869 2.83398C7.7163 2.83398 7.08203 3.47836 7.08203 4.27325C7.08203 5.06814 7.7163 5.66732 8.4987 5.66732ZM8.49889 6.66912C7.91659 6.66912 7.44454 7.1487 7.44454 7.74028V13.0961C7.44454 13.6877 7.91659 14.1673 8.49889 14.1673C9.08119 14.1673 9.55324 13.6877 9.55324 13.0961V7.74029C9.55324 7.1487 9.08119 6.66912 8.49889 6.66912Z"
          fill={styles.colors.WHITE}
        />
      </g>
      <defs>
        <clipPath id="clip0_11174_77698">
          <rect width="2.83333" height="11.3333" fill="white" transform="translate(7.08203 2.83398)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconInfoOutlined;
