import React from 'react';

import Messages from './Messages';
import InputToolBar from './InputToolBar';
import MessageBubble from './MessageBubble';

import { getMessagesOverwrites } from './helpers/functions';

import { ChatFlexWrapper } from './styled';

const Chat = ({
  currentUser,
  isMessagesLoading,
  isInputToolbarDisabled,
  maxLength,
  messages,
  keyExtractor,
  onMessagesEnd,
  placeholder,
  onMessageSend,
  overwriteComponents = {}
}) => {
  const toolBarComponents = {
    actions: overwriteComponents.actions,
    sendButton: overwriteComponents.sendButton
  };
  const messagesComponents = getMessagesOverwrites(overwriteComponents);

  return (
    <ChatFlexWrapper>
      <Messages
        currentUser={currentUser}
        isMessagesLoading={isMessagesLoading}
        messages={messages}
        keyExtractor={keyExtractor}
        onMessagesEnd={onMessagesEnd}
        overwriteComponents={messagesComponents}
      />
      {!isInputToolbarDisabled && (
        <InputToolBar
          placeholder={placeholder}
          onMessageSend={onMessageSend}
          overwriteComponents={toolBarComponents}
          maxLength={maxLength}
        />
      )}
    </ChatFlexWrapper>
  );
};

export { Chat, MessageBubble };
