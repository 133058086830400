import React from 'react';
import styles from 'constants/styles';

const IconOkOutlined = ({ size = 24, color = styles.colors.DARK_GREEN_2, strokeWidth }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
      stroke={color}
      {...(strokeWidth ? { strokeWidth } : {})}
    />
    <path
      d="M6.85742 12L10.286 15.4286L17.1431 8.57141"
      stroke={color}
      strokeWidth={strokeWidth || '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconOkOutlined;
