import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'components';
import { updateSupportMessage, updateTaskMessage } from 'store/actions';
import { selectUpdateMessageLoading } from 'store/reducers/supportChat';
import { selectTaskUpdateMessageLoading } from 'store/reducers/taskChat';
import { getMessageAuthor } from 'containers/Chat/helpers';
import { MessageFormContainer, MessageInput, MessageButtons, MessageAuthor } from './styled';

const EditChatForm = ({ message, updateSupportMessage, updateTaskMessage, onHideForm, loading, userID }) => {
  const messageRef = createRef();
  const author = getMessageAuthor(message.author_id, message.author_name);
  const [description, setDescription] = useState(message.text);

  const onConfirm = () => {
    if (userID) {
      updateSupportMessage({ message, description, userID, onSuccess: onHideForm });
    } else {
      updateTaskMessage({ message, description, onSuccess: onHideForm });
    }
  };
  const handleCloseEditForm = () => onHideForm();
  const textAreaChange = (text) => {
    text.style.height = '16px';
    text.style.height = text.scrollHeight + 'px';
  };
  useEffect(() => {
    textAreaChange(messageRef.current);
  }, []);
  const handleMessageChange = (e) => {
    setDescription(e.target.value);
    textAreaChange(e.target);
  };

  return (
    <MessageFormContainer>
      <MessageAuthor>{author}</MessageAuthor>

      <MessageInput ref={messageRef} value={description} onChange={handleMessageChange} />

      <MessageButtons>
        <Button title="Cancel" onClick={handleCloseEditForm} variant="primary" />
        <Button title="Save" onClick={onConfirm} disabled={loading || !description.length} loading={loading} />
      </MessageButtons>
    </MessageFormContainer>
  );
};

const mapStateToProps = (state, { userID }) => ({
  loading: userID ? selectUpdateMessageLoading(state) : selectTaskUpdateMessageLoading(state)
});

const mapDispatchToProps = {
  updateSupportMessage,
  updateTaskMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChatForm);
