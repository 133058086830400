const styles = {
  colors: {
    // base colors
    BLACK: '#282A2B',
    SHADOW_BLACK: '#282A2B66',
    DARK_BLUE: '#273444',
    GRAY: '#97A0A0',
    LIGHT_GRAY: '#D6D9E5',
    LIGHT_GRAY_2: '#F7F8FB',
    LIGHT_GRAY_3: '#F1F1F1',
    LIGHT_GRAY_4: '#586663',
    LIGHT_GREEN: '#37AD8C',
    LIGHT_ORANGE: '#F2AA49',
    WHITE: '#FFFFFF',
    SHADOW_WHITE: '#FFFFFF66',
    DARK_1: '#20292D',
    DARK_2: '#2C3537',
    DARK_3: '#262E30',
    DARK_4: '#434B4E',
    DARK_GREEN: '#66FFE3',
    DARK_GREEN_2: '#379584',
    DARK_GREEN_3: '#47BFAA',
    DARK_GREY: '#97A0A0',
    DARK_GREY_2: '#676D70',
    DARK_GREY_3: '#353D41',
    DARK_GREY_4: '#D6E1E1',
    DARK_GREY_7: '#2E4447',
    DARK_GREY_8: '#282e30',
    DARK_RED: '#FF8982',
    DARK_YELLOW: '#FFC25F',
    DARK_BLUE_3: '#6B7EFF',
    DARK_BLUE_4: '#004871',
    DARK_VIOLET: '#B582FF',

    // accent colors
    GREEN: '#00AE7D',
    ASSIGNMENT_GREEN: '#379584',
    RED: '#E0125E',
    YELLOW: '#FD9D00',
    YELLOW_HIGHLIGHT: '#FFFAF2',
    BRAND_DARK: '#05004E',

    // rare colors (to get rid of them):
    GREEN_HOVER: '#26886C',
    GRAYSCALE: '#F7F8FC',
    LIGHT_RED: '#FCF4F7',
    DARK_BLUE_2: '#323CBF',
    LIGHT_RED_2: '#C81859',
    DISABLED_RED: '#EF88AF',
    GRAY_WHITE: '#F5F5F5',
    BLACK_MARKER: '#393939',
    MODAL_BG: '#B5B5B580'
  },
  boxShadows: {
    THIN_DARK: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    THIN_DARK_2:
      '0px 3px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 3px 8px 0px rgba(0, 0, 0, 0.15)',
    THIN_DARK_3: '0px 0px 10px #12171a',
    THIN_DARK_4: '0px 0px 10px rgba(18, 23, 26, 0.2)',
    DARK: '0px 0px 10px rgba(0, 0, 0, 0.25)'
  },
  filter: {
    THIN_DARK: 'drop-shadow(0px 0px 10px #12171a)'
  },
  layout: {
    headerHeight: 56,
    navigationWidth: 192,
    paginationContainerHeight: 40,
    filtersHeaderHeight: 50,
    tabsHeaderHeight: 50
  }
};

export const dark = {
  ...styles,
  colors: {
    ...styles.colors,
    SECONDARY: styles.colors.DARK_2,
    ACCENT_1: styles.colors.DARK_GREEN,
    WHITE: styles.colors.WHITE,
    BORDER_1: styles.colors.DARK_3,
    TEXT: styles.colors.WHITE,
    TEXT_DISABLED: styles.colors.DARK_GREY,

    SWITCH_BTN: styles.colors.DARK_1,
    SWITCH_BG_CHECKED: styles.colors.DARK_GREEN_2,
    SWITCH_BG_UNCHECKED: styles.colors.DARK_GREY,

    CHECKBOX_BG: styles.colors.DARK_4,

    INFO_BG: styles.colors.DARK_4,
    INFO_ICON: styles.colors.WHITE,
    INFO_BG_HOVER: styles.colors.DARK_4,

    NOTIFICATION_HOVER: styles.colors.DARK_GREY_3,
    NOTIFICATION_BG: styles.colors.DARK_2,

    NOTIFICATION_COLOR_TRANSPARENT: styles.colors.WHITE,
    NOTIFICATION_DIVIDER: styles.colors.DARK_4,
    NOTIFICATION_TARGET: styles.colors.WHITE,

    TOOLTIP_BG: styles.colors.WHITE,
    TOOLTIP_TEXT: styles.colors.DARK_1,
    SIDEBAR_ACTIVE_SECTION: styles.colors.DARK_GREY_3,

    MEDIA_UPLOADER: styles.colors.DARK_GREY_3,
    MEDIA_UPLOADER_BORDER: styles.colors.DARK_GREY_2,
    ICON_BTN: styles.colors.DARK_4,
    ICON_BTN_HOVER: styles.colors.DARK_GREY_2,

    ICON_BTN_PRIMARY: styles.colors.DARK_GREEN_2,
    ICON_BTN_PRIMARY_HOVER: styles.colors.DARK_GREEN_3,

    RADIO_TEXT: styles.colors.DARK_GREY_2,
    RADIO_TEXT_CHECK: styles.colors.WHITE,

    INPUT_BG: styles.colors.DARK_GREY_3,
    INPUT_FOCUS_BG: styles.colors.DARK_4,
    INPUT_COLOR: styles.colors.WHITE,
    INPUT_PLACEHOLDER: styles.colors.DARK_GREY_2,
    INPUT_ERROR: styles.colors.DARK_RED,

    SELECT_ITEM: styles.colors.DARK_4,
    SELECT_ITEM_TEXT: styles.colors.WHITE,
    DROPDOWN: styles.colors.DARK_GREY_3,
    DROPDOWN_MENU: styles.colors.DARK_GREY_3,
    DROPDOWN_ITEM_BORDER_HOVER: styles.colors.DARK_4,
    DROPDOWN_VALUE: styles.colors.DARK_4,
    DROPDOWN_HEADER: styles.colors.DARK_2,

    MULTI_VALUE_LABEL: styles.colors.WHITE,

    FOOTER: styles.colors.DARK_2,

    PAGE_NUMBER: styles.colors.WHITE,

    DISABLED_DEFAULT_ICON_BUTTON: styles.colors.DARK_GREY,
    DEFAULT_ICON_BUTTON: styles.colors.WHITE,
    AVATAR_BG: styles.colors.DARK_4,

    TOP_NAVBAR: styles.colors.DARK_2,
    TOP_NAVBAR_BORDER: styles.colors.DARK_3,

    MENU_ITEM_TEXT: styles.colors.WHITE,

    BREADCRUMB: styles.colors.WHITE,
    BREADCRUMB_DISABLED: styles.colors.DARK_GREY,

    DISABLED_ICON: styles.colors.WHITE,

    PAGINATION_TEXT: styles.colors.WHITE,

    TABLE_BORDER: styles.colors.DARK_2,
    TABLE_BG: styles.colors.DARK_1,
    TABLE_BG_HOVER: styles.colors.DARK_GREY_3,
    TABLE_BG_HIGHLIGHT: styles.colors.DARK_3,
    TABLE_TEXT: styles.colors.WHITE,
    TABS_TEXT: styles.colors.WHITE,

    MESSAGE_DATE: styles.colors.GRAY,
    CHAT_BORDER: styles.colors.DARK_GREY_3,
    CHAT_BG: styles.colors.DARK_1,
    TABS_BG: styles.colors.DARK_3,
    VIDEO_CALL_TEXT_CURRENT: styles.colors.DARK,
    VIDEO_CALL_TEXT: styles.colors.WHITE,

    MESSAGE_OPS: styles.colors.WHITE,
    MESSAGE_PRO_AGENT: styles.colors.DARK_2,
    MESSAGE_HO: styles.colors.DARK_BLUE_4,
    MODAL: styles.colors.DARK_1,

    DATE_MODAL_TITLE: styles.colors.WHITE,
    DATE_MODAL_TITLE_BG: styles.colors.DARK_3,
    DATE_MODAL_BG: styles.colors.DARK_2,
    DATE_MODAL_WEEK: styles.colors.WHITE,
    DATE_MODAL_DIVIDER: styles.colors.DARK_4,

    BUTTON_TEXT: styles.colors.WHITE,

    INVOICE_TEXT: styles.colors.WHITE,
    INVOICE_TITLE_NOTE: styles.colors.YELLOW,
    INVOICE_BG: styles.colors.DARK_1,

    HIGHLIGHTED_ROW: styles.colors.DARK_3,

    SPINNER_BG: styles.colors.DARK_1,
    SPINNER_TEXT: styles.colors.WHITE,

    NOTIFICATIONS: styles.colors.DARK_2,
    NOTIFICATIONS_HEADER: styles.colors.DARK_3,

    NOTES_TEXTAREA: styles.colors.DARK_GREY_3,
    BODY: styles.colors.DARK_2
  }
};

export const light = {
  ...styles,
  colors: {
    ...styles.colors,
    SECONDARY: styles.colors.BRAND_DARK,
    ACCENT_1: styles.colors.YELLOW,
    WHITE: styles.colors.WHITE,
    BORDER_1: styles.colors.LIGHT_GRAY,
    TEXT: styles.colors.BLACK,

    SWITCH_BTN: styles.colors.WHITE,
    SWITCH_BG_CHECKED: styles.colors.LIGHT_GREEN,
    SWITCH_BG_UNCHECKED: styles.colors.LIGHT_ORANGE,

    CHECKBOX_BG: styles.colors.WHITE,

    INFO_BG: styles.colors.LIGHT_GRAY_2,
    INFO_ICON: styles.colors.GRAY,
    INFO_BG_HOVER: styles.colors.LIGHT_GRAY,

    NOTIFICATION_HOVER: styles.colors.LIGHT_GRAY_2,
    NOTIFICATION_BG: styles.colors.BLACK,

    NOTIFICATION_COLOR_TRANSPARENT: styles.colors.BLACK,
    NOTIFICATION_DIVIDER: styles.colors.LIGHT_GRAY_2,
    NOTIFICATION_TARGET: styles.colors.BLACK,

    TOOLTIP_BG: styles.colors.BLACK,
    TOOLTIP_TEXT: styles.colors.WHITE,
    SIDEBAR_ACTIVE_SECTION: styles.colors.WHITE + 33,

    MEDIA_UPLOADER: 'none',
    MEDIA_UPLOADER_BORDER: styles.colors.DARK_GREY_2,
    ICON_BTN: styles.colors.LIGHT_GRAY_3,
    ICON_BTN_BG: styles.colors.WHITE,
    ICON_BTN_HOVER: styles.colors.WHITE,

    RADIO_TEXT: styles.colors.DARK_GREY_2,
    RADIO_TEXT_CHECK: styles.colors.GRAY,

    INPUT_BG: styles.colors.LIGHT_GRAY_2,
    INPUT_FOCUS_BG: styles.colors.WHITE,
    INPUT_COLOR: styles.colors.BLACK,
    INPUT_PLACEHOLDER: styles.colors.LIGHT_GRAY,
    INPUT_ERROR: styles.colors.RED,

    SELECT_ITEM: styles.colors.LIGHT_GRAY_2,
    SELECT_ITEM_TEXT: styles.colors.BLACK,
    DROPDOWN: styles.colors.LIGHT_GRAY_2,
    DROPDOWN_MENU: styles.colors.WHITE,
    DROPDOWN_ITEM_BORDER_HOVER: styles.colors.WHITE,
    DROPDOWN_VALUE: styles.colors.WHITE,
    DROPDOWN_HEADER: styles.colors.WHITE,

    MULTI_VALUE_LABEL: styles.colors.BLACK,

    FOOTER: styles.colors.WHITE,

    PAGE_NUMBER: styles.colors.BLACK,

    ICON_BTN_PRIMARY: styles.colors.RED,

    DISABLED_DEFAULT_ICON_BUTTON: styles.colors.BLACK,
    DEFAULT_ICON_BUTTON: styles.colors.BLACK,
    AVATAR_BG: styles.colors.GRAY_WHITE,

    TOP_NAVBAR: styles.colors.WHITE,
    TOP_NAVBAR_BORDER: styles.colors.WHITE,

    MENU_ITEM_TEXT: styles.colors.BLACK,

    BREADCRUMB: styles.colors.BLACK,
    BREADCRUMB_DISABLED: styles.colors.DARK_GREY,

    DISABLED_ICON: styles.colors.BLACK,

    PAGINATION_TEXT: styles.colors.BLACK,

    TABLE_BORDER: styles.colors.LIGHT_GRAY,
    TABLE_BG: styles.colors.WHITE,
    TABLE_BG_HOVER: styles.colors.LIGHT_GRAY_2,
    TABLE_BG_HIGHLIGHT: styles.colors.YELLOW_HIGHLIGHT,
    TABLE_TEXT: styles.colors.BLACK,
    TABS_TEXT: styles.colors.BLACK,

    MESSAGE_DATE: styles.colors.BLACK,
    CHAT_BORDER: styles.colors.LIGHT_GRAY,
    CHAT_BG: styles.colors.WHITE,
    TABS_BG: styles.colors.WHITE,
    VIDEO_CALL_TEXT_CURRENT: styles.colors.BLACK,
    VIDEO_CALL_TEXT: styles.colors.BLACK,

    MESSAGE_OPS: styles.colors.DARK_1,
    MESSAGE_PRO_AGENT: styles.colors.LIGHT_GREEN,
    MESSAGE_HO: styles.colors.DARK_BLUE_4,
    MODAL: styles.colors.WHITE,

    DATE_MODAL_TITLE: styles.colors.BLACK,
    DATE_MODAL_TITLE_BG: styles.colors.WHITE,
    DATE_MODAL_BG: styles.colors.WHITE,
    DATE_MODAL_WEEK: styles.colors.BLACK,
    DATE_MODAL_DIVIDER: styles.colors.LIGHT_GRAY,

    BUTTON_TEXT: styles.colors.BLACK,
    INVOICE_TEXT: styles.colors.BLACK,
    INVOICE_BG: styles.colors.WHITE,

    HIGHLIGHTED_ROW: styles.colors.LIGHT_GRAY_2,
    SPINNER_BG: styles.colors.WHITE,
    SPINNER_TEXT: styles.colors.BLACK,

    NOTIFICATIONS: styles.colors.WHITE,
    NOTIFICATIONS_HEADER: styles.colors.WHITE,
    NOTES_TEXTAREA: styles.colors.WHITE,
    BODY: styles.colors.WHITE
  }
};

export const amplifyTheme = {
  name: 'gryffindor-theme',
  tokens: {
    components: {
      authenticator: {
        maxWidth: { value: '100%' }
      }
    },
    colors: {
      font: {
        error: {
          value: styles.colors.RED
        }
      },
      red: {
        20: { value: styles.colors.WHITE }
      },
      overlay: {
        50: { value: styles.colors.WHITE + '20' }
      },
      brand: {
        primary: {
          10: { value: styles.colors.YELLOW + '20' },
          20: { value: styles.colors.YELLOW + '20' },
          60: { value: styles.colors.YELLOW },
          80: { value: styles.colors.YELLOW },
          90: { value: styles.colors.YELLOW + 'CC' },
          100: { value: styles.colors.YELLOW + 'CC' }
        }
      }
    }
  }
};

export const avatarTableCellStyle = {
  paddingTop: 14,
  paddingBottom: 14,
  paddingLeft: 16,
  paddingRight: 16
};

export const twoStoryLabelTextStyle = {
  paddingTop: 7,
  paddingBottom: 7
};

export default styles;
