import React from 'react';
import styles from 'constants/styles';

const IconEmptyBox = ({ size = 160, color = styles.colors.LIGHT_GRAY_2 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="80" cy="80" r="80" fill={color} />
      <path d="M80.0005 58.873L76.4066 82.5542L80.0005 124L115.721 112.159V70.7137L80.0005 58.873Z" fill="#999999" />
      <path d="M44.2799 70.7137V112.159L80.0002 124V58.873L44.2799 70.7137Z" fill="#C1C1C1" />
      <path d="M77.3047 32H82.6953V50H77.3047V32Z" fill="#D2D2D2" />
      <path d="M51.9541 40.2368L56.6224 37.5415L65.6224 53.1295L60.9541 55.8248L51.9541 40.2368Z" fill="#D2D2D2" />
      <path d="M94.3761 53.1313L103.376 37.5433L108.044 40.2386L99.0444 55.8267L94.3761 53.1313Z" fill="#D2D2D2" />
      <path d="M69.7203 98.8825L80 82.5539L44.2797 70.7134L34 87.0419L69.7203 98.8825Z" fill="#F7F8FB" />
      <path d="M90.2797 98.8825L80 82.5539L115.72 70.7134L126 87.0419L90.2797 98.8825Z" fill="#D2D2D2" />
    </svg>
  );
};

export default IconEmptyBox;
