import styled from 'styled-components';
import styles from 'constants/styles';

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: ${({ margin }) => margin || '4px 0 0 12px'};
  border-radius: 50%;
  background: ${({ backgroundColor }) => backgroundColor || styles.colors.WHITE};
  box-shadow: ${({ boxShadow }) => boxShadow || '0px 0px 5px rgba(0, 0, 0, 0.25)'};

  &:hover:not([disabled]) {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    ${({ isHoverEffect }) =>
      isHoverEffect &&
      `
      div svg path {
        fill: ${styles.colors.RED};
      }
      span {
        background: ${styles.colors.RED};
      }
    `}
  }
`;
