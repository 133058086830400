import { useMemo } from 'react';
import { useNavigate, useLocation, useParams, useSearchParams as useQueryParams } from 'react-router-dom';

const useRouter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [queryParams, setQueryParams] = useQueryParams();

  return useMemo(
    () => ({
      navigate,
      replace: (pathname) => navigate(pathname, { replace: true }),
      pathname: location.pathname,
      queryParams: {
        getAll() {
          return Object.fromEntries(queryParams);
        },
        get(param) {
          return queryParams.get(param);
        },
        set(key, value, options = { replace: true }) {
          const updatedQueryParams = queryParams;
          updatedQueryParams.set(key, value);
          setQueryParams(queryParams, options);
        },
        setAll(queryParams, options = { replace: true }) {
          setQueryParams(queryParams, options);
        }
      },
      params,
      location
    }),
    [navigate, params, location, queryParams, setQueryParams]
  );
};

export default useRouter;
