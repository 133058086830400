import { jobTypesByTab } from 'common/enums/job';

export const getEmptyStateText = ({ customerId, type, hasJobs }) => {
  if (!hasJobs) {
    return customerId ? 'The Homeowner \nhasn’t created any job, yet' : 'The Pro \nhasn’t been assigned to any job yet';
  }
  const name = customerId ? 'Homeowner' : 'Pro';

  let listName = '';
  if (type === jobTypesByTab.ACTIVE) {
    listName = 'active';
  }
  if (type === jobTypesByTab.CANCELLED) {
    listName = 'canceled';
  }
  if (type === jobTypesByTab.PAID) {
    listName = 'paid';
  }
  return `The ${name} \ndoesn’t have ${listName} jobs`;
};
