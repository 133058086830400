import React from 'react';
import PropTypes from 'prop-types';

import { Placeholder, LoadingSpinner } from 'components';
import { ContentWrapper, FlexOne } from './styled';
import Pagination from './Pagination';

const PaginationFullScreen = ({
  children,
  count,
  currentPage,
  length,
  loading,
  onPageChange,
  searchTerm,
  hasFilters,
  hasError,
  hasEmptyPagination,
  hasNavigationTabs,
  pageSize
}) => {
  return (
    <ContentWrapper hasFilters={hasFilters} hasNavigationTabs={hasNavigationTabs}>
      <FlexOne hasFilters={hasFilters} hasNavigationTabs={hasNavigationTabs}>
        {loading ? (
          <LoadingSpinner text="Please wait" type="logo" />
        ) : !length ? (
          <Placeholder
            iconName="emptyList"
            searchWord={searchTerm}
            hasError={hasError}
            hasNavigationTabs={hasNavigationTabs}
          />
        ) : (
          children
        )}
      </FlexOne>
      {Boolean(length || hasEmptyPagination) && (
        <Pagination
          count={count}
          loading={loading}
          currentPage={currentPage}
          onPageChange={onPageChange}
          pageSize={pageSize}
        />
      )}
    </ContentWrapper>
  );
};

PaginationFullScreen.propTypes = {
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

PaginationFullScreen.defaultProps = {
  pageSize: 50,
  count: 0,
  currentPage: 1,
  onPageChange: () => {},
  length: 0,
  loading: false,
  searchTerm: '',
  hasFilters: false
};

export default PaginationFullScreen;
