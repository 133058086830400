import styled from 'styled-components';
import styles from 'constants/styles';

export const CardBorder = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  border: 1px ${({ dashed }) => (dashed ? 'dashed' : 'solid')} ${styles.colors.DARK_GREY_3};
  box-sizing: border-box;
  border-radius: 10px;

  padding: 20px 16px;

  min-width: 29%;
  width: 30%;
  max-width: 32%;
  min-height: 141px;
  max-height: 185px;

  ${({ isActive = true }) => !isActive && `color: ${styles.colors.LIGHT_GRAY};`}
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TitleText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.WHITE};
  margin: ${({ margin }) => margin || '9px 0 0 0'};
  white-space: pre;

  ${({ withHover }) =>
    withHover
      ? `&:hover {
    color: ${styles.colors.YELLOW};
  }`
      : ''}
`;

export const DropDownWrapper = styled.div`
  margin-left: 15px;
  position: relative;
  right: -7px;
`;

export const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: stretch;
`;

export const FlexColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ControlRow = styled(FlexRow)`
  flex: 0;
  align-items: center;
  justify-conter: end;
`;

export const FieldTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.DARK_GREY};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SummaryTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const FieldValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  margin-top: 9px;
  color: ${({ theme }) => theme.colors.WHITE};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DelimiterBlock = styled.div`
  flex: 1;
  width: 100%;

  margin: 13px 0;
  border-top: 1px solid ${styles.colors.DARK_GREY_3};
`;

export const UsersColumn = styled(FlexColumn)`
  flex: 0;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;
  &:hover {
    color: ${styles.colors.DARK_GREEN_3};
  }
`;
