import styled from 'styled-components';
import styles from 'constants/styles';

export const DropdownContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const Dropdown = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 100%;
  width: ${({ dropdownWidth }) => dropdownWidth || 166}px;
  max-height: 480px;
  background: ${({ theme }) => theme.colors.DROPDOWN_MENU};
  box-shadow: ${({ theme }) => theme.boxShadows.THIN_DARK_3};
`;

export const DropdownMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.GRAY};
  padding: 12px 12px 12px 18px;
  background: ${({ theme }) => theme.colors.DROPDOWN_HEADER};
`;

export const DropdownItems = styled.div`
  overflow-y: overlay;
  max-height: 422px;
  div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
  }
`;

export const DropdownMenuDescription = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 16px 13px 0;

  position: relative;
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER} !important;
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    margin-left: 0px;
    padding-left: 16px;
  }
  &:hover:after {
    background-color: ${({ theme }) => theme.colors.DROPDOWN_ITEM_BORDER_HOVER};
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
  }
  ${({ disabled, theme }) =>
    !disabled &&
    `
    cursor: pointer;
    &:hover {
      background: ${theme.colors.SELECT_ITEM};
    }
  `};
`;
export const DropdownItemText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.SELECT_ITEM_TEXT};
`;

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor || styles.colors.BLACK};
  margin: 2.5px;
`;
