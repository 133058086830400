import React from 'react';

const IconEditFill = props => {
  const { size = 16, color = '#9297A5' } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6414 0.994887C13.9816 0.335038 12.9118 0.335038 12.252 0.994887L5.55344 7.69341C5.50753 7.73932 5.47438 7.79622 5.45708 7.8587L4.5762 11.0389C4.53997 11.1692 4.57679 11.3089 4.67241 11.4046C4.76819 11.5003 4.90781 11.5371 5.0382 11.501L8.21836 10.62C8.28084 10.6027 8.33774 10.5695 8.38365 10.5236L15.082 3.82495C15.7409 3.16466 15.7409 2.09576 15.082 1.43547L14.6414 0.994887ZM13.5389 13.6225C13.5381 13.9685 13.2578 14.2486 12.912 14.2489H2.37779C2.0319 14.2486 1.75148 13.9683 1.75093 13.6222V3.83859C1.75148 3.49272 2.03172 3.21241 2.37765 3.21187H5.71104C6.19457 3.21187 6.5865 2.81993 6.5865 2.3364C6.5865 1.85315 6.19469 1.46094 5.71104 1.46094L2.37676 1.46094C1.06472 1.46242 0.00148579 2.52622 0 3.83826V13.6232C0.00148579 14.9352 1.06528 15.9985 2.37732 16L12.913 16C14.2251 15.9985 15.2883 14.9347 15.2898 13.6226V10.2888C15.2898 9.80525 14.8979 9.41331 14.4143 9.41331C13.9308 9.41331 13.5389 9.80525 13.5389 10.2888V13.6225Z"
        fill={color}
      />
    </svg>
  );
};

export default IconEditFill;
