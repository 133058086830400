import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resetUserChatUnreadMessagesMark, resetUserChatUnreadMessagesDivider } from 'store/actions/supportChat';
import { selectHasUserChatUreadMessages } from 'store/reducers/supportChat';

import {
  changeActiveTab,
  resetTaskChatUnreadMessagesMark,
  resetTaskChatUnreadMessagesDivider
} from 'store/actions/taskChat';
import { selectActiveTabIndex, selectHasTaskChatUreadMessages } from 'store/reducers/taskChat';

import { OUTDATED_TABS } from 'components';
import styles from 'constants/styles';

const tabHeaderStyle = {
  width: 500
};

const ChatTabs = ({
  changeActiveTab,
  customerId,
  providerId,
  headers,
  activeTabIndex,
  hasServiceChatUnreadMessages,
  hasCustomerChatUnreadMessages,
  hasProviderChatUnreadMessages,
  resetTaskChatUnreadMessagesMark,
  resetUserChatUnreadMessagesMark,
  resetTaskChatUnreadMessagesDivider,
  resetUserChatUnreadMessagesDivider,
  children
}) => {
  const onChatTabChange = (index) => {
    changeActiveTab(index);

    if (index === 0) {
      resetTaskChatUnreadMessagesMark();
      resetUserChatUnreadMessagesDivider(customerId);
      providerId && resetUserChatUnreadMessagesDivider(providerId);
    } else if (index === 1) {
      resetUserChatUnreadMessagesMark(customerId);
      resetTaskChatUnreadMessagesDivider();
      providerId && resetUserChatUnreadMessagesDivider(providerId);
    } else if (index === 2) {
      providerId && resetUserChatUnreadMessagesMark(providerId);
      resetTaskChatUnreadMessagesDivider();
      resetUserChatUnreadMessagesDivider(customerId);
    }
  };

  useEffect(() => {
    return () => {
      if (activeTabIndex === 0) {
        resetTaskChatUnreadMessagesDivider();
      } else if (activeTabIndex === 1) {
        resetUserChatUnreadMessagesDivider(customerId);
      } else if (activeTabIndex === 2) {
        resetUserChatUnreadMessagesDivider(providerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OUTDATED_TABS
      headers={headers}
      boldFont
      defaultTabIndex={activeTabIndex}
      markedTabs={[hasServiceChatUnreadMessages, hasCustomerChatUnreadMessages, hasProviderChatUnreadMessages]}
      minHeight={`calc(100vh - ${styles.layout.headerHeight}px)`}
      onTabChange={onChatTabChange}
      headerWrapperStyle={tabHeaderStyle}>
      {children}
    </OUTDATED_TABS>
  );
};

ChatTabs.propTypes = {
  onChangeTab: PropTypes.func,
  children: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired
};

const mapStateToProps = (state, { customerId, providerId }) => ({
  activeTabIndex: selectActiveTabIndex(state),
  hasServiceChatUnreadMessages: selectHasTaskChatUreadMessages(state),
  hasCustomerChatUnreadMessages: selectHasUserChatUreadMessages(state, customerId),
  hasProviderChatUnreadMessages: selectHasUserChatUreadMessages(state, providerId)
});

const mapDispatchToProps = {
  changeActiveTab,
  resetTaskChatUnreadMessagesMark,
  resetUserChatUnreadMessagesMark,
  resetTaskChatUnreadMessagesDivider,
  resetUserChatUnreadMessagesDivider
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTabs);
