import React, { useState, useRef, useEffect } from 'react';

import Avatar from './Avatar';
import MessageText from './MessageText';
import MessageImage from './MessageImage';
import MessageTime from './MessageTime';

import { MessageBubbleContainer, MessageBubbleWrapper, EditedText, MessageFooter } from './styled';
import { ChatActions } from 'pages/ServicePage/containers';
import EditChatForm from 'containers/EditChatForm';
import { Icon } from 'components';

const MessageBubble = (props) => {
  const {
    hasChatActionsDisabled,
    message = {},
    nextMessage = {},
    isCurrentUser = true,
    color = null,
    overwriteComponents = {},
    userID,
    hasRightPosition
  } = props;
  const [showEditingForm, setEditingForm] = useState(false);
  const { messageText, messageImage, messageTime } = overwriteComponents;
  const messageRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(messageRef.current.offsetWidth);
  }, [messageRef]);

  return (
    <MessageBubbleContainer isCurrentUser={isCurrentUser} showEditingForm={showEditingForm}>
      {!showEditingForm && !hasRightPosition && (
        <Avatar
          message={message}
          nextMessage={nextMessage}
          isCurrentUser={isCurrentUser}
          overwriteComponents={overwriteComponents}
        />
      )}
      <MessageBubbleWrapper
        isCurrentUser={isCurrentUser}
        color={color}
        hasRightPosition={hasRightPosition}
        showEditingForm={showEditingForm}
        ref={messageRef}
        isImage={message.image}>
        {showEditingForm && <EditChatForm message={message} onHideForm={setEditingForm} userID={userID} />}

        {!hasChatActionsDisabled && !showEditingForm && (
          <ChatActions
            message={message}
            handleEdit={setEditingForm}
            editingDisabled={Boolean(message.action || message.image)}
            userID={userID}
            hasRightPosition={hasRightPosition}
            isCurrentUser={isCurrentUser}
            width={width}
          />
        )}
        {!showEditingForm && message.text ? messageText ? messageText(props) : <MessageText {...props} /> : null}
        {!showEditingForm && message.image ? messageImage ? messageImage(props) : <MessageImage {...props} /> : null}
        {!showEditingForm && (
          <MessageFooter>
            {message.was_edited && (
              <>
                <Icon name="penFill" size={12} />
                <EditedText>
                  Edited{message.edited_by_user ? ` by ${message.edited_by_user.given_name}` : ''}
                </EditedText>
              </>
            )}
            {messageTime ? messageTime(props) : <MessageTime {...props} />}
          </MessageFooter>
        )}
      </MessageBubbleWrapper>
      {!showEditingForm && hasRightPosition && (
        <Avatar
          message={message}
          nextMessage={nextMessage}
          isCurrentUser={false}
          overwriteComponents={overwriteComponents}
        />
      )}
    </MessageBubbleContainer>
  );
};

export default MessageBubble;
