import { sortingDirections, columnNames } from 'common/enums/sortings';

export const defaultParams = {
  PAGE: {
    DEFAULT_VALUE: 1,
    NAME: 'page'
  },
  SORTING_DIRECTION: {
    DEFAULT_VALUE: sortingDirections.DESCENDING,
    NAME: 'sorting_direction'
  },
  SORTING_COLUMN: {
    DEFAULT_VALUE: columnNames.ID,
    NAME: 'sorting_column'
  }
};

export const defaultQueryParams = {
  [defaultParams.PAGE.NAME]: defaultParams.PAGE.DEFAULT_VALUE,
  [defaultParams.SORTING_DIRECTION.NAME]: defaultParams.SORTING_DIRECTION.DEFAULT_VALUE,
  [defaultParams.SORTING_COLUMN.NAME]: defaultParams.SORTING_COLUMN.DEFAULT_VALUE
};

export const apiPageSizes = {
  DEFAULT: 50,
  SMALL: 10
};
