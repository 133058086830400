// React
import React, { useEffect, useState } from 'react';
// Util Libs
import PropTypes from 'prop-types';
// Hooks
import { useComponentVisible } from 'hooks';
// Components
import { Avatar, Checkbox, Icon, LoadingSpinner } from 'components';
// Utils
import { truncateTextItemsWithTransformation, truncateTextLengthWithTransformation, findArraysDiff } from 'utils';
// Styles
import styles from 'constants/styles';
import {
  DropdownMultiSelectContainer,
  SelectorLabel,
  SelectorContainer,
  SelectedValuesContainer,
  SelectedValues,
  DropdownMenu,
  SelectAllOptionContainer,
  SelectAllOption,
  SelectAllOptionText,
  IconButtonsContainer,
  IconButton,
  DropdownMenuItems,
  MenuItem,
  MenuItemImage,
  MenuItemText
} from './styled';
import { useTheme } from 'styled-components';

const DropdownMultiSelect = ({
  options = [],
  selectedOptions = [],
  hasExtraOptions = false,
  extraOptions = [],
  isLoading = false,
  isDisabled = true,
  isOpen = false,
  hasImages = false,
  hasCloseButton = true,
  applyOnItemSelection = true,
  onSelect,
  onDeselect,
  onSelectAll,
  onApplySelection,
  label = '',
  selectPlaceholderText = 'Select Options',
  selectAllOptionText = 'Select All',
  selectedItemsCountTruncateLimit: itemsCountLimit,
  selectedItemsStringTruncateLimit: stringLengthLimit = 23,
  transformSelectedValuesText = (selected) => selected,
  transformMenuOptionsText = (option) => option,
  selectedValuesColor = styles.colors.GRAY,
  selectedValuesBackgroundColor = styles.colors.LIGHT_GRAY,
  dropdownOptionsColor = styles.colors.BLACK,
  dropdownSelectedOptionsColor = styles.colors.DARK_RED,
  selectedValuesWidth,
  dropdownOptionsWidth,
  dropdownOptionsHeight,
  right
}) => {
  const [values, setValues] = useState(options);
  const [extraValues, setExtraValues] = useState(extraOptions);
  const [selectedValues, setSelectedValues] = useState(selectedOptions);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [isApplyButtonDisabled, setApplyButtonDisabled] = useState(true);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(isOpen);

  /**
   * Set selected values list and all iterable options list
   */
  useEffect(() => {
    setSelectedValues(selectedOptions);
    if (selectedOptions.length) {
      const valuesWithChecks = options.map((value) =>
        selectedOptions.find((selected) => selected?.id === value?.id) ? { ...value, checked: true } : value
      );
      setValues(valuesWithChecks);
    } else {
      setValues(options);
    }
  }, [options, selectedOptions]);

  /**
   * Set additional options list
   */
  useEffect(() => {
    if (hasExtraOptions) {
      setExtraValues(extraOptions);
    }
  }, [hasExtraOptions, extraOptions]);

  /**
   * Check for diff in selected options state
   */
  useEffect(() => {
    const diff = findArraysDiff(selectedOptions, selectedValues);
    const areSelectedEqual = selectedOptions.length === selectedValues.length && !diff.length;
    setApplyButtonDisabled(areSelectedEqual);
  }, [selectedOptions, selectedValues]);

  /**
   * Function to map values and modify check state of options
   * @param {Object} option - current option to set check prop
   * @param {Object} value - option to compare id with
   * @param {boolean} isChecked - check prop to set
   * @returns {Object} option if current item is not the one to modify or modified option
   */
  const checkSelectedOption = (option, value, isChecked) =>
    option?.id === value?.id ? { ...option, checked: isChecked } : option;

  const toggleSelectVisibility = () => setIsComponentVisible((prevVisible) => !prevVisible);

  /**
   * Handles select/deselect logic of list item. Update depends on 'applyOnItemSelection' prop:
   * if true - corresponding item select/deselect callbacks are called
   * @param {Object} value - option from dd mapped list with which user interacted
   */
  const onSelectValue = (value) => () => {
    if (selectedValues.find((selected) => selected?.id === value?.id)) {
      // Deselecting dropdown item
      const updatedSelectedValues = selectedValues.filter((selected) => selected?.id !== value?.id);
      if (updatedSelectedValues.length < values.length) setSelectedAll(false); // check if all values were selected
      setSelectedValues(updatedSelectedValues);
      setValues((prevValues) => prevValues.map((option) => checkSelectedOption(option, value, false)));

      if (applyOnItemSelection) onDeselect(value);
    } else {
      // Selecting dropdown item
      const updatedSelectedValues = [...selectedValues, value];
      if (updatedSelectedValues.length === values.length) setSelectedAll(true); // check if all values became selected
      setSelectedValues(updatedSelectedValues);
      setValues((prevValues) => prevValues.map((option) => checkSelectedOption(option, value, true)));

      if (applyOnItemSelection) onSelect(value);
    }

    if (applyOnItemSelection && isComponentVisible) {
      onApplySelection();
      toggleSelectVisibility();
    }
  };

  /**
   * Handles select/deselect logic for all list items. Update depends on 'applyOnItemSelection' prop:
   * if true - corresponding onSelectAll, onApplySelection callbacks are called
   */
  const handleSelectAll = () => {
    // Update list of all iterable options (state: values)
    const allValues = values.map((value) => ({ ...value, checked: !isSelectedAll }));
    // Update list of selected options (state: selectedValues)
    const newSelectedValues = !isSelectedAll ? allValues : [];

    setSelectedAll((prevSelectAll) => !prevSelectAll);
    setValues(allValues);
    setSelectedValues(newSelectedValues);

    if (applyOnItemSelection) onSelectAll(newSelectedValues);

    if (applyOnItemSelection && isComponentVisible) {
      onApplySelection();
      toggleSelectVisibility();
    }
  };

  /**
   * Handles Apply button logic (mutually exclusive with 'applyOnItemSelection=true')
   */
  const handleApplySelection = () => {
    onApplySelection(selectedValues);
    if (isComponentVisible) toggleSelectVisibility();
  };

  const theme = useTheme();
  return (
    <DropdownMultiSelectContainer>
      <SelectorLabel>{label && `${label}:`}</SelectorLabel>

      <SelectorContainer ref={ref} disabled={isDisabled}>
        <SelectedValuesContainer
          background={selectedValuesBackgroundColor}
          onClick={isDisabled ? null : toggleSelectVisibility}
          disabled={isDisabled}
          width={selectedValuesWidth}>
          <SelectedValues color={selectedValuesColor} disabled={isDisabled} noneSelected={!selectedOptions.length}>
            {Boolean(!isComponentVisible && selectedOptions.length) && itemsCountLimit
              ? selectedOptions.map((value, idx, self) =>
                  truncateTextItemsWithTransformation(idx, itemsCountLimit, value, self, transformSelectedValuesText)
                )
              : truncateTextLengthWithTransformation(selectedOptions, stringLengthLimit, transformSelectedValuesText)}
            {Boolean(!isComponentVisible && !selectedOptions.length) && selectPlaceholderText}
          </SelectedValues>
          {!isComponentVisible && isLoading && <LoadingSpinner color="dark" />}
          {!isComponentVisible && !isLoading && <Icon name="arrowFillBottom" backgroundSize={16} />}
        </SelectedValuesContainer>

        {isComponentVisible && (
          <DropdownMenu width={dropdownOptionsWidth} right={right}>
            <SelectAllOptionContainer hasExtraOptions={hasExtraOptions}>
              <SelectAllOption onClick={handleSelectAll}>
                <Checkbox checked={isSelectedAll} disabled={true} smallSize={true} smallLabelFont={true} green={true} />
                <SelectAllOptionText
                  selected={isSelectedAll}
                  selectedColor={dropdownSelectedOptionsColor}
                  color={dropdownOptionsColor}>
                  {selectAllOptionText}
                </SelectAllOptionText>
              </SelectAllOption>

              <IconButtonsContainer>
                {!applyOnItemSelection && (
                  <Icon
                    name="okOutlined"
                    color={isApplyButtonDisabled ? styles.colors.DARK_GREY : styles.colors.DARK_GREEN_2}
                    backgroundSize={16}
                    size={16}
                    onClick={isApplyButtonDisabled ? null : handleApplySelection}
                    clickable
                  />
                )}

                {hasCloseButton && (
                  <IconButton>
                    <Icon
                      name="closeFill2"
                      backgroundSize={16}
                      onClick={toggleSelectVisibility}
                      secondColor={theme.colors.DARK_GREY}
                      clickable
                    />
                  </IconButton>
                )}
              </IconButtonsContainer>
            </SelectAllOptionContainer>

            <DropdownMenuItems>
              {values.map((option) => (
                <MenuItem
                  isExtraOption={Boolean(option.isExtra)}
                  extrasLength={extraValues.length}
                  disabled={option.disabled}
                  key={`dd_option-${option.id}`}
                  selected={option.checked}
                  onClick={option.disabled ? null : onSelectValue(option)}
                  height={dropdownOptionsHeight}>
                  <Checkbox checked={option.checked} disabled smallSize smallLabelFont green />
                  {hasImages && !option.isExtra && (
                    <MenuItemImage>
                      <Avatar url={option?.profile_image_url} alt="User Avatar" size={24} />
                    </MenuItemImage>
                  )}
                  <MenuItemText
                    selected={option.checked}
                    selectedColor={isSelectedAll ? dropdownOptionsColor : dropdownSelectedOptionsColor}
                    color={dropdownOptionsColor}>
                    {transformMenuOptionsText(option)}
                  </MenuItemText>
                </MenuItem>
              ))}
            </DropdownMenuItems>
          </DropdownMenu>
        )}
      </SelectorContainer>
    </DropdownMultiSelectContainer>
  );
};

DropdownMultiSelect.propTypes = {
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onApplySelection: PropTypes.func,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  extraOptions: PropTypes.array,
  hasExtraOptions: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  selectPlaceholderText: PropTypes.string,
  selectAllOptionText: PropTypes.string,
  selectedValuesWidth: PropTypes.number,
  dropdownOptionsWidth: PropTypes.number,
  dropdownOptionsHeight: PropTypes.number,
  selectedItemsBeforeTruncate: PropTypes.number,
  selectedStringLengthBeforeTruncate: PropTypes.number,
  transformMenuOptionsText: PropTypes.func,
  transformSelectedValuesText: PropTypes.func,
  applyOnItemSelection: PropTypes.bool,
  hasImages: PropTypes.bool,
  hasCloseButton: PropTypes.bool
};

export default DropdownMultiSelect;
