import React from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';
import { dark, light } from 'constants/styles';
import { isDarkMode as isDarkModeSelector } from 'store/reducers/app';
import { useSelector } from 'react-redux';

const ThemeProvider = ({ children }) => {
  const isDarkMode = useSelector(isDarkModeSelector);
  const theme = isDarkMode ? dark : light;

  return <BaseThemeProvider theme={{ ...theme, darkMode: isDarkMode }}>{children}</BaseThemeProvider>;
};

export default ThemeProvider;
