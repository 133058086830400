export const videoExtensions = {
  'video/x-msvideo': '.avi',
  'video/mp4': '.mp4',
  'video/mpeg': '.mpeg',
  'video/mov': '.mov', // video/quicktime
  'video/quicktime': '.qt', // video/quicktime
  'video/ogg': '.ogv',
  'video/mp2t': '.ts',
  'video/webm': '.webm',
  'video/3gpp': '.3gp',
  'video/3gpp2': '.3g2',
  'video/x-m4v': '.m4v'
};
