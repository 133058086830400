import { useEffect, useDispatch, useSelector } from 'hooks';

import { isTestTasksVisible } from 'store/reducers/app';
import { selectBadges } from 'store/reducers/user';

import { getBadges } from 'store/actions';
import { useCallback } from 'react';

/***
 * updates badge count on each menu item change
 */
const useNavigationBadges = ({ init } = {}) => {
  const dispatch = useDispatch();
  const menuBadges = useSelector(selectBadges);
  const isTestTasks = useSelector(isTestTasksVisible);

  const handleFetchBadges = useCallback(() => {
    dispatch(getBadges());
  }, [dispatch]);

  useEffect(() => {
    if (!init) {
      return;
    }
    handleFetchBadges();
  }, [init, isTestTasks]);

  return {
    menuBadges,
    handleFetchBadges
  };
};

export default useNavigationBadges;
