import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { getCustomerReferrals } from 'store/actions';
import { selectCustomerReferrals } from 'store/reducers/customer';
import { CustomerReferralsList, LoadingSpinner } from 'components';
import { EmptyServiceLists, EmptyText, FlexOneContainer } from './styled';

const CustomerReferrals = ({ userId }) => {
  const referrals = useSelector(selectCustomerReferrals);
  const dispatch = useDispatch();
  const getList = (page = 1) => {
    dispatch(
      getCustomerReferrals({
        userId,
        page
      })
    );
  };
  useEffect(() => {
    getList();
  }, [userId]);

  return (
    <FlexOneContainer>
      <CustomerReferralsList onLoadMore={() => getList(referrals?.page + 1)} referrals={referrals} />

      {!referrals.list?.length && (
        <EmptyServiceLists>
          {referrals.loading ? (
            <LoadingSpinner text="Please wait" type="logo" />
          ) : (
            <EmptyText>No homeowners invited yet</EmptyText>
          )}
        </EmptyServiceLists>
      )}
    </FlexOneContainer>
  );
};

export default CustomerReferrals;
