import styled from 'styled-components';

export const FlexRow = styled.div`
  position: relative;
  display: inline-flex;
  white-space: pre-wrap;
`;

export const TooltipContent = styled.div`
  padding-right: 2px;
`;
