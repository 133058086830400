import { icons } from 'common/enums/icons';
import { sidebarMenuItems } from 'common/enums/navigation';

export const notificationTypes = {
  JOB: 'service_demand',
  NOTE: 'note',
  REMINDER: 'reminder',
  REVIEW: 'review',
  USER: 'user',
  COMMUNICATION: 'sms',
  INVOICE: 'invoice'
};

export const notificationActions = {
  CHAT: 'chat',
  NEW: 'new',
  CREATE: 'create'
};

export const redirectNotificationTypes = {
  CHAT: 'chat',
  NEGATIVE: 'negative',
  NEGATIVE_REVIEW: 'negative_review',
  NOTE: 'note',
  POSITIVE: 'positive',
  POSITIVE_REVIEW: 'positive_review',
  REMINDER: 'reminder',
  COMMUNICATION: notificationTypes.SMS
};

export const pageTypes = {
  COMMUNICATION: 'COMMUNICATION',
  CURRENT_AGENT: 'CURRENT_AGENT',
  CURRENT_PROVIDER: 'CURRENT_PROVIDER',
  CURRENT_CUSTOMER: 'CURRENT_CUSTOMER',
  CURRENT_COMMUNICATION_CHAT: 'CURRENT_COMMUNICATION_CHAT',
  CURRENT_JOB: 'CURRNET_JOB',
  JOB_LIST: 'JOB_LIST',
  OTHER: 'OTHER'
};

export const notificationIcons = {
  [redirectNotificationTypes.CHAT]: icons.CHAT_OUTLINED,
  [redirectNotificationTypes.COMMUNICATION]: icons.CHAT_OUTLINED,
  [redirectNotificationTypes.NEGATIVE]: icons.CLOSE_OUTLINED,
  [redirectNotificationTypes.NEGATIVE_REVIEW]: icons.THUMB_DOWN_OUTLINED,
  [redirectNotificationTypes.NOTE]: icons.COMMENT,
  [redirectNotificationTypes.POSITIVE]: icons.OK_OUTLINED,
  [redirectNotificationTypes.POSITIVE_REVIEW]: icons.THUMB_UP_OUTLINED,
  [redirectNotificationTypes.REMINDER]: icons.TIME_FILLED
};

/**
 * use this structure to define notification handling logic
 */
export const wsEvents = {
  INVOICE_ADMIN_PENDING: {
    TYPE: 'INVOICE_ADMIN_PENDING',
    HAS_ALERT: true,
    HAS_PERSISTENT_NOTIFICATION: false,
    HAS_SIDEBAR_BADGES_UPDATE: true,
    RELOAD_PAGES: [
      {
        MENU_ITEM: sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE
      },
      {
        CURRENT_PAGE_TYPES: [pageTypes.CURRENT_JOB]
      }
    ],
    EVENT: {
      type: notificationTypes.INVOICE
    }
  },
  SMS_RECEIVED: {
    TYPE: 'SMS_RECEIVED',
    HAS_ALERT: true,
    HAS_PERSISTENT_NOTIFICATION: false,
    HAS_SIDEBAR_BADGES_UPDATE: true,
    RELOAD_PAGES: [
      {
        MENU_ITEM: sidebarMenuItems.COMMUNICATION.TYPE
      }
    ],
    EVENT: {
      type: notificationTypes.COMMUNICATION,
      action: notificationActions.NEW
    }
  }
};
