import React from 'react';

import messages from 'constants/messages';

import { TextareaRow, StyledTextarea, Label, FlexColumn, Error } from './styled';

const Textarea = ({ label, name, register, rules = {}, error = {}, disabled, ...props }) => {
  return (
    <FlexColumn>
      {label && <Label disabled={disabled}>{label}</Label>}
      <TextareaRow>
        <StyledTextarea
          component="textarea"
          autoComplete="off"
          disabled={disabled}
          {...props}
          {...register(name, rules)}
          invalid={Boolean(error?.type)}
        />
      </TextareaRow>
      {Boolean(messages.formErrors[error.type?.toUpperCase()]) && (
        <Error>{messages.formErrors[error.type?.toUpperCase()]}</Error>
      )}
    </FlexColumn>
  );
};
export default Textarea;
