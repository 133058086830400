import React from 'react';
import styles from 'constants/styles';

const IconLogo = (props) => {
  const { width = 123, height = 32, color = styles.colors.WHITE } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 123 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7243 3.80035C14.9673 2.80819 16.7276 2.80819 17.9706 3.80035L27.3629 11.2974C28.1773 11.9474 28.6518 12.935 28.6518 13.9796V25.4493H31.6953V13.9796C31.6953 12.0032 30.7974 10.1347 29.2567 8.90487L19.8644 1.40787C17.5127 -0.469289 14.1823 -0.469289 11.8306 1.40787L2.43823 8.90487C0.897501 10.1347 -0.000375748 12.0032 -0.000375748 13.9796V25.4493H3.04309V13.9796C3.04309 12.935 3.51766 11.9474 4.33201 11.2974L13.7243 3.80035Z"
        fill={color}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.847 8.90047L8.2544 14.9218L8.2544 25.4487H5.21094L5.21094 14.3344C5.21094 13.7702 5.46815 13.2371 5.90903 12.8875L14.7091 5.90853C15.3762 5.37945 16.3178 5.37945 16.9849 5.90853L25.7849 12.8875C26.2258 13.2371 26.483 13.7702 26.483 14.3344L26.483 25.4487H23.4396L23.4396 14.9219L15.847 8.90047Z"
        fill={color}
      />

      <path
        d="M18.2773 21.5002C18.2773 20.9446 18.7259 20.4941 19.2792 20.4941H20.3189C20.8723 20.4941 21.3208 20.9446 21.3208 21.5002V25.4486H18.2773V21.5002Z"
        fill={color}
      />
      <path
        d="M56.0517 23.4938V4.42578H50.622C50.5289 4.51925 50.3117 4.79966 50.3117 5.29817C50.3117 5.98363 50.684 6.38866 51.3666 6.38866H52.5767V12.4643H52.5456C51.9872 11.9034 50.8081 10.9999 48.6983 10.9999C45.0372 10.9999 42.0586 13.8975 42.0586 18.571C42.0586 22.4968 44.2615 25.7994 48.2329 25.7994C50.622 25.7994 52.0182 24.335 52.5767 23.3692H52.6077V25.4567H57.8202C57.9133 25.3632 58.1304 25.0828 58.1304 24.6154C58.1304 23.8988 57.7581 23.4938 57.0755 23.4938H56.0517ZM52.5767 20.16C52.5767 22.4968 51.2115 23.7119 49.3809 23.7119C47.209 23.7119 45.8128 21.5932 45.8128 18.2906C45.8128 14.8945 47.5193 12.9939 49.6601 12.9939C51.0874 12.9939 52.1113 13.6171 52.5767 14.3337V20.16Z"
        fill={color}
      />
      <path
        d="M66.0297 25.8617C70.8078 25.8617 73.6002 22.4968 73.6002 18.1348C73.6002 13.5859 70.6837 10.9064 66.371 10.9064C61.5618 10.9064 58.7694 14.1467 58.7694 18.5398C58.7694 23.0887 61.6549 25.8617 66.0297 25.8617ZM66.2468 23.9611C63.7957 23.9611 62.5547 21.4997 62.5547 18.4775C62.5547 15.6423 63.5785 12.7447 66.1227 12.7447C68.5739 12.7447 69.8149 15.2684 69.8149 18.1971C69.8149 21.0947 68.791 23.9611 66.2468 23.9611Z"
        fill={color}
      />
      <path
        d="M83.354 10.9687C80.9649 10.9687 79.5377 12.4331 79.0102 13.399H78.9792V4.42578H73.7357C73.6426 4.51925 73.4254 4.79966 73.4254 5.29817C73.4254 5.98363 73.7977 6.38866 74.4493 6.38866H75.4732V23.5561C76.1868 24.4596 77.9863 25.7682 81.8337 25.7682C86.5497 25.7682 89.4973 22.8395 89.4973 18.166C89.4973 14.1779 87.2013 10.9687 83.354 10.9687ZM81.9267 23.93C80.0341 23.93 79.1964 22.933 78.9792 22.4344V16.4835C78.9792 14.5206 80.1892 13.0562 82.175 13.0562C84.4399 13.0562 85.743 15.3307 85.743 18.3529C85.743 21.749 84.2227 23.93 81.9267 23.93Z"
        fill={color}
      />
      <path
        d="M99.277 10.9687C96.8879 10.9687 95.4607 12.4331 94.9333 13.399H94.9022V4.42578H89.6587C89.5656 4.51925 89.3484 4.79966 89.3484 5.29817C89.3484 5.98363 89.7208 6.38866 90.3723 6.38866H91.3962V23.5561C92.1098 24.4596 93.9094 25.7682 97.7567 25.7682C102.473 25.7682 105.42 22.8395 105.42 18.166C105.42 14.1779 103.124 10.9687 99.277 10.9687ZM97.8498 23.93C95.9571 23.93 95.1194 22.933 94.9022 22.4344V16.4835C94.9022 14.5206 96.1123 13.0562 98.098 13.0562C100.363 13.0562 101.666 15.3307 101.666 18.3529C101.666 21.749 100.146 23.93 97.8498 23.93Z"
        fill={color}
      />
      <path
        d="M115.888 11.2803C115.795 11.4049 115.578 11.6542 115.578 12.1527C115.578 12.8693 115.95 13.2432 116.664 13.2432H117.75C117.595 13.6794 117.223 14.6452 116.881 15.4865L114.43 21.4997H114.399L110.49 13.2432H111.421C112.103 13.2432 112.444 12.8381 112.444 12.1527C112.444 11.6542 112.258 11.4049 112.165 11.2803H105.029C104.936 11.4049 104.75 11.6542 104.75 12.1527C104.75 12.8381 105.122 13.2432 105.805 13.2432H106.611L111.234 22.8395C111.824 24.1169 112.041 24.6466 112.041 25.7682C112.041 27.0457 111.39 29.6005 109.59 29.6005C107.915 29.6005 107.604 28.4477 106.549 28.4477C105.991 28.4477 105.681 28.8528 105.681 29.4136C105.681 30.6599 106.984 31.9996 109.187 31.9996C113.096 31.9996 113.53 28.0738 115.95 22.341L118.184 17.1378C119.208 14.7387 119.767 13.6171 119.922 13.2432H120.853C121.628 13.2432 122.001 12.8693 122.001 12.1527C122.001 11.6542 121.815 11.4049 121.721 11.2803H115.888Z"
        fill={color}
      />
    </svg>
  );
};

export default IconLogo;
