// React
import React, { useRef, useEffect } from 'react';
// Util libs
import { useController } from 'react-hook-form';
// Utils
import { normalizers, formatters } from 'utils';
// Constants
import { fieldTypes } from 'common/enums/form';
import messages from 'constants/messages';
// Styles
import styles from 'constants/styles';
import { StyledTextarea, TextareaRow, Error, Label, FlexColumn, CloseButton } from './styled';
import { Icon } from 'components';
import { useTheme } from 'hooks';

const ControlledTextarea = ({
  name,
  control,
  label,
  placeholder,
  textareaConfig = {},
  fieldType = {},
  rules = {},
  wrapperStyle = {},
  style = {},
  reset,
  isHideErrorText,
  lengthInfoPrefix = '',
  hasSmallLegthTitle,
  maxLength,
  renderTooltip,
  disabled,
  renderNotice,
  onCustomChange,
  hasPrefix = false
}) => {
  const {
    field: { ref, onChange, value, ...textareaProps },
    fieldState: { error, invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  });
  const textRef = useRef();
  useEffect(() => {
    const currentHeight = Math.max(Number(style.minHeight) || 0, textRef.current.scrollHeight);
    textRef.current.style.height = `${currentHeight}px`;
  }, [textRef, style.minHeight]);

  const transform = {
    output: normalizers[fieldType?.formatterName] || normalizers.default,
    input: formatters[fieldType?.formatterName] || formatters.default
  };

  const onChangeHandler = (e) => {
    const target = e.target;
    const updatedValue = rules?.maxLength
      ? transform.output(target.value.substring(0, rules.maxLength))
      : transform.output(target.value);
    onChange(updatedValue);
    if (onCustomChange) {
      onCustomChange({ previousValue: value, value: updatedValue });
    }

    textRef.current.style.height = style?.minHeight || '35px';

    let height = textRef.current.scrollHeight;

    if (rules?.maxHeight) {
      if (textRef.current.scrollHeight <= rules.maxHeight) {
        height = textRef.current.scrollHeight;
      } else {
        height = rules.maxHeight + 3;
      }
    }

    textRef.current.style.height = `${height}px`;
  };

  const theme = useTheme();
  const maxInputLength = maxLength || rules.maxLength;
  const hasMaxLengthLimitReached = Boolean(maxInputLength) && value?.length >= maxInputLength;

  return (
    <FlexColumn style={wrapperStyle}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <TextareaRow>
        <StyledTextarea
          component="textarea"
          autoComplete="off"
          ref={textRef}
          reset={reset}
          placeholder={placeholder}
          style={style}
          disabled={disabled}
          {...textareaProps}
          {...textareaConfig}
          {...fieldType}
          onChange={onChangeHandler}
          value={transform.input(value)}
          invalid={invalid && isTouched}
          hasPrefix={hasPrefix}
          {...(rules?.maxLength ? { maxLength: rules.maxLength } : {})}
        />
        {Boolean(reset && value) && (
          <CloseButton>
            <Icon
              backgroundSize={16}
              backgroundColor={theme.colors.DARK_3}
              name="closeFill"
              onClick={() => onChange('')}
            />
          </CloseButton>
        )}
        {Boolean(renderTooltip) && renderTooltip()}
      </TextareaRow>

      <TextareaRow>
        {Boolean(error?.type && !isHideErrorText && error?.type !== 'maxLength') ? (
          <Error>{error.message || messages.formErrors[error.type?.toUpperCase()]}</Error>
        ) : (
          <div />
        )}
        {(maxLength || rules?.maxLength) && (
          <Label
            disabled={disabled}
            margin={'5px 0'}
            color={hasMaxLengthLimitReached ? styles.colors.RED : null}
            small={hasSmallLegthTitle}>
            {lengthInfoPrefix}
            {lengthInfoPrefix ? ' (' : ''}
            {value?.length}/{maxLength || rules.maxLength}
            {lengthInfoPrefix ? ')' : ''}
          </Label>
        )}
      </TextareaRow>
      {Boolean(renderNotice) && renderNotice()}
    </FlexColumn>
  );
};

ControlledTextarea.defaultProps = {
  fieldType: fieldTypes.TEXT,
  size: 'small',
  hasPrefix: false
};

export default ControlledTextarea;
