import styled from 'styled-components';
import styles from 'constants/styles';

export const SearchInputWrapper = styled.div`
  position: relative;
  ${({ isFullWidth }) => `margin-right: ${isFullWidth ? '0px' : '12px'}`};
`;

export const StyledSearchInput = styled.input`
  border: none;
  box-shadow: none;
  background: ${({ theme }) => theme.colors.INPUT_BG};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  padding: 8px 12px;
  min-width: 240px;
  ${({ isFullWidth }) => isFullWidth && 'width: 100%'};
  outline: 0 none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }
  &:active,
  &:focus {
    outline: none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
  }
`;

export const SearchInputReset = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;
