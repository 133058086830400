import styled from 'styled-components';
import { buttonVariants } from 'common/enums/buttons';

export const StyledButton = styled.button`
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.BUTTON_TEXT};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  width: 176px;
  height: 40px;

  ${({ variant, width = '176px', height = '48px', theme }) => {
    switch (variant) {
      case buttonVariants.PRIMARY:
        return `
        border: 1px solid ${theme.colors.DARK_GREY_2};
        width: ${width};
        &:hover:not([disabled]) {
          color: ${theme.colors.DARK_GREEN};
        }
        &:disabled {
          color: ${theme.colors.DARK_GREY};
        }
      `;
      case buttonVariants.SECONDARY:
        return `
        color: ${theme.colors.DARK_GREY};
        font-weight: 700;
        font-size: 14px;
        line-height: 1.14;
        border-radius: 4px;
        width: ${width};
        height: ${height};
        background: ${theme.colors.DARK_1};
        &:hover:not([disabled]) {
          background: ${theme.colors.DARK_GREY_3};
          color: ${theme.colors.WHITE};
        }
      `;
      default:
        return `
        background: ${theme.colors.DARK_GREEN_2};
        width: ${width};
        &:hover:not([disabled]) {
          background: ${theme.colors.DARK_GREEN_3};
        }
        &:disabled {
          background: ${theme.colors.DARK_4};
        }
      `;
    }
  }}

  ${({ smallSize }) =>
    smallSize &&
    `width: 83px;
  height: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;`}
  ${({ margin }) => margin && `margin: ${margin}`};
`;
