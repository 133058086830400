import React, { useState } from 'react';
import moment from 'moment-timezone';

import { DateRangePicker as UIDateRangePicker } from 'materialui-daterange-picker';
import { formatters } from 'utils';
import dates from 'constants/dates';

import { InputRow, PseudoInput, InputWrapper, DatePickerWrapper } from './styled';

const DateRangePicker = ({
  startDatePlaceholder = 'From',
  endDatePlaceholder = 'To',
  startDateName,
  endDateName,
  inputWidth,
  setValue,
  getValues
}) => {
  const [isDatePickerShown, setIsDatePickerShown] = useState(false);
  const [startDateValue, endDateValue] = getValues([startDateName, endDateName]);

  const onDateRangeChange = (range) => {
    toggleDatePicker();
    setValue(startDateName, moment(range.startDate).format(dates.DATETIME_ISO), { shouldValidate: true });
    setValue(endDateName, moment(range.endDate).format(dates.DATETIME_ISO), { shouldValidate: true });
  };

  const toggleDatePicker = () => setIsDatePickerShown((value) => !value);

  return (
    <InputRow>
      <InputWrapper>
        <PseudoInput hasPlaceholder={!startDateValue} onClick={toggleDatePicker} width={inputWidth}>
          {formatters.dateUTC(startDateValue) || startDatePlaceholder}
        </PseudoInput>
        <DatePickerWrapper>
          <UIDateRangePicker
            open={isDatePickerShown}
            toggle={toggleDatePicker}
            onChange={onDateRangeChange}
            initialDateRange={{
              startDate: startDateValue,
              endDate: endDateValue
            }}
            wrapperClassName="datepicker-wrapper"
          />
        </DatePickerWrapper>
      </InputWrapper>
      <div>
        <PseudoInput hasPlaceholder={!endDateValue} onClick={toggleDatePicker} width={inputWidth}>
          {formatters.dateUTC(endDateValue) || endDatePlaceholder}
        </PseudoInput>
      </div>
    </InputRow>
  );
};

export default DateRangePicker;
