import React from 'react';
import { useComponentVisible } from 'hooks';

import { IconButton } from 'components';

import {
  DropdownContainer,
  Dropdown,
  DropdownMenuHeader,
  DropdownMenuDescription,
  DropdownMenuItem,
  DropdownItemText,
  DropdownItems
} from './styled';
import { Icon, LoadingSpinner } from 'components';
import { useTheme } from 'styled-components';

const MenuButton = ({ isComponentVisible, toggle, menuType = 'dots' }) => {
  if (menuType === 'avatar') {
    return (
      <div onClick={toggle}>
        <IconButton iconName="avatar" iconSize={16} size={32} />
      </div>
    );
  }
  return isComponentVisible ? (
    <IconButton iconName="close2" iconSize={10} onClick={toggle} />
  ) : (
    <IconButton iconName="dots" onClick={toggle} iconSize={20} />
  );
};

const DropdownMenu = ({
  options = [],
  placeholder = 'Select Option',
  menuType,
  buttonColor,
  margin,
  dropdownWidth
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggle = () => setIsComponentVisible((prevVisible) => !prevVisible);
  const theme = useTheme();
  return (
    <DropdownContainer ref={ref}>
      <MenuButton
        isComponentVisible={isComponentVisible}
        toggle={toggle}
        margin={margin}
        menuType={menuType}
        buttonColor={buttonColor}
      />
      {isComponentVisible && (
        <Dropdown dropdownWidth={dropdownWidth}>
          <DropdownMenuHeader>
            <DropdownMenuDescription>{placeholder}</DropdownMenuDescription>
          </DropdownMenuHeader>
          <DropdownItems>
            {options.map((option) => (
              <DropdownMenuItem key={option.id} onClick={option.onClick}>
                <DropdownItemText>
                  {option.name}
                  {option?.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    option.selected && <Icon name="okOutlined" size={16} strokeWidth="1.9" />
                  )}
                </DropdownItemText>
              </DropdownMenuItem>
            ))}
          </DropdownItems>
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default DropdownMenu;
