import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;
  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 0;
    justify-content: space-between;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const InvalidMessage = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.RED};
`;

export const NameWithOptions = styled.div`
  ${FlexRow};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ disabled }) => disabled && 'pointer-events: none'};
  .react-select__control {
    background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    border: unset;
    box-shadow: unset;
    height: 40px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.DARK_4};
    }
  }
  .react-select__menu {
    background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    overflow: visible;
  }
  .react-select__menu-notice {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

export const Option = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

export const OptionPhoto = styled.div`
  margin-right: 13px;
`;

export const OptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;
