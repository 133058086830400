import React from 'react';
import styles from 'constants/styles';

const IconOkFill = ({ size = 16, color = styles.colors.DARK_GREEN_2 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={color}
      />
      <path
        d="M6.85742 12L10.286 15.4286L17.1431 8.57141"
        stroke="#F7F7F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconOkFill;
