import React from 'react';

const IconMicrophoneCross2 = ({ size = 48 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#434B4E" />
      <path
        d="M23.0006 17C23.0006 16.45 23.4506 16 24.0006 16C24.5506 16 25.0006 16.45 25.0006 17V22.17L26.8206 23.99C26.9306 23.68 27.0006 23.35 27.0006 23V17C27.0006 15.34 25.6606 14 24.0006 14C22.3406 14 21.0006 15.34 21.0006 17V18.17L23.0006 20.17V17ZM14.8106 14.81L13.3906 16.22L25.0406 27.87C24.7106 27.95 24.3606 28 24.0006 28C21.2406 28 19.0006 25.76 19.0006 23H17.0006C17.0006 26.53 19.6106 29.43 23.0006 29.92V33H25.0006V29.92C25.5706 29.84 26.1206 29.68 26.6406 29.46L31.7806 34.6L33.1906 33.19L14.8106 14.81ZM31.0006 23H29.0006C29.0006 23.91 28.7406 24.75 28.3106 25.48L29.7706 26.94C30.5718 25.7823 31.0009 24.4079 31.0006 23Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default IconMicrophoneCross2;
