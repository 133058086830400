import React from 'react';
import styles from 'constants/styles';

const IconPenFill = ({ size = 12, color = styles.colors.GRAY }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.32106 2.35944C8.84176 1.88019 8.0647 1.88019 7.58541 2.35944L2.71979 7.22463C2.68644 7.25797 2.66237 7.2993 2.6498 7.34468L2.00995 9.65445C1.98364 9.74915 2.01038 9.85056 2.07984 9.92012C2.14941 9.98958 2.25083 10.0163 2.34553 9.99011L4.65551 9.35022C4.70089 9.33765 4.74223 9.31357 4.77557 9.28023L9.64109 4.41494C10.1196 3.93536 10.1196 3.15901 9.64109 2.67944L9.32106 2.35944Z"
      fill={color}
    />
  </svg>
);

export default IconPenFill;
