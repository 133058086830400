import React from 'react';

import { formatTime } from './helpers/dates';

import { MessageTimeContainer, MessageTimeText } from './styled';

const MessageTime = ({ message = { createdAt: '' } }) => {
  return (
    <MessageTimeContainer>
      <MessageTimeText>{formatTime(message.createdAt)}</MessageTimeText>
    </MessageTimeContainer>
  );
};

export default MessageTime;
