import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Storage } from 'aws-amplify';

import {
  Label,
  MediaPreview,
  Wrapper,
  FlexView,
  Value,
  PlayButtonWrapper,
  RelativeWrapper,
  StyledReactPlayer
} from './styled';
import { Icon, LoadingSpinner } from 'components';

import { videoExtensions } from 'common/enums/media';
import { getUrlFileExtension } from 'common/helpers/media';
import { convertUrlToS3Config } from 'utils';
import { VideoPlayer } from 'modals/common';

export const MediaGallery = ({
  media,
  label,
  mediaUrls = [],
  labelStyle = {},
  isOriginalForPreview,
  placeholderStyle = {},
  wrapperStyle = {},
  mediaStyle = {},
  mediaWrapperStyle = {},
  loading,
  showScroll
}) => {
  const [showImageSlider, setShowImageSlider] = useState(false);
  const [videoPlayerSignedUrl, setHasVideoPlayerShown] = useState(null);

  const imageUrls = mediaUrls
    .map((url, index) => {
      return { url, mediaIndex: index, mime_type: media?.[index]?.media?.mime_type };
    })
    .filter((mediaItem) => mediaItem?.mime_type?.startsWith('image/'));

  const [imageIndex, setImageIndex] = useState(imageUrls[0]?.mediaIndex || 0);

  if (!media?.length) {
    return (
      <>
        {Boolean(label) && <Label style={labelStyle}>{label}</Label>}
        <Value style={placeholderStyle}>-</Value>
      </>
    );
  }

  const handleOpenVideoPlayer = async ({ path }) => {
    const config = convertUrlToS3Config(path);
    const signedUrl = await Storage.get(config.Key);
    setHasVideoPlayerShown(signedUrl);
  };

  const handleCloseVideoPlayer = () => {
    setHasVideoPlayerShown(null);
  };

  const toggleImageSlider = (initialMediaIndex) => () => {
    const { media: media_file, media_id } = media[initialMediaIndex];
    if (!media_file || !media_id) {
      return;
    }

    const ext = getUrlFileExtension(media_file?.path || '');
    const hasVideo = Object.values(videoExtensions).includes(ext) || media_file?.mime_type?.startsWith('video/');
    if (hasVideo && !showImageSlider) {
      handleOpenVideoPlayer({ path: media_file?.path });
      return;
    }

    setShowImageSlider(!showImageSlider);
    const imageUrlIndex = imageUrls?.findIndex(({ mediaIndex }) => mediaIndex);
    const imageIndexCorrected = imageUrlIndex > 0 && imageUrlIndex < imageUrls?.length ? imageUrlIndex : 0;
    setImageIndex(imageIndexCorrected);
  };

  const onMoveImagePrev = () => setImageIndex((imageIndex + imageUrls.length - 1) % imageUrls.length);

  const onMoveImageNext = () => setImageIndex((imageIndex + 1) % imageUrls.length);

  return (
    <>
      {Boolean(label) && <Label style={labelStyle}>{label}</Label>}
      <Wrapper showScroll={showScroll}>
        <FlexView style={wrapperStyle}>
          {loading ? (
            <LoadingSpinner color="light" />
          ) : (
            <>
              {media?.map(({ media: media_file, media_id, id, previewUrl, file }, idx) => {
                const hasOriginalUrl = Boolean(isOriginalForPreview && mediaUrls[idx]);
                const hasPreview = !isOriginalForPreview && Boolean((media_file && media_id) || previewUrl);
                const hasLocalVideoPreview = previewUrl?.startsWith('blob') && file?.type?.startsWith('video/');

                if (!hasPreview && !hasOriginalUrl) {
                  return null;
                }

                const hasRemoteVideoThumbnail =
                  media_file?.mime_type.startsWith('video/') && media_file?.thumbnail_path;
                const imagePreview = isOriginalForPreview ? mediaUrls[idx] : media_file?.thumbnail_path || previewUrl;
                const playButtonSize = Math.max(Number(mediaStyle?.height) || 0, 70) - 30;
                return (
                  <RelativeWrapper
                    disabled={!media_file || !media_id}
                    onClick={toggleImageSlider(idx)}
                    key={id || previewUrl}
                    style={mediaWrapperStyle}>
                    {!hasLocalVideoPreview && (
                      <MediaPreview
                        style={mediaStyle}
                        src={hasRemoteVideoThumbnail ? media_file?.thumbnail_path : imagePreview}
                      />
                    )}
                    {hasLocalVideoPreview && (
                      <StyledReactPlayer style={mediaStyle} src={previewUrl}>
                        <source src={previewUrl} type={file?.type} />
                      </StyledReactPlayer>
                    )}
                    {hasRemoteVideoThumbnail && (
                      <PlayButtonWrapper size={playButtonSize}>
                        <Icon name="mediaPlay" size={playButtonSize / 2} />
                      </PlayButtonWrapper>
                    )}
                  </RelativeWrapper>
                );
              })}
              {showImageSlider && (
                <Lightbox
                  mainSrc={imageUrls?.length ? imageUrls[imageIndex]?.url : ''}
                  nextSrc={imageUrls?.length > 1 ? imageUrls[(imageIndex + 1) % imageUrls.length]?.url : null}
                  prevSrc={
                    imageUrls?.length > 1
                      ? imageUrls[(imageIndex + imageUrls.length - 1) % imageUrls.length]?.url
                      : null
                  }
                  onCloseRequest={toggleImageSlider(imageIndex)}
                  onMovePrevRequest={onMoveImagePrev}
                  onMoveNextRequest={onMoveImageNext}
                />
              )}
              {Boolean(videoPlayerSignedUrl) && (
                <VideoPlayer signedUrl={videoPlayerSignedUrl} onModalClose={handleCloseVideoPlayer} />
              )}
            </>
          )}
        </FlexView>
      </Wrapper>
    </>
  );
};
