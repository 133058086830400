import styles from 'constants/styles';

export const invoiceTypes = {
  QUOTATION: {
    TABLE_LABEL: 'Quote',
    TYPE: 0,
    value: 'QUOTATION',
    label: 'Quotation',
    confirmationLabel: 'Quotation',
    tabelLabel: 'Quotation'
  },
  DEPOSIT: {
    TABLE_LABEL: 'Deposit',
    TYPE: 1,
    value: 'DEPOSIT_TO_PRO',
    label: 'Deposit Invoice',
    confirmationLabel: 'Deposit Invoice',
    tabelLabel: 'Deposit'
  },
  PARTIAL: {
    TABLE_LABEL: 'Deposit',
    TYPE: 2,
    value: 'DEPOSIT',
    label: 'Deposit Invoice',
    confirmationLabel: 'Deposit Invoice',
    tabelLabel: 'Deposit'
  },
  FINAL: {
    TABLE_LABEL: 'Final',
    TYPE: 3,
    value: 'FINAL_PAYMENT',
    label: 'Final Invoice',
    confirmationLabel: 'Final Invoice',
    tabelLabel: 'Final'
  }
};

export const invoiceItemTypes = {
  LABOR: {
    TYPE: 0,
    NAME: 'Labor'
  },
  MATERIAL: {
    TYPE: 1,
    NAME: 'Material'
  },
  FLAT: {
    TYPE: 2,
    NAME: 'Flat rate'
  },
  DEPOSIT: {
    TYPE: 3,
    NAME: 'Deposit'
  }
};

export const invoiceStatuses = {
  PENDING_ADMIN: {
    TYPE: 1,
    LABEL: 'Not Validated',
    VALUE: null,
    COLOR: styles.colors.DARK_YELLOW
  },
  PENDING_CUSTOMER: {
    TYPE: 2,
    LABEL: 'Pending',
    VALUE: null,
    COLOR: styles.colors.DARK_YELLOW
  },
  COMPLETED: {
    TYPE: 3,
    LABEL: 'Paid',
    VALUE: true,
    COLOR: styles.colors.DARK_GREEN
  },
  REJECTED: {
    TYPE: 4,
    LABEL: 'Rejected',
    VALUE: false,
    COLOR: styles.colors.DARK_GREY
  }
};

export const paymentInvoiceStatuses = {
  PENDING_ADMIN: 1,
  PENDING_CUSTOMER: 2,
  // payment statuses
  PROCESSING: 3,
  PAID: 4,
  INSUFFICIENT_FUNDS: 9,
  INCORRECT_ACCOUNT_DETAILS: 10,
  BANK_CANNOT_PROCESS: 11,
  DEBIT_NOT_AUTHORIZED: 12,
  INVALID_ACCOUNT: 13,
  // quotation statuses
  APPROVED: 5,
  REJECTED: 6,
  NOT_APPROVED: 7,
  EXPIRED: 8
};

export const commissionTypes = {
  SUB_FEE: 1,
  MARGIN: 2
};

export const dynamicPlatformFee = {
  BIG: {
    MIN_AMOUNT: 0,
    MAX_AMOUNT: 1000000,
    PERCENT_AMOUNT: '20',
    PERCENT_AMOUNT_BE: 2000
  },
  MEDIUM: {
    MIN_AMOUNT: 1000000,
    PERCENT_AMOUNT: '15',
    PERCENT_AMOUNT_BE: 1500
  }
};

export const jobQuotedStatuses = {
  DEFAULT: {
    NAME: ''
  },
  PENDING: {
    COLOR: styles.colors.DARK_YELLOW,
    NAME: 'Pending',
    TYPE: 1
  },
  APPROVED: {
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'Approved',
    TYPE: 2
  }
};

export const paymentMethods = {
  CARD: 0,
  DOBBY_CASH: 1,
  CASH: 3,
  TRANSFER: 4,
  ACH_DEBIT: 5
};
