import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from 'constants/styles';

import styled from 'styled-components';

export const RadioIcon = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.DARK_GREY_2};
  &:hover {
    background: unset;
  }
`;

export const RadioCheckedIcon = styled(RadioIcon)`
  &:before {
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.colors.DARK_GREEN_2};
    content: '';
  }
`;

export const StyledRadio = styled(Radio)`
  padding: 3px;

  svg:first-of-type.MuiSvgIcon-root {
    color: ${styles.colors.LIGHT_GRAY};
  }

  &.Mui-checked {
    color: ${styles.colors.RED};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.colors.RADIO_TEXT};
  padding-right: ${({ row }) => (row ? 26 : 0)}px;

  .MuiTypography-root {
    line-height: 1.33;
    font-weight: 400;
    font-size: 14px;
  }
  & .Mui-disabled {
    color: ${({ theme }) => theme.colors.RADIO_TEXT} !important;
  }
  & .Mui-checked + span {
    color: ${({ theme }) => theme.colors.RADIO_TEXT_CHECK};
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .MuiFormGroup-root {
    margin-left: 2px;
    ${({ row }) => row && 'flex-direction: row'};
    ${({ gap }) => gap && `gap: ${gap}`};
  }

  .MuiFormControlLabel-root {
    padding-right: 6px !important;
  }
  &:first-child {
    margin: 0;
  }
  .MuiRadio-root {
    padding: 4px 8px;
  }
  .MuiFormControlLabel-root {
    margin-left: -8px;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${styles.colors.GRAY};
  margin-bottom: 8px;
`;

export const Error = styled(Label)`
  color: ${styles.colors.RED};
  margin-top: 5px;
`;
