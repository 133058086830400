import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import { conversationTypes } from 'common/enums/chat';
import icon_close from 'static/images/close-icon.png';
import { unreadMessageSeparator } from 'common/enums/chat';
import dates from 'constants/dates';
import styles from 'constants/styles';
import modalTypes from 'constants/modalTypes';
import { formatters, isDateTimeAfter, convertUrlToS3Config } from 'utils';
import { Chat as ChatComponent } from 'components';
import { MessageBubble } from 'components/Chat';
import { videoExtensions } from 'common/enums/media';
import { getUrlFileExtension } from 'common/helpers/media';
import { adminStatuses } from 'common/enums/job';

import { getTaskMessageList, sendTaskMessage, getTaskMessageById } from 'store/actions/taskChat';
import { getSupportMessageList, sendSupportMessage, getSupportMessageById } from 'store/actions/supportChat';
import { deletePersistentNotification, openModal } from 'store/actions';
import {
  selectTaskMessageList,
  selectTaskMessageCount,
  selectTaskFirstMessagesLoaded,
  isTaskHistoryMessageLeft
} from 'store/reducers/taskChat';
import {
  selectSupportMessageList,
  selectSupportMessageCount,
  selectSupportFirstMessagesLoaded,
  isSupportHistoryMessageLeft
} from 'store/reducers/supportChat';
import { isTestTasksVisible } from 'store/reducers/app';

import { Icon, Avatar, LoadingSpinner } from 'components';
import { Message } from './components';
import {
  AttachmentButton,
  Time,
  SystemMessageText,
  MessageImagePreview,
  MediaMessageInnerWrapper,
  MediaMessageWrapper,
  LoadingContainer,
  ChatContainer,
  ChatPlaceholder,
  LoadingMessageAttachmentContainer,
  PlayButtonWrapper,
  StyledReactPlayer
} from './styled';
import { withTheme } from 'styled-components';

const PDFViewer = lazy(() => import('../../components/PDFViewer'));
const Lightbox = lazy(() => import('react-image-lightbox'));

const sendButtonStyles = {
  height: 40,
  width: 40,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center'
};

class Chat extends Component {
  attachmentFile = React.createRef();
  chatList = React.createRef();
  handleGetMessages = null;
  thumbnailImagesIntervals = [];

  state = {
    isLightBoxShown: false,
    isPDFViewerShown: false,
    mediaUrl: null
  };

  componentDidMount() {
    this.fetchMessageList({ firstMessagesLoaded: false, setIsOpen: false });
    if (this.props.setIsOpen) {
      this.deleteSupportChatPersistentNotifications();
    }
    // Add inner option for refresh. Store the same way as dev switch
    // TODO: add disabling of this in a config
    this.handleGetMessages = setInterval(() => {
      this.fetchMessageList({
        // This is needed when message polling is triggered and we have random id at the start of array
        idGt: this.props.messageList.find(
          (message) => !message._id.includes('-') && message._id !== unreadMessageSeparator.id
        )?._id,
        setIsOpen: this.props.setIsOpen
        // This is needed to not miss incoming messages when idGt of sent message is bigger then incoming for some reasons
        // idGt: this.props.messageList.find((x) => x.user._id !== 'support')?._id || -1
      });
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    const { setIsOpen, isInsideTabs } = this.props;
    if (prevProps.setIsOpen !== setIsOpen && setIsOpen) {
      this.fetchMessageList({ setIsOpen: true });
    }
    if (prevProps.setIsOpen !== setIsOpen && setIsOpen && isInsideTabs) {
      this.deleteSupportChatPersistentNotifications();
    }
  }

  componentWillUnmount() {
    if (this.props.setIsOpen) {
      this.fetchMessageList({ setIsOpen: true });
    }
    clearInterval(this.handleGetMessages);
    this.thumbnailImagesIntervals.forEach((interval) => {
      interval.clearInterval && interval.clearInterval();
    });
    if (this.chatList.current) {
      this.chatList.current.getScrollableNode().removeEventListener('wheel', this.invertedWheelEvent);
    }
  }

  fetchMessageById = ({ id = 0 } = {}) => {
    const { getTaskMessageById, getSupportMessageById, serviceId, userId } = this.props;
    if (serviceId) {
      getTaskMessageById({ id, job_id: serviceId });
    } else {
      getSupportMessageById({ id, user_id: userId });
    }
  };

  deleteSupportChatPersistentNotifications = () => {
    const { userId, deletePersistentNotification, setIsOpen } = this.props;
    if (userId && setIsOpen) {
      deletePersistentNotification({
        user_id: userId,
        conversation_type: conversationTypes.SUPPORT
      });
    }
  };

  fetchMessageList = ({ idLt = 0, idGt = 0, firstMessagesLoaded = true, setIsOpen } = {}) => {
    const { serviceId, userId, getTaskMessageList, getSupportMessageList } = this.props;

    const getMessagesArgs = {
      idGt,
      idLt,
      firstMessagesLoaded,
      setIsOpen
    };

    if (serviceId) {
      getTaskMessageList({ serviceId, ...getMessagesArgs });
    } else {
      getSupportMessageList({ userId, ...getMessagesArgs });
    }
  };

  setChatListRef = (element) => {
    this.chatList.current = element;
    if (this.chatList.current) {
      this.chatList.current.getScrollableNode().addEventListener('wheel', this.invertedWheelEvent);
    }
  };

  invertedWheelEvent = (e) => {
    if (this.chatList.current) {
      this.chatList.current.getScrollableNode().scrollTop -= e.deltaY;
      e.preventDefault();
    }
  };

  onEndReached = () => {
    const { messageList, messageCount, setIsOpen, isHistoryMessageLeft } = this.props;

    if (!isHistoryMessageLeft || messageList.length >= messageCount) {
      return;
    }
    this.fetchMessageList({
      idLt: messageList[messageList.length - 1]._id,
      setIsOpen
    });
  };

  renderSend = ({ onClick, disabled, text }) => {
    const sendButtonBackground = {
      backgroundColor: !text ? styles.colors.DISABLED_RED : styles.colors.RED
    };
    return (
      <div onClick={onClick} disabled={!text} style={{ ...sendButtonStyles, ...sendButtonBackground, display: 'flex' }}>
        <Icon name="send" size={18} />
      </div>
    );
  };

  handleMessageSend = (userMessages) => {
    const { serviceId, userId, sendTaskMessage, sendSupportMessage } = this.props;

    const messageText = userMessages[0] && userMessages[0].text;
    const createdAt = userMessages[0] && userMessages[0].createdAt.toISOString().split('.')[0];
    if (messageText) {
      const newMessage = {
        id: uuidv4(),
        date_created: createdAt,
        message: messageText,
        author_id: null
      };

      if (serviceId) {
        sendTaskMessage({
          ...newMessage,
          service_request_id: serviceId
        });
      } else {
        sendSupportMessage({
          ...newMessage,
          user_id: userId
        });
      }
    }
  };

  handleUrlPress = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  renderMessageText = ({ message, isCurrentUser }) => {
    const { serviceId, serviceInstance } = this.props;
    const author_id = message?.user?._id;
    const isCustomer = author_id === serviceInstance.user_id;

    if (!message) {
      return null;
    }
    return (
      <Message
        key={message._id}
        currentMessage={message}
        serviceInstance={serviceInstance}
        serviceId={serviceId}
        isCurrentUser={isCurrentUser && !isCustomer}
      />
    );
  };

  renderMessageImage = ({ message: { _id, image: image_url, createdAt, thumb_image_url, media } }) => {
    const ext = getUrlFileExtension(image_url || '');
    const hasMediaLocalVideoFileType = media?.type?.startsWith('video/');
    const hasVideo = Object.values(videoExtensions).includes(ext);
    const playButtonSize = 62;

    if (!thumb_image_url && hasMediaLocalVideoFileType) {
      const fileUrl = URL.createObjectURL(media, image_url, media);
      const hasUploading = image_url === 'uploadingImage';
      return (
        <MediaMessageWrapper>
          <MediaMessageInnerWrapper onClick={hasUploading ? null : this.openAttachedFile(image_url)}>
            <StyledReactPlayer>
              <source src={fileUrl} type={media?.type} />
            </StyledReactPlayer>
            {!hasUploading && (
              <PlayButtonWrapper size={playButtonSize}>
                <Icon name="mediaPlay" size={playButtonSize / 2} />
              </PlayButtonWrapper>
            )}
          </MediaMessageInnerWrapper>
        </MediaMessageWrapper>
      );
    }

    if (!thumb_image_url) {
      const isThumbnailCreating = isDateTimeAfter({
        datetime: createdAt,
        duration: 3
      });
      if (!_id.includes('-') && !this.thumbnailImagesIntervals[_id] && isThumbnailCreating) {
        this.thumbnailImagesIntervals[_id] = setInterval(() => this.fetchMessageById({ id: _id }), 7500);
      }
      return (
        <div onClick={image_url !== 'uploadingImage' ? this.openAttachedFile(image_url) : null}>
          <Icon size={100} backgroundSize={120} name={ext === '.pdf' ? 'file' : 'attachment'} />
        </div>
      );
    }

    const thumbExt = getUrlFileExtension(thumb_image_url || '');
    if (this.thumbnailImagesIntervals[_id]) {
      clearInterval(this.thumbnailImagesIntervals[_id]);
      delete this.thumbnailImagesIntervals[_id];
    }

    if (thumbExt === '.pdf') {
      // for cases when backend put wrong thumbnail for pdf
      return (
        <div onClick={this.openAttachedFile(image_url)}>
          <Icon size={100} backgroundSize={120} name="file" />
        </div>
      );
    }

    return (
      <MediaMessageWrapper>
        <MediaMessageInnerWrapper onClick={this.openAttachedFile(image_url)}>
          <MessageImagePreview type={ext === '.pdf' ? 'vertical' : 'horizontal'} src={thumb_image_url} />
          {hasVideo && (
            <PlayButtonWrapper size={playButtonSize}>
              <Icon name="mediaPlay" size={playButtonSize / 2} />
            </PlayButtonWrapper>
          )}
        </MediaMessageInnerWrapper>
      </MediaMessageWrapper>
    );
  };

  renderTime = ({ message }) => (
    <Time>
      {message.deliveryError || message.updateError ? (
        <Icon src={icon_close} size={10} backgroundSize={16} backgroundColor={styles.colors.RED} />
      ) : null}
      {formatters.dateUTC(message.createdAt, dates.TIME)}
    </Time>
  );

  allowedFileType = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'video/*'];

  addMessageMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.attachmentFile.current.click();
  };

  openDateTimePicker = () => {
    const { openModal, serviceId } = this.props;
    if (serviceId) {
      openModal(modalTypes.SCHEDULE_VISIT, {
        task_id: serviceId
      });
    }
  };

  onSelectedAttachment = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const files = Array.from(e.target.files);

    const { serviceId, userId, sendTaskMessage, sendSupportMessage } = this.props;
    files.forEach((file) => {
      const newMessage = {
        id: uuidv4(),
        date_created: moment(),
        author_id: null,
        media: file,
        image_url: 'uploadingImage' // it is needed to not show empty bubble when uploading image
      };

      if (serviceId) {
        sendTaskMessage({
          ...newMessage,
          service_request_id: serviceId
        });
      } else {
        sendSupportMessage({
          ...newMessage,
          user_id: userId
        });
      }
    });
  };

  renderActions = () => {
    const { serviceId, serviceInstance } = this.props;

    const hasJobAcceptedStatus = [adminStatuses.REQUESTED.TYPE, adminStatuses.ASSIGNED.TYPE].includes(
      serviceInstance.admin_status
    );
    const hasJobFinishedStatus = [
      adminStatuses.BUNDLED.TYPE,
      adminStatuses.PAID.TYPE,
      adminStatuses.UNFULFILLED.TYPE
    ].includes(serviceInstance.admin_status);

    const displayScheduleButton = serviceInstance.is_open && !hasJobAcceptedStatus && !hasJobFinishedStatus;
    return (
      <>
        <AttachmentButton>
          <input
            type="file"
            ref={this.attachmentFile}
            multiple
            style={{ display: 'none' }}
            accept={this.allowedFileType.join(',')}
            onChange={this.onSelectedAttachment}
          />
          <div style={{ display: 'flex' }} onClick={this.addMessageMedia}>
            <Icon name="attachment" size={24} color={styles.colors.LIGHT_GRAY} />
          </div>
        </AttachmentButton>
        {Boolean(serviceId) && displayScheduleButton && (
          <AttachmentButton>
            <div onClick={this.openDateTimePicker}>
              <Icon name="calendar" size={24} />
            </div>
          </AttachmentButton>
        )}
      </>
    );
  };

  handleGetChatColor = ({ author_id, isCurrentUser }) => {
    const { serviceInstance, theme, has3WayChat, hasCustomerChat } = this.props;
    const isCustomerIn3WayChat = has3WayChat && author_id === serviceInstance.user_id;
    const incomingBubleColor =
      isCustomerIn3WayChat || hasCustomerChat ? theme.colors.MESSAGE_HO : theme.colors.MESSAGE_PRO_AGENT;
    return {
      color: isCurrentUser ? theme.colors.MESSAGE_OPS : incomingBubleColor,
      hasRightPosition: isCustomerIn3WayChat
    };
  };

  renderBubble = (props) => {
    const { userId } = this.props;
    const { isCurrentUser } = props;
    const { color, hasRightPosition } = this.handleGetChatColor({
      author_id: props.message?.user?._id,
      isCurrentUser
    });

    return (
      <MessageBubble
        {...props}
        hasRightPosition={hasRightPosition}
        isCurrentUser={hasRightPosition || isCurrentUser}
        color={color}
        userID={userId}
      />
    );
  };

  renderAvatar = ({ message }) => {
    const { serviceInstance, userInstance } = this.props;
    if (!message.author_id) {
      return null;
    }

    // chats on the ServicePage
    if (serviceInstance?.id) {
      if (serviceInstance?.user_id && message.author_id === serviceInstance?.user_id) {
        return <Avatar avatarSize={16} url={serviceInstance.user.profile_image} size={36} />;
      }

      if (serviceInstance.service_provider_id && message.author_id === serviceInstance.service_provider_id) {
        return <Avatar avatarSize={16} url={serviceInstance.service_provider.user.profile_image} size={36} />;
      }

      return <Avatar avatarSize={16} size={36} />;
    }

    // chats on the UserPage
    return <Avatar avatarSize={16} size={36} url={userInstance?.profile_image_thumbnail} />;
  };

  renderTicks = ({ _id, author_id }) => (
    <LoadingMessageAttachmentContainer>
      {!author_id && _id?.includes('-') && <LoadingSpinner />}
    </LoadingMessageAttachmentContainer>
  );

  renderSystemMessage = ({ message }) => {
    return <SystemMessageText>{` ${message.text} `}</SystemMessageText>;
  };

  renderEmptyMessage = () => {
    const { messageList, firstMessagesLoaded } = this.props;

    if (!firstMessagesLoaded) {
      return (
        <LoadingContainer>
          <LoadingSpinner text="Please wait" type="logo" />
        </LoadingContainer>
      );
    }
    if (!messageList.length) {
      return (
        <ChatPlaceholder>
          <Icon size={175} name="feather" />
          <span>Just leave your message here with more details and pictures...</span>
        </ChatPlaceholder>
      );
    }
    return null;
  };

  renderLoading = () => <span>Loading...</span>;

  openAttachedFile = (path) => async () => {
    const config = convertUrlToS3Config(path);
    const fileExt = getUrlFileExtension(config.Key || '');
    const signedUrl = await Storage.get(config.Key);

    const hasVideo = Object.values(videoExtensions).includes(fileExt);
    if (hasVideo) {
      const { openModal } = this.props;
      openModal(modalTypes.VIDEO_PLAYER, {
        signedUrl
      });
      return;
    }

    const hasPDF = fileExt === '.pdf';
    this.setState({
      mediaUrl: signedUrl,
      ...(hasPDF ? { isPDFViewerShown: true } : { isLightBoxShown: true })
    });
  };

  closePDFViewer = () =>
    this.setState({
      isPDFViewerShown: false,
      mediaUrl: null
    });

  closeImageLightBox = () =>
    this.setState({
      isLightBoxShown: false,
      mediaUrl: null
    });

  render() {
    const { isLightBoxShown, isPDFViewerShown, mediaUrl } = this.state;
    const { messageList, isInputToolbarDisabled } = this.props;

    return (
      <>
        <ChatContainer>
          <ChatComponent
            currentUser={{ _id: 'support' }}
            isMessagesLoading={false}
            isInputToolbarDisabled={isInputToolbarDisabled}
            messages={messageList}
            keyExtractor={(item) => `${item._id}_${item.link_list_loaded}_${Boolean(item.thumb_image_url)}`}
            onMessagesEnd={this.onEndReached}
            placeholder="Type here ..."
            onMessageSend={this.handleMessageSend}
            overwriteComponents={{
              actions: this.renderActions,
              sendButton: this.renderSend,
              emptyMessageList: this.renderEmptyMessage,
              loadingIndicator: this.renderLoading,
              messageSystem: this.renderSystemMessage,
              messageBubble: this.renderBubble,
              messageText: this.renderMessageText,
              messageImage: this.renderMessageImage,
              messageTime: this.renderTime,
              avatar: this.renderAvatar,
              messageTicks: this.renderTicks
            }}
          />
        </ChatContainer>
        {isLightBoxShown && <Lightbox mainSrc={mediaUrl} onCloseRequest={this.closeImageLightBox} />}
        {isPDFViewerShown && <PDFViewer onViewerClose={this.closePDFViewer} mediaUrl={mediaUrl} />}
      </>
    );
  }
}

Chat.propTypes = {
  serviceInstance: PropTypes.object.isRequired,
  serviceId: PropTypes.number,
  userId: PropTypes.number,
  getTaskMessageList: PropTypes.func.isRequired,
  getSupportMessageList: PropTypes.func.isRequired,
  messageCount: PropTypes.number,
  messageLoading: PropTypes.bool.isRequired,
  messageList: PropTypes.array.isRequired
};

Chat.defaultProps = {
  serviceInstance: {},
  messageCount: 0,
  messageLoading: true,
  messageList: []
};

const mapStateToProps = (state, { serviceId, userId }) => ({
  messageList: serviceId ? selectTaskMessageList(state) : selectSupportMessageList(state, userId),
  messageCount: serviceId ? selectTaskMessageCount(state) : selectSupportMessageCount(state, userId),
  firstMessagesLoaded: serviceId
    ? selectTaskFirstMessagesLoaded(state)
    : selectSupportFirstMessagesLoaded(state, userId),
  isTestTasksVisible: isTestTasksVisible(state),
  isHistoryMessageLeft: serviceId ? isTaskHistoryMessageLeft(state) : isSupportHistoryMessageLeft(state, userId)
});

const mapDispatchToProps = {
  getTaskMessageList,
  sendTaskMessage,
  getSupportMessageList,
  sendSupportMessage,
  getSupportMessageById,
  getTaskMessageById,
  deletePersistentNotification,
  openModal
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Chat));
