import React from 'react';

const IconAttachment = (props) => {
  const { size = 24, color = '#D6D9E5' } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.49614 9.99992L9.46935 2.32939C11.171 0.457572 14.0344 0.230542 16.0098 1.81083C18.1695 3.53862 18.4226 6.7308 16.5621 8.77735L7.25994 19.0097C6.00785 20.387 3.87821 20.4938 2.49468 19.2486C1.11229 18.0045 0.993762 15.8776 2.22936 14.4876L10.7403 4.91274C11.4403 4.12528 12.6389 4.03556 13.4483 4.71005C14.295 5.41567 14.3898 6.68203 13.6575 7.50584L6.99614 14.9999"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconAttachment;
