import styles from 'constants/styles';

export const modalIconProps = {
  INSPECTION_OK: {
    backgroundColor: styles.colors.LIGHT_GRAY_2,
    backgroundSize: 62,
    color: styles.colors.RED,
    name: 'ok',
    size: 27
  },
  TASK_OK: {
    backgroundColor: styles.colors.GREEN,
    name: 'thumbUp'
  },
  TASK_ERROR: {
    backgroundColor: styles.colors.RED,
    name: 'thumbDown'
  },
  OK: {
    name: 'ok'
  },
  DEFAULT: {
    color: styles.colors.WHITE,
    size: 23,
    backgroundSize: 65
  }
};

export const modalProps = {
  INSPECTION_OK: {
    maxWidth: 323,
    minWidth: 296
  },
  CANCEL_TASK_REASON: {
    minHeight: 400,
    middlePaddings: true,
    overflowMode: 'none'
  },
  DEFAULT: {
    maxWidth: 504,
    minWidth: 504,
    maxHeight: 500
  }
};
