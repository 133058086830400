import React from 'react';
import styles from 'constants/styles';

const IconCloseOutlined = ({ size = 24, color = styles.colors.DARK_RED }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
        stroke={color}
      />
      <path d="M16.2863 16.2857L7.71484 7.71428" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.71373 16.2857L16.2852 7.71428" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default IconCloseOutlined;
