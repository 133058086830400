import styled from 'styled-components';

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  border-radius: 50%;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.INPUT_BG};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  display: inline-block;
  outline: 0 none;
  width: 100%;
  padding: 8px ${({ reset }) => (reset ? '32px' : '16px')} 8px 16px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }

  ${({ invalid, theme }) =>
    invalid &&
    `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}

  ${({ width }) =>
    Boolean(width) &&
    `
    width: ${width}px;
  `};

  &:active,
  &:focus {
    outline: 0 none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
    border: none;

    ${({ invalid, theme }) =>
      invalid &&
      `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}
  }

  &[disabled],
  &[readOnly] {
    color: ${({ theme }) => theme.colors.DARK_GREY_2};
    box-shadow: none;
    background: #353d41;
    &::placeholder {
      color: #676d70;
    }
  }

  ${({ clickOnly }) => clickOnly && 'pointer-events: none;'}
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.GRAY};
  margin-bottom: 5px;

  ${({ small, theme }) =>
    small &&
    `
    font-weight: 500;
    font-size: 10px;
    line-height: 1.2;
    color: ${theme.colors.DARK_GREY_2};
  `}

  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && ` color: ${color};`}
`;

export const Error = styled(Label)`
  color: ${({ theme }) => theme.colors.INPUT_ERROR};
  margin-top: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
