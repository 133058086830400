export const unreadMessageSeparator = {
  id: 'unread_messages_separator_id',
  message: 'Unread messages'
};

export const conversationTypes = {
  DIRECT: 0,
  JOB_GROUP: 1,
  SUPPORT: 3
};
