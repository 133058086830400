import React, { useCallback, Fragment } from 'react';
import { useRouter } from 'hooks';
import { StyledTooltip } from 'components';
import { TabHeader, TabList, TabsWrapper, TabWrapper } from './styled';

const Tabs = ({
  boldFont,
  children,
  headerWrapperStyle,
  minHeight,
  onTabChange = () => {},
  tabs,
  hasCounters,
  scrollLeft,
  scrollRight
}) => {
  const { queryParams } = useRouter();
  const tabId = queryParams.get('tab_id');

  const handleTabChange = useCallback(
    (tab, index) => () => {
      if (index > 6) {
        scrollRight();
      }
      if (index < 5) {
        scrollLeft();
      }
      if (onTabChange) {
        onTabChange(tab);
        return;
      }
      queryParams.set('tab_id', tab.TYPE);
    },
    [onTabChange, queryParams, scrollLeft, scrollRight]
  );

  return (
    <TabsWrapper minHeight={minHeight}>
      <TabList style={headerWrapperStyle}>
        {tabs.map((tab, i) => {
          const TabItemPrefixWraper = tab?.HEADER_PREFIX_TOOLTIP ? StyledTooltip : Fragment;
          const tabItemPrefixWraperProps = tab?.HEADER_PREFIX_TOOLTIP
            ? {
                placement: 'top-start',
                arrow: true,
                title: tab?.HEADER_PREFIX_TOOLTIP
              }
            : {};
          return (
            <TabHeader
              key={tab.TYPE}
              boldFont={boldFont}
              isActive={tab.TYPE === tabId}
              onClick={handleTabChange(tab, i)}>
              {Boolean(tab.HEADER_PREFIX) && (
                <TabItemPrefixWraper {...tabItemPrefixWraperProps}>
                  <span>{tab.HEADER_PREFIX}</span>
                </TabItemPrefixWraper>
              )}
              {tab.HEADER_TITLE}
              {hasCounters && tab.count ? ` (${tab.count})` : ''}
            </TabHeader>
          );
        })}
      </TabList>
      {children?.filter(Boolean).map((tab) => (
        <TabWrapper isActive={tab.TYPE === tabId}>
          {React.cloneElement(tab, { isActive: tab.TYPE === tabId })}
        </TabWrapper>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
