import React from 'react';

import { isDayDifferent } from './helpers/dates';
import { isUserDifferent } from './helpers/functions';

import { AvatarMargin } from './styled';

const Avatar = ({ message = {}, nextMessage = {}, isCurrentUser, overwriteComponents = {} }) => {
  const { avatar = null } = overwriteComponents;
  if (isCurrentUser || avatar === null) {
    return null;
  }
  const isVisible =
    isDayDifferent(message.createdAt, nextMessage.createdAt) || isUserDifferent(message.user, nextMessage.user);
  if (!isVisible) {
    return <AvatarMargin />;
  }

  return avatar({ message });
};

export default Avatar;
