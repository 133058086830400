import styled, { css } from 'styled-components';
import styles from 'constants/styles';

export const Label = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${styles.colors.DARK_GREY};
`;

export const Wrapper = styled.div`
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x proximity;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  float: left;
`;

export const AddFileButton = styled.label`
  height: 64px;
  width: 88px;
  margin: 11px 10px 0 0;
  border: 1px dashed ${({ theme }) => theme.colors.MEDIA_UPLOADER_BORDER};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.MEDIA_UPLOADER};

  div svg path {
    fill: ${styles.colors.GRAY};
  }
`;

const attachedFileCss = css`
  height: 64px;
  width: 88px;
  border-radius: 8px;
`;

const attachedFileCssMargins = css`
  margin-top: 11px;
  margin-right: 18px;
  position: relative;
`;

export const AttachedFile = styled.div`
  ${attachedFileCss}
  ${attachedFileCssMargins}

  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledReactPlayer = styled.video`
  ${attachedFileCss}
  object-fit: cover;
`;

export const PlayerWrapper = styled.div`
  ${attachedFileCssMargins}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
`;
