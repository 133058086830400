export const filterTypes = {
  SELECT: 'SELECT',
  DATE: 'DATE',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN'
};

export const customJobFilters = {
  ADMIN_STATUS: {
    NAME: 'Stage',
    TYPE: 'admin_status',
    DATA_TYPE: filterTypes.SELECT
  },
  DATETIME_CANCELED_FROM: {
    NAME: 'Canceled Date',
    TYPE: 'datetime_canceled_gte',
    DATA_TYPE: filterTypes.DATE
  },
  DATETIME_CANCELED_TO: {
    NAME: 'Canceled Date',
    TYPE: 'datetime_canceled_lte',
    DATA_TYPE: filterTypes.DATE
  },
  DATETIME_COMPLETED_FROM: {
    NAME: 'Completed Date',
    TYPE: 'datetime_completed_gte',
    DATA_TYPE: filterTypes.DATE
  },
  DATETIME_COMPLETED_TO: {
    NAME: 'Completed Date',
    TYPE: 'datetime_completed_lte',
    DATA_TYPE: filterTypes.DATE
  },
  DATETIME_CREATED_FROM: {
    NAME: 'Request Date',
    TYPE: 'date_created_gte',
    DATA_TYPE: filterTypes.DATE
  },
  DATETIME_CREATED_TO: {
    NAME: 'Request Date',
    TYPE: 'date_created_lte',
    DATA_TYPE: filterTypes.DATE
  },
  HAS_EARLY_WARNING: {
    NAME: 'With Early Warning',
    TYPE: 'is_warning_enabled',
    DATA_TYPE: filterTypes.BOOLEAN
  },
  HAS_UNREAD_MESSAGES: {
    NAME: 'With Unread Messages',
    TYPE: 'is_unread_message',
    DATA_TYPE: filterTypes.BOOLEAN
  },
  PROMO_CODE_ID: {
    NAME: 'Reward',
    TYPE: 'promo_code_id',
    DATA_TYPE: filterTypes.STRING
  },
  JOBS_LOST: {
    TYPE: 'jobs_lost',
    VALUES: [
      {
        NAME: 'Jobs unassigned',
        VALUE: '1'
      },
      {
        NAME: 'Jobs saved',
        VALUE: '0'
      }
    ]
  },
  PROVIDER_ID: {
    TYPE: 'provider_id'
  }
};

export const addressStringFilters = {
  PROVIDER_FULL_NAME: {
    TYPE: 'provider_full_name'
  }
};

export const jobFilters = [
  customJobFilters.ADMIN_STATUS,
  customJobFilters.DATETIME_CREATED_FROM,
  customJobFilters.DATETIME_CREATED_TO,
  customJobFilters.DATETIME_COMPLETED_FROM,
  customJobFilters.DATETIME_COMPLETED_TO,
  customJobFilters.DATETIME_CANCELED_FROM,
  customJobFilters.DATETIME_CANCELED_TO,
  customJobFilters.HAS_EARLY_WARNING,
  customJobFilters.HAS_UNREAD_MESSAGES
];

export const hiddenFilters = [customJobFilters.PROMO_CODE_ID, customJobFilters.JOBS_LOST, customJobFilters.PROVIDER_ID];
