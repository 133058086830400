import React from 'react';

import { RotatedSvg } from './styled';

const IconTriangle = ({ size = 16, rotate = 0, color = '#D6D9E5' }) => {
  return (
    <RotatedSvg
      width={size}
      height={size * 0.5625}
      viewBox="0 0 16 9"
      fill="none"
      rotate={rotate}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0H0L7.77143 9L16 0Z" fill={color} />
    </RotatedSvg>
  );
};

export default IconTriangle;
