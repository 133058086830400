import React from 'react';

const IconLeftArrow = () => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20733 0.292893C8.59786 0.683417 8.59786 1.31658 8.20733 1.70711L2.88672 7.02772L8.23503 12.8217C8.60963 13.2275 8.58433 13.8602 8.17851 14.2348C7.77269 14.6094 7.14003 14.5841 6.76542 14.1783L1.41711 8.38428C0.688999 7.59549 0.713449 6.37256 1.47251 5.6135L6.79312 0.292893C7.18364 -0.0976311 7.81681 -0.0976311 8.20733 0.292893Z"
      fill="white"
    />
  </svg>
);

export default IconLeftArrow;
