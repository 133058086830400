import styled from 'styled-components';
import styles from 'constants/styles';

export const Wrapper = styled.div`
  width: 100%;
  padding: 33px 0 0 0;
`;

export const Divider = styled.div`
  border: 1px solid ${styles.colors.LIGHT_GRAY_2};
  margin: 0 16px 0 21px;
`;

export const LoadMoreButton = styled.button`
  border: none;
  box-shadow: none;
  padding: 25px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.GRAY};
  background-color: ${styles.colors.WHITE};
  padding: 15px 20px 20px;

  &:hover:not([disabled]) {
    color: ${styles.colors.DARK_GREEN_3};
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  padding: 0 16px;
  color: ${styles.colors.GRAY};
`;

export const LocationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 16px 23px 24px;
  &:hover:not([disabled]) {
    background-color: ${styles.colors.LIGHT_GRAY_2};
    cursor: pointer;
  }
`;

export const SideBlock = styled.div`
  margin-top: 5px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FullName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 7px;
  color: ${({ color }) => color || styles.colors.BLACK};
`;

export const PhoneNumber = styled(FullName)`
  margin-bottom: 6px;
`;

export const Address = styled(FullName)`
  margin-bottom: 3px;
`;

export const InviteNote = styled(FullName)`
  font-weight: 500;
  margin-bottom: 3px;
`;

export const LocationCustomer = styled.div`
  padding: 10px 12px 8px 13px;
  margin-left: 16px;
  background-color: ${styles.colors.WHITE};
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.7;
  font-weight: 300;
  text-align: center;
  color: ${styles.colors.BLACK};
`;

export const LocationPhoto = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin-right: 19px;
`;

export const LocationPhotoImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;
