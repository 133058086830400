import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { LoadingDots } from 'components';

import { WORKER_URL } from './constants';
import {
  ViewerShadow,
  ViewerWrapper,
  InputToolbarItem,
  ToolbarItem,
  ToolbarWrapper,
  SeparateToolbarItem,
  TextToolbarItem
} from './styled';

const PDFViewer = ({ onViewerClose, mediaUrl, fileNameGenerator }) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  if (!mediaUrl) {
    return null;
  }

  const onDocumentLoadSuccess = ({ doc } = {}) => {
    const numPages = doc?._pdfInfo?.numPages;
    if (numPages !== numberOfPages) {
      setNumberOfPages(numPages);
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    ...(fileNameGenerator
      ? {
          toolbarPlugin: {
            getFilePlugin: { fileNameGenerator }
          }
        }
      : {}),
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {({
          CurrentPageInput,
          GoToNextPage,
          Download,
          GoToPreviousPage,
          // NumberOfPages,
          ZoomIn,
          Zoom,
          ZoomOut,
          Print,
          ShowSearchPopover
        }) => (
          <ToolbarWrapper>
            <ToolbarItem>
              <ShowSearchPopover />
            </ToolbarItem>
            <ToolbarItem>
              <ZoomOut />
            </ToolbarItem>
            <ToolbarItem>
              <Zoom />
            </ToolbarItem>
            <ToolbarItem>
              <ZoomIn />
            </ToolbarItem>
            <SeparateToolbarItem>
              <GoToPreviousPage />
            </SeparateToolbarItem>
            <InputToolbarItem>
              <CurrentPageInput />
            </InputToolbarItem>
            <TextToolbarItem>of {numberOfPages || 0}</TextToolbarItem>
            {/* <NumberOfPages /> - disapears on document loaded: */}
            <ToolbarItem>
              <GoToNextPage />
            </ToolbarItem>
            <SeparateToolbarItem>
              <Download />
            </SeparateToolbarItem>
            <ToolbarItem>
              <Print />
            </ToolbarItem>
          </ToolbarWrapper>
        )}
      </Toolbar>
    )
  });

  return (
    <ViewerShadow onClick={onViewerClose}>
      <Worker workerUrl={WORKER_URL}>
        <ViewerWrapper onClick={(e) => e.stopPropagation()}>
          <Viewer
            fileUrl={mediaUrl}
            plugins={[defaultLayoutPluginInstance]}
            onDocumentLoad={onDocumentLoadSuccess}
            renderLoader={() => <LoadingDots />}
          />
        </ViewerWrapper>
      </Worker>
    </ViewerShadow>
  );
};

export default PDFViewer;
