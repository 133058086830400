import React from 'react';
import styles from 'constants/styles';

const IconInfo = ({ size = 2, color = styles.colors.WHITE }) => {
  return (
    <svg width={size} height={size * 4} viewBox="0 0 2 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2C1.55229 2 2 1.57705 2 1.01595C2 0.454855 1.55228 0 0.999996 0C0.447718 0 0 0.454855 0 1.01595C0 1.57705 0.447722 2 1 2ZM1.00013 2.70716C0.589097 2.70716 0.255887 3.04568 0.255887 3.46327V7.24386C0.255887 7.66143 0.589097 8 1.00013 8C1.41117 8 1.74438 7.66143 1.74438 7.24386V3.46328C1.74438 3.04568 1.41117 2.70716 1.00013 2.70716Z"
        fill={color}
      />
    </svg>
  );
};

export default IconInfo;
