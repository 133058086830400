import styled from 'styled-components';
import styles from 'constants/styles';

export const Background = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ backgroundSize = 30, width }) => width ?? backgroundSize}px;
  height: ${({ backgroundSize = 30, height }) => height ?? backgroundSize}px;
  border-radius: 50%;
  background: ${({ backgroundColor }) => backgroundColor || 'none'};
  ${({ border }) =>
    border &&
    `
    border: 1px solid ${styles.colors.WHITE};
  `};
  ${({ clickable }) =>
    clickable &&
    `
    &:hover {
      cursor: pointer;
    }
  `};
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const Image = styled.img`
  width: ${({ size = 20 }) => size}px;
  height: ${({ size = 20 }) => size}px;
  color: ${({ color }) => color || '#FFF'};
`;

export const IconGalleryWrapper = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  justify-content: center;

  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const IconItem = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

export const RotatedSvg = styled.svg`
  ${({ rotate }) => (rotate ? `transform:rotate(${rotate}deg)` : '')}
`;
