export const operationalAreaIDs = {
  DCMA: {
    TYPE: 'DCMA',
    NAME: 'DMV'
  },
  CHL: {
    TYPE: 'CHL',
    NAME: 'CLT'
  },
  SFBA: {
    TYPE: 'SFBA',
    NAME: 'SFBA'
  },
  null: {
    TYPE: 'null',
    NAME: 'Other',
    IS_EDIT_FORM_DISABLED: true
  }
};
