import styled from 'styled-components';
import { typographyVariants } from 'common/enums/typography';

export const StyledTypography = styled.p`
  color: ${({ color }) => color};
  ${({ variant }) => {
    switch (variant) {
      case typographyVariants.H1:
        return `
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
      `;
      case typographyVariants.H2:
        return `
        font-size: 20px;
        line-height: 1.2;
        font-weight: 400;
      `;
      case typographyVariants.H3:
        return `
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
      `;
      case typographyVariants.H4:
        return `
        font-size: 14px;
        line-height: 1.2;
        font-weight: 700;
      `;
      case typographyVariants.TEXT:
        return `
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
      `;
      case typographyVariants.D1:
        return `
        font-size: 12px;
        line-height: 1.2;
        font-weight: 500;
      `;
      case typographyVariants.D2:
        return `
        font-size: 12px;
        line-height: 1.3;
        font-weight: 400;
      `;
      case typographyVariants.C1:
        return `
        font-size: 10px;
        line-height: 1.2;
        font-weight: 500;
      `;
      case typographyVariants.C2:
        return `
        font-weight: 400;
        font-size: 8px;
        line-height: 1.25;
      `;
      default:
        return `
        font-size: 14px;
        line-height: 1.15;
        font-weight: 400;
      `;
    }
  }}
`;
