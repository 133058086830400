import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouter } from 'hooks';

import { apiPageSizes } from 'common/enums/queryParams';
import { getUserServiceList } from 'store/actions/user';
import {
  selectCustomerActiveServiceList,
  selectCustomerRecentlyCompletedServiceList,
  selectCustomerCompletedServiceList,
  selectCustomerCancelledServiceList
} from 'store/reducers/customer';
import {
  selectProviderActiveServiceList,
  selectProviderRecentlyCompletedServiceList,
  selectProviderCompletedServiceList,
  selectProviderCancelledServiceList
} from 'store/reducers/provider';
import routes from 'constants/routes';
import { openNestedAvailable } from 'utils';

import { UserServiceList, OUTDATED_TABS } from 'components';
import { changeHasGoBackButton } from 'store/actions/navigation';
import { selectHasGoBackButton } from 'store/reducers/navigation';
import { jobTypesByTab } from 'common/enums/job';
import { getJobRequestParams } from 'common/helpers/job';

const UserServiceLists = ({
  customerId,
  providerId,
  activeServiceList,
  // recentlyCompletedServiceList,
  completedServiceList,
  getUserServiceList,
  cancelledServiceList,
  userGroupId,
  routePrefix,
  rootId,
  changeHasGoBackButton,
  siblingPaginationType,
  hasGoBackButton
}) => {
  const getServiceList = ({ page, is_open, type }) => {
    getUserServiceList({
      customer_id: customerId,
      provider_id: providerId,
      page,
      is_open,
      limit: apiPageSizes.SMALL,
      ...getJobRequestParams(type)
    });
  };

  useEffect(() => {
    getServiceList({
      page: 1,
      is_open: 1,
      type: jobTypesByTab.ACTIVE
    });
    // getServiceList({
    //   page: 1,
    //   type: jobTypesByTab.RECENTLY_COMPLETED
    // });
    getServiceList({
      page: 1,
      type: jobTypesByTab.CANCELLED
    });
    getServiceList({
      page: 1,
      type: jobTypesByTab.PAID
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, providerId]);

  const {
    location: { pathname },
    navigate
  } = useRouter();
  const onJobClick = ({ type, id }, e) => {
    e.stopPropagation();
    e.preventDefault();
    const prettifyRoutePrefix = openNestedAvailable({ pathname, isGoBack: hasGoBackButton }) ? routePrefix : '';
    const route = `${prettifyRoutePrefix}${routes.SERVICE}/${id}`;
    if (e.type === 'click' && (e.ctrlKey || e.metaKey)) {
      window.open(route, '_blank');
      return;
    }
    if (e.type === 'contextmenu') {
      window.open(route, '_blank').focus();
      return;
    }

    if (!openNestedAvailable({ pathname, isGoBack: hasGoBackButton }) && !hasGoBackButton) {
      changeHasGoBackButton(true); // for navigate only, not on new tab
    }
    navigate(route, {
      state: {
        jobType: type,
        hasLoadMoreButton: true,
        rootId,
        siblingPaginationType
      }
    });
  };

  const hasJobs = Boolean(
    activeServiceList?.count ||
      // recentlyCompletedServiceList.count ||
      completedServiceList.count ||
      cancelledServiceList.count
  );
  return (
    <OUTDATED_TABS
      bg="unset"
      border
      stickHeader
      headers={[
        `Active (${activeServiceList.count || 0})`,
        // `Recently completed (${recentlyCompletedServiceList.count || 0})`,
        `Paid (${completedServiceList.count || 0})`,
        `Canceled (${cancelledServiceList.count || 0})`
      ]}
      boldFont>
      <UserServiceList
        onJobClick={onJobClick}
        onLoadMore={() =>
          getServiceList({
            page: activeServiceList.page + 1,
            type: jobTypesByTab.ACTIVE
          })
        }
        list={activeServiceList}
        hasJobs={hasJobs}
        userGroupId={userGroupId}
        type={jobTypesByTab.ACTIVE}
        customerId={customerId}
      />
      {/* <UserServiceList
        onJobClick={onJobClick}
        onLoadMore={() =>
          getServiceList({
            page: recentlyCompletedServiceList.page + 1,
            type: jobTypesByTab.RECENTLY_COMPLETED
          })
        }
        list={recentlyCompletedServiceList}
        hasJobs={hasJobs}
        userGroupId={userGroupId}
        type={jobTypesByTab.RECENTLY_COMPLETED}
        customerId={customerId}
      /> */}
      <UserServiceList
        onJobClick={onJobClick}
        onLoadMore={() =>
          getServiceList({
            page: completedServiceList.page + 1,
            type: jobTypesByTab.PAID
          })
        }
        list={completedServiceList}
        userGroupId={userGroupId}
        type={jobTypesByTab.PAID}
        hasJobs={hasJobs}
        customerId={customerId}
      />
      <UserServiceList
        onJobClick={onJobClick}
        onLoadMore={() =>
          getServiceList({
            page: cancelledServiceList.page + 1,
            type: jobTypesByTab.CANCELLED
          })
        }
        list={cancelledServiceList}
        hasJobs={hasJobs}
        userGroupId={userGroupId}
        type={jobTypesByTab.CANCELLED}
        customerId={customerId}
      />
    </OUTDATED_TABS>
  );
};

UserServiceLists.propTypes = {
  getUserServiceList: PropTypes.func.isRequired,
  activeServiceList: PropTypes.object.isRequired,
  completedServiceList: PropTypes.object.isRequired
};

const mapStateToProps = (state, { customerId }) => ({
  activeServiceList: customerId ? selectCustomerActiveServiceList(state) : selectProviderActiveServiceList(state),
  // recentlyCompletedServiceList: customerId
  //   ? selectCustomerRecentlyCompletedServiceList(state)
  //   : selectProviderRecentlyCompletedServiceList(state),
  completedServiceList: customerId
    ? selectCustomerCompletedServiceList(state)
    : selectProviderCompletedServiceList(state),
  cancelledServiceList: customerId
    ? selectCustomerCancelledServiceList(state)
    : selectProviderCancelledServiceList(state),
  hasGoBackButton: selectHasGoBackButton(state)
});

const mapDispatchToProps = {
  getUserServiceList,
  changeHasGoBackButton
};

export default connect(mapStateToProps, mapDispatchToProps)(UserServiceLists);
