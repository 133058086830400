import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { modalProps } from 'common/enums/modal';
import { selectModalType } from 'store/reducers/modal';
import { IconButton } from 'components';

import { ModalShadow, ModalContent, Heading, FlexRow } from './styled';

const Modal = ({
  children,
  modalType,
  openedModalType,
  visible = false,
  hideModal = () => {},
  closeButton,
  backgroundColor,
  bundleModal,
  heading = '',
  middlePaddings,
  maxWidth = modalProps.DEFAULT.maxWidth,
  minWidth = modalProps.DEFAULT.minWidth,
  maxHeight = modalProps.DEFAULT.maxHeight,
  overflowMode,
  padding,
  margin,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  background,
  isFullscreen,
  borderRadius
}) => {
  const isModalVisible = modalType === openedModalType || visible;

  if (!isModalVisible) {
    return null;
  }
  const CloseBtn = <IconButton iconName="close2" iconSize={9.5} onClick={hideModal} />;
  return (
    <ModalShadow visible={modalType ? modalType === openedModalType : visible} onMouseDown={hideModal}>
      <ModalContent
        borderRadius={borderRadius}
        onMouseDown={(e) => e.stopPropagation()}
        backgroundColor={backgroundColor}
        middlePaddings={middlePaddings}
        smallPaddings={Boolean(closeButton)}
        bundleModal={bundleModal}
        maxWidth={maxWidth}
        minWidth={minWidth}
        maxHeight={maxHeight}
        overflowMode={overflowMode}
        padding={padding}
        margin={margin}
        background={background}
        heading={heading}
        marginTop={marginTop}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginBottom={marginBottom}
        isFullscreen={isFullscreen}>
        {Boolean(closeButton || heading) && (
          <FlexRow withoutHeading={closeButton && !heading} isFullscreen={isFullscreen}>
            {heading && (
              <Heading>
                {heading}
                {closeButton && CloseBtn}
              </Heading>
            )}
            {closeButton && !heading && CloseBtn}
          </FlexRow>
        )}
        {children}
      </ModalContent>
    </ModalShadow>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired
};

Modal.defaultProps = {
  hideModal: () => {},
  visible: false
};

const mapStateToProps = (state) => ({
  openedModalType: selectModalType(state)
});

export default connect(mapStateToProps)(Modal);
