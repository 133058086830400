import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './styled';

const Input = ({ type, placeholder, value, isPlain, onUpdate, ...rest }) => (
    <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        isPlain={isPlain}
        {...rest}
    />
);

Input.propTypes = {
    isPlain: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onUpdate: PropTypes.func // dirty fix
};

Input.defaultProps = {
    isPlain: false,
    type: 'text',
    placeholder: '',
    value: ''
};

export default Input;
