import React from 'react';
import PropTypes from 'prop-types';

import { sortingDirections, columnNames } from 'common/enums/sortings';

import { Cell, SortIcons, Divider, SkillCell } from './styled';
import { Icon } from 'components';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { useRouter } from 'hooks';

const HeaderCell = ({
  columnName,
  hasSorting = true,
  sortingColumnName,
  title = '',
  sortingDirection = sortingDirections.ASCENDING,
  onSort,
  maxWidth
}) => {
  const handleChangeSortDirection = (sortingDirection) => (event) => {
    onSort({ columnName, direction: sortingDirection })(event);
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const isCurrentColumn = sortingColumnName === columnName;
  const getColorByDirection = (direction) => {
    const color =
      isCurrentColumn && sortingDirection === direction ? theme.colors.DARK_YELLOW : theme.colors.DARK_GREY_3;
    return color;
  };

  const direction = isCurrentColumn
    ? sortingDirections.DESCENDING === sortingDirection
      ? sortingDirections.ASCENDING
      : sortingDirections.DESCENDING
    : sortingDirections.DESCENDING;
  const { queryParams } = useRouter();

  if (columnName === columnNames.SKILL_NAME) {
    const filters = queryParams.getAll();
    return (
      <Cell>
        {filters?.page === '1' && (
          <SkillCell onClick={() => dispatch(openModal(modalTypes.CREATE_SKILL))}>
            <Icon name="add" size={14} color={theme.colors.WHITE} />
            Add new skill
          </SkillCell>
        )}
      </Cell>
    );
  }
  return (
    <Cell
      isCurrentColumn={isCurrentColumn}
      onClick={hasSorting ? handleChangeSortDirection(direction) : () => ({})}
      maxWidth={maxWidth}>
      {title}
      {hasSorting && (
        <SortIcons>
          <Icon
            color={getColorByDirection(sortingDirections.ASCENDING)}
            rotate={180}
            width={12}
            height={6}
            name="arrowFillBottom"
            onClick={handleChangeSortDirection(sortingDirections.ASCENDING)}
          />
          <Divider />
          <Icon
            color={getColorByDirection(sortingDirections.DESCENDING)}
            width={12}
            height={6}
            name="arrowFillBottom"
            onClick={handleChangeSortDirection(sortingDirections.DESCENDING)}
          />
        </SortIcons>
      )}
    </Cell>
  );
};

HeaderCell.propTypes = {
  ascendingSort: PropTypes.bool,
  onClick: PropTypes.func,
  sortable: PropTypes.bool,
  sortColumn: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default HeaderCell;
