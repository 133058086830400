import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox as UICheckbox } from 'components';
import styles from 'constants/styles';

const Checkbox = ({ label, name, control, rules = {}, error = {}, onCustomChange, ...props }) => {
  const {
    field: { onChange, value }
  } = useController({
    name,
    control,
    rules,
    defaultValue: false
  });

  const onCheckboxChange = (event) => {
    onChange(event);
    if (onCustomChange) {
      onCustomChange(event.target.value);
    }
  };

  return (
    <UICheckbox
      smallLabelFont
      withoutBorders
      label={label}
      checked={Boolean(value)}
      onChange={onCheckboxChange}
      error={error}
      {...props}
    />
  );
};

export default Checkbox;
