import React from 'react';
import { useTheme } from 'hooks';
import { typographyVariants } from 'common/enums/typography';

import { Typography } from './Typography';

const TypographyStoryBook = () => {
  const theme = useTheme();
  return (
    <div>
      {Object.values(typographyVariants).map((variant) => (
        <Typography key={variant} variant={variant} color={theme.colors.WHITE}>
          {variant}
        </Typography>
      ))}
    </div>
  );
};

export default TypographyStoryBook;
