import React from 'react';
import { cohortTypes } from 'common/enums/campaigns';

const handleDownloadFile = async ({ file, name }) => {
  try {
    let url;
    if (typeof file === 'string') {
      const response = await fetch(file, { responseType: 'blob' });
      if (!response.ok) {
        throw new Error('Failed to download csv');
      }
      const blob = await response.blob();
      url = window.URL.createObjectURL(blob);
    } else {
      url = window.URL.createObjectURL(new Blob([file]));
    }

    if (!url) {
      throw new Error('Failed to download csv');
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log('Error: ', err);
  }
};

export const getCohortValue = ({ cohort_type, csv_cohort_name, csv_cohort_path, recipients_list, service_areas }) => {
  switch (Number(cohort_type)) {
    case cohortTypes.ALL_USERS:
      return 'All Install Base';
    case cohortTypes.WORKING_AREA:
      return service_areas?.join(', ') + (service_areas?.length ? ';' : '');
    case cohortTypes.RECIPIENTS_LIST:
      return recipients_list?.join('; ') + (recipients_list?.length ? ';' : '');
    case cohortTypes.CSV_FILE:
      if (csv_cohort_path) {
        return (
          <span onClick={() => handleDownloadFile({ file: csv_cohort_path, name: csv_cohort_name })}>
            {csv_cohort_name}
          </span>
        );
      }
      return csv_cohort_name;
    default:
      return '-';
  }
};
