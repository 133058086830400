export default {
  TASK: 'TASK',
  PROMO_CODE: 'PROMO_CODE',
  REWARD: 'REWARD',
  MANAGE_REWARD: 'MANAGE_REWARD',
  DELETE_REWARD: 'DELETE_REWARD',
  APPROVE_QUOTATION: 'APPROVE_QUOTATION',
  CANCEL_TASK_REASON: 'CANCEL_TASK_REASON',
  CANCEL_VISIT: 'CANCEL_VISIT',
  CANCEL_VISIT_RESULT: 'CANCEL_VISIT_RESULT',
  CREATE_INVOICE_CONFIRMATION: 'CREATE_INVOICE_CONFIRMATION',
  CONFIRMATION: 'CONFIRMATION',
  DELETE_INVOICE_CONFIRMATION: 'DELETE_INVOICE_CONFIRMATION',
  DETACH_REWARD_FROM_INVOICE: 'DETACH_REWARD_FROM_INVOICE',
  CANCEL_INVOICE_FORM_CONFIRMATION: 'CANCEL_INVOICE_FORM_CONFIRMATION',
  DELETE_COGNITO_USER: 'DELETE_COGNITO_USER',
  DELETE_COGNITO_USER_RESULT: 'DELETE_COGNITO_USER_RESULT',
  ERROR: 'ERROR',
  EXPIRE_PROMO_CODE: 'EXPIRE_PROMO_CODE',
  EXECUTION_DATE: 'EXECUTION_DATE',
  ASSIGN_PROVIDER_MAP: 'ASSIGN_PROVIDER_MAP',
  ASSIGN_PROVIDER_RESULT_MAP: 'ASSIGN_PROVIDER_RESULT_MAP',
  UNASSIGN_PROVIDER: 'UNASSIGN_PROVIDER',
  UNASSIGN_PROVIDER_RESULT: 'UNASSIGN_PROVIDER_RESULT',
  BUNDLE_SERVICES: 'BUNDLE_SERVICES',
  BUNDLE_SERVICES_RESULT: 'BUNDLE_SERVICES_RESULT',
  EDIT_USER: 'EDIT_USER',
  TWILIO_VOICE: 'TWILIO_VOICE',
  TEST_EVENT: 'TEST_EVENT',
  USER_TOGGLE_IS_ACTIVATE: 'USER_TOGGLE_IS_ACTIVATE',
  USER_TOGGLE_IS_ACTIVATE_RESULT: 'USER_TOGGLE_IS_ACTIVATE_RESULT',
  UPLOAD_INSPECTION_REPORT: 'UPLOAD_INSPECTION_REPORT',
  CLOSE_TASK_SILENTLY: 'CLOSE_TASK_SILENTLY',
  CLOSE_TASK_SILENTLY_WITH_PAYOUT: 'CLOSE_TASK_SILENTLY_WITH_PAYOUT',
  CLOSE_TASK_SILENTLY_FINAL: 'CLOSE_TASK_SILENTLY_FINAL',
  CLOSE_TASK_SILENTLY_RESULT: 'CLOSE_TASK_SILENTLY_RESULT',
  CLEAR_ALL_PERSISTENT_NOTIFICATIONS: 'CLEAR_ALL_PERSISTENT_NOTIFICATIONS',
  CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT: 'CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT',
  CLOSE_INVOICE: 'CLOSE_INVOICE',
  CLOSE_INVOICE_CONFIRMATION: 'CLOSE_INVOICE_CONFIRMATION',
  CAMPAIGN_CREATION_CONFIRMATION: 'CAMPAIGN_CREATION_CONFIRMATION',
  DISCARD_CHANGES: 'DISCARD_CHANGES',
  DELETE_REVIEW: 'DELETE_REVIEW',
  DELETE_REVIEW_RESULT: 'DELETE_REVIEW_RESULT',
  DUPLICATE_TASK: 'DUPLICATE_TASK',
  REOPEN_TASK: 'REOPEN_TASK',
  DELETE_NOTE: 'DELETE_NOTE',
  INNER_HTML: 'INNER_HTML',
  ASSIGN_TASK_OWNER: 'ASSIGN_TASK_OWNER',
  SCHEDULE_VISIT: 'SCHEDULE_VISIT',
  PAYOUT_CONFIRMATION: 'PAYOUT_CONFIRMATION',
  RESULT: 'RESULT',
  TIMEZONE: 'TIMEZONE',
  UNBUNDLE_TASK: 'UNBUNDLE_TASK',
  PAYMENT_DATE: 'PAYMENT_DATE',
  OWNER_REPORT: 'OWNER_REPORT',
  SNOOZE_TASK: 'SNOOZE_TASK',
  DELETE_CHAT_MESSAGE: 'DELETE_CHAT_MESSAGE',
  CREATE_SKILL: 'CREATE_SKILL',
  VALIDATE_INVOICE: 'VALIDATE_INVOICE',
  UPDATE_SKILL: 'UPDATE_SKILL',
  QUESTION_DETAILS: 'QUESTION_DETAILS',
  TOGGLE_JOB_CHAT: 'TOGGLE_JOB_CHAT',
  VIDEO_PLAYER: 'VIDEO_PLAYER'
};
