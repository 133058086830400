import React from 'react';
import { useTheme } from 'hooks';

import Icon from './Icon';
import icons from './icons';
import { IconGalleryWrapper, IconItem } from './styled';

const IconGallery = () => {
  const theme = useTheme();
  return (
    <IconGalleryWrapper>
      {Object.keys(icons).map((iconName, index) => (
        <IconItem key={index}>
          <Icon name={iconName} size={50} backgroundSize={100} color={theme.colors.WHITE} />
          <span>{iconName}</span>
        </IconItem>
      ))}
    </IconGalleryWrapper>
  );
};

export default IconGallery;
