import { useDispatch, useMemo, useRouter, useSelector, useNavigationBadges } from 'hooks';

import { navigationTabs } from 'common/enums/navigation';
import { defaultParams } from 'common/enums/queryParams';
import routes from 'constants/routes';
import { analyticMetrics, defaultAnalyticsParams } from 'common/enums/analytics';
import { getFilledJobFilters } from 'common/helpers/job';
import { findNavigationTab } from 'common/helpers/navigation';
import { getSortingQueryParam } from 'common/helpers/sortings';
import { getFilledHiddenFilters } from 'common/helpers/queryParams';
import { getCampaignsList } from 'store/actions/campaigns';

import {
  getAgentList,
  getConversationList,
  getCustomerList,
  getProviderList,
  getServiceList,
  getPaymentList,
  getQuotationList,
  getReviewList,
  getReferralList,
  getSkills
} from 'store/actions';
import { getPromoCodeList } from 'store/actions/promoCode';
import { getNotificationList } from 'store/actions/notification';
import { selectActiveMenuItem } from 'store/reducers/navigation';
import { useCallback } from 'react';

/***
 * handle reload table info
 */
const useReloadTable = () => {
  const dispatch = useDispatch();
  const { queryParams, pathname, location } = useRouter();
  const filters = queryParams.getAll();
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const { handleFetchBadges } = useNavigationBadges();

  const getReloadTableHandler = ({ tabId, currentPathname }) => {
    switch (tabId) {
      case navigationTabs.REFERRAL.TYPE:
        return getReferralList;
      case navigationTabs.REVIEW.TYPE:
        return getReviewList;
      case navigationTabs.SKILLS.TYPE:
        return getSkills;
      case navigationTabs.INVOICE_ADMIN_PENDING_PAYMENT.TYPE:
        return getPaymentList;
      case navigationTabs.INVOICE_ADMIN_PENDING_QUOTE.TYPE:
        return getQuotationList;
      default:
        break;
    }

    switch (currentPathname) {
      case routes.ANALYTICS_JOBS:
      case routes.PRIORITY_TASKS:
      case routes.SERVICE:
        return getServiceList;
      case routes.AGENT:
        return getAgentList;
      case routes.PROMO_CODES_CUSTOMERS:
      case routes.CUSTOMER:
        return getCustomerList;
      case routes.CAMPAIGNS:
        return getCampaignsList;
      case routes.PROVIDER:
        return getProviderList;
      case routes.PROMO_CODES:
        return getPromoCodeList;
      case routes.COMMUNICATION:
        return getConversationList;
      case routes.PAYMENTS:
        return getPaymentList;
      case routes.INVOICE_PENDING:
        return getQuotationList;
      default:
        break;
    }
  };

  const reloadTableHandler = useMemo(
    () => getReloadTableHandler({ tabId: filters.tab_id, currentPathname: pathname }),
    [filters.tab_id, pathname]
  );

  const hasLoading = useMemo(
    () => getReloadTableHandler({ tabId: filters.tab_id, currentPathname: pathname }),
    [filters.tab_id, pathname]
  );

  const handleReloadTable = useCallback(
    ({
      hasUpdateBadges,
      hasUpdateNotificationList,
      newMenuItem,
      newPathname,
      newTab,
      newNavigationFilters = {},
      newRequestFilters = {},
      locationSearch,
      resetCustomFilters,
      hasSavingQueryParams = true
    } = {}) => {
      const menuItem = newMenuItem || activeMenuItem;
      const tabItem =
        newTab || findNavigationTab({ menuItemType: menuItem?.TYPE, tabId: newMenuItem ? null : filters.tab_id });

      const reloadHandler =
        newMenuItem || newTab || newPathname
          ? getReloadTableHandler({
              tabId: tabItem?.TYPE,
              currentPathname: newPathname || newMenuItem?.ROUTE || pathname
            })
          : reloadTableHandler;

      if (!reloadHandler) {
        return;
      }

      const metricType = Number(newNavigationFilters.metric_type ?? filters.metric_type);
      const currentMetric = Object.values(analyticMetrics).find(({ TYPE }) => TYPE === metricType);

      const updatedFilters = {
        [defaultParams.PAGE.NAME]: filters.page || defaultParams.PAGE.DEFAULT_VALUE,
        ...(menuItem?.PARAMS || {}),
        ...(tabItem?.PARAMS || {}),
        order_by: getSortingQueryParam({
          columnName: newNavigationFilters.sorting_column || filters.sorting_column,
          direction: newNavigationFilters.sorting_direction || filters.sorting_direction
        }),
        ...(filters.search ? { search: filters.search } : {}),
        ...(resetCustomFilters ? {} : getFilledJobFilters(filters)),
        ...(resetCustomFilters ? {} : getFilledHiddenFilters(filters)),
        ...(currentMetric?.REQUEST_PARAMS || {}),
        ...(currentMetric ? defaultAnalyticsParams : {}),
        ...newRequestFilters,
        hasSavingQueryParams: hasSavingQueryParams,
        menuItemType: menuItem.TYPE,
        locationSearch: locationSearch || location.search?.substring(1) || ''
      };

      dispatch(reloadHandler(updatedFilters));

      if (hasUpdateNotificationList) {
        dispatch(getNotificationList({ page: 1 }));
      }

      if (hasUpdateBadges) {
        handleFetchBadges();
      }
    },
    [activeMenuItem, dispatch, filters, handleFetchBadges, location.search, pathname, reloadTableHandler]
  );

  return {
    handleReloadTable,
    hasLoading
  };
};

export default useReloadTable;
