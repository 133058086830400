import styled, { keyframes } from 'styled-components';

export const Spinner = styled.div`
  display: inline-block;
  position: relative;
  left: ${({ left }) => left}px;
  width: 45px;
  height: 10px;
`;

const Ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const Ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
`;

const Ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const SpinnerDot = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ color, theme }) => color || theme.colors.LIGHT_GRAY};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  &:nth-child(1) {
    left: 4px;
    top: ${({ top }) => top}px;
    animation: ${Ellipsis1} 0.6s infinite;
  }
  &:nth-child(2) {
    left: 4px;
    top: ${({ top }) => top}px;
    animation: ${Ellipsis2} 0.6s infinite;
  }
  &:nth-child(3) {
    left: 18px;
    top: ${({ top }) => top}px;
    animation: ${Ellipsis2} 0.6s infinite;
  }
  &:nth-child(4) {
    left: 32px;
    top: ${({ top }) => top}px;
    animation: ${Ellipsis3} 0.6s infinite;
  }
`;
