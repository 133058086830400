import styled from 'styled-components';
import colors from './helpers/colors';
import styles from 'constants/styles';

export const ChatFlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const MessagesScrollWrapper = styled.div`
  flex: 1;
  overflow: overlay;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  background: ${({ theme }) => theme.colors.CHAT_BG};
  display: flex;
  flex-direction: column-reverse;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

export const MessageFlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const MessageDayContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageDayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
`;

export const MessageDayText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;

  color: ${({ theme }) => theme.colors.MESSAGE_DATE};
`;

export const MessageSystemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageSystemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 10px;

  font-size: 14px;
  line-height: 1.2;
`;

export const MessageSystemText = styled.span`
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.system};
`;

export const MessageUserContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const MessageUserWrapper = styled.div`
  flex: 1;
  display: flex;
  margin: 4px 0;
`;

export const MessageBubbleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin: 0 5px;
  justify-content: ${({ isCurrentUser }) => (isCurrentUser ? 'flex-end' : 'flex-start')};

  ${({ isCurrentUser }) => (isCurrentUser ? 'margin-left: 30px;' : 'margin-right: 30px;')}

  ${({ showEditingForm = false }) =>
    Boolean(showEditingForm) &&
    `margin-right: 0;
      margin-left: 0;
      background-color: ${styles.colors.LIGHT_GRAY_2};`}
`;

export const MessageBubbleWrapper = styled.div`
  min-height: 20px;
  border-radius: ${({ isCurrentUser }) => (isCurrentUser ? '8px 8px 0px 8px' : '8px 8px 8px 0px')};
  padding: 12px 28px 14px 16px;
  position: relative;
  margin: ${({ hasRightPosition }) => (hasRightPosition ? '0 8px 0 8px' : '0 0 0 8px')};
  ${({ isImage = false }) => Boolean(isImage) && `width: 57%`};
  background-color: ${({ isCurrentUser = false, color = null }) =>
    color ? color : isCurrentUser ? colors.blue : colors.gray};

  ${({ showEditingForm, theme }) =>
    showEditingForm &&
    `margin: 0;
    background-color: none;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.DARK_3};
    flex: 1;
    `}
`;

export const AvatarMargin = styled.div`
  margin-left: 36px;
  height: 100%;
  vertical-align: middle;
`;

export const MessageTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MessageTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 5px 0;

  font-size: 14px;
  line-height: 1.2;
`;

export const MessageTextText = styled.span`
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.black};
`;

export const MessageImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MessageImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 5px 0;

  font-size: 14px;
  line-height: 1.2;
`;

export const MessageImageImage = styled.image`
  width: 100%;
  max-height: 300px;
`;

export const MessageTimeContainer = styled.div`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.15;
`;

export const MessageTimeText = styled.span`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  font-weight: 600;
  background-color: transparent;
  text-align: right;
  color: ${colors.black};
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => !theme.darkMode && `border-top: 1px solid ${colors.gray};`}
`;

export const InputSectionWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 8px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background: ${({ theme }) => theme.colors.TABS_BG};
`;

export const StyledInputMessage = styled.textarea`
  flex: 1;
  border: none;
  box-shadow: none;
  background: ${({ theme }) => theme.colors.INPUT_BG};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;

  padding: 12px;
  margin: 4px;
  min-width: 240px;
  appearance: none;
  resize: none;
  width: calc(100% - 24px);
  min-height: 44px;
  max-height: 50vh;
  outline: 0 none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }
  &:active,
  &:focus {
    outline: none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
  }
`;

export const StyledSendButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const EditedText = styled.div`
  color: ${styles.colors.GRAY};
  font-size: 10px;
  line-height: 1.5;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
`;

export const MessageFooter = styled.div`
  display: flex;
`;
