import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';

import { IconButton, Icon, LoadingSpinner } from 'components';
import { createServiceNote, updateServiceNote, createUserNote, updateUserNote, openModal } from 'store/actions';
import {
  selectServiceNoteListLoading,
  selectServiceNoteList,
  selectServiceNoteListCount,
  selectUpdateServiceNoteLoading,
  selectDeleteServiceNoteLoading
} from 'store/reducers/service';

import {
  selectUserNoteListLoading,
  selectUserNoteList,
  selectUserNoteListCount,
  selectUserUpdateNoteLoading,
  selectUserDeleteNoteLoading
} from 'store/reducers/user';

import { formatDateShortTimeStamp } from 'utils';
import {
  NoteContainer,
  NoteData,
  NoteTitleRow,
  NoteTitle,
  NoteCreated,
  FilesFlexRow,
  NoteFile,
  NoteFileName,
  FieldTitle,
  AddNoteWrapper,
  LoadingContainerMedium,
  NoteListWrapper,
  ActionButtonsHolder,
  ReminderWrapper
} from './styled';
import modalTypes from 'constants/modalTypes';
import { noteTypes } from 'constants/content';
import styles from 'constants/styles';
import { adminPermissionStatuses } from 'common/enums/app';
import NoteForm from './NoteForm';
import { selectAdminInfo } from 'store/reducers/app';
import { videoExtensions } from 'common/enums/media';
import { getUrlFileExtension } from 'common/helpers/media';

const PDFViewer = lazy(() => import('../../components/PDFViewer'));
const Lightbox = lazy(() => import('react-image-lightbox'));

class Notes extends Component {
  state = {
    selectedNote: null,
    mediaUrl: null,
    isLightBoxShown: false,
    isPDFViewerShown: false
  };

  openAttachedFile = async (innerPath) => {
    const fileExt = getUrlFileExtension(innerPath || '');
    const signedUrl = await Storage.get(innerPath);
    const hasVideo = Object.values(videoExtensions).includes(fileExt);

    if (hasVideo) {
      const { openModal } = this.props;
      openModal(modalTypes.VIDEO_PLAYER, {
        signedUrl
      });
      return;
    }

    this.setState({
      mediaUrl: signedUrl,
      ...(fileExt === '.pdf' ? { isPDFViewerShown: true } : { isLightBoxShown: true })
    });
  };

  closePDFViewer = () =>
    this.setState({
      isPDFViewerShown: false,
      mediaUrl: null
    });

  closeImageLightBox = () =>
    this.setState({
      isLightBoxShown: false,
      mediaUrl: null
    });

  setSelectedNote = (note) =>
    this.setState(
      {
        selectedNote: note
      },
      () => (note ? this.props.openEditNoteForm() : this.props.closeEditNoteForm())
    );

  onNoteDelete = (deletedNote) => {
    const { openModal, serviceId } = this.props;
    openModal(modalTypes.DELETE_NOTE, {
      deletedNote,
      serviceId
    });
  };

  render() {
    const {
      createServiceNote,
      updateServiceNote,
      createUserNote,
      updateUserNote,
      serviceId,
      type,
      userId,
      noteList,
      displayNotesLoading,
      noteListLoading,
      showForm,
      updateNoteLoading,
      closeEditNoteForm,
      adminInfo
    } = this.props;
    const { selectedNote, mediaUrl, isLightBoxShown, isPDFViewerShown } = this.state;
    const hasAdminPermission = adminInfo?.permission?.some(
      ({ position }) => position === adminPermissionStatuses.ADMIN.POSITION_ID
    );
    return (
      <div>
        {Boolean(noteList.length) && (
          <FieldTitle margin="15px 0 0 24px">Notes of the {noteTypes[type] || 'Job'}</FieldTitle>
        )}
        {showForm && !selectedNote && (
          <AddNoteWrapper>
            <NoteForm
              createNote={serviceId ? createServiceNote : createUserNote}
              hideForm={closeEditNoteForm}
              serviceId={serviceId}
              userId={userId}
            />
          </AddNoteWrapper>
        )}
        {noteListLoading && displayNotesLoading ? (
          <LoadingContainerMedium>
            <LoadingSpinner text="Please wait" type="logo" />
          </LoadingContainerMedium>
        ) : (
          <NoteListWrapper>
            {noteList.map((note) => (
              <NoteContainer key={note.id}>
                <Icon name="comment" size={17} backgroundSize={17} color={styles.colors.DARK_GREY_3} />
                <div>
                  <NoteData>
                    <NoteTitleRow>
                      <NoteTitle>{note.author?.given_name || `(${note.author?.id || 'Uploading'})`}</NoteTitle>
                      <NoteCreated>{formatDateShortTimeStamp(note.date_created)}</NoteCreated>
                      {!showForm && note.author?.id && (
                        <ActionButtonsHolder>
                          <IconButton
                            size={24}
                            iconSize={12}
                            iconName="editFill"
                            onClick={() => this.setSelectedNote(note)}
                          />
                          {hasAdminPermission && (
                            <IconButton
                              size={24}
                              iconSize={16}
                              margin="0 0 0 5px"
                              iconName="trash"
                              onClick={() => this.onNoteDelete(note)}
                            />
                          )}
                        </ActionButtonsHolder>
                      )}
                    </NoteTitleRow>
                    {showForm && selectedNote?.id === note.id ? (
                      <AddNoteWrapper>
                        <NoteForm
                          updateNote={serviceId ? updateServiceNote : updateUserNote}
                          updateNoteLoading={updateNoteLoading}
                          noteDescription={note.description}
                          noteId={note.id}
                          editMode
                          hideForm={() => this.setSelectedNote(null)}
                          serviceId={serviceId}
                          userId={userId}
                          reminderCounter={note.reminder_counter}
                        />
                      </AddNoteWrapper>
                    ) : (
                      Boolean(note.description) && <NoteTitle>{note.description.trim()}</NoteTitle>
                    )}
                    {Boolean(note.media?.length) && (
                      <FilesFlexRow>
                        {note.media?.map((file) => (
                          <NoteFile
                            key={file.id}
                            clickable
                            onClick={(e) => this.openAttachedFile(file.media.inner_path)}>
                            <Icon name="file" size={17} backgroundSize={17} />
                            <NoteFileName>{file.media.original_name || file.media.file_name}</NoteFileName>
                          </NoteFile>
                        ))}
                      </FilesFlexRow>
                    )}
                  </NoteData>
                  {Boolean(note.reminder_counter) && (
                    <ReminderWrapper margin="9px 0 0 20px">
                      <Icon name="time" size={18} />
                      {`Remaind in ${note.reminder_counter}h`}
                    </ReminderWrapper>
                  )}
                </div>
              </NoteContainer>
            ))}
          </NoteListWrapper>
        )}
        {isLightBoxShown && <Lightbox mainSrc={mediaUrl} onCloseRequest={this.closeImageLightBox} />}
        {isPDFViewerShown && <PDFViewer onViewerClose={this.closePDFViewer} mediaUrl={mediaUrl} />}
      </div>
    );
  }
}

Notes.propTypes = {
  noteList: PropTypes.array.isRequired,
  noteListLoading: PropTypes.bool.isRequired,
  noteListCount: PropTypes.number.isRequired,
  createServiceNote: PropTypes.func.isRequired,
  updateServiceNote: PropTypes.func.isRequired
};

Notes.defaultProps = {
  noteList: [],
  noteListLoading: true,
  noteListCount: 0
};

const mapStateToProps = (state, { serviceId }) => ({
  noteList: serviceId ? selectServiceNoteList(state) : selectUserNoteList(state),
  noteListLoading: serviceId ? selectServiceNoteListLoading(state) : selectUserNoteListLoading(state),
  noteListCount: serviceId ? selectServiceNoteListCount(state) : selectUserNoteListCount(state),
  updateNoteLoading: serviceId ? selectUpdateServiceNoteLoading(state) : selectUserUpdateNoteLoading(state),
  deleteNoteLoading: serviceId ? selectDeleteServiceNoteLoading(state) : selectUserDeleteNoteLoading(state),
  adminInfo: selectAdminInfo(state)
});

const mapDispatchToProps = {
  createServiceNote,
  updateServiceNote,
  createUserNote,
  updateUserNote,
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
