import styled from 'styled-components';

export const Name = styled.div`
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const UserId = styled.div`
  padding: 4px 24px;
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 16px 20px 16px;
  cursor: pointer;
  gap: 20px;
  justify-content: space-between;
  &:hover {
    background: ${({ theme }) => theme.colors.DARK_GREY_3};
    ${UserId} {
      background: ${({ theme }) => theme.colors.DARK_4};
    }
  }
`;
export const AdditionalInfo = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Divider = styled.div`
  height: 1px;
  max-width: 100%;
  background: ${({ theme }) => theme.colors.DARK_2};
  margin: 0 16px;
`;

export const LoadMoreButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.DARK_2};
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.colors.DARK_GREY_3};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.DARK_GREEN_3};
  }
`;
