import React from 'react';
import { CloseButton } from 'components';
import { AbsoluteCloseButton, RewardWrapper, RewardContent } from './styled';

const Reward = ({ promocodeId, width, rewardStyle, isCloseButton, onCloseClick }) => {
  return (
    <RewardWrapper>
      <RewardContent width={width} style={rewardStyle}>
        {promocodeId}
      </RewardContent>
      {isCloseButton && (
        <AbsoluteCloseButton>
          <CloseButton size={18} iconSize={10} onClick={onCloseClick} />
        </AbsoluteCloseButton>
      )}
    </RewardWrapper>
  );
};

export default Reward;
