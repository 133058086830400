import React from 'react';
import styles from 'constants/styles';

const IconCloseFill3 = ({ size = 16, color = styles.colors.DARK_3 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49517 4.43395C5.20228 4.14106 4.72741 4.14106 4.43451 4.43395C4.14162 4.72684 4.14162 5.20172 4.43451 5.49461L6.76133 7.82142L4.43451 10.1482C4.14162 10.4411 4.14162 10.916 4.43451 11.2089C4.72741 11.5018 5.20228 11.5018 5.49517 11.2089L7.82199 8.88208L10.1488 11.2089C10.4417 11.5018 10.9166 11.5018 11.2095 11.2089C11.5024 10.916 11.5024 10.4411 11.2095 10.1482L8.88265 7.82142L11.2095 5.49461C11.5024 5.20172 11.5024 4.72684 11.2095 4.43395C10.9166 4.14106 10.4417 4.14106 10.1488 4.43395L7.82199 6.76076L5.49517 4.43395Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default IconCloseFill3;
