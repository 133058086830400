import React from 'react';
import styles from 'constants/styles';

const IconThumbDownOutlined = ({ size = 24, color = styles.colors.DARK_RED }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64872 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64872 23.5 12Z"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6745 11.6472C17.881 11.4317 18 11.1395 18 10.8259C18 10.3736 17.752 9.9765 17.3825 9.78241C17.4595 9.62224 17.5 9.44537 17.5 9.26066C17.5 8.80779 17.2515 8.41073 16.8821 8.21664C16.9966 7.97873 17.03 7.7048 16.9726 7.43296C16.8606 6.90444 16.3841 6.52147 15.8396 6.52147H11.2504C10.9224 6.52147 10.4074 6.62947 10.0004 6.82982V12.422L10.0604 12.4538L11.5004 15.709V17.4783C11.5004 17.5575 11.5349 17.6327 11.5939 17.6823C11.5942 17.6825 11.5947 17.683 11.5955 17.6835C11.6266 17.7083 11.9925 18 12.5003 18C13.0788 18 14.0002 17.0447 14.0002 15.913C14.0002 15.1992 13.7552 14.3107 13.6022 13.826H16.6906C17.3635 13.826 17.9359 13.3063 17.9945 12.6432C18.027 12.2712 17.908 11.9138 17.6745 11.6472ZM6 11.4784C6 12.1974 6.56097 12.7828 7.24993 12.7828H9.49981V6.26405C9.29031 6.09916 9.03133 6.00004 8.74985 6.00004H7.24993C6.56097 6.00004 6 6.58544 6 7.30441V11.4784Z"
      fill={color}
    />
  </svg>
);

export default IconThumbDownOutlined;
