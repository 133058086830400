import React from 'react';

const IconThumbDown = ({ size, color = '#FFFFFF' }) => (
  <svg
    width={size * 1.1}
    height={size}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1525 4.70597C10.3321 4.5264 10.4356 4.28291 10.4356 4.0216C10.4356 3.64464 10.2199 3.31375 9.89864 3.15201C9.9656 3.01853 10.0008 2.87114 10.0008 2.71722C10.0008 2.33983 9.78472 2.00894 9.46341 1.8472C9.56297 1.64894 9.59209 1.42067 9.5421 1.19413C9.44472 0.753703 9.03036 0.434558 8.55687 0.434558H4.56589C4.28066 0.434558 3.83283 0.52456 3.47892 0.691521V5.35164L3.53109 5.37816L4.7833 8.09084V9.56521C4.7833 9.63129 4.8133 9.6939 4.8646 9.73521C4.86491 9.73545 4.86537 9.7358 4.866 9.73628C4.89304 9.75694 5.21127 10 5.65287 10C6.15593 10 6.95725 9.2039 6.95725 8.26083C6.95725 7.66604 6.74421 6.9256 6.61116 6.52167H9.29688C9.8821 6.52167 10.3799 6.08861 10.4308 5.53599C10.4591 5.22599 10.3556 4.92814 10.1525 4.70597ZM0 4.56536C0 5.1645 0.487838 5.65233 1.08698 5.65233H3.04355V0.220041C2.86136 0.082634 2.63615 2.95639e-05 2.39136 2.95639e-05H1.08698C0.487838 2.95639e-05 0 0.487867 0 1.08701V4.56536Z"
      fill={color}
    />
  </svg>
);

export default IconThumbDown;
