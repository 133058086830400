import React from 'react';
import { Avatar } from 'components';
import { providerStages } from 'common/enums/user';
import { jobCategories } from 'common/enums/job';

import { DataRecord, DataRow, FieldTitle, FieldValue } from '../../commonStyled';
import { LabelRow, Pill } from './styled';

export const ProviderAreaCategory = ({
  service_area_list,
  category,
  primary_category,
  secondary_category,
  provider_skills,
  personal_logo_media_url,
  auto_assign_service_area_list,
  auto_assign_service_id,
  one_click_service_area_list,
  one_click_service_id,
  pro_stage,
  hasAutoAreaCategoryDisabled
}) => {
  const hasOneClickBooking =
    !hasAutoAreaCategoryDisabled &&
    ([providerStages.ONE_CLICK_BOOKING.TYPE].includes(pro_stage) ||
      Boolean(one_click_service_area_list?.length || one_click_service_id));
  const hasAutoAssignment =
    !hasAutoAreaCategoryDisabled &&
    ([providerStages.ONE_CLICK_BOOKING.TYPE, providerStages.NEW_HO_PRO.TYPE].includes(pro_stage) ||
      Boolean(auto_assign_service_area_list?.length || auto_assign_service_id));
  const hasAreaOrCategory = Boolean(service_area_list?.length || category?.length);

  return (
    <>
      <DataRow>
        <DataRecord>
          <FieldTitle>Working Area</FieldTitle>
          <FieldValue flex>
            {service_area_list?.length ? service_area_list.map((name) => <Pill key={name}>{name}</Pill>) : '-'}
          </FieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Company</FieldTitle>
          <FieldValue flex>
            {personal_logo_media_url ? (
              <Avatar
                alt="Pro Logo"
                hasDelay
                height={40}
                maxRetryAttempts={1}
                url={personal_logo_media_url}
                width={72}
              />
            ) : (
              '-'
            )}
          </FieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Primary Category</FieldTitle>
          <FieldValue>{primary_category?.name ? <Pill>{primary_category.name}</Pill> : '-'}</FieldValue>
        </DataRecord>
        <DataRecord />
      </DataRow>
      <DataRow>
        <DataRecord flex={Math.min(Math.max(secondary_category?.length, 1), 4)}>
          <FieldTitle>Secondary Category</FieldTitle>
          <FieldValue flexDirection={'row'} wrap>
            {secondary_category?.length ? secondary_category.map(({ id, name }) => <Pill key={id}>{name}</Pill>) : '-'}
          </FieldValue>
        </DataRecord>
        {secondary_category?.length < 4 && <DataRecord flex={4 - Math.min(secondary_category?.length, 3)} />}
      </DataRow>
      <DataRow>
        <DataRecord flex={Math.min(Math.max(provider_skills?.length, 1), 4)}>
          <FieldTitle>Skills</FieldTitle>
          <FieldValue flexDirection={'row'} wrap>
            {provider_skills?.length
              ? provider_skills.map(({ skill: { name, id } }) => <Pill key={id}>{name}</Pill>)
              : '-'}
          </FieldValue>
        </DataRecord>
        {provider_skills?.length < 4 && <DataRecord flex={4 - Math.min(provider_skills?.length, 3)} />}
      </DataRow>
      {hasAutoAssignment && (
        <DataRow marginTop={hasOneClickBooking ? 13 : 25}>
          <DataRecord>
            <FieldTitle>Auto assignment Area</FieldTitle>
            {auto_assign_service_area_list?.length ? (
              <LabelRow>
                {auto_assign_service_area_list.map((item) => (
                  <Pill key={item}>{item}</Pill>
                ))}
              </LabelRow>
            ) : (
              <FieldValue>-</FieldValue>
            )}
          </DataRecord>
          <DataRecord />
          <DataRecord>
            <FieldTitle>Auto assignment Category</FieldTitle>
            {auto_assign_service_id ? (
              <LabelRow>
                {
                  <Pill>
                    {jobCategories.find(({ id }) => id === auto_assign_service_id)?.name ||
                      auto_assign_service_id ||
                      '-'}
                  </Pill>
                }
              </LabelRow>
            ) : (
              <FieldValue>-</FieldValue>
            )}
          </DataRecord>
          <DataRecord />
        </DataRow>
      )}
      {hasOneClickBooking && (
        <DataRow marginTop={hasAreaOrCategory ? 13 : 25}>
          <DataRecord>
            <FieldTitle>1-Click Booking Area</FieldTitle>
            {one_click_service_area_list?.length ? (
              <LabelRow>
                {one_click_service_area_list.map((item) => (
                  <Pill key={item}>{item}</Pill>
                ))}
              </LabelRow>
            ) : (
              <FieldValue>-</FieldValue>
            )}
          </DataRecord>
          <DataRecord />
          <DataRecord>
            <FieldTitle>1-Click Booking Category</FieldTitle>
            {one_click_service_id ? (
              <LabelRow>
                {
                  <Pill>
                    {jobCategories.find(({ id }) => id === one_click_service_id)?.name || one_click_service_id || '-'}
                  </Pill>
                }
              </LabelRow>
            ) : (
              <FieldValue>-</FieldValue>
            )}
          </DataRecord>
          <DataRecord />
        </DataRow>
      )}
    </>
  );
};
