import styled from 'styled-components';

export const DobbyCash = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DobbyCashInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const DobbyCashTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

export const DobbyCashTitleSpan = styled.span`
  margin: 6px 0;
`;

export const DobbyCashDesc = styled.div`
  font-size: 20px;
  line-height: 1.2;
  color: ${({ color, theme }) => color || theme.colors.WHITE};
`;
