import styled from 'styled-components';
import styles from 'constants/styles';

export const DropdownContainer = styled.div`
  position: relative;
`;

export const Dropdown = styled.div`
  background: ${({ theme }) => theme.colors.DARK_2};
  box-shadow: ${styles.boxShadows.THIN_DARK_3};
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 8px;
  border-radius: 4px;
  position: absolute;
  ${({ isCurrentUser, width }) => (isCurrentUser || width > 150 ? `right: 100%` : 'left: 100%')};
  bottom: 100%;
  width: 184px;
  z-index: 2;
`;

export const Actions = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  background-color: ${({ background }) => background};
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px;
  overflow-y: hidden;
`;

export const DropdownItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.13;
  margin: 0 0 0 10px;

  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Divider = styled.div`
  border: 1px solid #353d41;
  width: 100%;
`;
