export const icons = {
  ARROW: 'arrow',
  ARROW_FILL_RIGHT: 'arrowFillRight',
  ARROW_FILL_BOTTOM: 'arrowFillBottom',
  ADD: 'add',
  ARROW_FILL_LEFT: 'arrowFillLeft',
  ARROW_RIGHT: 'arrowRight',
  ARROW_LEFT: 'arrowLeft',
  ATTACHMENT: 'attachment',
  AVATAR: 'avatar',
  BELL: 'bell',
  BELL_FILL: 'bellFill',
  BLOCKED: 'blocked',
  CALENDAR: 'calendar',
  CALL: 'call',
  CALL2: 'call2',
  CALL_END: 'callEnd',
  CALL_END2: 'callEnd2',
  CHAT: 'chat',
  CHAT_FILL: 'chatFill',
  CHAT_OUTLINED: 'chatOutlined',
  CHECK_IN: 'checkIn',
  CLOSE: 'close',
  CLOSE2: 'close2',
  COLLAPSE: 'collapse',
  COPY: 'copy',
  CLOSE_FILL: 'closeFill',
  CLOSE_FILL2: 'closeFill2',
  CLOSE_FILL3: 'closeFill3',
  CLOSE_OUTLINED: 'closeOutlined',
  CLOSE_OUTLINED2: 'closeOutlined2',
  COMMENT: 'comment',
  DOTS: 'dots',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  EDIT_FILL: 'editFill',
  EDIT_MESSAGE: 'editMessage',
  EMPTY_BOX: 'emptyBox',
  EMPTY_LIST: 'emptyList',
  FEATHER: 'feather',
  FILE: 'file',
  FILTER: 'filter',
  FILTERS: 'filters',
  GO_BACK: 'goBack',
  INFO: 'info',
  LOGO: 'logo',
  LEFT_ARROW: 'leftArrow',
  LOAD_MORE: 'loadMore',
  MEDIA_PLAY: 'mediaPlay',
  MICROPHONE: 'microphone',
  MICROPHONE2: 'microphone2',
  MICROPHONE_CROSS: 'microphoneCross',
  MICROPHONE_CROSS2: 'microphoneCross2',
  OK: 'ok',
  OK_FILL: 'okFill',
  INFO_OUTLINED: 'infoOutlined',
  OK_OUTLINED: 'okOutlined',
  PACKAGE: 'package',
  PEN_FILL: 'penFill',
  PLUS: 'plus',
  SEND: 'send',
  SWITCH: 'switch',
  THUMB_DOWN: 'thumbDown',
  THUMB_DOWN_OUTLINED: 'thumbDownOutlined',
  THUMB_UP: 'thumbUp',
  THUMB_UP_OUTLINED: 'thumbUpOutlined',
  TIME: 'time',
  TIME_FILL: 'timeFill',
  TRASH: 'trash',
  TRIANGLE: 'triangle',
  VIDEO_CALL: 'videoCall'
};
