import React from 'react';
import { RotatedSvg } from './styled';

const IconCollapse = ({ size = 16, rotate = 0 }) => {
  return (
    <RotatedSvg
      width={size}
      height={size}
      rotate={rotate}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 8.5L8.5547 6.8698C8.2188 6.64587 7.7812 6.64587 7.4453 6.8698L5 8.5"
        stroke="#97A0A0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="8" r="7.5" transform="rotate(-180 8 8)" stroke="#97A0A0" />
    </RotatedSvg>
  );
};

export default IconCollapse;
