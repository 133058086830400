const activeTimezones = {
  EST: {
    name: 'Eastern Standard Time',
    value: 'America/New_York'
  },
  // For future use
  // CST: {
  //     name: 'Central Standard Time',
  //     value: 'America/Chicago'
  // },
  // MST: {
  //     name: 'Mountain Standard Time',
  //     value: 'America/Denver'
  // },
  PST: {
    name: 'Pacific Standard Time',
    value: 'America/Los_Angeles'
  }
};

export default activeTimezones;
