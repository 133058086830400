import styled from 'styled-components';
import styles from 'constants/styles';
import { Field } from 'redux-form';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const StyledTextarea = styled.textarea`
  display: inline-block;
  outline: 0 none;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.INPUT_BG};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  padding: 8px ${({ reset }) => (reset ? '32px' : '16px')} 8px 16px;
  width: 100%;
  resize: none;
  flex: 1;
  margin-bottom: 0;
  margin-top: ${({ marginTop }) => marginTop || 5}px;
  min-width: ${({ width }) => width}px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }

  ${({ invalid, theme }) =>
    invalid &&
    `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}

  &:active,
  &:focus {
    outline: 0 none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
    border: none;

    ${({ invalid, theme }) =>
      invalid &&
      `
      border: 1px solid ${theme.colors.INPUT_ERROR};
  `}
  }

  &[disabled] {
    box-shadow: none;
    color: ${styles.colors.GRAY};
    background: ${styles.colors.LIGHT_GRAY_2};
  }
`;
