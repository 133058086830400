import React from 'react';
import styles from 'constants/styles';

import { RotatedSvg } from './styled';

const IconLoadMore = ({ size = 16, color = styles.colors.GRAY }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 7.5L7.4453 9.1302C7.7812 9.35413 8.2188 9.35413 8.5547 9.1302L11 7.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="8" r="7.5" stroke={color} />
  </svg>
);

export default IconLoadMore;
