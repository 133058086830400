import React from 'react';

import { useTheme } from 'hooks';
import { getDaysCountFromDate, formatters, getHumanizedDuration } from 'utils';
import dates, { PERIODS } from 'constants/dates';

import { DataRow, DataRecord, FieldTitle, FieldValue, AnalyticFieldValue, InfoSectionHeader } from './styled';

const ProviderAnalytics = ({
  date_login,
  date_created,
  amount_total_earned,
  amount_average_earned,
  count_jobs_assigned,
  count_jobs_accepted,
  count_jobs_ongoing,
  count_jobs_scheduled,
  count_jobs_completed,
  count_jobs_unfulfilled,
  lifetime_margin,
  lifetime_margin_percent,
  percent_completed,
  percent_completed_per_period = {},
  percent_scheduled
}) => {
  const theme = useTheme();
  return (
    <>
      <DataRow>
        <DataRecord>
          <InfoSectionHeader>Total Money Earned</InfoSectionHeader>
          <AnalyticFieldValue color={theme.colors.DARK_GREEN}>
            {amount_total_earned ? formatters.dollarCurrencyDB(amount_total_earned || 0) : '-'}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <InfoSectionHeader>Lifetime Margin</InfoSectionHeader>
          <AnalyticFieldValue color={theme.colors.DARK_GREEN}>
            {lifetime_margin
              ? `${formatters.dollarCurrencyDB(lifetime_margin || 0)} (${lifetime_margin_percent}%)`
              : '-'}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <InfoSectionHeader>Average Job Value</InfoSectionHeader>
          <AnalyticFieldValue color={theme.colors.DARK_GREEN}>
            {amount_average_earned ? formatters.dollarCurrencyDB(amount_average_earned) : '-'}
          </AnalyticFieldValue>
        </DataRecord>
      </DataRow>
      <DataRow>
        <DataRecord>
          <FieldTitle>Jobs Sent</FieldTitle>
          <AnalyticFieldValue>{count_jobs_assigned || '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Jobs Accepted</FieldTitle>
          <AnalyticFieldValue>{count_jobs_accepted || '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Jobs Ongoing</FieldTitle>
          <AnalyticFieldValue>{count_jobs_ongoing || '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Jobs Scheduled</FieldTitle>
          <AnalyticFieldValue>{count_jobs_scheduled || '-'}</AnalyticFieldValue>
        </DataRecord>
      </DataRow>
      <DataRow>
        <DataRecord>
          <FieldTitle>Jobs Completed</FieldTitle>
          <AnalyticFieldValue>{count_jobs_completed || '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Jobs Unfulfilled</FieldTitle>
          <AnalyticFieldValue>{count_jobs_unfulfilled || '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Completion Rate (lifetime)</FieldTitle>
          <AnalyticFieldValue>
            {!percent_completed && percent_completed !== 0 ? '-' : `${(percent_completed || 0).toFixed()}%`}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Scheduling Rate (lifetime)</FieldTitle>
          <AnalyticFieldValue>
            {!percent_scheduled && percent_scheduled !== 0 ? '-' : `${(percent_scheduled || 0).toFixed()}%`}
          </AnalyticFieldValue>
        </DataRecord>
      </DataRow>
      <DataRow>
        <DataRecord>
          <FieldTitle>Completion Rate (1 year)</FieldTitle>
          <AnalyticFieldValue>
            {percent_completed_per_period[PERIODS.YEAR]
              ? `${percent_completed_per_period[PERIODS.YEAR].toFixed()}%`
              : '0%'}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Completion Rate (6 months)</FieldTitle>
          <AnalyticFieldValue>
            {percent_completed_per_period[PERIODS.HALF_YEAR]
              ? `${percent_completed_per_period[PERIODS.HALF_YEAR].toFixed()}%`
              : '0%'}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Completion Rate (3 months)</FieldTitle>
          <AnalyticFieldValue>
            {percent_completed_per_period[PERIODS.QUARTER]
              ? `${percent_completed_per_period[PERIODS.QUARTER].toFixed()}%`
              : '0%'}
          </AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Completion Rate (1 month)</FieldTitle>
          <AnalyticFieldValue>
            {percent_completed_per_period[PERIODS.MONTH]
              ? `${percent_completed_per_period[PERIODS.MONTH].toFixed()}%`
              : '0%'}
          </AnalyticFieldValue>
        </DataRecord>
      </DataRow>
      <DataRow>
        <DataRecord>
          <FieldTitle>Days Since Sign-Up</FieldTitle>
          <AnalyticFieldValue>{Boolean(date_created) ? getDaysCountFromDate(date_created) : '-'}</AnalyticFieldValue>
        </DataRecord>
        <DataRecord>
          <FieldTitle>Last Activity Date</FieldTitle>
          {Boolean(date_login) ? (
            <FieldValue isHighlighted={getDaysCountFromDate(date_login) >= 30}>
              {formatters.dateWithTimezone(date_login, dates.DATE)}
              {',\n'}
              <div>{getHumanizedDuration(date_login)}</div>
            </FieldValue>
          ) : (
            <FieldValue>-</FieldValue>
          )}
        </DataRecord>
        <DataRecord />
        <DataRecord />
      </DataRow>
    </>
  );
};

export default ProviderAnalytics;
