export const DEFAULT_VISIT_WINDOW = 8;
export const MAX_VISIT_WINDOW = 8;
export const MIN_VISIT_WINDOW = 2;
export const MINUTES_IN_DAY = 1440;
export const MINUTES_IN_HOUR = 48;
export const TIME_PICKER_MINUTES_STEP = 30;

export default {
  DEFAULT_VISIT_WINDOW,
  MAX_VISIT_WINDOW,
  MIN_VISIT_WINDOW,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  TIME_PICKER_MINUTES_STEP
};
