import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';
import { useRef } from 'hooks';
import Tab from './Tab';
import { Wrapper, Button, TabsWrapper, TabHeader, TabList, UnreadMessagesMark, EmptySpace } from './styled';

const Tabs = ({
  headers,
  onTabChange = () => {},
  defaultTabIndex = 0,
  markedTabs,
  boldFont,
  minHeight,
  bg,
  border,
  children,
  headerWrapperStyle
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
  const [isShowScroll, setIsShowScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState();
  const ref = useRef(null);
  const tabsRef = useRef(null);

  const updateScrollPosition = () => {
    setScrollPosition(ref.current.getBoundingClientRect().left - tabsRef.current?.getBoundingClientRect().left);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', updateScrollPosition, false);
      const currentRef = ref.current;
      return function cleanup() {
        currentRef.removeEventListener('scroll', updateScrollPosition, false);
      };
    }
  }, []);

  useEffect(() => {
    setIsShowScroll(ref.current?.scrollWidth > ref.current?.clientWidth);
  }, [ref.current?.scrollWidth, ref.current?.clientWidth]);

  const scrollLeft = () => ref.current?.scrollTo({ left: 0, behavior: 'smooth' });
  const scrollRight = () => ref.current?.scrollTo({ left: 10000, behavior: 'smooth' });

  useEffect(() => {
    setActiveTabIndex(defaultTabIndex < 0 ? 0 : defaultTabIndex);
  }, [defaultTabIndex]);

  useEffect(() => {
    if (headers.length - 1 < activeTabIndex) {
      setActiveTabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers.length]);

  const onChange = (index) => () => {
    setActiveTabIndex(index);
    onTabChange(index);
  };

  const headersRender = headers.map((header, index) => {
    return (
      <TabHeader key={index} boldFont={boldFont} isActive={index === activeTabIndex} onClick={onChange(index)}>
        {markedTabs && markedTabs[index] && [...Array(header.length).keys()].includes(index) ? (
          <UnreadMessagesMark />
        ) : null}
        {header}
      </TabHeader>
    );
  });

  const tabsRender = children?.filter(Boolean).map((tab, index) => {
    return (
      <Tab key={index} isActive={index === activeTabIndex}>
        {React.cloneElement(tab, { isActive: index === activeTabIndex })}
      </Tab>
    );
  });

  return (
    <TabsWrapper minHeight={minHeight} bg={bg}>
      <Wrapper ref={ref}>
        <div ref={tabsRef}>
          <TabList border={border} style={headerWrapperStyle}>
            {headersRender}
          </TabList>
        </div>
        <EmptySpace />
      </Wrapper>
      {isShowScroll &&
        (scrollPosition > 14 ? (
          <Button onClick={scrollLeft}>
            <Icon name="leftArrow" />
          </Button>
        ) : (
          <Button right onClick={scrollRight}>
            <Icon name="leftArrow" />
          </Button>
        ))}

      {tabsRender || null}
    </TabsWrapper>
  );
};

Tabs.propTypes = {
  onTabChange: PropTypes.func,
  activeTabIndex: PropTypes.number,
  children: PropTypes.array,
  headers: PropTypes.array.isRequired,
  markedTabs: PropTypes.array
};

export default Tabs;
