import styled from 'styled-components';
import styles from 'constants/styles';

export const TooltipRelativeWrapper = styled.div`
  position: relative;
  line-height: 1;
  vertical-align: top;
  display: ${({ inline }) => (inline ? 'inline' : 'flex')};
`;

export const RewardRow = styled.div`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  &:hover {
    ${TooltipRelativeWrapper} {
      display: flex;
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.TOOLTIP_BG};
  padding: 8px 16px;
  bottom: 35px;
  z-index: 5;
  max-height: 200px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'max-content')};
  ${({ direction }) =>
    direction === 'right'
      ? `
    left: 15px;
  `
      : `
    right: 15px;
  `}
  ${({ left }) =>
    left !== undefined &&
    `
    left: ${left}px;
    right: initial;
  `};
  ${({ right }) =>
    right !== undefined &&
    `
    right: ${right}px;
    left: initial;
  `};
  ${({ bottom }) =>
    bottom !== undefined &&
    `
    bottom: ${bottom}px;
    top: initial;
  `};
  ${({ top }) =>
    top !== undefined &&
    `
    top: ${top}px;
    bottom: initial;
  `};
`;

export const WrappedComponent = styled.div`
  display: inline-flex;
  ${({ clickable }) => clickable && 'cursor: pointer'};
  &:hover + div {
    display: block;
  }
`;

export const TooltipTitle = styled.div`
  color: ${({ theme }) => theme.colors.TOOLTIP_TEXT};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  ${({ maxWidth }) =>
    Boolean(maxWidth) &&
    `
      max-width: ${maxWidth}px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
    `}
`;

export const TooltipArrow = styled.div`
  width: 0;
  height: 0;
  bottom: -7px;

  ${({ direction }) => (direction === 'right' ? 'left' : 'right')}: 8px;
  border-right: 13px solid transparent;
  border-top: 7px solid ${({ theme }) => theme.colors.TOOLTIP_BG};

  position: absolute;
`;
