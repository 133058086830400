import styled from 'styled-components';
import styles from 'constants/styles';

const StyledInput = styled.input`
  display: ${({ isPlain }) => (isPlain ? 'block' : 'inline-block')};
  margin-bottom: ${({ isPlain }) => (isPlain ? '28' : '16')}px;
  margin-right: ${({ isPlain, isPhone }) => {
    if (isPhone || isPlain) return '0';
    return '10px';
  }};
  background: ${({ disabled }) => (!disabled ? styles.colors.WHITE : styles.colors.GRAY)};
  font-family: 'Roboto', sans-serif;
  font-size: ${({ isPlain }) => (isPlain ? '18' : '14')}px;
  line-height: 1.2;
  letter-spacing: ${({ isPlain }) => (isPlain ? '0' : '1px')};
  padding: ${({ isPlain }) => (isPlain ? '4px 0' : '20px 27px 18px')};
  width: 100%;
  box-sizing: border-box;
  color: ${styles.colors.DARK_BLUE};
  border-radius: 5px;

  ${({ isPlain }) =>
    isPlain
      ? `
        border: none;
        border-bottom: 4px solid rgba(195,54,90,.3);
    `
      : `
        border: 1px solid rgba(101,129,163,.28);
    `}

  outline: none;
  transition: all 0.2s ease-out;
  &::placeholder {
    font-family: 'Roboto', sans-serif;
    color: ${styles.colors.GRAY};
  }
  &:hover,
  &:focus {
    ${({ isPlain }) =>
      isPlain
        ? `
            border-bottom-color: rgba(195,54,90,.5)
        `
        : `
            outline: none;
        `}
  }
  @media screen and (min-width: 1024px) {
    width: ${({ isPlain, isPhone }) => {
      if (isPhone) return '320px';
      if (isPlain) return '400px';
      return '240px';
    }};
    max-width: 100%;
    ${({ isPlain }) => !isPlain && 'border: none'};
    box-shadow: ${({ isPlain }) => (isPlain ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.1)')};
  }
`;

export default StyledInput;
