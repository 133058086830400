const SHORT_MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const isDayDifferent = (firstDateString, secondDateString) => {
  if (!firstDateString || !secondDateString) {
    return true;
  }

  try {
    const firstDate = new Date(firstDateString);
    const secondDate = new Date(secondDateString);

    // Day is in local tz, offset is not required
    return firstDate.toDateString() !== secondDate.toDateString();
  } catch (RangeError) {
    return false;
  }
};

const formatDate = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    const date = new Date(dateString);
    return `${SHORT_MONTH_NAME[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  } catch (RangeError) {
    return '';
  }
};

const leftPadZero = (num, len = 2) => {
  const numStr = num + '';
  const sizeDiff = len - numStr.length;
  if (sizeDiff < 1) {
    return numStr;
  }

  const zerosArr = Array(sizeDiff).fill('0');
  return zerosArr.join('') + numStr;
};

const formatTime = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    const date = new Date(dateString);
    const hour = date.getHours();
    const dayPartHour = hour > 11 ? hour - 12 : hour;
    const dayPart = hour > 11 ? 'PM' : 'AM';
    return `${leftPadZero(dayPartHour)}:${leftPadZero(date.getMinutes())} ${dayPart}`;
  } catch (RangeError) {
    return '';
  }
};

export { isDayDifferent, formatDate, formatTime };
