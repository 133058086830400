import React, { useState } from 'react';
import { TagsInput, Tag, TagsInfo, Label } from './styled';
import { Icon } from 'components';

const IdsInput = ({
  values = [],
  onChange,
  notice = 'You can enter only 20 ID’s. Use “Space Bar” to add another ID.',
  noticeStyle,
  label
}) => {
  const [value, setValues] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    const isNumber = /^\d+$/.test(e.target.value);

    if ((!isNumber && value !== '' && value[value.length - 1] !== ' ') || value === ' ') {
      return;
    }

    if (e.target.value.includes(' ') && !values.includes(value.trim()) && values.length <= 20) {
      onChange([...values, value.trim()]);
      setValues('');
      return;
    }

    setValues(value.trim());
  };

  return (
    <div>
      {Boolean(label) && <Label>{label}</Label>}
      <TagsInput>
        {values.map((item) => (
          <Tag key={item}>
            <div>{item}</div>
            <Icon name="closeFill" size={16} clickable onClick={() => onChange(values.filter((i) => i !== item))} />
          </Tag>
        ))}
        <input value={value} placeholder={values.length ? '|   Add more' : 'Enter User’s ID'} onChange={handleChange} />
      </TagsInput>
      <TagsInfo style={noticeStyle}>{notice}</TagsInfo>
    </div>
  );
};

export default IdsInput;
