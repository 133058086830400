import styled from 'styled-components';
import styles from 'constants/styles';

export const Wrapper = styled.div`
  width: 100%;
  overflow: overlay;
  background-color: ${({ theme }) => theme.colors.DARK_1};
  height: calc(100vh - ${styles.layout.headerHeight + styles.layout.tabsHeaderHeight}px) !important;
`;

export const ReviewDivider = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.DARK_2};
  margin: 0 16px 0 21px;
`;

export const LoadingWrapper = styled.div`
  min-height: 300px;
  position: relative;
  flex: 1;
`;

export const LoadMoreButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  width: 100%;
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  background-color: ${({ theme }) => theme.colors.DARK_3};
  &:hover {
    background-color: ${({ theme }) => theme.colors.DARK_4};
    color: ${({ theme }) => theme.colors.WHITE};
  }
  svg {
    margin-right: 8px;
  }
  &:focus {
    outline: 0;
  }
`;

export const ModalText = styled.div`
  margin: 11px 0 8px 0;
`;

export const BoldTextWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const EmptyList = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  position: relative;
`;

export const EmptyPlaceholder = styled.div`
  padding: 50px 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;
