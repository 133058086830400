import React, { useState, useCallback, useEffect } from 'react';
import { Icon, LoadingDots, IconButton } from 'components';
import {
  FieldValue,
  InputContainer,
  StyledInput,
  IconMargin,
  SpinnerContainer,
  EditableValue,
  SearchListWrapper,
  SearchValue,
  SearchValueText
} from './styled';
import { debounce } from 'utils';

const AutocompleteSearch = ({
  searchList = [],
  defaultSearchText = '',
  onSubmit = null,
  onSearch = null,
  loading,
  name,
  format,
  selectedEditingField,
  disabled = false,
  width
}) => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [isSearchListShown, setIsSearchListShown] = useState(false);
  const [searchText, setSearchText] = useState(defaultSearchText);

  const emptyValue = searchText === null || searchText === '';

  const onSearchDebounced = useCallback(
    debounce((nextValue) => onSearch(nextValue), 400),
    []
  );

  const onChange = (event) => {
    const { value: nextValue } = event.target;
    onSearchDebounced(nextValue);
    setSearchText(nextValue);

    if (nextValue) {
      setIsSearchListShown(true);
    } else {
      setIsSearchListShown(false);
    }
  };

  useEffect(() => {
    if (isInputShown && !loading) {
      setIsInputShown(false);
      setIsSearchListShown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onEmptyValueClick = () => {
    if (emptyValue) {
      setIsInputShown(true);
    }
  };

  const onSearchValueClick = (searchValue) => () => {
    if (format(searchValue) === defaultSearchText) {
      onCancelEditing();
    } else {
      onSubmit(searchValue);
      setIsSearchListShown(false);
      setSearchText(format(searchValue));
    }
  };

  const onCancelEditing = () => {
    setIsInputShown(false);
    setSearchText(defaultSearchText);
  };

  return (
    <FieldValue
      isEmptyValue={emptyValue}
      isInputShown={isInputShown}
      onClick={disabled ? null : onEmptyValueClick}
      disabled={disabled}>
      {isInputShown ? (
        <>
          {loading && selectedEditingField === name ? (
            <SpinnerContainer>
              <LoadingDots left={0} top={1} />
            </SpinnerContainer>
          ) : (
            <InputContainer>
              <StyledInput
                value={searchText}
                name={name}
                onChange={onChange}
                autoComplete="off"
                autoFocus
                width={width}
              />
              <IconMargin left={7} top={4}>
                <Icon name="closeFill2" backgroundSize={16} onClick={onCancelEditing} />
              </IconMargin>
            </InputContainer>
          )}
        </>
      ) : emptyValue && !disabled ? (
        'Set up'
      ) : emptyValue && disabled ? (
        <>&mdash;</>
      ) : (
        <div>
          <EditableValue disabled={disabled}>{searchText}</EditableValue>
          <IconMargin left={7} top={-2}>
            {!disabled && <IconButton iconSize={10} iconName="editFill" onClick={() => setIsInputShown(true)} />}
          </IconMargin>
        </div>
      )}
      {searchList.length && isSearchListShown && isInputShown && !loading ? (
        <SearchListWrapper>
          {searchList.map((searchValue) => (
            <SearchValue key={searchValue.id} onClick={onSearchValueClick(searchValue?.user)}>
              <SearchValueText>{format(searchValue?.user)}</SearchValueText>
            </SearchValue>
          ))}
        </SearchListWrapper>
      ) : null}
    </FieldValue>
  );
};

export default AutocompleteSearch;
