import React from 'react';
import styles from 'constants/styles';

const IconArrowRight = ({ width = 8, height = 14, color = styles.colors.WHITE }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.333984 12.5858V1.41421C0.333984 0.523309 1.41112 0.0771399 2.04109 0.707105L7.62688 6.29289C8.0174 6.68342 8.0174 7.31658 7.62688 7.70711L2.04109 13.2929C1.41113 13.9229 0.333984 13.4767 0.333984 12.5858Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowRight;
