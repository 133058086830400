import React from 'react';

import styles from 'constants/styles';
import { CloseButton } from 'components';
import {
  RightSidebarShadow,
  RightSidebarContent,
  RightSidebarHeader,
  RightSidebarHeaderText
} from './styled';

const RightSidebar = ({ onSidebarClose, visible, children, title }) => (
  <RightSidebarShadow visible={visible} onClick={onSidebarClose}>
    <RightSidebarContent onClick={e => e.stopPropagation()}>
      {Boolean(title) && (
        <RightSidebarHeader>
          <RightSidebarHeaderText>{title}</RightSidebarHeaderText>
          <CloseButton
            size={18}
            iconSize={10}
            iconColor={styles.colors.BLACK}
            onClick={onSidebarClose}
          />
        </RightSidebarHeader>
      )}
      {children}
    </RightSidebarContent>
  </RightSidebarShadow>
);

export default RightSidebar;
