import React from 'react';
import styles from 'constants/styles';

const IconCalendar = ({ size = 24, color = styles.colors.LIGHT_GRAY }) => {
  return (
    <svg width={size} height={size * 0.9} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9062 1.875H18.75V0.75C18.75 0.335813 18.4142 0 18 0C17.5858 0 17.25 0.335813 17.25 0.75V1.875H6.75V0.75C6.75 0.335813 6.41423 0 6 0C5.58577 0 5.25 0.335813 5.25 0.75V1.875H3.09375C1.38783 1.875 0 3.26283 0 4.96875V20.9062C0 22.6122 1.38783 24 3.09375 24H20.9062C22.6122 24 24 22.6122 24 20.9062V4.96875C24 3.26283 22.6122 1.875 20.9062 1.875ZM3.09375 3.375H5.25V4.125C5.25 4.53919 5.58577 4.875 6 4.875C6.41423 4.875 6.75 4.53919 6.75 4.125V3.375H17.25V4.125C17.25 4.53919 17.5858 4.875 18 4.875C18.4142 4.875 18.75 4.53919 18.75 4.125V3.375H20.9062C21.7851 3.375 22.5 4.08994 22.5 4.96875V6.75H1.5V4.96875C1.5 4.08994 2.21494 3.375 3.09375 3.375ZM20.9062 22.5H3.09375C2.21494 22.5 1.5 21.7851 1.5 20.9062V8.25H22.5V20.9062C22.5 21.7851 21.7851 22.5 20.9062 22.5ZM5.33333 10.6667H8V13.3333H5.33333V10.6667ZM8 16H5.33333V18.6667H8V16ZM10.6667 10.6667H13.3333V13.3333H10.6667V10.6667ZM13.3333 16H10.6667V18.6667H13.3333V16ZM16 10.6667H18.6667V13.3333H16V10.6667ZM18.6667 16H16V18.6667H18.6667V16Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCalendar;
