import React from 'react';

const IconClose2 = props => {
  const { size = 14, color = '#253757' } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 14 14`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.714559 11.8751C0.32415 12.2658 0.324338 12.8989 0.714978 13.2893C1.10562 13.6798 1.73878 13.6796 2.12919 13.2889L7.00102 8.41421L11.873 13.2891C12.2634 13.6797 12.8966 13.6799 13.2872 13.2895C13.6778 12.8991 13.678 12.2659 13.2876 11.8753L8.41482 6.99958L13.2876 2.12389C13.678 1.73325 13.6778 1.10008 13.2872 0.709676C12.8966 0.319267 12.2634 0.319455 11.873 0.710095L7.00102 5.58494L2.12919 0.710222C1.73878 0.319582 1.10562 0.319394 0.714978 0.709803C0.324338 1.10021 0.32415 1.73338 0.714559 2.12402L5.58723 6.99958L0.714559 11.8751Z"
        fill={color}
      />
    </svg>
  );
};

export default IconClose2;
