import React from 'react';
import { StyledTooltip } from 'components';
import { CircleBackground } from './styled';

export const ProviderPositiveReviews = ({ percent_positive_reviews }) => {
  const notAvailablePositiveReviews = !percent_positive_reviews && percent_positive_reviews !== 0;
  return (
    <StyledTooltip title="Percentage of positive reviews" arrow placement="top-start">
      <CircleBackground>{notAvailablePositiveReviews ? 'n/a' : `${percent_positive_reviews || 0}%`}</CircleBackground>
    </StyledTooltip>
  );
};
