import styled from 'styled-components';
import styles from 'constants/styles';

export const Button = styled.button`
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${styles.colors.WHITE};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 1px 14px;
  height: 24px;
  min-width: 87px;
  div {
    margin: 0 auto;
  }

  &:hover:not([disabled]) {
    color: ${styles.colors.RED};
  }
`;

export const CurrentLabel = styled.span`
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: ${styles.colors.GREEN};
`;

export const EllipsisText = styled.div`
  word-break: break-word;
  overflow: ${({ overflow = 'hidden' }) => overflow};
  text-overflow: ellipsis;
  display: ${({ display = '-webkit-box' }) => display};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ textColor }) => textColor && `color: ${textColor};`};
`;

export const IconWrapper = styled.div`
  margin: 0 20px 0 0;
`;

export const Image = styled.img`
  width: 16px;
`;

export const Label = styled.div`
  color: ${({ color }) => color};
  text-transform: capitalize;
  background: ${({ theme }) => theme.colors.DARK_2};
  font-weight: 400;
  font-size: 12px;
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  border-radius: ${({ hasSubText }) => (hasSubText ? '12px' : '20px')};
  padding: ${({ hasSubText }) => (hasSubText ? '4px' : '4px 12px')};
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  ${({ isEllipsis }) =>
    isEllipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};

  ${({ listItem, textColor, theme }) =>
    listItem &&
    `
      padding: 4px 7px;
      margin: 2px;
      min-width: 75px;
      color: ${textColor === theme.colors.BLACK ? theme.colors.INVOICE_TEXT : textColor || styles.colors.WHITE};
    `}
`;

export const LabelList = styled.div`
  display: flex;
`;

export const LatestVersionMark = styled.div`
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  white-space: pre-wrap;
  color: ${({ isLatest }) => (isLatest ? styles.colors.GREEN : styles.colors.RED)};
  padding: 0;
`;

export const Mark = styled.div`
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  ${({ color }) => color && `background-color: ${color}`};
`;

export const RadioButton = styled.button`
  border: none;
  box-shadow: none;
  width: 24px;
  height: 24px;
  background: ${({ selected }) => (selected ? styles.colors.GREEN : styles.colors.LIGHT_GRAY_2)};
  border-radius: 50%;
  border: ${({ selected }) => (selected ? 'none' : `1px solid ${styles.colors.LIGHT_GRAY}`)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RichText = styled.div`
  max-width: 162px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RichTextHeader = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const SubText = styled.div`
  font-weight: 400;
  font-size: 8px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const TooltipText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.DARK_1};
  text-align: left;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Skill = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 16px 0;
`;
