import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const NotificationTarget = styled.div`
  padding: 4px 24px;
  background-color: ${({ theme }) => theme.colors.DARK_2};
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 20px;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
  max-width: 125px;
  min-width: 110px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const NotificationWrapper = styled.div`
  width: ${({ width }) => width || '410px'};

  ${({ transparent, theme }) =>
    transparent
      ? `
      box-shadow: none;
      background: transparent;
    `
      : `
      background: ${theme.colors.DARK_4};
      border-radius: 8px;
      box-shadow: ${theme.boxShadows.THIN_DARK};
    `}

  padding: ${({ padding }) => padding || '12px 11px 11px 14px'};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.NOTIFICATION_HOVER};
    ${NotificationTarget} {
      border-color: ${({ theme }) => theme.colors.NOTIFICATION_HOVER};
    }
  }

  ${({ hasAnimation }) =>
    hasAnimation &&
    `
      transform: translateX(110%);
      transition: transform 300ms ease-in;

      &.translate-enter-done {
        transform: translateX(0);
      }
    `}
`;

export const NotificationHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationLeftHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: ${({ padding }) => padding};
`;

export const NotificationHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 28px;
`;

export const NotificationHeaderText = styled.div`
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ transparent, theme }) => (transparent ? theme.colors.BLACK : theme.colors.WHITE)};

  span {
    font-weight: bold;
  }
`;

export const NotificationHeaderTime = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.GRAY};
`;

export const NotificationText = styled.div`
  margin-top: 8px;
  padding-left: 34px;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  max-width: 350px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const NotificationTitle = styled(ReactMarkdown)`
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  }
`;
