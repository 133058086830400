import React from 'react';
import styles from 'constants/styles';
import { RotatedSvg } from './styled';

const IconArrowFillBottom = ({ color = styles.colors.DARK_GREY, width = 10, height = 6, rotate = 0 }) => (
  <RotatedSvg
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    rotate={rotate}>
    <path
      d="M8.58579 0L1.41421 0C0.523309 0 0.0771425 1.07714 0.707107 1.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0Z"
      fill={color}
    />
  </RotatedSvg>
);

export default IconArrowFillBottom;
