import styled from 'styled-components';
import BaseToggleButton from '@mui/material/ToggleButton';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-size: 12px;
  line-height: 1.17;
  font-weight: 500;
  margin-bottom: 8px;

  color: ${({ theme }) => theme.colors.GRAY};
`;

export const Error = styled(Label)`
  color: ${({ theme }) => theme.colors.INPUT_ERROR};
  margin-top: 5px;
`;

export const ToggleButton = styled(BaseToggleButton)`
  &.MuiToggleButton-root {
    color: ${({ theme }) => theme.colors.DARK_GREY_2};
    font-weight: 500;
    height: 32px;
    min-width: 50px;
    padding: 8px 4px;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
    box-shadow: none;
    transition: all 0.3s ease-in-out;

    font-size: 14px;
    line-height: 1.17;
    text-transform: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.DARK_GREY_2};
      color: ${({ theme }) => theme.colors.WHITE};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.DARK_GREY_2};
      color: ${({ theme }) => theme.colors.WHITE};
      &:hover {
        background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
        color: ${({ theme }) => theme.colors.DARK_GREY_2};
      }
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.colors.TEXT_DISABLED};
      border-color: ${({ theme }) => theme.colors.BORDER_DISABLED};
      background-color: ${({ theme }) => theme.colors.BG_DISABLED};
    }
  }
`;
