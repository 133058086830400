import React from 'react';
import { useController } from 'react-hook-form';

import MuiTextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

import { PickerWrapper, FieldWrapper } from './styled';

const DatePicker = ({
  name,
  control,
  rules,
  defaultValue,
  shouldDisableDate,
  loading,
  disabled,
  disableFuture,
  label
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const commonProps = {
    value,
    onChange,
    shouldDisableDate,
    loading,
    disabled,
    disableFuture
  };

  return (
    <PickerWrapper>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MuiDatePicker
          {...commonProps}
          label={label}
          renderInput={(params) => (
            <FieldWrapper>
              <MuiTextField {...params} />
            </FieldWrapper>
          )}
          showDaysOutsideCurrentMonth={true}
          {...inputProps}
        />
      </LocalizationProvider>
    </PickerWrapper>
  );
};

export default DatePicker;
