import React from 'react';

import { getHomeownerTags, getFullName, getProviderStage } from 'common/helpers/user';
import { HighlightedText, StyledTooltip } from 'components';
import { FlexRow, TooltipContent } from './styled';

const UserName = ({
  stage,
  pro_stage,
  given_name,
  family_name,
  id,
  is_active,
  searchTerm,
  isEllipsis,
  textStyle,
  disabled
}) => {
  const retentionPrefix = getHomeownerTags({ stage });
  const proStagePrefix = getProviderStage({ pro_stage });
  const prefix = retentionPrefix || proStagePrefix;

  const name = getFullName({ given_name, family_name, id, is_active });
  const props = searchTerm ? { textArray: [given_name, family_name] } : { text: name };

  return (
    <FlexRow>
      {Boolean(prefix) && (
        <StyledTooltip title={prefix.DESCRIPTION} arrow placement="top-start">
          <TooltipContent>
            <HighlightedText text={prefix.EMOJI} highlight={''} textStyle={textStyle} />
          </TooltipContent>
        </StyledTooltip>
      )}
      <HighlightedText
        {...props}
        highlight={searchTerm}
        isEllipsis={isEllipsis}
        textStyle={textStyle}
        disabled={disabled}
      />
    </FlexRow>
  );
};

export default UserName;
