import styles from 'constants/styles';

export const jobTypesByTab = {
  ACTIVE: 1,
  // RECENTLY_COMPLETED: 4,
  PAID: 2,
  CANCELLED: 3
};

export const jobFLows = {
  DEFAULT: {
    TYPE: 1,
    NAME: 'Full'
  },
  VIDEO: {
    TYPE: 2,
    NAME: 'Video'
  }
};

export const adminStatuses = {
  REQUESTED: {
    TYPE: 0,
    COLOR: styles.colors.DARK_RED,
    NAME: 'requested'
  },
  ASSIGNED: {
    TYPE: 8,
    COLOR: styles.colors.LIGHT_GRAY,
    NAME: 'assigned'
  },
  ACCEPTED: {
    TYPE: 1,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'accepted'
  },
  SCHEDULED: {
    TYPE: 2,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'scheduled'
  },
  COMPLETED: {
    TYPE: 3,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'completed'
  },
  PAID: {
    TYPE: 4,
    COLOR: styles.colors.WHITE,
    NAME: 'paid'
  },
  UNFULFILLED: {
    TYPE: 5,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'unfulfilled'
  },
  BUNDLED: {
    TYPE: 6,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'bundled'
  },
  HOLD: {
    TYPE: 7,
    COLOR: styles.colors.DARK_GREEN,
    NAME: 'on-hold'
  }
};

export const jobTags = {
  DEFAULT: {
    IS_CLOSED_JOB: true,
    NAME: 'Default',
    TYPE: 0
  },
  QUOTED_JOBS: {
    NAME: 'Quoted Jobs',
    TYPE: 1
  },
  LARGE: {
    NAME: 'Large Jobs',
    TYPE: 2
  },
  PAYMENT: {
    IS_AUTOMATED: true,
    NAME: 'Payment',
    TYPE: 3
  },
  ACCEPTED: {
    IS_AUTOMATED: true,
    NAME: 'Accepted',
    TYPE: 5
  },
  TROUBLED: {
    IS_CLOSED_JOB: true,
    NAME: 'Troubled',
    TYPE: 4
  },
  LOVE_LETTER: {
    IS_CLOSED_JOB: true,
    NAME: 'Love Letter',
    TYPE: 6
  },
  AR: {
    IS_AUTOMATED: true,
    NAME: 'AR',
    TYPE: 7
  },
  PAST_SCHEDULED: {
    IS_AUTOMATED: true,
    NAME: 'Past Scheduled',
    TYPE: 8
  },
  HOLD: {
    IS_AUTOMATED: true,
    NAME: 'On-Hold',
    TYPE: 9
  }
};

export const jobTypes = {
  NORMAL: {
    id: 0,
    name: 'Maintenance & Repairs',
    is_active: true
  },
  PROJECT: {
    id: 1,
    name: 'Renovation & Upgrades',
    is_active: true
  },
  RECURRING: {
    id: 2,
    name: 'Maintenance & Repairs',
    is_active: false
  },
  SEASON_CLEANING: {
    id: 10,
    name: 'Cleaning & Landscaping',
    is_active: true
  },
  INSPECTION: {
    id: 3,
    name: 'Home Inspection',
    is_active: true
  },
  INSPECTION_SELFPAY: {
    id: 4,
    name: 'Home Inspection',
    is_active: false
  },
  HOME_CHECKUP: {
    id: 5,
    name: 'Home Inspection',
    is_active: false
  },
  HOME_CHECKUP_SELFPAY: {
    id: 6,
    name: 'Home Inspection',
    is_active: false
  },
  URGENT: {
    id: 9,
    name: 'Urgent Repair',
    is_active: false
  },
  UNFULFILLED: {
    id: 7,
    name: 'Unfulfilled',
    is_active: false
  },
  TESTING: {
    id: 8,
    name: 'QA & Testing',
    is_active: false
  }
};

export const jobCategories = [
  {
    id: 26,
    name: 'Appliance Repair',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 4,
    name: 'Architect',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 5,
    name: 'Asphalt',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 6,
    name: 'Carpet Cleaning',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 7,
    name: 'Chimney and Fireplace',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 70,
    name: 'Closet Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 10,
    name: 'Countertop',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 47,
    name: 'Drywall',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 11,
    name: 'Duct Cleaning',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 3,
    name: 'Electrical',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 12,
    name: 'Fence',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 29,
    name: 'Flooring',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 13,
    name: 'Foundation',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 14,
    name: 'Garage Door',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 45,
    name: 'Gardening',
    job_types: [jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 27,
    name: 'Gutter Cleaning',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 1,
    name: 'Handyman',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 15,
    name: 'Hardscaping',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 34,
    name: 'Home Inspection',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 9,
    name: 'House Cleaning',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 25,
    name: 'HVAC',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 18,
    name: 'Interior Design',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 19,
    name: 'Junk Hauling',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 28,
    name: 'Landscaping',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 20,
    name: 'Locksmith',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 21,
    name: 'Masonry',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 22,
    name: 'Mold Remediation',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 39,
    name: 'Mounting',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 46,
    name: 'Painting',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 23,
    name: 'Pest Control',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 69,
    name: 'Planning Mode',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 24,
    name: 'Plumbing',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 48,
    name: 'Pressure Washing',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 36,
    name: 'Radon Testing',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 38,
    name: 'Renovation',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 2,
    name: 'Roofing',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 66,
    name: 'Solar Panel Cleaning',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 40,
    name: 'Stone Repair',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 41,
    name: 'Tile',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 8,
    name: 'Tree Care',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 61,
    name: 'Tree Trimming',
    job_types: [jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 42,
    name: 'Wallpaper',
    job_types: [jobTypes.NORMAL.id]
  },
  {
    id: 43,
    name: 'Water Damage',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 44,
    name: 'Window Washing',
    job_types: [jobTypes.NORMAL.id, jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 60,
    name: 'Yard Clean-Up',
    job_types: [jobTypes.SEASON_CLEANING.id]
  },
  {
    id: 32,
    name: 'Other',
    job_types: [jobTypes.NORMAL.id, jobTypes.URGENT.id]
  },
  {
    id: 35,
    name: 'Home Inspection',
    job_types: [jobTypes.INSPECTION.id]
  },
  {
    id: 56,
    name: 'Basement Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 50,
    name: 'Bathroom Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 55,
    name: `Bedroom/Kids Room Renovation`,
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 63,
    name: 'Deck Installation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 62,
    name: 'Deck Repair',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 65,
    name: 'Fence Installation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 64,
    name: 'Fence Repair',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 52,
    name: 'Flooring Installation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 54,
    name: 'Garage Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 58,
    name: 'Home Office Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 51,
    name: 'Interior or Exterior Painting',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 49,
    name: 'Kitchen Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 53,
    name: 'Roof Replacement',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 57,
    name: 'Yard & Landscaping Renovation',
    job_types: [jobTypes.PROJECT.id]
  },
  {
    id: 59,
    name: 'Name your project!',
    job_types: [jobTypes.PROJECT.id]
  },
  // categories to display proper name for old tasks
  // (to delete)
  {
    id: 16,
    name: 'Landscaping (Full)',
    job_types: null
  },
  {
    id: 17,
    name: 'Landscaping (Maintenance)',
    job_types: null
  },
  {
    id: 37,
    name: 'Painting & Drywall',
    job_types: null
  },
  {
    id: 30,
    name: 'Pest & Mold',
    job_types: null
  },
  {
    id: 31,
    name: 'Preventive maintenance',
    job_types: null
  },
  {
    id: 67,
    name: 'Deck',
    job_types: null
  },
  {
    id: 68,
    name: 'Pool/SPA Inspection',
    job_types: null
  }
];

export const providerSpecialties = [
  {
    id: 1,
    name: 'Handyman'
  },
  {
    id: 3,
    name: 'Electrical'
  },
  {
    id: 24,
    name: 'Plumbing'
  },
  {
    id: 26,
    name: 'Appliance Repair'
  },
  {
    id: 4,
    name: 'Architect'
  },
  {
    id: 5,
    name: 'Asphalt'
  },
  {
    id: 6,
    name: 'Carpet Cleaning'
  },
  {
    id: 7,
    name: 'Chimney and Fireplace'
  },
  {
    id: 9,
    name: 'Cleaning'
  },
  {
    id: 56,
    name: 'Closet Renovation'
  },
  {
    id: 10,
    name: 'Countertop'
  },
  {
    id: 47,
    name: 'Drywall'
  },
  {
    id: 11,
    name: 'Duct Cleaning'
  },
  {
    id: 12,
    name: 'Fence'
  },
  {
    id: 29,
    name: 'Flooring'
  },
  {
    id: 13,
    name: 'Foundation'
  },
  {
    id: 14,
    name: 'Garage Door'
  },
  {
    id: 45,
    name: 'Gardening'
  },
  {
    id: 27,
    name: 'Gutter Cleaning'
  },
  {
    id: 15,
    name: 'Hardscaping'
  },
  {
    id: 34,
    name: 'Home Inspection'
  },
  {
    id: 25,
    name: 'HVAC'
  },
  {
    id: 18,
    name: 'Interior Design'
  },
  {
    id: 19,
    name: 'Junk Hauling'
  },
  {
    id: 28,
    name: 'Landscaping'
  },
  {
    id: 20,
    name: 'Locksmith'
  },
  {
    id: 21,
    name: 'Masonry'
  },
  {
    id: 22,
    name: 'Mold Remediation'
  },
  {
    id: 39,
    name: 'Mounting'
  },
  {
    id: 46,
    name: 'Painting'
  },
  {
    id: 23,
    name: 'Pest Control'
  },
  {
    id: 48,
    name: 'Pressure Washing'
  },
  {
    id: 36,
    name: 'Radon Testing'
  },
  {
    id: 38,
    name: 'Renovation'
  },
  {
    id: 2,
    name: 'Roofing'
  },
  {
    id: 51,
    name: 'Solar Panel Cleaning'
  },
  {
    id: 40,
    name: 'Stone Repair'
  },
  {
    id: 41,
    name: 'Tile'
  },
  {
    id: 8,
    name: 'Tree Care'
  },
  {
    id: 50,
    name: 'Tree Trimming'
  },
  {
    id: 42,
    name: 'Wallpaper'
  },
  {
    id: 43,
    name: 'Water Damage'
  },
  {
    id: 44,
    name: 'Window Washing'
  },
  {
    id: 49,
    name: 'Yard Clean-Up'
  },
  {
    id: 52,
    name: 'Deck'
  },
  {
    id: 53,
    name: 'Pool/SPA Inspection'
  },
  {
    id: 32,
    name: 'Other'
  }
];

export const timeframeEstimateList = [
  {
    id: 1,
    name: 'Over the next few days',
    job_types: []
  },
  {
    id: 2,
    name: 'In the next couple of weeks',
    job_types: []
  },
  {
    id: 3,
    name: 'Flexible over the next month',
    job_types: []
  },
  {
    id: 5,
    name: 'Sometime soon',
    job_types: [
      jobTypes.NORMAL.id,
      jobTypes.INSPECTION.id,
      jobTypes.PROJECT.id,
      jobTypes.SEASON_CLEANING.id,
      jobTypes.TESTING.id
    ]
  },
  {
    id: 4,
    name: 'ASAP',
    job_types: [
      jobTypes.NORMAL.id,
      jobTypes.URGENT.id,
      jobTypes.INSPECTION.id,
      jobTypes.PROJECT.id,
      jobTypes.SEASON_CLEANING.id,
      jobTypes.TESTING.id
    ]
  }
];
export const authors = {
  HO: 1,
  PRO: 2,
  OPS: 3,
  AUTO: 4
};
export const systemStatuses = {
  NEW: 0,
  COMPLETED: 1,
  IN_PROGRESS: 2,
  CANCELED: 3,
  REJECTED: 4,
  FINAL_PAID: 5,
  PROPOSED: 6,
  ACCEPTED: 7
};
export const dobbyCashEntity = {
  PRO: 'provider',
  HO: 'customer',
  PAYMENT: 'payment',
  JOB: 'job'
};
