import dates from 'constants/dates';
import routes from 'constants/routes';
import { customJobFilters } from 'common/enums/filters';
import { DEFAULT_UTC_OFFSET } from 'common/constants/analytics';

export const defaultAnalyticsParams = {
  utc_offset: DEFAULT_UTC_OFFSET,
  test_status: 0
};

export const analyticPeriods = {
  DAY: {
    NAME: 'Day',
    TYPE: 0,
    LABEL_FORMAT: dates.DATE_METRIC_SHORT,
    TITLE_FORMAT: dates.DATE_METRIC,
    MOMENT_NAME: 'days'
  },
  WEEK: {
    NAME: 'Week',
    TYPE: 1,
    LABEL_FORMAT: dates.DATE_METRIC_SHORT,
    TITLE_FORMAT: dates.DATE_METRIC,
    MOMENT_NAME: 'weeks'
  },
  MONTH: {
    NAME: 'Month',
    TYPE: 2,
    LABEL_FORMAT: dates.MONTH_NAME_SHORT,
    TITLE_FORMAT: dates.MONTH_NAME,
    MOMENT_NAME: 'months'
  },
  YEAR: {
    NAME: 'Year',
    TYPE: 3,
    LABEL_FORMAT: dates.YEAR,
    TITLE_FORMAT: dates.YEAR,
    MOMENT_NAME: 'years'
  }
};

export const analyticMetrics = {
  SUBMITTED_TASKS: {
    NAME: 'Total New Requests',
    TYPE: 0,
    SWITCHED_TYPE: 15,
    ROUTE: routes.ANALYTICS_JOBS,
    FILTERS: {
      START_DATE_TYPE: customJobFilters.DATETIME_CREATED_FROM.TYPE,
      END_DATE_TYPE: customJobFilters.DATETIME_CREATED_TO.TYPE
    },
    REQUEST_PARAMS: {
      skip_spectora: true
    }
  },
  COMPLETED_TASKS: {
    NAME: 'Total Completed Maintenances',
    TYPE: 1,
    SWITCHED_TYPE: 16,
    ROUTE: routes.ANALYTICS_JOBS,
    FILTERS: {
      START_DATE_TYPE: customJobFilters.DATETIME_COMPLETED_FROM.TYPE,
      END_DATE_TYPE: customJobFilters.DATETIME_COMPLETED_TO.TYPE
    },
    REQUEST_PARAMS: {
      skip_spectora: true,
      admin_status_group: 'completed'
    }
  },
  CANCELLED_TASKS: {
    NAME: 'Services Canceled',
    TYPE: 2
  },
  PAID_TASKS: {
    NAME: 'Services Paid',
    TYPE: 3
  },
  TASK_COST: {
    NAME: 'Services Avarage Cost',
    TYPE: 4
  },
  TASK_GMV: {
    NAME: 'Total Gross Revenue',
    FORMATTER: 'dollarCurrencyDB',
    COMPRESS_VALUE: 100,
    TYPE: 5
  },
  NEW_HOMEOWNERS: {
    NAME: 'Total Install Base',
    TYPE: 6,
    ROUTE: routes.CUSTOMER
  },
  HOS_SUBMITTED: {
    NAME: 'Homeowners Job Submit Period',
    TYPE: 7
  },
  TASK_PAYMENT_PERIOD: {
    NAME: 'Service payment period',
    TYPE: 8
  },
  ACTIVE_USERS: {
    NAME: 'Number of Active Users',
    TYPE: 9
  },
  AVG_NUM_OF_TASKS: {
    NAME: 'Avg # of Jobs Completed',
    SUB_NAME: 'Per Active User',
    TYPE: 10
  },
  AVG_SIZE_OF_TASKS: {
    NAME: 'Avg Job Size Per Active User',
    FORMATTER: 'dollarCurrencyDB',
    COMPRESS_VALUE: 100,
    TYPE: 11
  },
  INACTIVE_USERS: {
    NAME: '# of Inactive Users',
    SUB_NAME: 'In The Last 90 Days',
    TYPE: 12
  },
  ACTIVE_PROS: {
    NAME: '# of Active Pros',
    TYPE: 13
  },
  INACTIVE_PROS: {
    NAME: '# of Inactive Pros',
    TYPE: 14
  },
  SUBMITTED_TASKS_FULL: {
    NAME: 'Total New Requests',
    TYPE: 15,
    SWITCH_TITLE: 'Spectora jobs'
  },
  COMPLETED_TASKS_FULL: {
    NAME: 'Total Completed Maintenances',
    TYPE: 16,
    SWITCH_TITLE: 'Spectora jobs',
    PARAMS: {
      admin_status_group: 'completed'
    }
  }
};

export const chartTypes = {
  LINE: 'line',
  BAR: 'bar'
};

export const snaphotTiles = [
  analyticMetrics.TASK_GMV,
  analyticMetrics.SUBMITTED_TASKS,
  analyticMetrics.COMPLETED_TASKS,
  analyticMetrics.NEW_HOMEOWNERS,
  analyticMetrics.ACTIVE_USERS,
  analyticMetrics.AVG_NUM_OF_TASKS,
  analyticMetrics.AVG_SIZE_OF_TASKS,
  analyticMetrics.INACTIVE_USERS,
  analyticMetrics.ACTIVE_PROS,
  analyticMetrics.INACTIVE_PROS
];
