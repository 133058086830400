import React from 'react';
import styles from 'constants/styles';

const IconCall = ({ size = 10.5, color = styles.colors.BLACK }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.12339 9.1894C1.98052 9.1894 2.82212 9.05534 3.61965 8.79178C4.01044 8.65849 4.49085 8.78077 4.72936 9.02573L6.30352 10.2141C8.12909 9.23956 9.25361 8.11541 10.2148 6.30354L9.06144 4.77039C8.76179 4.47114 8.65431 4.03401 8.78308 3.62385C9.04777 2.82213 9.18223 1.98093 9.18223 1.12343C9.18219 0.503964 9.68615 0 10.3056 0H12.8766C13.496 0 14 0.503964 14 1.12339C14 8.22365 8.22363 14 1.12339 14C0.503962 14 0 13.496 0 12.8766V10.3127C3.60489e-05 9.69336 0.503998 9.1894 1.12339 9.1894Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCall;
