import styled from 'styled-components';
import styles from 'constants/styles';
import { FieldTitle, FieldValue } from './commonStyled';

export const ActivateButton = styled.button`
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  max-width: 85px;
  color: ${styles.colors.RED};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${styles.colors.WHITE};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 1px 12px;
  height: 24px;
  min-width: 87px;
  div {
    margin: 0 auto;
  }
  &:hover:not([disabled]) {
    border: 1px solid ${styles.colors.RED};
  }
`;

export const AvailableRewards = styled.h3`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  margin-bottom: 9px;
  color: ${styles.colors.GRAY};
`;

export const AvatarRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0;
  align-items: center;
`;

export const BoldTextWrapper = styled.span`
  font-weight: 600;
`;

export const FullWidthSection = styled.div`
  width: 100%;
`;

export const AnalyticFieldTitle = styled(FieldTitle)`
  margin-bottom: 10px;
  width: ${({ width }) => width || 80}%;
`;

export const AnalyticFieldValue = styled(FieldValue)`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme, color }) => color || theme.colors.INVOICE_TEXT};
`;

export const InfoSectionHeader = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT}};

  margin-bottom: 8px;
`;

export const ModalText = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 12px;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const LatestVersionMark = styled(FieldValue)`
  display: inline;
  font-size: 12px;
  color: ${({ isLatest, theme }) => (isLatest ? theme.colors.DARK_GREEN : theme.colors.DARK_RED)};
  padding: ${({ padding }) => padding ?? '0 20px 0 0'};
`;

export const ReportFileName = styled.span`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  margin-right: 13px;
  &:hover {
    color: ${styles.colors.DARK_GREEN};
    cursor: pointer;
  }
`;

export const RewardsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RewardsWrapper = styled.div`
  padding: 0 45px 0 0;
`;

export const Section = styled.div`
  padding: 0 25px 20px;
`;

export const UserEmail = styled.div`
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  a {
    color: ${({ theme }) => theme.colors.DARK_GREY};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;

    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
`;

export const UserFullNameRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const UserPhone = styled.div`
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  a {
    color: ${({ theme }) => theme.colors.DARK_GREY};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;

    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
  margin: 3px 0;
`;
export const RemoveIcon = styled.div`
  position: absolute;
  top: -6px;
  right: 3px;
  z-index: 99;
  display: none;
  cursor: pointer;
`;
export const RewardWrapper = styled.div`
  position: relative;
  &:hover {
    ${RemoveIcon} {
      display: block;
    }
  }
`;

export const ProLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DARK_1};
  background: ${({ theme, isCore }) => (isCore ? theme.colors.DARK_YELLOW : theme.colors.WHITE)};
  padding: 4px 8px;
  border-radius: 4px;
  align-self: flex-start;
`;

export const userFullNameStyle = {
  fontSize: 20,
  color: styles.colors.DARK_GREEN
};

export const UserCheckbox = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const lineStyle = {
  marginTop: 24,
  marginBottom: 24
};

export const rewardStyle = {
  padding: '4px 28px 3px'
};

export const MainInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
`;
