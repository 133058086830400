import React from 'react';
import styles from 'constants/styles';

const IconArrowLeft = ({ width = 8, height = 14, color = styles.colors.WHITE }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66602 12.5858V1.41421C7.66602 0.523309 6.58888 0.0771402 5.95891 0.707105L0.373123 6.29289C-0.0174007 6.68342 -0.0174017 7.31658 0.373123 7.70711L5.95891 13.2929C6.58887 13.9229 7.66602 13.4767 7.66602 12.5858Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowLeft;
