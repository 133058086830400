import styled, { css } from 'styled-components';
import 'react-image-lightbox/style.css';

export const Wrapper = styled.div`
  overflow-x: overlay;
  padding-bottom: 4px;
  ${({ showScroll }) =>
    !showScroll &&
    ` -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x proximity;

  &::-webkit-scrollbar {
    display: none;
  }`}
`;

export const FlexView = styled.div`
  display: flex;
  float: left;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const Value = styled.p`
  font-size: 13px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.WHITE};
`;

const mediaPreviewCSS = css`
  height: 60px;
  width: 76px;
`;

export const MediaPreview = styled.div`
  ${mediaPreviewCSS}
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PlayButtonWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.BLACK}80;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border-radius: 8px;
  margin-right: 18px;

  &:not([disabled]) {
    cursor: pointer;
  }
`;

export const StyledReactPlayer = styled.video`
  ${mediaPreviewCSS}
  object-fit: cover;
`;
