import React from 'react';
import { getFullName } from 'common/helpers/user';

import styles from 'constants/styles';
import {
  LocationWrapper,
  SideBlock,
  DescriptionWrapper,
  FullName,
  PhoneNumber,
  Address,
  InviteNote,
  LocationCustomer,
  LocationPhoto,
  LocationPhotoImg
} from './styled';
import { formatPhone } from 'utils';

const Location = ({ openInstance, location: agentLocation, isActive }) => {
  const { user, user_id, location, note } = agentLocation;
  const { address, unit_number } = location || {};
  const full_name = getFullName(user || {});
  const phone_number = user?.phone_number || agentLocation?.phone_number;
  const textColor = styles.colors[isActive ? 'BLACK' : 'GRAY'];
  return (
    <LocationWrapper disabled={!user_id} onClick={user_id ? openInstance : null}>
      <SideBlock>
        {Boolean(isActive) && (
          <LocationPhoto>
            {user?.profile_image_url && <LocationPhotoImg src={user?.profile_image_url} alt="User" />}
          </LocationPhoto>
        )}
      </SideBlock>
      <DescriptionWrapper>
        {Boolean(full_name) && <FullName color={textColor}>{full_name}</FullName>}
        {Boolean(phone_number) && <PhoneNumber color={styles.colors.GRAY}>{formatPhone(phone_number)}</PhoneNumber>}
        {Boolean(address) && (
          <Address color={textColor}>
            {address}
            {unit_number ? ', Unit #' + unit_number : ''}
          </Address>
        )}
        {Boolean(note) && <InviteNote color={textColor}>Note: {note}</InviteNote>}
      </DescriptionWrapper>
      {Boolean(user_id) && (
        <SideBlock>
          <LocationCustomer>User ID {user_id}</LocationCustomer>
        </SideBlock>
      )}
    </LocationWrapper>
  );
};

export default Location;
