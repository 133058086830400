import styled from 'styled-components';
import styles from 'constants/styles';
import { Label } from 'components/Table/components/Cell/styled';

export const Table = styled.table`
  margin: 0 auto;
  padding: 0;
  height: fit-content;
  box-sizing: border-box;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const TableHead = styled.thead`
  position: relative;
  z-index: 1;
`;

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.TABLE_BG};
  width: 100%;
  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.TABLE_BG_HOVER};
    cursor: pointer;
    ${Label} {
      background-color: ${({ theme }) => theme.colors.DARK_4};
    }
  }

  ${({ hasRowHighlight, theme }) => hasRowHighlight && `background-color: ${theme.colors.TABLE_BG_HIGHLIGHT};`}
`;

export const HeadCell = styled.th`
  padding: 15px;
  font-size: 14px;
  line-height: 17px;
  background-color: ${({ theme }) => theme.colors.TABLE_BG};
  position: sticky;
  z-index: 1;
  top: -1px;
  color: ${styles.colors.GRAY};
  font-weight: 600;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  &:first-child {
    max-width: 84px;
    padding: 0 0 0 20px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.TABLE_BORDER};
  text-align: initial;
`;

export const HeaderCellInnerWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const TableCell = styled.td`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 14px;
  div > span > span {
    color: ${({ theme }) => theme.colors.TABLE_TEXT};
  }
  div > span {
    color: ${({ theme }) => theme.colors.TABLE_TEXT};
  }

  color: ${({ theme }) => theme.colors.TABLE_TEXT};

  font-weight: 400;
  line-height: 1.14;

  border-bottom: 1px solid ${({ theme }) => theme.colors.TABLE_BORDER};

  &:first-child {
    padding: 0 0 0 20px;
    ${'' /* text-align: center; */}
  }
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
`;

export const TableBody = styled.tbody``;
